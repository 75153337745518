import * as _ from 'lodash';

import { StageEnrollments } from './stage-enrollments';
import { LocationEnum } from '../../../../shared/entities/enums/location.enum';
import { Period } from '../../../../shared/entities/enums/period.enum';
import { Footnote } from '../../../../shared/components/footnote/entities/footnote';

export class Projection {
    location: LocationEnum;
    periods: Array<Period>;
    stagesEnrollments: Array<StageEnrollments>;
    totals: StageEnrollments;
    relativeProjection: StageEnrollments;
    sourceInformations: Array<Footnote>;
    currentOfferNote: Footnote;
    notesOutOfSchoolPopulation: Array<Footnote>;
    locationNote: Footnote;

    private _title: string;

    get title(): string {
        switch (this.location) {
            case LocationEnum.urban:
                this._title = 'Urbanas ';
                break;

            case LocationEnum.rural:
                this._title = 'Rurais ';
                break;

            default:
                break;
        }

        switch (_.first(this.periods)) {
            case Period.matutinal:
            case Period.vespertine:
                this._title += 'Diurnas';
                break;

            case Period.nocturnal:
                this._title += 'Noturnas';
                break;

            default:
                break;
        }

        return this._title;
    }

    constructor(init?: Partial<Projection>) {
        Object.assign(this, init);
        this._title = this.title;
    }

    toJSON(): any {
        const json = {};
        Object.keys(this).forEach(key => json[(key[0] !== '_' ? key : key.replace('_', ''))] = this[key]);
        return json;
    }
}
