import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { EnrollmentBySchoolLocation } from './enrollment-by-school-location';
import { PercentageTeacherCareer } from './percentage_teacher_career';

export class EnrollmentByStageSeriesBySchool extends NavigableComponentData {
    year: number;
    state_id: number;
    state_name: string;
    city_id: number;
    city_name: string;
    school_id: string;
    school_name: string;
    adm_dependency_id: number;
    adm_dependency_name: string;
    enrollmentBySchoolLocation: Array<EnrollmentBySchoolLocation>;
    hasEnrollment: boolean;
    percentageTeacherCareer: Array<PercentageTeacherCareer>;

    constructor(init?: Partial<EnrollmentByStageSeriesBySchool>) {
        super();
        Object.assign(this, init);
    }
}
