import { Component, OnInit } from '@angular/core';

import { FunctionalityRoute } from '../shared/entities/functionality/functionality-route';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  homeRoute: Array<string> = [FunctionalityRoute.homeRoute];

  constructor() {
  }

  ngOnInit() {
  }
}
