import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { HttpService } from '../../../../shared/services/http/http.service';
import { SessionService } from '../../../../shared/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class SourceInformationService {

  private readonly namespace: string = 'simulation.sourceinformation.';
  private readonly enrollment: string = `${this.namespace}enrollment`;
  private readonly school: string = `${this.namespace}school`;
  private readonly teacher: string = `${this.namespace}teacher`;
  private readonly classroom: string = `${this.namespace}classroom`;
  private readonly population: string = `${this.namespace}population`;
  private readonly pibPerCapita: string = `${this.namespace}pibpercapita`;
  private readonly idhm: string = `${this.namespace}idhm`;
  private readonly siope: string = `${this.namespace}siope`;
  private readonly infrastructure: string = `${this.namespace}infrastructure`;
  private readonly class: string = `${this.namespace}class`;
  private readonly cub: string = `${this.namespace}cub`;
  private readonly employees: string = `${this.namespace}employees`;
  private readonly transport: string = `${this.namespace}transport`;
  private readonly financial: string = `${this.namespace}financial`;
  private readonly dailyChargeAmount: string = `${this.namespace}daily_charge_amount`;

  private sourceInformationObservables: Array<Observable<any>>;

  constructor(private httpService: HttpService, private sessionService: SessionService) { }

  loadSourceInformations(): Observable<any> {
    const sourceInformationSessionKeys: Array<string> = this.getSourceInformationSessionKeys();

    this.sourceInformationObservables = new Array<Observable<any>>();

    for (const sourceInformationSessionKey of sourceInformationSessionKeys) {
      const partialUrl: string = sourceInformationSessionKey.replace(this.namespace, '');
      this.processSourceInformations(sourceInformationSessionKey, partialUrl);
    }

    if (this.sourceInformationObservables.length === 0) {
      return of(null);
    } else {
      return forkJoin(this.sourceInformationObservables);
    }
  }

  clearSourceInformationSession(): Observable<void> {
    return Observable.create((observer: { next: () => void; complete: () => void; }) => {
      this.sessionService.clear(this.namespace);

      observer.next();
      observer.complete();
    });
  }

  getSourceInformationSessionKeys(): Array<string> {
    return new Array<string>(
      this.enrollment,
      this.school,
      this.teacher,
      this.classroom,
      // this.population,
      this.pibPerCapita,
      this.idhm,
      // this.siope,
      this.infrastructure,
      this.class,
      // this.cub,
      this.employees,
      this.transport,
      // this.financial,
      this.dailyChargeAmount);
  }

  getEnrollmentSourceInformation(): string {
    return this.sessionService.getItem<string>(this.enrollment);
  }

  getSchoolSourceInformation(): string {
    return this.sessionService.getItem<string>(this.school);
  }

  getTeacherSourceInformation(): string {
    return this.sessionService.getItem<string>(this.teacher);
  }

  getClassroomSourceInformation(): string {
    return this.sessionService.getItem<string>(this.classroom);
  }

  getPopulationSourceInformation(): string {
    return this.sessionService.getItem<string>(this.population);
  }

  getPibPerCapitaSourceInformation(): string {
    return this.sessionService.getItem<string>(this.pibPerCapita);
  }

  getIdhmSourceInformation(): string {
    return this.sessionService.getItem<string>(this.idhm);
  }

  getIdhmPnudSourceInformation(): string {
    // Este é utilizado somente na tela 'select location'.
    return 'Atlas do Desenvolvimento Humano no Brasil/PNUD (com dados do Censo de 2010)';
  }

  getSiopeSourceInformation(): string {
    return this.sessionService.getItem<string>(this.siope);
  }

  getInfrastructureSourceInformation(): string {
    return this.sessionService.getItem<string>(this.infrastructure);
  }

  getClassSourceInformation(): string {
    return this.sessionService.getItem<string>(this.class);
  }

  getCubSourceInformation(): string {
    return this.sessionService.getItem<string>(this.cub);
  }

  getEmployeeSourceInformation(): string {
    return this.sessionService.getItem<string>(this.employees);
  }

  getTransportSourceInformation(): string {
    return this.sessionService.getItem<string>(this.transport);
  }

  getFinancialSourceInformation(): string {
    return this.sessionService.getItem<string>(this.financial);
  }

  getDailyChargeAmountSourceInformation(): string {
    return this.sessionService.getItem<string>(this.dailyChargeAmount);
  }

  getOutOfSchoolPopulationSourceInformation(): string {
    return 'Pnad contínua (suplemento educação)/IBGE';
  }

  private processSourceInformations(sourceInformationKey: string, partialUrl: string): void {
    const sourceInformationFromSession: string = this.sessionService.getItem<string>(sourceInformationKey);

    if (!sourceInformationFromSession || sourceInformationFromSession === '') {
      this.sourceInformationObservables.push(this.httpService.getApiEndpoint().pipe(
        switchMap(apiEndpoint => {
          return this.httpService.get<Array<any>>(`${apiEndpoint}/${partialUrl}/source`).pipe(
            map(sourceInformations => {
              const sourceInformation: any = _.first(sourceInformations);

              let source: string = '';

              if (sourceInformation) {
                source = sourceInformation.source;

                if (source && partialUrl !== 'pibpercapita') {
                  source = `${source.charAt(0).toLowerCase()}${source.slice(1)}`;
                }
              }

              this.sessionService.setItem(sourceInformationKey, source);
            }));
        })));
    }
  }

}
