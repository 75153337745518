import { CostDriveByLocation } from './cost-drive-by-location';

export class CostDrive {

  city_id: string;
  cityDescription: string;
  costDrivesByLocations: Array<CostDriveByLocation>;

  constructor(init?: Partial<CostDrive>) {
    Object.assign(this, init);
  }

}
