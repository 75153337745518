import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { TeacherNumberByStage } from './teacher-number-by-stage';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class TeacherNumber extends NavigableComponentData {

  resultForTeacherNumber: Array<TeacherNumberByStage>;
  years: Array<number>;
  yearCurrent: number;
  sourceInformation: Array<Footnote>;
  noteInfo: Array<Footnote>;

  constructor(init?: Partial<TeacherNumber>) {
    super();
    Object.assign(this, init);
  }

}
