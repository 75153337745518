import { LocationEnum } from '../../../../shared/entities/enums/location.enum';
import { Enrollment } from './enrollment';
import { StageEnrollments } from './stage-enrollments';

export class EnrollmentProjectionByLocation {

  id: number;
  description: string;
  totalCurrentOffersProjection: number;
  totalsEnrollmentProjection: Array<Enrollment>;
  stagesEnrollments: Array<StageEnrollments>;
  totalExistingClassrooms: number;

  constructor(init?: Partial<EnrollmentProjectionByLocation>) {
    Object.assign(this, init);
  }
}
