import { Component, OnInit, Type, Input, ComponentFactoryResolver, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { ConfirmComponent } from '../../../../shared/components/modal/confirm/confirm.component';
import { PqrService } from '../../../pqr/services/pqr.service';
import { ComponentHostDirective } from '../../../../shared/directives/component-host.directive';
import { BaseUnsubscribe } from '../../../../shared/entities/base/base-unsubscribe';
import { PqrComponentData } from '../../entities/base/pqr-component-data';

@Component({
  selector: 'app-pqr-info',
  templateUrl: './pqr-info.component.html',
  styleUrls: ['./pqr-info.component.scss']
})
export class PqrInfoComponent extends BaseUnsubscribe implements OnInit {

  @Input() componentType: Type<PqrComponentData>;
  @Output() copyData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('pqrModalViewer', { static: false }) pqrModalViewer: ModalDirective;
  @ViewChild(ComponentHostDirective, { static: false }) componentHost: ComponentHostDirective;

  dropdownIsOpen: boolean = false;

  private bsModalRef: BsModalRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private pqrService: PqrService,
    private bsModalService: BsModalService,
    private notificationService: NotificationService) {
    super();
  }

  ngOnInit() {
  }

  openPqrDropdown(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.dropdownIsOpen = true;
  }

  openPqrModalViewer(): void {
    this.pqrModalViewer.show();
    this.loadComponent();
  }

  closePqrModalViewer(): void {
    this.pqrModalViewer.hide();
  }

  requestCopyData(): void {
    this.bsModalRef = this.bsModalService.show(ConfirmComponent);
    this.bsModalRef.content.body = 'Ao restaurar os valores com base no PQR <strong>tudo que já foi informado será perdido</strong>. Deseja continuar?';
    this.bsModalRef.content.confirmed
      .subscribe(confirmed => {
        if (confirmed) {
          this.pqrService.getPqrData(this.componentType).pipe(
            takeUntil(this.unsubscribe))
            .subscribe(pqrData => {
              this.copyData.emit(pqrData);
              this.notificationService.showSuccess('O valores foram restaurados', 'PQR');
            });
        }
      });
  }

  private loadComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentType);
    const viewContainerRef = this.componentHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.pqrAdminMode = false;
    componentRef.instance.pqrMode = true;
    componentRef.instance.pqrModalMode = true;

    this.pqrService.getPqrData(this.componentType).pipe(
      takeUntil(this.unsubscribe))
      .subscribe(pqrData => componentRef.instance.setPqrData(pqrData));
  }

}
