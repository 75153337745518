import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import { UtilitiesService } from '../../../shared/services/utilities/utilities.service';
import { NavigableComponentService } from '../../shared/entities/base/navigable-component-service';
import { SimulationTime } from '../entities/simulation-time';
import { SessionService } from '../../../shared/services/session/session.service';
import { Functionality } from '../../../shared/entities/functionality/functionality';

@Injectable({
  providedIn: 'root'
})
export class SimulationTimeService implements NavigableComponentService {

  simulationTimeObserver: Observable<number>;

  private simulationTimeMessenger: Subject<number> = new Subject<number>();

  constructor(private sessionService: SessionService) {
    this.simulationTimeObserver = this.simulationTimeMessenger.asObservable();
  }

  getData(): Observable<SimulationTime> {
    const simulationTime: SimulationTime = new SimulationTime({ time: UtilitiesService.minSimulationTime });
    return of(simulationTime);
  }

  getSimulationTimes(): Observable<Array<number>> {
    const simulationTimes: Array<number> = new Array<number>();

    for (let i = 1; i <= UtilitiesService.maxSimulationTime; i++) {
      simulationTimes.push(i);
    }

    return of(simulationTimes);
  }

  notifySimulationTimeObservers(simulationTime: number): void {
    if (simulationTime === undefined) {
      const simulationTimeFromSession: SimulationTime = this.sessionService.getItem(Functionality.simulationTime.key);

      if (simulationTimeFromSession) {
        simulationTime = simulationTimeFromSession.time;
      } else {
        simulationTime = UtilitiesService.minSimulationTime;
      }
    }

    this.simulationTimeMessenger.next(simulationTime);
  }
}
