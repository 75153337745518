import { Shift } from './shift';

export class TeachingLoad {

  id: number;
  descriptionStage: string;
  shifts: Array<Shift>;

  constructor(init?: Partial<TeachingLoad>) {
    Object.assign(this, init);
  }

}
