import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { NumberStudentClassService } from './services/number-student-class.service';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { NumberStudentClass } from './entities/number-student-class';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

@Component({
  selector: 'app-number-student-class',
  templateUrl: './number-student-class.component.html',
  styleUrls: ['./number-student-class.component.scss']
})
export class NumberStudentClassComponent extends BaseNavigableComponent<NumberStudentClass> implements PqrComponentData {

  data: NumberStudentClass = new NumberStudentClass();
  functionality: FunctionalityInfo = Functionality.numberStudentClass;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = NumberStudentClassComponent;

  constructor(private numberStudentClassService: NumberStudentClassService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<NumberStudentClass> {
    return this.numberStudentClassService.getData();
  }

  processSpecificBehaviors(): void {
    if (!this.pqrMode) {
      this.numberStudentClassService.getDiagnostic(this.data).pipe(
        takeUntil(this.unsubscribe))
        .subscribe();
    }
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
    this.processSpecificBehaviors();
  }

  processValidation(): void {
    super.processValidation();

    for (const numberStudentClass of this.data.studentClasses) {
      if (isNaN(numberStudentClass.numberStudentClass) || numberStudentClass.numberStudentClass === null
        || numberStudentClass.numberStudentClass === undefined || numberStudentClass.numberStudentClass === 0) {
        this.inconsistencies.push(new Inconsistency({
          message: `O número de alunos por turma da etapa ${numberStudentClass.stageDescription} não é válida. Informe um valor de 1 a 40 alunos .`
        }));
      }
    }
  }

}
