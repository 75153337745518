import { NavigableComponentService } from '../../shared/entities/base/navigable-component-service';

export class SimulatorItem {

    key: string;
    service: NavigableComponentService;

    constructor(init?: Partial<SimulatorItem>) {
        Object.assign(this, init);
    }
}
