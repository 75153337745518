import { TotalizerStudentCostByShift } from './totalizer-student-cost-by-shift';

export class TotalizerStudentCostByLocation {

  id: number;
  description: string;
  totalizersStudentsCostByShifts: Array<TotalizerStudentCostByShift>;
  constructor(init?: Partial<TotalizerStudentCostByLocation>) {
    Object.assign(this, init);
  }

}
