import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { OfferGoalEnrollmentFullTime } from '../entities/offer-goal-enrollment-full-time';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { FullTime } from './../entities/full-time';

@Injectable({
  providedIn: 'root'
})
export class OfferGoalEnrollmentFullTimeService implements NavigableComponentService {

  constructor(
    private sessionService: SessionService,
    private httpService: HttpService,
    private currentYearService: CurrentYearService,
    private utilitiesService: UtilitiesService
  ) { }

  getData(): Observable<OfferGoalEnrollmentFullTime> {
    let offerGoalEnrollmentFullTime = new OfferGoalEnrollmentFullTime();
    offerGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.pqrKey);
    offerGoalEnrollmentFullTime.currentYear = this.currentYearService.getEnrollmentCurrentYear();
    offerGoalEnrollmentFullTime.sourceInformation = new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.enrollment });

    offerGoalEnrollmentFullTime.infoNote = new Footnote({
      indice: 2,
      note: 'São consideradas somente matrículas de escolas públicas. Não são consideradas matrículas de Atividade Complementar e/ou Atendimento ' +
        'Educacional Especializado (AEE). O cálculo do percentual de estudantes em tempo integral considera apenas os estudantes que estão em turmas com ' +
        'carga horária igual ou superior a 7 horas. Não são consideradas as matrículas de EJA e Educação Profissional.'
    });

    return (this.setFullTime(offerGoalEnrollmentFullTime));
    // issue 747
    // for (let j = 0; j < offerGoalEnrollmentFullTime.fullTime.length; j++) {
    //   offerGoalEnrollmentFullTime.fullTime[j].offerGoal = offerGoalEnrollmentFullTime.fullTime[j].diagnostic > offerGoalEnrollmentFullTime.fullTime[j].offerGoal ?
    //   offerGoalEnrollmentFullTime.fullTime[j].diagnostic : offerGoalEnrollmentFullTime.fullTime[j].offerGoal;
    // }

    // return of(offerGoalEnrollmentFullTime);
  }

  setFullTime(data: OfferGoalEnrollmentFullTime): Observable<OfferGoalEnrollmentFullTime> {

    const fullTimes: Array<FullTime> = new Array<FullTime>();

    for (let i = 0; i < data.fullTime.length; i++) {
      fullTimes.push(new FullTime({
        id: data.fullTime[i].id,
        stageDescription: data.fullTime[i].stageDescription,
        offerGoal: data.fullTime[i].offerGoal,
        diagnostic: undefined
      }));
    }

    return this.getEnrollmentT4().pipe(
      switchMap(enrollmentT4 => {
        return this.getDiagnostic().pipe(
          map(diagnostic => {
            for (let i = 0; i < diagnostic.length; i++) {
              for (let j = 0; j < fullTimes.length; j++) {
                if (fullTimes[j].id === diagnostic[i].education_level_short_id) {
                  const enrollmentStage = _.find(enrollmentT4, rEP => rEP.education_level_short_id === fullTimes[j].id);
                  fullTimes[j].diagnostic = enrollmentStage.total > 0 ?
                    ((diagnostic[i].total / enrollmentStage.total) * 100) : undefined;
                  // issue 747
                  fullTimes[j].offerGoal = fullTimes[j].diagnostic > fullTimes[j].offerGoal ?
                    fullTimes[j].diagnostic : fullTimes[j].offerGoal;

                }
              }
            }
            data.fullTime = fullTimes;
            return data;
          }));
      })
    );
  }

  getDiagnostic(): Observable<any> {

    let filtersLocation: Array<string> = new Array<string>();
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();

    filtersLocation = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string> = new Array<string>(
      `min_year:"${enrollmentCurrentYear}"`,
      `max_year:"${enrollmentCurrentYear}"`,
      `integral_time:1`,
      `education_level_short:["1","2","3","4","5"]`,
      'period_not:[99]'
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    filters = filters.concat(filtersLocation);

    const options: any = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['filter', filters.join(',')]]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/enrollment?dims=education_level_short,integral_time`, options).pipe(
          map(diagnostic => diagnostic));
      }));
  }

  getEnrollmentT4(): Observable<any> {
    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();

    let filters: Array<string> = new Array<string>(
      `min_year:"${enrollmentCurrentYear}"`,
      `max_year:"${enrollmentCurrentYear}"`,
      'period_not:[99]'
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    filters = filters.concat(filtersLocation);

    const options: any = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['filter', filters.join(',')]]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/enrollment?dims=education_level_short`, options).pipe(
          map(diagnosticT4 => diagnosticT4));
      }));
  }

}
