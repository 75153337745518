export class Role {

  id: number;
  description: string;
  flagOther: boolean;

  constructor(init?: Partial<Role>) {
    Object.assign(this, init);
  }

}
