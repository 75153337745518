import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { Location } from '../../../../shared/entities/location';
import { EnrollmentProjectionByLocation } from '../entities/enrollment-projection-by-location';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { StageEnrollments } from '../entities/stage-enrollments';
import { SerieEnrollments } from '../entities/serie-enrollments';
import { Enrollment } from '../entities/enrollment';
import { EnrollmentByStageSeries } from '../../enrollment-by-stage-series/entities/enrollment-by-stage-series';
import { SessionService } from '../../../../shared/services/session/session.service';
import { EnrollmentProjection } from '../entities/enrollment-projection';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { Projection } from '../../enrollment-by-stage-series/entities/projection';
import { Period } from '../../../../shared/entities/enums/period.enum';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentProjectionService implements NavigableComponentService {

  constructor(private sessionService: SessionService, private utilitiesService: UtilitiesService) { }

  getData(locations: Array<Location> = new Array<Location>(), projectionsReport: Array<Projection> = undefined): Observable<EnrollmentProjection> {

    let enrollmentProjection: EnrollmentProjection;
    const enrollmentsProjectionsByLocations: Array<EnrollmentProjectionByLocation> = new Array<EnrollmentProjectionByLocation>();
    let resultsEnrollmentByStageSeries: EnrollmentByStageSeries = new EnrollmentByStageSeries();

    if (!projectionsReport) {
      resultsEnrollmentByStageSeries = this.sessionService.getItem<EnrollmentByStageSeries>(Functionality.enrollmentByStageAndSeries.key);
    } else {
      resultsEnrollmentByStageSeries.projections = projectionsReport;
      resultsEnrollmentByStageSeries.years = this.utilitiesService.getSimulationYears(1);
    }

    enrollmentProjection = new EnrollmentProjection({
      years: resultsEnrollmentByStageSeries.years,
      offerYear: resultsEnrollmentByStageSeries.offerYear,
      enrollmentsProjectionsByLocations: new Array<EnrollmentProjectionByLocation>()
    });

    locations = locations.length > 0 ? locations : new Array<Location>(new Location({ description: 'Urbana e Rural' }));

    for (const location of locations) {

      enrollmentsProjectionsByLocations.push(new EnrollmentProjectionByLocation({
        id: location.id,
        description: location.description,
        totalCurrentOffersProjection: 0,
        totalsEnrollmentProjection: new Array<Enrollment>()
      }));

      const projectionsByLocations = location.id ? resultsEnrollmentByStageSeries.projections.filter(projections => projections.location === location.id)
        : resultsEnrollmentByStageSeries.projections;

      let totalEnrollmentsByStages: Array<Enrollment> = new Array<Enrollment>();
      const stagesEnrollments: Array<StageEnrollments> = new Array<StageEnrollments>();

      for (const projection of projectionsByLocations) {

        const period = _.head(projection.periods);

        for (const stage of projection.stagesEnrollments) {

          let elementStage = _.find(stagesEnrollments, s => s.id === stage.id);

          if (!elementStage) {
            stagesEnrollments.push(new StageEnrollments({
              id: stage.id,
              description: stage.description,
              totalCurrentOffers: stage.totalCurrentOffers,
              seriesEnrollments: new Array<SerieEnrollments>(),
              totalEnrollments: new Array<Enrollment>()
            }));
            elementStage = _.last(stagesEnrollments);
          } else {
            elementStage.totalCurrentOffers += stage.totalCurrentOffers;
          }

          for (const serie of stage.seriesEnrollments) {

            let elementSerie = _.find(elementStage.seriesEnrollments, se => se.id === serie.id);

            if (!elementSerie) {
              elementStage.seriesEnrollments.push(new SerieEnrollments({
                id: serie.id,
                description: serie.description,
                currentOffer: serie.currentOffer,
                enrollments: new Array<Enrollment>()
              }));
              elementSerie = _.last(elementStage.seriesEnrollments);
            } else {
              elementSerie.currentOffer += serie.currentOffer;
            }

            for (const enrollment of serie.enrollments) {

              const elementEnrollment = _.find(elementSerie.enrollments, en => en.year === enrollment.year);
              const elementEnrollmentStage = _.find(elementStage.totalEnrollments, tEn => tEn.year === enrollment.year);
              let enrollmentNocturnal: number = 0;

              if (period === Period.nocturnal) {
                enrollmentNocturnal = enrollment.quantity;
              }

              if (!elementEnrollment) {
                elementSerie.enrollments.push(new Enrollment({ year: enrollment.year, quantity: enrollment.quantity, quantityNocturnal: enrollmentNocturnal }));
              } else {
                elementEnrollment.quantity += enrollment.quantity;
                elementEnrollment.quantityNocturnal += enrollmentNocturnal;
              }

              if (!elementEnrollmentStage) {
                elementStage.totalEnrollments.push(new Enrollment({ year: enrollment.year, quantity: enrollment.quantity, quantityNocturnal: enrollmentNocturnal }));
              } else {
                elementEnrollmentStage.quantity += enrollment.quantity;
                elementEnrollmentStage.quantityNocturnal += enrollmentNocturnal;
              }
            }
          }
          totalEnrollmentsByStages = _.union(elementStage.totalEnrollments, totalEnrollmentsByStages);
        }
      }

      const enrollmentProjectionByLocation: EnrollmentProjectionByLocation = _.last(enrollmentsProjectionsByLocations);
      enrollmentProjectionByLocation.stagesEnrollments = stagesEnrollments;
      enrollmentProjectionByLocation.totalCurrentOffersProjection = _.sumBy(stagesEnrollments, s => { return s.totalCurrentOffers; });

      for (const year of enrollmentProjection.years) {
        enrollmentProjectionByLocation.totalsEnrollmentProjection.push(new Enrollment({
          year: year,
          quantity: totalEnrollmentsByStages
            .filter(enrollmentStage => enrollmentStage.year === year)
            .reduce((total, n) => n.quantity + total, 0),
          quantityNocturnal: totalEnrollmentsByStages
            .filter(enrollmentStage => enrollmentStage.year === year)
            .reduce((total, n) => n.quantityNocturnal + total, 0)
        }));
      }
    }

    enrollmentProjection.enrollmentsProjectionsByLocations = enrollmentsProjectionsByLocations;
    return of(enrollmentProjection);
  }

}
