import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { ItemCostExpenseReport } from './item-cost-expense-report';

export class ItemsCostExpensesReport extends NavigableComponentData {

  itemsCostExpensesReport: Array<ItemCostExpenseReport>;
  sourceInformationPQR: Footnote;

  constructor(init?: Partial<ItemsCostExpensesReport>) {
    super();
    Object.assign(this, init);
  }

}
