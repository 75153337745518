import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../shared/services/auth/auth.service';
import { User } from '../entities/user';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.authService.userLoggedIn().pipe(
      map(userLoggedIn => {
        if (userLoggedIn && next.routeConfig.path === 'new') {
          this.router.navigate(['/user', 'me'], { queryParamsHandling: 'merge' });
        } else if (!userLoggedIn && next.routeConfig.path === 'me') {
          this.router.navigate(['/user', 'new'], { queryParamsHandling: 'merge' });
        }
        return true;
      }));
  }
}
