export class CsvHeader {

    key: string;
    label: string;

    constructor(init?: Partial<CsvHeader>) {
        Object.assign(this, init);
    }

}
