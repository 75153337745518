import { Component, OnInit } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-about',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss']
})

export class PublicationsComponent implements OnInit {

  constructor(private matomoTracker: MatomoTracker) { }

  ngOnInit() {
    this.matomoTracker.setCustomUrl('/publications');
    this.matomoTracker.trackPageView();
  }

}
