import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { ViewEnrollmentByStageSeriesComponent } from './view-enrollment-by-stage-series/view-enrollment-by-stage-series.component';
import { EnrollmentByStageSeriesComponent } from './enrollment-by-stage-series/enrollment-by-stage-series.component';
import { CollaborationSchemeComponent } from './collaboration-scheme/collaboration-scheme.component';
import { AccessAndOfferComponent } from './access-and-offer/access-and-offer.component';
import { EnrollmentProjectionComponent } from './enrollment-projection/enrollment-projection.component';
import { EnrollmentByStageSeriesBySchoolComponent } from './enrollment-by-stage-series-by-school/enrollment-by-stage-series-by-school.component';
import { ResultEnrollmentProjectionComponent } from './result-enrollment-projection/result-enrollment-projection.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ViewEnrollmentByStageSeriesComponent,
    EnrollmentByStageSeriesComponent,
    AccessAndOfferComponent,
    CollaborationSchemeComponent,
    EnrollmentProjectionComponent,
    EnrollmentByStageSeriesBySchoolComponent,
    ResultEnrollmentProjectionComponent
  ]
})
export class AccessAndOfferModule { }
