import { Location } from '../../../../shared/entities/location';

export class ColumnReportByShift {

  id: number;
  description: string;

  constructor(init?: Partial<ColumnReportByShift>) {
    Object.assign(this, init);
  }
}
