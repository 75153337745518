import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { CollaborationSchemeService } from './services/collaboration-scheme.service';
import { CollaborationScheme } from './entities/collaboration-scheme';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';

@Component({
  selector: 'app-collaboration-scheme',
  templateUrl: './collaboration-scheme.component.html',
  styleUrls: ['./collaboration-scheme.component.scss']
})

export class CollaborationSchemeComponent extends BaseNavigableComponent<CollaborationScheme> {

  data: CollaborationScheme = new CollaborationScheme();
  functionality: FunctionalityInfo = Functionality.collaborationScheme;
  inconsistencies: Array<Inconsistency>;

  constructor(private colaborationSchemeService: CollaborationSchemeService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<CollaborationScheme> {
    return this.colaborationSchemeService.getData();
  }

  processValidation(): void {
    super.processValidation();

    let totalPercentage: number = 0;
    let municipalPercentage: number = 0;
    let statePercentage: number = 0;
    let federalPercentage: number = 0;

    for (const item of this.data.collaborationSchemeByStage) {

      if (!isNaN(item.municipalPercentage)) {
        municipalPercentage = item.municipalPercentage;
      }
      if (!isNaN(item.statePercentage)) {
        statePercentage = item.statePercentage;
      }
      if (!isNaN(item.federalPercentage)) {
        federalPercentage = item.federalPercentage;
      }

      totalPercentage = municipalPercentage + statePercentage + federalPercentage;

      if (item.numberCurrentPublicEnrollment === 0 && item.numberProposalPublicEnrollment === 0) {

        if (parseFloat(totalPercentage.toFixed(2)) > 0) {
          this.inconsistencies.push(new Inconsistency({
            message: `Divisão de responsabilidade pela oferta de matrículas não poderá ser informada na etapa ${item.stageDescription}
          quando o total de matriculas da rede pública e proposta for zero.`
          }));
        }
      } else {

        if (parseFloat(totalPercentage.toFixed(2)) !== 100 && parseFloat(totalPercentage.toFixed(2)) !== 0) {
          this.inconsistencies.push(new Inconsistency({
            message: `A soma dos porcentagens (federal + estadual + municipal) da etapa ${item.stageDescription}
          totaliza ${parseFloat(totalPercentage.toFixed(2))}%, os percentuais de cada etapa deverão totalizar 100%`
          }));
        }
      }

      municipalPercentage = 0;
      statePercentage = 0;
      federalPercentage = 0;
    }
  }
}
