import { ClassNumberBySerie } from './class-number-by-serie';
import { ClassNumberByYear } from './class-number-by-year';

export class ClassNumberByStage {

  id: number;
  description: string;
  classNumberBySerie: Array<ClassNumberBySerie>;
  classesNumberByYear: Array<ClassNumberByYear>;

  constructor(init?: Partial<ClassNumberByStage>) {
    Object.assign(this, init);
  }
}
