import { DemandClassRoom } from './demand-class-room';

export class DemandClassRoomByYear {

    year: number;
    demandClassRooms: Array<DemandClassRoom>;

    constructor(init?: Partial<DemandClassRoomByYear>) {
        Object.assign(this, init);
    }
}
