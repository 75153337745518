export class SchoolQuantityExisting {

    id: number;
    description: string;
    quantity: number;

    constructor(init?: Partial<SchoolQuantityExisting>) {
        Object.assign(this, init);
    }
}
