import { Component, OnInit } from '@angular/core';

import { Functionality } from '../../../shared/entities/functionality/functionality';

@Component({
  selector: 'app-access-and-offer',
  templateUrl: './access-and-offer.component.html',
  styleUrls: ['./access-and-offer.component.scss']
})
export class AccessAndOfferComponent implements OnInit {

  accessAndOfferName: string = Functionality.accessandoffer.name;

  constructor() { }

  ngOnInit() {
  }

}
