import { DescriptionValue } from './description-value';

export class AdmDependencyAndLocation {
    admDependencyDescription: string;
    urbanQuantity: number = 0;
    ruralQuantity: number = 0;
    totalQuantity: number = 0;

    constructor(init?: Partial<AdmDependencyAndLocation>) {
        Object.assign(this, init);
    }
}
