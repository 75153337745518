export class CareerLevel {
  sequence: string;
  denomination: string;
  quantity: number;
  totalTeacherFullPeriodCareer: number;
  totalTeacherPartialCareer: number;

  constructor(init?: Partial<CareerLevel>) {
    Object.assign(this, init);
  }
}
