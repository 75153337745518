import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { HttpService } from '../../shared/services/http/http.service';
import { Schooling } from '../entities/schooling';
import { Segment } from '../entities/segment';
import { State } from '../entities/state';
import { Role } from '../entities/role';
import { City } from '../entities/city';
import { User } from '../entities/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService: HttpService) { }

  getSchooling(): Observable<Array<Schooling>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/user/schooling`).pipe(
          map(schoolings => schoolings.map(schooling =>
            new Schooling({
              id: undefined,
              description: schooling,
              flagOther: this.getFlagOther(schooling, 'schooling')
            }))));
      }));
  }

  getSegment(): Observable<Array<Segment>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/user/segment`).pipe(
          map(segments => segments.map(segment =>
            new Segment({
              id: undefined,
              description: segment,
              flagOther: this.getFlagOther(segment, 'segment')
            }))));
      }));
  }

  getRole(segmentDescription: string): Observable<Array<Role>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/user/role`).pipe(
          map(roles => {
            for (const role of roles) {
              const rolesDescriptions = role[segmentDescription];

              if (rolesDescriptions) {
                return rolesDescriptions.map(roleDescription => new Role({ id: undefined, description: roleDescription, flagOther: this.getFlagOther(roleDescription, 'role') }));
              }
            }
          }));
      }));
  }

  getState(): Observable<Array<State>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/state`).pipe(
          map(states => states.map(state =>
            new State({
              id: state.id,
              description: state.name,
              abbreviation: state.abbreviation
            }))));
      }));
  }

  getCity(stateId: number): Observable<Array<City>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/city?filter=state:${stateId}`).pipe(
          map(cities => cities.map(city =>
            new City({
              value: city.id.toString(),
              label: city.name
            }))));
      }));
  }

  addUser(user: User): Observable<any> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.post<User>(`${apiEndpoint}/user`, user).pipe(
          map(result => result));
      }));
  }

  alterUser(user: User): Observable<any> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.put<User>(`${apiEndpoint}/user/${user._id}`, user).pipe(
          map(result => result));
      }));
  }

  getUser(): Observable<User> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<User>(`${apiEndpoint}/user/me`).pipe(
          map(user => user));
      }));
  }

  private getFlagOther(atributeValue: string, fieldName: string): boolean {

    if (fieldName === 'segment' && atributeValue === 'Outro [citar segmento]') {
      return true;
    } else if (fieldName === 'schooling') {
      if (atributeValue === 'Graduação' || atributeValue === 'Mestrado' || atributeValue === 'Doutorado') {
        return true;
      }
    } else if (fieldName === 'role' && atributeValue === 'Outro [citar função]') {
      return true;
    }
    return false;
  }
}
