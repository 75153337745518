import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { MatomoTracker } from 'ngx-matomo';

import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BudgetForecastReport } from './entities/budget-forecast-report';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { BudgetForecastReportService } from './services/budget-forecast-report.service';

@Component({
  selector: 'app-budget-forecast-report',
  templateUrl: './budget-forecast-report.component.html',
  styleUrls: ['./budget-forecast-report.component.scss']
})
export class BudgetForecastReportComponent extends BaseNavigableComponent<BudgetForecastReport> {

  data: BudgetForecastReport = new BudgetForecastReport();
  functionality: FunctionalityInfo = Functionality.budgetForecastReport;
  inconsistencies: Array<Inconsistency>;
  editSimulation: boolean = this.simulationLevel();

  constructor(private injector: Injector, private budgetForecastReportService: BudgetForecastReportService, private matomoTracker: MatomoTracker) {
    super(injector);

    this.matomoTracker.setCustomUrl('/simulator/results/budgetforecastreport');
    this.matomoTracker.trackPageView();
  }

  processData(): Observable<BudgetForecastReport> {
    return this.budgetForecastReportService.getBudgetForecastReport();
  }

  dataHasChanged(): boolean {
    return false;
  }

  downloadCsv(datas: Number): void {
    this.budgetForecastReportService.downloadCsv(this.data, datas);
  }

  simulationLevel(): boolean {
    return this.budgetForecastReportService.isSimulationCity();
  }

  getNextStepLabel(): string {
    return 'Finalizar';
  }
}
