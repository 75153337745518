import { ClassroomExistingByLocation } from './classroom-existing-by-location';

export class ClassroomExistingByCity {

  cityId: number;
  cityDescription: string;
  classroomExistingByLocations: Array<ClassroomExistingByLocation>;

  constructor(init?: Partial<ClassroomExistingByCity>) {
    Object.assign(this, init);
  }
}
