import { ExpenseByState } from './expense-by-state';
import { RevenueByState } from './revenue-by-state';
import { TotalizerStudentCostByYear } from '../../../shared/services/calculate-student-cost/entities/totalizer-student-cost-by-year';
import { TotalizerStudentCostByStage } from '../../../shared/services/calculate-student-cost/entities/totalizer-student-cost-by-stage';

export class FinancingFundsReport {

  stateId: number;
  stateDescription: string;
  stateAbbreviation: string;
  enrollmentTotal: number;
  totalizersStudentsCostByStages: Array<TotalizerStudentCostByStage>;
  totalizersStudentsCostByYearTotal: Array<TotalizerStudentCostByYear>;
  caqUf: number;
  revenue: RevenueByState;
  expense: ExpenseByState;
  unionComplementation: number;
  percentUnionComplementation: number;
  offerYear: number;
  simulationYearInitial: number;

  constructor(init?: Partial<FinancingFundsReport>) {
    Object.assign(this, init);
  }
}
