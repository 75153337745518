import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { SimulationType } from '../../../simulator/entities/enums/simulation-type.enum';
import { BaseUnsubscribe } from '../../../../shared/entities/base/base-unsubscribe';
import { SimulatorService } from '../../../simulator/services/simulator.service';
import { takeUntil } from 'rxjs/operators';
import { SelectLocationService } from '../../../select-location/services/select-location.service';
import { SimulationTimeService } from '../../../simulation-time/services/simulation-time.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';

@Component({
  selector: 'app-simulation-tag',
  templateUrl: './simulation-tag.component.html',
  styleUrls: ['./simulation-tag.component.scss']
})
export class SimulationTagComponent extends BaseUnsubscribe implements OnInit {

  simulationType: string;
  selectedLocation: string;
  simulationTime: string;

  constructor(
    private selectLocationService: SelectLocationService,
    private simulationTimeService: SimulationTimeService,
    private simulatorService: SimulatorService,
    private utilitiesService: UtilitiesService) {
    super();

    this.selectLocationService.selectedLocationObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(selectedLocation => this.selectedLocation = selectedLocation);

    this.selectLocationService.selectedSimulatioTypeObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(simulationType => this.simulationType = simulationType);

    this.simulationTimeService.simulationTimeObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(simulationTime => this.simulationTime = this.utilitiesService.getLiteralSimulationYear(simulationTime));

    this.simulatorService.simulationTypeObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(simulationType => {
        switch (simulationType) {
          case SimulationType.caq:
            this.simulationType = UtilitiesService.caqSimulationTagText;
            break;

          case SimulationType.planning:
            this.simulationType = UtilitiesService.planningSimulationTagText;
            break;

          case SimulationType.planningByStateSphereAdm:
            this.simulationType = UtilitiesService.planningByStateSphereAdmSimulationTagText;
            break;

          case SimulationType.planningByCitySphereAdm:
            this.simulationType = UtilitiesService.planningByCitySphereAdmSimulationTagText;
            break;

          case SimulationType.financing:
            this.simulationType = UtilitiesService.financingSimulationTagText;
            break;

          case SimulationType.financingFederatedEntitiesByStateSphereAdm:
            this.simulationType = UtilitiesService.financingFederatedEntitiesByStateSphereAdmSimulationTagText;
            break;

          case SimulationType.financingFederatedEntitiesByCitySphereAdm:
            this.simulationType = UtilitiesService.financingFederatedEntitiesByCitySphereAdmSimulationTagText;
            break;
          case SimulationType.financingFederatedEntitiesGroupByCityOrState:
            this.simulationType = UtilitiesService.financingFederatedEntitiesByCitySphereAdmSimulationTagText;
            break;            
        }
      });

    this.selectLocationService.notifySelectedLocationObservers(undefined);
    this.simulationTimeService.notifySimulationTimeObservers(undefined);
  }

  ngOnInit() {
  }

}
