export class ClassNumberByYear {

  year: number;
  classNumber: number;
  classNumberIntegral: number;
  classNumberDaytimePartial: number;
  classNumberNocturnal: number;
  classroomDemand: number;

  constructor(init?: Partial<ClassNumberByYear>) {
    Object.assign(this, init);
  }
}
