import { SerieEnrollments } from './serie-enrollments';
import { Enrollment } from './enrollment';

export class StageEnrollments {
  id: number;
  description: string;
  totalCurrentOffers: number;
  integralPercentage: number;
  seriesEnrollments: Array<SerieEnrollments>;
  totalEnrollments: Array<Enrollment>;

  constructor(init?: Partial<StageEnrollments>) {
    Object.assign(this, init);
  }
}
