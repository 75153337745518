import { NavigableComponentData } from './../../../shared/entities/base/navigable-component-data';
import { FinancingFederatedEntitiesReport } from './financing_federated-entities-report';

export class FinancingsFederatedsEntitiesReport extends NavigableComponentData {

  financingsFederatedsEntitiesReport: Array<FinancingFederatedEntitiesReport>;

  constructor(init?: Partial<FinancingsFederatedsEntitiesReport>) {
    super();
    Object.assign(this, init);
  }
}
