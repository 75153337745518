import { CareerLevelByYear } from './career-level-by-year';

export class TeacherNumberByStageCalc {

  id: number;
  description: string;
  careerLevelsByYear: Array<CareerLevelByYear>;

  constructor(init?: Partial<TeacherNumberByStageCalc>) {
    Object.assign(this, init);
  }

}
