import { ExpenseByYear } from './expense-by-year';
export class ExpenseByItem {

  description: string;
  currentOffer: number;
  expensesByYears: Array<ExpenseByYear>;

  constructor(init?: Partial<ExpenseByItem>) {
    Object.assign(this, init);
  }
}
