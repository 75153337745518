import { TeacherNumberByYear } from './teacher-number-by-year';

export class TeacherNumberByStage {

  id: number;
  stageDescription: string;
  currentNumber: number;
  auxiliarNumber: number;
  teacherNumberByYear: Array<TeacherNumberByYear>;

  constructor(init?: Partial<TeacherNumberByStage>) {
    Object.assign(this, init);
  }

}
