import { School } from './school';

export class SchoolByCity {

  cityId: number;
  schools: Array<School>;

  constructor(init?: Partial<SchoolByCity>) {
    Object.assign(this, init);
  }

}
