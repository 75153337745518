import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatomoTracker } from 'ngx-matomo';

import { NotificationService } from '../shared/services/notification/notification.service';
import { UtilitiesService } from '../shared/services/utilities/utilities.service';
import { HttpService } from '../shared/services/http/http.service';
import { BaseUnsubscribe } from '../shared/entities/base/base-unsubscribe';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends BaseUnsubscribe implements OnInit {

  fullName: string;
  email: string;
  message: string;

  private notificationTitle: string = 'Atenção!';

  constructor(private notificationService: NotificationService, private utilitiesService: UtilitiesService, private httpService: HttpService, private matomoTracker: MatomoTracker) {
    super();
  }

  ngOnInit() {
    this.matomoTracker.setCustomUrl('/contact');
    this.matomoTracker.trackPageView();
  }

  send() {
    if (this.contactIsValid()) {
      const postBody: any = {
        name: this.fullName,
        email: this.email,
        contents: this.message,
        origin: 'SIMCAQ'
      };

      this.httpService.post<any>(`${this.httpService.apiEndpointDadosEducacionaisHomV1}/message`, postBody).pipe(
        takeUntil(this.unsubscribe))
        .subscribe(
          () => {
            this.fullName = null;
            this.email = null;
            this.message = null;
            this.notificationService.showSuccess('Obrigado pelo seu contato!', 'Contato enviado com sucesso!');
          },
          error => {
            if (error && error.errorBody && error.errorBody.error && error.errorBody.text) {
              this.notificationService.showError(error.errorBody.text, error.errorBody.error);
            } else {
              this.notificationService.showError('Tente mais tarde!', 'Erro ao enviar o contato!');
            }
          });
    }
  }

  private contactIsValid(): boolean {
    if (!this.fullName || !this.email || !this.message) {
      this.notificationService.showError('Todos os campos devem ser informados!', this.notificationTitle);
      return false;
    }

    if (!this.utilitiesService.emailIsValid(this.email)) {
      this.notificationService.showError('Email inválido!', this.notificationTitle);
      return false;
    }

    return true;
  }

}
