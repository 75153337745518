import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { State } from '../entities/state';

@Component({
  selector: 'app-br-map',
  templateUrl: './br-map.component.html',
  styleUrls: ['./br-map.component.scss']
})
export class BrMapComponent implements OnInit {

  @Input() selectedState: State;
  @Output() selectedStateChange: EventEmitter<State> = new EventEmitter<State>();

  constructor() { }

  ngOnInit(): void {
  }

  selectState(selectedState: State) {
     if (this.selectedState && this.selectedState.value === selectedState.value) {
       this.selectedState = undefined;
     } else {
       this.selectedState = selectedState;
     }

     this.selectedStateChange.emit(this.selectedState);
  }
}
