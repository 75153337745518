import { StageEnrollments } from 'app/simulator/access-and-offer/enrollment-projection/entities/stage-enrollments';
import { BudgetForecastReportByYear } from './budget-forecast-report-by-year';

export class BudgetForecastReportByItem {

  idItem: number;
  description: string;
  currentOffer: number;
  potentialRevenue: number;
  expensesMDE: number;
  noteNumber: string;
  budgetsForecastReportByYears: Array<BudgetForecastReportByYear>;
  totalEnrollment: number;
  stagesEnrollments: Array<StageEnrollments>;
  variation: number;
  complementation: number;
  constructor(init?: Partial<BudgetForecastReportByItem>) {
    Object.assign(this, init);
  }
}
