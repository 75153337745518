import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { HttpService } from '../../../../shared/services/http/http.service';
import { SessionService } from '../../../../shared/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentYearService {
  private readonly max_year: number = 2020;
  private readonly namespace: string = 'simulation.currentyear.';
  private readonly enrollment: string = `${this.namespace}enrollment`;
  private readonly school: string = `${this.namespace}school`;
  private readonly teacher: string = `${this.namespace}teacher`;
  private readonly classroom: string = `${this.namespace}classroom`;
  private readonly population: string = `${this.namespace}population`;
  private readonly pibPerCapita: string = `${this.namespace}pibpercapita`;
  private readonly idhm: string = `${this.namespace}idhm`;
  private readonly siope: string = `${this.namespace}siope`;
  private readonly infrastructure: string = `${this.namespace}infrastructure`;
  private readonly class: string = `${this.namespace}class`;
  private readonly cub: string = `${this.namespace}cub`;
  private readonly employees: string = `${this.namespace}employees`;
  private readonly transport: string = `${this.namespace}transport`;
  private readonly financial: string = `${this.namespace}financial`;
  private readonly dailyChargeAmount: string = `${this.namespace}daily_charge_amount`;

  private currentYearObservables: Array<Observable<any>>;

  constructor(private httpService: HttpService, private sessionService: SessionService) { }

  loadCurrentYears(): Observable<any> {
    const currentYearSessionKeys: Array<string> = this.getCurrentYearSessionKeys();

    this.currentYearObservables = new Array<Observable<any>>();

    for (const currentYearSessionKey of currentYearSessionKeys) {
      const partialUrl: string = currentYearSessionKey.replace(this.namespace, '');
      this.processCurrentYears(currentYearSessionKey, partialUrl);
    }

    if (this.currentYearObservables.length === 0) {
      return of(null);
    } else {
      return forkJoin(this.currentYearObservables);
    }
  }

  clearCurrentYearsSession(): Observable<void> {
    return Observable.create((observer: { next: () => void; complete: () => void; }) => {
      this.sessionService.clear(this.namespace);

      observer.next();
      observer.complete();
    });
  }

  getCurrentYearSessionKeys(): Array<string> {
    return new Array<string>(
      this.enrollment,
      this.school,
      this.teacher,
      this.classroom,
      this.population,
      this.pibPerCapita,
      this.idhm,
      this.siope,
      this.infrastructure,
      this.class,
      this.cub,
      this.employees,
      this.transport,
      this.financial,
      this.dailyChargeAmount);
  }

  getEnrollmentCurrentYear(): number {
    if (this.sessionService.getItem<number>(this.enrollment) === null) {
      return this.max_year;
    } else {
      return (this.sessionService.getItem<number>(this.enrollment) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.enrollment);
    }
  }

  getSchoolCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.enrollment) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.enrollment);
  }

  getTeacherCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.teacher) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.teacher);
  }

  getClassroomCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.classroom) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.classroom);
  }

  getPopulationCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.population) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.population);
  }

  getPibPerCapitaCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.pibPerCapita) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.pibPerCapita);
  }

  getIdhmCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.idhm) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.idhm);
  }

  getIdhmPnudCurrentYear(): number {
    // Este é utilizado somente na tela 'select location'.
    return 2013;
  }

  getSiopeCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.siope) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.siope);
  }

  getInfrastructureCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.infrastructure) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.infrastructure);
  }

  getClassCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.class) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.class);
  }

  getCubCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.cub) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.cub);
  }

  getEmployeeCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.employees) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.employees);
  }

  getTransportCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.transport) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.transport);
  }

  getFinancialCurrentYear(): number {
    ////return (this.sessionService.getItem<number>(this.financial) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.financial);
    return this.sessionService.getItem<number>(this.financial);
  }

  getDailyChargeAmountCurrentYear(): number {
    return (this.sessionService.getItem<number>(this.dailyChargeAmount) > this.max_year) ? this.max_year : this.sessionService.getItem<number>(this.dailyChargeAmount);
  }

  getOutOfSchoolPopulationCurrentYear(): number {
    return this.sessionService.getItem<number>(this.population);
    ////2018
  }

  private processCurrentYears(currentYearKey: string, partialUrl: string): void {
    const currentYear: number = this.sessionService.getItem(currentYearKey);

    if (!currentYear || currentYear === 0) {
      this.currentYearObservables.push(this.httpService.getApiEndpoint().pipe(
        switchMap(apiEndpoint => {
          return this.httpService.get<Array<any>>(`${apiEndpoint}/${partialUrl}/years`).pipe(
            map(years => {
              const lastYear: number = this.getLastYear(years);
              this.sessionService.setItem(currentYearKey, lastYear);
            }));
        })));
    }
  }

  private getLastYear(years: Array<any>): number {
    const sortedYears: Array<any> = _.sortBy(years, [year => year.year]);
    return sortedYears && sortedYears.length > 0 ? _.last(sortedYears).year : 0;
  }

}
