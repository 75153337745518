export class CollaborationSchemesByStage {

    id: number;
    stageDescription: string;
    numberCurrentPublicEnrollment: number;
    numberProposalPublicEnrollment: number;
    federalPercentage: number;
    statePercentage: number;
    municipalPercentage: number;

    constructor(init?: Partial<CollaborationSchemesByStage>) {
        Object.assign(this, init);
    }
}
