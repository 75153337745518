import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { FullPriceListItem } from './full-price-list-item';

export class FullPriceList extends NavigableComponentData {

    fullPriceListItems: Array<FullPriceListItem> = new Array<FullPriceListItem>();

    constructor(init?: Partial<FullPriceList>) {
        super();
        Object.assign(this, init);
    }

}
