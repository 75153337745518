import { HttpService } from './../../shared/services/http/http.service';
import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { LoginComponent } from '../login.component';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private bsModalRef: BsModalRef;

  constructor(private bsModalService: BsModalService, private httpService: HttpService) { }

  openLogin(): void {
    this.bsModalRef = this.bsModalService.show(LoginComponent, { class: 'modal-sm' });
  }

}
