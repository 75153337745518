import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap, map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { HigherDemandClassroom } from '../../../shared/services/calculate-class-number/entities/higher-demand-classroom';
import { DemandClassRoom } from '../entities/demand-class-room';
import { NewRoomBuilding } from '../entities/new-room-building';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { DemandClassRoomByYear } from '../entities/demand-class-room-by-year';
import { DemandsClassroomsEnum } from '../entities/enums/demandsClassrooms.enum';
import { DemandClassRoomLocation } from '../entities/demand-class-room-location';
import { SessionService } from '../../../../shared/services/session/session.service';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { LocationEnum } from '../../../../shared/entities/enums/location.enum';
import { UnitOfMeasurementEnum } from '../../../../shared/entities/enums/unit-of-measurement.enum';
import { NewRoomsBuildings } from '../entities/new-rooms-buildings';
import { Location } from '../../../../shared/entities/location';
import { CalculateClassNumberService } from '../../../shared/services/calculate-class-number/calculate-class-number.service';
import { SelectLocation } from '../../../select-location/entities/select-location';
import { ClassroomExistingByLocation } from '../entities/classroom-existing-by-location';
import { ClassroomExistingByCity } from '../entities/classroom-existing-by-city';
import { NewRooms } from '../entities/new-rooms';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { EnrollmentProjectionByLocationService } from '../../../access-and-offer/enrollment-projection/services/enrollment-projection-by-location.service';
import { CurrentYearMonthService } from '../../../shared/services/current-year-month/current-year-month.service';
import { NumberDemandLocation } from '../entities/number-demand-location';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { EnrollmentBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/enrollment-by-school';
import { CreateProjectionsService } from 'app/simulator/shared/services/create-projections/create-projections.service';
import { EnrollmentProjection } from 'app/simulator/access-and-offer/enrollment-projection/entities/enrollment-projection';
import { isUndefined } from 'lodash';
import { EnrollmentAndClassAndClassroom } from 'app/simulator/results/financing-funds-report/entities/enrollment-and-class-and-classroom';

@Injectable({
  providedIn: 'root'
})
export class NewRoomBuildingService implements NavigableComponentService {

  constructor(
    private httpService: HttpService,
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService,
    private calculateClassNumberService: CalculateClassNumberService,
    private currentYearService: CurrentYearService,
    private enrollmentProjectionByLocationService: EnrollmentProjectionByLocationService,
    private currentYearMonthService: CurrentYearMonthService,
    private createProjectionsService: CreateProjectionsService
  ) { }

  getData(pqrMode: boolean = false): Observable<NewRoomsBuildings> {
    const resultForEnrollmentSchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);
    const observablesCommon: Array<Observable<any>> = new Array<Observable<any>>();
    const observablesCalculation: Array<Observable<any>> = new Array<Observable<any>>();
    let locations, stages, unitsOfMeasurement, priceCub;

    observablesCommon.push(this.utilitiesService.getLocations().pipe(
      map(resultLocations => locations = resultLocations)));

    observablesCommon.push(this.utilitiesService.getStages().pipe(
      map(resultStages => stages = resultStages)));

    observablesCommon.push(this.utilitiesService.getUnitOfMeasurement([UnitOfMeasurementEnum.alunoMes, UnitOfMeasurementEnum.m2]).pipe(
      map(resultUnitsOfMeasurement => unitsOfMeasurement = resultUnitsOfMeasurement)));

    if (!pqrMode) {
      observablesCommon.push(this.utilitiesService.getCub().pipe(
        map(resultPriceCub => priceCub = resultPriceCub)));
    }
    /*observablesCommon.push(this.getNewRoomBuildingItems().pipe(
      map(resultNewRooms => newRooms = resultNewRooms)));*/

    return forkJoin(observablesCommon).pipe(
      mergeMap(() => {

        if (!pqrMode) {
          const enrollmentsAndClassesAndClassroomsBySchool = this.getEnrollmentAndClassAndClassroom(resultForEnrollmentSchool);
          if (enrollmentsAndClassesAndClassroomsBySchool.length > 0) {
            for (let i = 0; i < enrollmentsAndClassesAndClassroomsBySchool.length; i++) {
              const enrollmentAndClasseAndClassroomBySchool = enrollmentsAndClassesAndClassroomsBySchool[i];
              const classroomExistingSchool = this.getClassroomExistingBySchool(enrollmentAndClasseAndClassroomBySchool.enrollmentProjection);
              const newRoomsBuildings: NewRoomsBuildings = new NewRoomsBuildings();

              observablesCalculation.push(this.getNewRoomBuildingItems().pipe(
                mergeMap(resultNewRooms => {
                  newRoomsBuildings.newRooms = resultNewRooms;
                  this.setUnitPriceAndReferenceDate(newRoomsBuildings.newRooms, priceCub);
                  return this.utilitiesService.getDemandConstruction().pipe(
                    mergeMap(resultDemandConstruction => {
                      return this.calculateClassNumberService.calculateClassroomNumber(locations, stages, undefined, classroomExistingSchool, enrollmentAndClasseAndClassroomBySchool).pipe(
                        mergeMap(higherDemandClassroom => {
                          return this.getDemandClassRoomsItems(locations, resultDemandConstruction, higherDemandClassroom).pipe(
                            map(demandClassRoomByYear => {
                              newRoomsBuildings.newRooms.demandClassRoomByYear = demandClassRoomByYear;
                              this.calculateClassroomsDistribution(resultNewRooms, demandClassRoomByYear, locations);
                              return newRoomsBuildings;
                            }));
                        }));
                    }));
                })));
            }
          } else {
            const newRoomsBuildings: NewRoomsBuildings = new NewRoomsBuildings();
            return this.getNewRoomBuildingItems().pipe(
              map(resultNewRooms => {
                newRoomsBuildings.newRooms = resultNewRooms;
                return newRoomsBuildings;
              }));
          }
        } else {
          const newRoomsBuildings: NewRoomsBuildings = new NewRoomsBuildings();
          return this.getNewRoomBuildingItems().pipe(
            map(resultNewRooms => {
              newRoomsBuildings.newRooms = resultNewRooms;
              return newRoomsBuildings;
            }));
        }

        return forkJoin(observablesCalculation).pipe(
          map(results => {
            const newRoomsBuildingsTotal: NewRoomsBuildings = this.getTotalNewRoomBuilding(results);
            newRoomsBuildingsTotal.locations = locations;
            newRoomsBuildingsTotal.sourceInformations = this.getSourceInformations();
            return newRoomsBuildingsTotal;
          }));
      }));

    /*return this.utilitiesService.getDemandConstruction().pipe(
      mergeMap(demandConstruction => {
        return this.utilitiesService.getUnitOfMeasurement([UnitOfMeasurementEnum.alunoMes, UnitOfMeasurementEnum.m2]).pipe(
          mergeMap(unitsOfMeasurement => {
            newRoomsBuildings.unitsOfMeasurement = unitsOfMeasurement;
            // On functionality process all informations.
            if (!pqrMode) {
              return this.utilitiesService.getLocations().pipe(
                mergeMap(locations => {
                  newRoomsBuildings.locations = locations;
                  return this.utilitiesService.getStages().pipe(
                    mergeMap(stages => {
                      if (resultForSelectLocation.selectedCity) {
                        return this.utilitiesService.getCub().pipe(
                          mergeMap(priceCub => {
                            return this.enrollmentProjectionByLocationService.getEnrollmentByStagesSeriesByLocation(locations).pipe(
                              mergeMap(() => {
                                return this.getNewRoomBuildingItems().pipe(
                                  mergeMap(newRooms => {
                                    newRoomsBuildings.newRooms = newRooms;
                                    this.setUnitPriceAndReferenceDate(newRoomsBuildings.newRooms, priceCub);
                                    return this.getClassroomExisting().pipe(
                                      mergeMap(classroomExistingByCity => {
                                        return this.calculateClassNumberService.calculateClassroomNumber(locations, stages, classroomExistingByCity).pipe(
                                          mergeMap(higherDemandClassroom => {
                                            return this.getDemandClassRoomsItems(locations, demandConstruction, higherDemandClassroom).pipe(
                                              map(demandClassRoomByYear => {
                                                newRoomsBuildings.newRooms.demandClassRoomByYear = demandClassRoomByYear;
                                                this.calculateClassroomsDistribution(newRooms, demandClassRoomByYear, locations);
                                                newRoomsBuildings.sourceInformations = this.getSourceInformations();
                                                return newRoomsBuildings;
                                              }));
                                          }));
                                      }));
                                  }));
                              }));
                          }));

                      } else {
                        return this.utilitiesService.getCub().pipe(
                          mergeMap(priceCub => {
                            return this.getNewRoomBuildingItems().pipe(
                              mergeMap(newRooms => {
                                newRoomsBuildings.newRooms = newRooms;
                                this.setUnitPriceAndReferenceDate(newRoomsBuildings.newRooms, priceCub);
                                return this.calculateClassNumberService.getClassroomNumberCalculated().pipe(
                                  mergeMap(higherDemandClassroom => {
                                    return this.getDemandClassRoomsItems(locations, demandConstruction, higherDemandClassroom).pipe(
                                      map(demandClassRoomByYear => {
                                        newRoomsBuildings.newRooms.demandClassRoomByYear = demandClassRoomByYear;
                                        this.calculateClassroomsDistribution(newRooms, demandClassRoomByYear, locations);
                                        newRoomsBuildings.sourceInformations = this.getSourceInformations();
                                        return newRoomsBuildings;
                                      }));
                                  }));
                              }));
                          }));
                      }
                    }));
                }));
            } else {
              // On full PQR only process few informations.
              return this.getNewRoomBuildingItems().pipe(
                map(newRooms => {
                  newRoomsBuildings.newRooms = newRooms;
                  return newRoomsBuildings;
                }));
            }
          }));
      }));*/
  }

  getNewRoomsBuildingsByState(locations: Array<Location>, demandConstruction: Array<DemandClassRoom>,
    enrollmentAndClassAndClassroomByState: EnrollmentAndClassAndClassroom): Observable<NewRoomsBuildings> {

    const newRoomsBuildings: NewRoomsBuildings = new NewRoomsBuildings();

    return this.getNewRoomBuildingItems().pipe(
      mergeMap(newRooms => {
        newRoomsBuildings.newRooms = newRooms;
        return this.getDemandClassRoomsItems(locations, demandConstruction, enrollmentAndClassAndClassroomByState.classNumber.higherDemandClassroom).pipe(
          map(demandClassRoomByYear => {
            this.calculateClassroomsDistribution(newRooms, demandClassRoomByYear, locations);
            return newRoomsBuildings;
          }));
      }));
  }

  getDemandClassRoomsItems(locations: Array<Location>, demandConstruction: Array<DemandClassRoom>, higherDemandClassroom: HigherDemandClassroom = undefined): Observable<DemandClassRoomByYear> {

    const demandsByClassroom: Array<DemandClassRoom> = demandConstruction;
    const demandClassRoomsByYear: DemandClassRoomByYear = new DemandClassRoomByYear();
    const classroomCurrentYear: number = this.currentYearService.getClassroomCurrentYear();

    demandsByClassroom.map(demandByClassroom => {
      demandByClassroom.quantityDemandClassRoomLocation = locations.map(location =>
        new DemandClassRoomLocation({
          location: location,
          value: this.getValueDemandClassRoomLocation(demandByClassroom.id, location.id, higherDemandClassroom)
        }));
    });

    demandClassRoomsByYear.demandClassRooms = demandsByClassroom;
    demandClassRoomsByYear.year = classroomCurrentYear;

    return of(demandClassRoomsByYear);

  }

  calculateClassroomsDistribution(newRooms: NewRooms, demandClassRoomByYear: DemandClassRoomByYear, locations: Array<Location>): void {

    const demandsClassroomsLocations: Array<DemandClassRoomLocation> = _.find(demandClassRoomByYear.demandClassRooms, d =>
      d.id === DemandsClassroomsEnum.ClassroomDemand).quantityDemandClassRoomLocation;
    let demandClassroomUrban: number;
    let demandClassroomRural: number;

    for (const demandClassroomLocation of demandsClassroomsLocations) {
      if (demandClassroomLocation.location.id === LocationEnum.urban) {
        demandClassroomUrban = demandClassroomLocation.value;
      }
      if (demandClassroomLocation.location.id === LocationEnum.rural) {
        demandClassroomRural = demandClassroomLocation.value;
      }
    }

    const numberClassroomRef: number = 1;
    const numberClassroomUrbanRef: number = 12;
    const numberClassroomRuralRef: number = 2;

    let numberClassroomUrban: number = undefined;
    let numberClassroomRural: number = 0;

    const demandClassroomUrbanDistribution: number = Math.trunc(demandClassroomUrban / numberClassroomUrbanRef);
    const demandClassroomRuralDistribution: number = Math.trunc(demandClassroomRural / numberClassroomRuralRef);

    for (let i = 0; i < newRooms.buildings.length; i++) {
      newRooms.buildings[i].numberDemandLocation = this.getNumberDemandLocation(locations);
    }

    let newRoomBuilding: NewRoomBuilding = _.find(newRooms.buildings, n => n.numberClassroom === numberClassroomUrbanRef);

    for (const demandLocation of newRoomBuilding.numberDemandLocation) {
      if (demandLocation.location.id === LocationEnum.urban) {
        demandLocation.value = demandClassroomUrbanDistribution > 0 ? demandClassroomUrbanDistribution : undefined;
        numberClassroomUrban = demandClassroomUrban - (demandClassroomUrbanDistribution * numberClassroomUrbanRef);
      }
    }

    newRoomBuilding = _.find(newRooms.buildings, n => n.numberClassroom === numberClassroomRuralRef);
    newRoomBuilding.numberDemandLocation = this.getNumberDemandLocation(locations);

    for (const demandLocation of newRoomBuilding.numberDemandLocation) {
      if (demandLocation.location.id === LocationEnum.rural) {
        demandLocation.value = demandClassroomRuralDistribution > 0 ? demandClassroomRuralDistribution : undefined;
        numberClassroomRural = demandClassroomRural - (demandClassroomRuralDistribution * numberClassroomRuralRef);
      }
    }

    newRoomBuilding = _.find(newRooms.buildings, n => n.numberClassroom === numberClassroomRef);
    newRoomBuilding.numberDemandLocation = this.getNumberDemandLocation(locations);

    for (const demandLocation of newRoomBuilding.numberDemandLocation) {

      if (demandLocation.location.id === LocationEnum.urban) {
        demandLocation.value = numberClassroomUrban > 0 ? numberClassroomUrban : undefined;
      }

      if (demandLocation.location.id === LocationEnum.rural) {
        demandLocation.value = numberClassroomRural > 0 ? numberClassroomRural : undefined;
      }
    }

  }

  setNewRoomBuilding(newRoomBuildingOld: Array<NewRoomBuilding>, newRoomBuildingActual: Array<NewRoomBuilding>): void {
    if (newRoomBuildingOld) {
      for (let i = 0; i < newRoomBuildingActual.length; i++) {
        if (newRoomBuildingOld[i]) {
          newRoomBuildingActual[i].numberDemandLocation = newRoomBuildingOld[i].numberDemandLocation;
          newRoomBuildingActual[i].unitPrice = newRoomBuildingOld[i].unitPrice;
        }
      }
    }
  }

  setUnitPriceAndReferenceDate(newRooms: NewRooms, priceCub: Array<any>): void {

    const yearsMonth = this.currentYearMonthService.getCubCurrentYearMonth();
    for (let i = 0; i < newRooms.buildings.length; i++) {
      newRooms.buildings[i].unitPrice = _.first(priceCub).preco;
      newRooms.buildings[i].referenceDate = _.first(yearsMonth).month + '/' + _.first(yearsMonth).year;
    }
  }

  getClassroomExisting(): Observable<Array<ClassroomExistingByCity>> {

    let filtersLocation: Array<string> = new Array<string>();
    const classroomsExistingByCities: Array<ClassroomExistingByCity> = new Array<ClassroomExistingByCity>();
    const classroomCurrentYear: number = this.currentYearService.getClassroomCurrentYear();

    filtersLocation = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string> = new Array<string>(
      `min_year:"${classroomCurrentYear}"`,
      `max_year:"${classroomCurrentYear}"`
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    filters = filters.concat(filtersLocation);

    const options: any = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['filter', filters.join(',')]]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/classroom?dims=city,location`, options).pipe(
          map(classrooms => {

            for (let i = 0; i < classrooms.length; i++) {
              const classroomExistingByCity: ClassroomExistingByCity = new ClassroomExistingByCity({
                cityId: classrooms[i].city_id,
                cityDescription: classrooms[i].city_name,
                classroomExistingByLocations: new Array<ClassroomExistingByLocation>(this.getClassroomExistingByLocation(classrooms[i]))
              });

              let classroomExistingByCityFound: ClassroomExistingByCity;

              for (let j = 0; j < classroomsExistingByCities.length; j++) {
                if (classrooms[i].city_id === classroomsExistingByCities[j].cityId) {
                  classroomExistingByCityFound = classroomsExistingByCities[j];
                  classroomsExistingByCities[j].classroomExistingByLocations.push(this.getClassroomExistingByLocation(classrooms[i]));
                  break;
                }
              }

              if (!classroomExistingByCityFound) {
                classroomsExistingByCities.push(classroomExistingByCity);
              }

            }

            return classroomsExistingByCities;

          }));
      }));
  }

  private getNewRoomBuildingItems(): Observable<NewRooms> {
    return of(this.sessionService.getItem<NewRooms>(Functionality.newRoomBuilding.pqrKey));
  }

  private getValueDemandClassRoomLocation(
    demandByClassroom_id: number,
    location_id: number,
    higherDemandClassroom: HigherDemandClassroom = undefined): number {

    if (demandByClassroom_id === DemandsClassroomsEnum.ClassroomsExisting) {

      for (let i = 0; i < higherDemandClassroom.higherDemandsClassroomsByLocations.length; i++) {
        if (higherDemandClassroom.higherDemandsClassroomsByLocations[i].location.id === location_id) {
          return higherDemandClassroom.higherDemandsClassroomsByLocations[i].demandClassroomExisting;
        }
      }

    } else if (demandByClassroom_id === DemandsClassroomsEnum.ClassroomRequired) {

      for (let i = 0; i < higherDemandClassroom.higherDemandsClassroomsByLocations.length; i++) {
        if (higherDemandClassroom.higherDemandsClassroomsByLocations[i].location.id === location_id) {
          return higherDemandClassroom.higherDemandsClassroomsByLocations[i].demandClassroomRequired;
        }
      }

    } else if (demandByClassroom_id === DemandsClassroomsEnum.ClassroomDemand) {

      for (let i = 0; i < higherDemandClassroom.higherDemandsClassroomsByLocations.length; i++) {
        if (higherDemandClassroom.higherDemandsClassroomsByLocations[i].location.id === location_id) {
          return higherDemandClassroom.higherDemandsClassroomsByLocations[i].demandNewClassroom;
        }
      }
    }

    return 0;
  }

  private getClassroomExistingByLocation(classrooms: any): ClassroomExistingByLocation {

    let classroomExistingByLocations: ClassroomExistingByLocation = new ClassroomExistingByLocation();

    classroomExistingByLocations = new ClassroomExistingByLocation({
      location: new Location({ id: classrooms.location_id, description: classrooms.location_name }),
      quantityClassroomExisting: classrooms.total
    });

    return classroomExistingByLocations;
  }

  private getNumberDemandLocation(locations: Array<Location>): Array<NumberDemandLocation> {

    const numberDemandsLocations: Array<NumberDemandLocation> = new Array<NumberDemandLocation>();

    for (let i = 0; i < locations.length; i++) {
      numberDemandsLocations.push(new NumberDemandLocation({
        location: locations[i]
      }));
    }
    return numberDemandsLocations;
  }

  private getSourceInformations(): Array<Footnote> {

    const footNotes: Array<Footnote> = new Array<Footnote>();

    footNotes.push(new Footnote({
      indice: 1,
      note: 'Quando há demanda por construção de novas escolas, o simulador sugere prédios com 12 salas, ' +
        'pois esse é o padrão mais comum entre as escolas brasileiras, segundo o Censo Escolar/INEP. ' +
        'Para essas escolas é estimada a oferta de 500 matrículas e um quadro de funcionários conforme parâmetros da tela “Quadro de funcionários e remuneração”'
    }));
    footNotes.push(new Footnote({
      indice: 2,
      note: 'Quando há demanda por construção de novas escolas, o simulador sugere prédios com 2 salas, ' +
        'pois esse é o padrão mais comum entre as escolas brasileiras, segundo o Censo Escolar/INEP. ' +
        'Para essas escolas é estimada a oferta de 50 matrículas e um quadro de funcionários conforme parâmetros da tela  “Quadro de funcionários e remuneração”'
    }));

    footNotes.push(new Footnote({
      indice: 3,
      note: 'A contagem do número de salas existentes considera apenas aquelas que estavam em escolas informados como ‘em atividade’ no ano do ' +
        'Censo, que funcionavam em prédio escolar e que tinham pelo menos uma matrícula de Ensino Regular, Educação de Jovens e Adultos (EJA) e/ou ' +
        'Educação Profissional. Para o cálculo do número de salas é utilizada, a partir de 2019, a variável ‘número de salas existentes dentro do prédio’.'
    }));
    return footNotes;
  }

  private getEnrollmentAndClassAndClassroom(enrollmentBySchool: EnrollmentBySchool): Array<EnrollmentAndClassAndClassroom> {

    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();

    for (let i = 0; i < enrollmentBySchool.enrollmentByStageSeriesBySchool.length; i++) {

      const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
        school_id: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].school_id,
        school_description: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].school_name,
        enrollmentProjection: this.createProjectionsService.getEnrollmentProjectionBySchool(enrollmentBySchool.enrollmentByStageSeriesBySchool[i]),
        hasEnrollment: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].hasEnrollment,
        percentageTeacherCareer: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].percentageTeacherCareer
      });

      enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);
    }
    return enrollmentAndClassAndClassrooms;
  }

  private getClassroomExistingBySchool(enrollmentProjection: EnrollmentProjection): ClassroomExistingByCity {

    const classroomsExistingBySchool: Array<ClassroomExistingByCity> = new Array<ClassroomExistingByCity>();
    const classroomExisting: ClassroomExistingByCity = new ClassroomExistingByCity({
      classroomExistingByLocations:
        new Array<ClassroomExistingByLocation>()
    });

    for (let i = 0; i < enrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {
      const enrollmentProjectionByLocation = enrollmentProjection.enrollmentsProjectionsByLocations[i];

      classroomExisting.classroomExistingByLocations.push(new ClassroomExistingByLocation({
        location:
          new Location({ id: enrollmentProjectionByLocation.id, description: enrollmentProjectionByLocation.description }),
        quantityClassroomExisting: enrollmentProjectionByLocation.totalExistingClassrooms
      }));

    }
    return classroomExisting;
  }

  private getTotalNewRoomBuilding(newRoomBuildings: Array<NewRoomsBuildings>): NewRoomsBuildings {

    let newRoomBuildingTotal: NewRoomsBuildings = new NewRoomsBuildings();

    for (let i = 0; i < newRoomBuildings.length; i++) {

      if (i === 0) {
        newRoomBuildingTotal = newRoomBuildings[i];
      } else {
        for (let j = 0; j < newRoomBuildings[i].newRooms.buildings.length; j++) {
          const newRoomTotal = _.find(newRoomBuildingTotal.newRooms.buildings, nRt => nRt.id === newRoomBuildings[i].newRooms.buildings[j].id);
          for (let k = 0; k < newRoomBuildings[i].newRooms.buildings[j].numberDemandLocation.length; k++) {
            const numberDemandLocationTotal = _.find(newRoomTotal.numberDemandLocation, nDl => nDl.location.id === newRoomBuildings[i].newRooms.buildings[j].numberDemandLocation[k].location.id);
            const quantity = numberDemandLocationTotal.value !== undefined ? numberDemandLocationTotal.value : 0;
            const quantitySchool = newRoomBuildings[i].newRooms.buildings[j].numberDemandLocation[k].value !== undefined ? newRoomBuildings[i].newRooms.buildings[j].numberDemandLocation[k].value : 0;
            numberDemandLocationTotal.value = quantity + quantitySchool;
            if (numberDemandLocationTotal.value === 0) {
              numberDemandLocationTotal.value = undefined;
            }
          }
        }

        for (let j = 0; j < newRoomBuildings[i].newRooms.demandClassRoomByYear.demandClassRooms.length; j++) {
          const demandClassRoomTotal = _.find(newRoomBuildingTotal.newRooms.demandClassRoomByYear.demandClassRooms, dCr =>
            dCr.id === newRoomBuildings[i].newRooms.demandClassRoomByYear.demandClassRooms[j].id);
          for (let k = 0; k < newRoomBuildings[i].newRooms.demandClassRoomByYear.demandClassRooms[j].quantityDemandClassRoomLocation.length; k++) {
            const demandClassRoomLocationSchool = newRoomBuildings[i].newRooms.demandClassRoomByYear.demandClassRooms[j].quantityDemandClassRoomLocation[k];
            const demandClassRoomLocationTotal = _.find(demandClassRoomTotal.quantityDemandClassRoomLocation, qDl => qDl.location.id === demandClassRoomLocationSchool.location.id);
            const quantityDemandClassRoom = demandClassRoomLocationTotal.value !== undefined ? demandClassRoomLocationTotal.value : 0;
            const quantityDemandClassRoomSchool = demandClassRoomLocationSchool.value !== undefined ? demandClassRoomLocationSchool.value : 0;
            demandClassRoomLocationTotal.value = quantityDemandClassRoom + quantityDemandClassRoomSchool;
          }
        }
      }

    }

    for (let i = 0; i < newRoomBuildingTotal.newRooms.demandClassRoomByYear.demandClassRooms.length; i++) {
      for (let j = 0; j < newRoomBuildingTotal.newRooms.demandClassRoomByYear.demandClassRooms[i].quantityDemandClassRoomLocation.length; j++) {
        const demand = newRoomBuildingTotal.newRooms.demandClassRoomByYear.demandClassRooms[i].quantityDemandClassRoomLocation[j];
        demand.value = parseFloat(demand.value.toFixed(0));
      }
    }

    return newRoomBuildingTotal;
  }

}
