export class OutOfSchoolPopulation {
    id: number;
    value: number;
    noteIndice: number;
    noteText: string;

    constructor(init?: Partial<OutOfSchoolPopulation>) {
        Object.assign(this, init);
    }
}
