import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { Simulation } from './entities/simulation';
import { SimulationsService } from './services/simulations.service';
import { BaseUnsubscribe } from '../../shared/entities/base/base-unsubscribe';
import { ConfirmComponent } from '../../shared/components/modal/confirm/confirm.component';
import { Functionality } from '../../shared/entities/functionality/functionality';

@Component({
  selector: 'app-simulations',
  templateUrl: './simulations.component.html',
  styleUrls: ['./simulations.component.scss']
})
export class SimulationsComponent extends BaseUnsubscribe implements OnInit {

  simulations: Array<Simulation>;

  private bsModalRef: BsModalRef;

  constructor(private simulationsService: SimulationsService, private router: Router, private bsModalService: BsModalService) {
    super();
  }

  ngOnInit(): void {
    this.getSimulations();
  }

  startNewSimulation(): void {
    if (this.existsCurrentSimulation()) {
      this.getConfirmStream('Ao iniciar uma nova simulação os dados da simulação corrente serão perdidos caso não tenham sido salvos. Deseja continuar?').pipe(
        takeUntil(this.unsubscribe))
        .subscribe(confirmed => {
          if (confirmed) {
            this.simulationsService.discardSimulation().pipe(
              takeUntil(this.unsubscribe))
              .subscribe(() => this.navigateToFirstStepOfSimulation());
          }
        });
    } else {
      this.navigateToFirstStepOfSimulation();
    }
  }

  loadSimulation(id: string): void {
    if (this.existsCurrentSimulation()) {
      this.getConfirmStream('Ao carregar uma simulação os dados da simulação corrente serão perdidos caso não tenham sido salvos. Deseja continuar?').pipe(
        takeUntil(this.unsubscribe))
        .subscribe(confirmed => {
          if (confirmed) {
            this.loadSimulationToSession(id);
          }
        });
    } else {
      this.loadSimulationToSession(id);
    }
  }

  saveSimulation(simulation: Simulation): void {
    this.simulationsService.saveSimulation(simulation).pipe(
      takeUntil(this.unsubscribe))
      .subscribe();
  }

  deleteSimulation(simulation: Simulation): void {
    this.getConfirmStream(`A simulação '${simulation.name}' será excluída. Deseja continuar?`).pipe(
      takeUntil(this.unsubscribe))
      .subscribe(confirmed => {
        if (confirmed) {
          this.simulationsService.deleteSimulation(simulation.id).pipe(
            takeUntil(this.unsubscribe))
            .subscribe(() => {
              _.remove(this.simulations, s => s.id === simulation.id);

              const currentSimulation: Simulation = this.simulationsService.getCurrentSimulation();

              if (currentSimulation && currentSimulation.id === simulation.id) {
                this.simulationsService.discardSimulation(true, false).pipe(
                  takeUntil(this.unsubscribe))
                  .subscribe();
              }
            });
        }
      });
  }

  sameCreatedAndUpdatedDateTime(createdAt: string, updatedAt: string): boolean {
    return createdAt.match(/(.*)\./)[1] === updatedAt.match(/(.*)\./)[1];
  }

  private getSimulations(): void {
    this.simulationsService.getSimulations().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(simulations => this.simulations = simulations);
  }

  private navigateToFirstStepOfSimulation(): void {
    this.router.navigate(Functionality.selectLocation.route, { queryParamsHandling: 'merge' });
  }

  private getConfirmStream(body: string): Observable<boolean> {
    this.bsModalRef = this.bsModalService.show(ConfirmComponent);
    this.bsModalRef.content.body = body;

    return this.bsModalRef.content.confirmed;
  }

  private loadSimulationToSession(id: string): void {
    this.simulationsService.loadSimulation(id).pipe(
      takeUntil(this.unsubscribe))
      .subscribe(() => this.navigateToFirstStepOfSimulation());
  }

  private existsCurrentSimulation(): boolean {
    const currentSimulation: Simulation = this.simulationsService.getCurrentSimulation();
    return currentSimulation ? true : false;
  }

}
