export class RevenueByState {

  stateId: number;
  taxes: number;
  potential: number;
  potentialPlusAgreement: number;
  fundeb: number;

  constructor(init?: Partial<RevenueByState>) {
    Object.assign(this, init);
  }
}
