import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError, Subject, Observable, of, empty } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { UtilitiesService } from '../utilities/utilities.service';
import { NotificationService } from '../notification/notification.service';
import { HttpService } from '../http/http.service';
import { SessionService } from '../session/session.service';
import { UserInfo } from './entities/user-info';
import { FunctionalityRoute } from '../../entities/functionality/functionality-route';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static readonly tokenSessionKey: string = 'auth.token';

  userLoggedObserver: Observable<boolean>;

  private userLoggedMessenger: Subject<boolean> = new Subject<boolean>();
  private readonly unauthorized: string = 'Unauthorized';
  private messagesMap: Map<string, string> = new Map<string, string>([
    ['Invalid resource owner credentials', 'Email ou senha inválido(s)'],
    [this.unauthorized, 'Operação não autorizada']
  ]);

  constructor(
    private sessionService: SessionService,
    private httpService: HttpService,
    private router: Router,
    private notificationService: NotificationService,
    private utilitiesService: UtilitiesService) {
    this.userLoggedObserver = this.userLoggedMessenger.asObservable();
  }

  login(username: string, password: string): Observable<void> {
    this.logout(false, false);

    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_id', '5a12ca99dffb72138e3f884e')
      .set('client_secret', 'FcmZp9bZpk8yxSJA');

    const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };

    // Get the auth toke.
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.post(`${apiEndpoint}/auth/token`, body.toString(), requestOptions).pipe(
          map((authInfo: any) => {
            this.sessionService.setItem(AuthService.tokenSessionKey, authInfo.access_token);
            this.userLoggedMessenger.next(true);

            return empty();
          }),
          map(() => {
            if (this.utilitiesService.isFullPqrRoute()) {
              this.router.navigate([FunctionalityRoute.homeRoute], { queryParamsHandling: 'merge' });
            } else if (this.router.url.indexOf('user') !== -1) {
              window.location.reload();
            }
          }),
          catchError((err: any) => {
            this.userLoggedMessenger.next(false);
            return this.getErrorMessage(err, 'Não foi possível fazer o login');
          }));
      }));
  }

  logout(redirect: boolean = true, notifyObservers: boolean = true): void {
    this.sessionService.removeItem(AuthService.tokenSessionKey);

    if (redirect) {
      this.router.navigate([FunctionalityRoute.homeRoute], { queryParamsHandling: 'merge' })
        .then(() => {
          if (notifyObservers) {
            this.userLoggedMessenger.next(false);
          }
        });
    } else {
      if (notifyObservers) {
        this.userLoggedMessenger.next(false);
      }
    }
  }

  getUserInfo(): Observable<UserInfo> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<any>(`${apiEndpoint}/user/me`).pipe(
          map(me => {
            const userInfo: UserInfo = new UserInfo({
              name: me.name,
              nickname: me.nickname,
              email: me.email,
              admin: me.admin,
              researcher: me.segment === 'Comunidade acadêmica' && me.role === 'Pesquisador/a'
            });

            return userInfo;
          }),
          catchError((err: any) => {
            const authToken: string = this.getAuthToken();

            this.logout(false);

            if (err && err.errorBody && err.errorBody === this.unauthorized) {
              if (authToken) {
                this.notificationService.showInfo('Sessão expirada');
              }
              return of(new UserInfo());
            } else {
              return this.getErrorMessage(err, 'Erro ao obter informações do usuário');
            }
          }));
      }));
  }

  userLoggedIn(): Observable<boolean> {
    return this.getUserInfo().pipe(
      map(userInfo => userInfo.email ? true : false));
  }

  getAuthToken(): string {
    return this.sessionService.getItem<string>(AuthService.tokenSessionKey);
  }

  getAuthErrorHandling(err: any): any {
    return () => {
      if (err && err.errorBody && err.errorBody === this.unauthorized) {
        this.notificationService.showError('Usuário não autorizado');
      } else {
        return throwError(err);
      }
    };
  }

  triggerUserLogged(): void {
    this.userLoggedMessenger.next(true);
  }

  private getErrorMessage(err: any, defaultMessage: string): Observable<any> {
    let message: string;

    if (err) {
      if (err.errorBody) {
        const errorBody = err.errorBody;

        if (errorBody.error_description) {
          message = errorBody.error_description;
        } else {
          message = errorBody;
        }
      } else {
        message = err;
      }
    }

    if (message) {
      message = this.messagesMap.get(message);
    }

    if (!message) {
      message = defaultMessage;
    }

    return throwError(message);
  }

}
