import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { NumberStudentClass } from '../entities/number-student-class';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { HttpService } from '../../../../shared/services/http/http.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';

@Injectable({
  providedIn: 'root'
})
export class NumberStudentClassService implements NavigableComponentService {

  constructor(private httpService: HttpService, private utilitiesService: UtilitiesService, private sessionService: SessionService, private currentYearService: CurrentYearService) { }

  getData(): Observable<NumberStudentClass> {
    let numberStudentClass: NumberStudentClass = new NumberStudentClass();
    numberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.pqrKey);
    numberStudentClass.currentYear = this.currentYearService.getEnrollmentCurrentYear();
    numberStudentClass.sourceInformation = new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.dailyChargeAmount });
    numberStudentClass.noteInfo = new Footnote({
      indice: 2,
      note: 'O cálculo da média do número de alunos por turma não considera matrículas em unidades socioeducativas/prisionais, ' +
        'hospitalares, de atendimento educacional especializado e atividade complementar.'
    });

    return of(numberStudentClass);
  }

  getDiagnostic(data: NumberStudentClass): Observable<any> {

    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string>;
    let options: any;

    filters = new Array<string>(
      `min_year:"${enrollmentCurrentYear}"`,
      `max_year:"${enrollmentCurrentYear}"`,
      `education_level_short:["1","2","3","4","5","6"]`,
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    filters = filters.concat(filtersLocation);

    options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['dims', 'education_level_short,location'],
      ['filter', filters.join(',')]
    ]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/class_count/count`, options).pipe(
          map(diagnostics => {
            for (let i = 0; i < diagnostics.length; i++) {
              const educationTypeId: number = diagnostics[i].education_level_short_id;
              const sc = _.find(data.studentClasses, el => el.id === educationTypeId);
              const scLocation = _.find(sc.studentsTeachersClass, stc => stc.location.id === diagnostics[i].location_id);
              if (scLocation !== undefined) {
                scLocation.diagnostic = diagnostics[i].average;
              }
            }
          }));
      }));
  }
}
