import { StudentCostByLocation } from './student-cost-by-location';

export class StudentCost {

  city_id: string;
  cityDescription: string;
  studentsCostByLocations: Array<StudentCostByLocation>;

  constructor(init?: Partial<StudentCost>) {
    Object.assign(this, init);
  }

}
