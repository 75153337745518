import { Component, OnInit } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';
import { formatDate } from '@angular/common';

@Component({
  selector: 'caq-result',
  templateUrl: './caq-result.component.html',
  styleUrls: ['./caq-result.component.scss']
})

export class CaqResultComponent implements OnInit {

  currentDate: string = ''
  textToCopy: string = ''

  constructor(private matomoTracker: MatomoTracker) { 
    const today = new Date();
    this.currentDate = formatDate(today, 'dd MMM. yyyy', 'pt-BR');
    this.textToCopy = `SIMULADOR de Custo-Aluno Qualidade: Valores do Custo-Aluno Qualidade (CAQ) para o Brasil, 2023. Disponível em: <https://simcaq.c3sl.ufpr.br/caq-result>. Acesso em: ${this.currentDate}.`;
  }

  ngOnInit() {
    this.matomoTracker.setCustomUrl('/caq-result');
    this.matomoTracker.trackPageView();
  }

  async copyTextToClipboard() {
    
    try {
      await navigator.clipboard.writeText(this.textToCopy);
    } catch (err) {
      console.error('Falha ao copiar o texto para a área de transferência', err);
    }
  }  

}
