import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Enrollment } from '../../../access-and-offer/enrollment-projection/entities/enrollment';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { StageEnrollments } from '../../../access-and-offer/enrollment-projection/entities/stage-enrollments';
import { EnrollmentProjection } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { EnrollmentProjectionByLocation } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection-by-location';
import { Stage } from './../../../../shared/entities/stage';
import { Location } from './../../../../shared/entities/location';
import { StageEnum } from 'app/shared/entities/enums/stage.enum';
import { SerieEnrollments } from 'app/simulator/access-and-offer/enrollment-projection/entities/serie-enrollments';
import { SessionService } from 'app/shared/services/session/session.service';
import { OfferGoalEnrollmentFullTime } from 'app/simulator/quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { EnrollmentBySchoolLocation } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/enrollment-by-school-location';
import { EnrollmentByStageSeriesBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/enrollment-by-stage-series-by-school';
import { StageEnrollmentsBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/stage-enrollments-by-school';
import { SerieEnrollmentsBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/serie-enrollments-by-school';

@Injectable({
  providedIn: 'root'
})
export class CreateProjectionsService {

  constructor(
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService) { }

  getEnrollmentProjection(datas: any): EnrollmentProjection {

    const enrollmentProjection: EnrollmentProjection = new EnrollmentProjection();
    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    enrollmentProjection.years = simulationsYear;
    enrollmentProjection.offerYear = datas.year;
    enrollmentProjection.enrollmentsProjectionsByLocations = this.getEnrollmentsProjectionsByLocations(datas.locations);

    return enrollmentProjection;
  }

  getEmptyEnrollmentProjection(locations: Array<Location>, stages: Array<Stage>) {
    const enrollmentProjection: EnrollmentProjection = new EnrollmentProjection();
    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    enrollmentProjection.years = simulationsYear;
    enrollmentProjection.enrollmentsProjectionsByLocations = this.getEmptyEnrollmentsProjectionsByLocations(locations, stages);

    return enrollmentProjection;
  }

  getEnrollmentProjectionBySchool(enrollmentByStageSeriesBySchool: EnrollmentByStageSeriesBySchool): EnrollmentProjection {

    const enrollmentProjection: EnrollmentProjection = new EnrollmentProjection();
    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    enrollmentProjection.years = simulationsYear;
    enrollmentProjection.offerYear = enrollmentByStageSeriesBySchool.year;
    enrollmentProjection.enrollmentsProjectionsByLocations = this.getEnrollmentsProjectionsBySchoolByLocations(enrollmentByStageSeriesBySchool.enrollmentBySchoolLocation);

    return enrollmentProjection;
  }

  private getEnrollmentsProjectionsByLocations(datasLocations: Array<any>): Array<EnrollmentProjectionByLocation> {

    const enrollmentsProjectionsByLocations: Array<EnrollmentProjectionByLocation> = new Array<EnrollmentProjectionByLocation>();

    for (let i = 0; i < datasLocations.length; i++) {

      const enrollmentProjectionByLocation: EnrollmentProjectionByLocation = new EnrollmentProjectionByLocation({
        id: datasLocations[i].location_id,
        description: datasLocations[i].location_name,
        totalsEnrollmentProjection: this.getEnrollments(datasLocations[i].education_level),
        stagesEnrollments: this.getStagesEnrollments(datasLocations[i].education_level),
        totalExistingClassrooms: datasLocations[i].total_classroom
      });

      enrollmentsProjectionsByLocations.push(enrollmentProjectionByLocation);

    }

    return enrollmentsProjectionsByLocations;
  }

  private getEnrollments(datasEducationLevels: Array<any>): Array<Enrollment> {

    const enrollments: Array<Enrollment> = new Array<Enrollment>();
    const simulationsYears: Array<number> = this.utilitiesService.getSimulationYears();
    let quantity: number = 0;
    let quantityNocturnal: number = 0;

    for (let i = 0; i < datasEducationLevels.length; i++) {
      quantity += datasEducationLevels[i].enrollment.total_enrollment_day + datasEducationLevels[i].enrollment.total_enrollment_night;
      quantityNocturnal += datasEducationLevels[i].enrollment.total_enrollment_night;
    }

    for (let i = 0; i < simulationsYears.length; i++) {

      enrollments.push(new Enrollment({
        year: simulationsYears[i],
        quantity: quantity,
        quantityNocturnal: quantityNocturnal
      }));

    }
    return enrollments;
  }

  private getStagesEnrollments(datasEducationLevels: Array<any>): Array<StageEnrollments> {

    const stageEnrollments: Array<StageEnrollments> = new Array<StageEnrollments>();
    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    const resultsForOfferGoalEnrollmentFullTime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.key);

    for (let i = 0; i < datasEducationLevels.length; i++) {

      const quantity = datasEducationLevels[i].enrollment.total_enrollment_day + datasEducationLevels[i].enrollment.total_enrollment_night;
      const quantityNocturnal = datasEducationLevels[i].enrollment.total_enrollment_night;

      /*const offerGoalEnrollmentIntegralByStage = _.find(resultsForOfferGoalEnrollmentFullTime.fullTime, offerGoal => offerGoal.id === datasEducationLevels[i].education_level_short_id);
      const offerGoalStage = offerGoalEnrollmentIntegralByStage ? offerGoalEnrollmentIntegralByStage.offerGoal : 0;*/
      let integralPercentageValue = datasEducationLevels[i].enrollment.integral_percentage;

      if (datasEducationLevels[i].education_level_short_id === StageEnum.eja) {
        integralPercentageValue = 0;
      }

      stageEnrollments.push(new StageEnrollments({
        id: datasEducationLevels[i].education_level_short_id,
        description: datasEducationLevels[i].education_level_short_name,
        integralPercentage: integralPercentageValue,
        seriesEnrollments: datasEducationLevels[i].education_level_short_id === StageEnum.creche ?
          this.getSeriesEnrollments(datasEducationLevels[i].classes_school_year, datasEducationLevels[i].education_level_short_id) : undefined,
        totalCurrentOffers: quantity,
        totalEnrollments: simulationsYear.map(yearSimulation =>
          new Enrollment({ year: yearSimulation, quantity: quantity, quantityNocturnal: quantityNocturnal }))
      }));
    }

    return stageEnrollments;
  }

  private getEmptyEnrollmentsProjectionsByLocations(locations: Array<Location>, stages: Array<Stage>): Array<EnrollmentProjectionByLocation> {

    const enrollmentsProjectionsByLocations: Array<EnrollmentProjectionByLocation> = new Array<EnrollmentProjectionByLocation>();

    for (let i = 0; i < locations.length; i++) {

      const enrollmentProjectionByLocation: EnrollmentProjectionByLocation = new EnrollmentProjectionByLocation({
        id: locations[i].id,
        description: locations[i].description,
        totalsEnrollmentProjection: this.getEmptyEnrollments(),
        stagesEnrollments: this.getEmptyStagesEnrollments(stages),
        totalCurrentOffersProjection: 0
      });

      enrollmentsProjectionsByLocations.push(enrollmentProjectionByLocation);

    }

    return enrollmentsProjectionsByLocations;
  }

  private getEmptyEnrollments(): Array<Enrollment> {

    const enrollments: Array<Enrollment> = new Array<Enrollment>();
    const simulationsYears: Array<number> = this.utilitiesService.getSimulationYears();

    simulationsYears.map(yearSimulation => enrollments.push(new Enrollment({ year: yearSimulation, quantity: 0, quantityNocturnal: 0 })));
    return enrollments;
  }

  private getEmptyStagesEnrollments(stages: Array<Stage>): Array<StageEnrollments> {

    const stageEnrollments: Array<StageEnrollments> = new Array<StageEnrollments>();
    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < stages.length; i++) {

      stageEnrollments.push(new StageEnrollments({
        id: stages[i].id,
        description: stages[i].description,
        totalCurrentOffers: 0,
        totalEnrollments: simulationsYear.map(yearSimulation =>
          new Enrollment({ year: yearSimulation, quantity: 0, quantityNocturnal: 0 }))
      }));
    }

    return stageEnrollments;
  }

  private getSeriesEnrollments(classesSchoolYear: Array<any>, id: number): Array<SerieEnrollments> {

    const seriesEnrollments: Array<SerieEnrollments> = new Array<SerieEnrollments>();
    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < classesSchoolYear.length; i++) {
      seriesEnrollments.push(new SerieEnrollments({
        id: classesSchoolYear[i].school_year_id,
        description: classesSchoolYear[i].school_year_name,
        enrollments: simulationsYear.map(yearSimulation =>
          new Enrollment({ year: yearSimulation, quantity: classesSchoolYear[i].total_enrollment_day, quantityNocturnal: classesSchoolYear[i].total_enrollment_night }))
      }));
    }
    return seriesEnrollments;
  }

  private getEnrollmentsProjectionsBySchoolByLocations(enrollmentBySchoolLocations: Array<EnrollmentBySchoolLocation>): Array<EnrollmentProjectionByLocation> {

    const enrollmentsProjectionsByLocations: Array<EnrollmentProjectionByLocation> = new Array<EnrollmentProjectionByLocation>();

    for (let i = 0; i < enrollmentBySchoolLocations.length; i++) {

      const enrollmentProjectionByLocation: EnrollmentProjectionByLocation = new EnrollmentProjectionByLocation({
        id: enrollmentBySchoolLocations[i].id,
        description: enrollmentBySchoolLocations[i].description,
        totalsEnrollmentProjection: this.getEnrollmentsSchool(enrollmentBySchoolLocations[i].stagesEnrollmentsByScholl),
        stagesEnrollments: this.getStagesEnrollmentsSchool(enrollmentBySchoolLocations[i].stagesEnrollmentsByScholl),
        totalExistingClassrooms: enrollmentBySchoolLocations[i].totalExistingClassrooms
      });

      enrollmentsProjectionsByLocations.push(enrollmentProjectionByLocation);

    }

    return enrollmentsProjectionsByLocations;
  }

  private getEnrollmentsSchool(stagesEnrollmentsByScholl: Array<StageEnrollmentsBySchool>): Array<Enrollment> {

    const enrollments: Array<Enrollment> = new Array<Enrollment>();
    const simulationsYears: Array<number> = this.utilitiesService.getSimulationYears();
    let quantity: number = 0;
    let quantityNocturnal: number = 0;

    for (let i = 0; i < stagesEnrollmentsByScholl.length; i++) {
      quantity += stagesEnrollmentsByScholl[i].totalEnrollmentDay + stagesEnrollmentsByScholl[i].totalEnrollmentNight;
      quantityNocturnal += stagesEnrollmentsByScholl[i].totalEnrollmentNight;
    }

    for (let i = 0; i < simulationsYears.length; i++) {

      enrollments.push(new Enrollment({
        year: simulationsYears[i],
        quantity: quantity,
        quantityNocturnal: quantityNocturnal
      }));

    }
    return enrollments;
  }

  private getStagesEnrollmentsSchool(stagesEnrollmentsByScholl: Array<StageEnrollmentsBySchool>): Array<StageEnrollments> {

    const stageEnrollments: Array<StageEnrollments> = new Array<StageEnrollments>();
    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < stagesEnrollmentsByScholl.length; i++) {

      const quantity = stagesEnrollmentsByScholl[i].totalEnrollmentDay + stagesEnrollmentsByScholl[i].totalEnrollmentNight;
      const quantityNocturnal = stagesEnrollmentsByScholl[i].totalEnrollmentNight;

      const offerGoalEnrollmentIntegralByStage = stagesEnrollmentsByScholl[i].percentageIntegral;
      let integralPercentageValue = offerGoalEnrollmentIntegralByStage ? offerGoalEnrollmentIntegralByStage : 0;

      if (stagesEnrollmentsByScholl[i].id === StageEnum.eja) {
        integralPercentageValue = 0;
      }

      stageEnrollments.push(new StageEnrollments({
        id: stagesEnrollmentsByScholl[i].id,
        description: stagesEnrollmentsByScholl[i].description,
        integralPercentage: integralPercentageValue,
        seriesEnrollments: stagesEnrollmentsByScholl[i].id === StageEnum.creche ?
          this.getSeriesEnrollmentsBySchool(stagesEnrollmentsByScholl[i].serieEnrollmentsBySchool, stagesEnrollmentsByScholl[i].id) : undefined,
        totalCurrentOffers: quantity,
        totalEnrollments: simulationsYear.map(yearSimulation =>
          new Enrollment({ year: yearSimulation, quantity: quantity, quantityNocturnal: quantityNocturnal }))
      }));
    }

    return stageEnrollments;
  }

  private getSeriesEnrollmentsBySchool(serieEnrollmentsBySchool: Array<SerieEnrollmentsBySchool>, id: number): Array<SerieEnrollments> {

    const seriesEnrollments: Array<SerieEnrollments> = new Array<SerieEnrollments>();
    const simulationsYear: Array<number> = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < serieEnrollmentsBySchool.length; i++) {
      seriesEnrollments.push(new SerieEnrollments({
        id: serieEnrollmentsBySchool[i].id,
        description: serieEnrollmentsBySchool[i].description,
        enrollments: simulationsYear.map(yearSimulation =>
          new Enrollment({ year: yearSimulation, quantity: serieEnrollmentsBySchool[i].totalEnrollmentDay, quantityNocturnal: serieEnrollmentsBySchool[i].totalEnrollmentNight }))
      }));
    }
    return seriesEnrollments;
  }
}
