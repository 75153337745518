import { UserGuard } from './guard/user.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivateChild } from '@angular/router';

import { UserNewComponent } from './user-new/user-new.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserComponent } from './user.component';

const userRoutes: Routes = [
  {
    path: 'user', component: UserComponent, canActivateChild: [UserGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'new' },
      { path: 'new', component: UserNewComponent },
      { path: 'me', component: UserEditComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(userRoutes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
