import { EnrollmentProjectionByLocation } from './enrollment-projection-by-location';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class EnrollmentProjection extends NavigableComponentData {

  years: Array<number>;
  offerYear: number;
  enrollmentsProjectionsByLocations: Array<EnrollmentProjectionByLocation>;

  constructor(init?: Partial<EnrollmentProjection>) {
    super();
    Object.assign(this, init);
  }
}
