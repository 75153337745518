import { FunctionalityRoute } from './functionality-route';
import { FunctionalityKey } from './functionality-key.enum';
import { FunctionalityInfo } from './functionality-info';

export class Functionality {

  static selectLocation: FunctionalityInfo = new FunctionalityInfo({
    name: 'Plano educacional',
    subtitle: 'Selecione a localidade e o nível do plano educacional',
    key: FunctionalityKey.selectLocation,
    route: FunctionalityRoute.selectLocation,
    nextStep: FunctionalityRoute.simulationTime
  });

  static simulationTime: FunctionalityInfo = new FunctionalityInfo({
    name: 'Período da simulação',
    subtitle: 'Informe a duração do plano educacional em número de anos.',
    key: FunctionalityKey.simulationTime,
    route: FunctionalityRoute.simulationTime,
    nextStep: FunctionalityRoute.viewEnrollmentByStageAndSeries
  });

  static accessandoffer: FunctionalityInfo = new FunctionalityInfo({
    name: 'Acesso e oferta educacional',
    route: FunctionalityRoute.accessAndOffer,
    mainStep: true
  });

  static viewEnrollmentByStageAndSeries: FunctionalityInfo = new FunctionalityInfo({
    name: 'Diagnóstico de matrícula',
    subtitle: 'População fora da escola ({outOfSchoolPopulationCurrentYear}) e características da matrícula ({enrollmentCurrentYear}).',
    key: FunctionalityKey.viewEnrollmentByStageAndSeries,
    route: FunctionalityRoute.viewEnrollmentByStageAndSeries,
    previousStep: FunctionalityRoute.simulationTime,
    nextStep: FunctionalityRoute.enrollmentByStageAndSeriesByShool,
    requiredData: new Array<FunctionalityInfo>(Functionality.selectLocation)
  });

  static enrollmentByStageAndSeries: FunctionalityInfo = new FunctionalityInfo({
    name: 'Projeção da oferta de matrículas',
    subtitle: 'Informe o número de matrículas a ser ofertado em cada ano/série por turno e área da localidade.',
    key: FunctionalityKey.enrollmentByStageAndSeries,
    route: FunctionalityRoute.enrollmentByStageAndSeries,
    previousStep: FunctionalityRoute.viewEnrollmentByStageAndSeries,
    nextStep: FunctionalityRoute.enrollmentProjection,
    requiredData: new Array<FunctionalityInfo>(Functionality.selectLocation, Functionality.simulationTime)
  });

  static enrollmentProjection: FunctionalityInfo = new FunctionalityInfo({
    name: 'Resultado da projeção de matrículas',
    key: FunctionalityKey.enrollmentProjection,
    route: FunctionalityRoute.enrollmentProjection,
    previousStep: FunctionalityRoute.enrollmentByStageAndSeries,
    nextStep: FunctionalityRoute.enrollmentByStageAndSeriesByShool,
    requiredData: new Array<FunctionalityInfo>(Functionality.selectLocation, Functionality.simulationTime)
  });

  static enrollmentByStageAndSeriesBySchool: FunctionalityInfo = new FunctionalityInfo({
    name: 'Projeção da oferta de matrículas por escola',
    subtitle: 'Informe o número de matrículas a ser ofertado em cada escola.',
    key: FunctionalityKey.enrollmentByStageAndSeriesBySchool,
    route: FunctionalityRoute.enrollmentByStageAndSeriesByShool,
    previousStep: FunctionalityRoute.viewEnrollmentByStageAndSeries,
    nextStep: FunctionalityRoute.resultEnrollmentProjection,
    requiredData: new Array<FunctionalityInfo>(Functionality.selectLocation, Functionality.simulationTime)
  });

  static resultEnrollmentProjection: FunctionalityInfo = new FunctionalityInfo({
    name: 'Resultado da Projeção de Matrícula',
    key: FunctionalityKey.resultEnrollmentProjection,
    route: FunctionalityRoute.resultEnrollmentProjection,
    previousStep: FunctionalityRoute.enrollmentByStageAndSeriesByShool,
    nextStep: FunctionalityRoute.collaborationScheme,
    requiredData: new Array<FunctionalityInfo>(Functionality.selectLocation, Functionality.simulationTime, Functionality.enrollmentByStageAndSeriesBySchool)
  });

  static collaborationScheme: FunctionalityInfo = new FunctionalityInfo({
    name: 'Divisão de responsabilidade pela oferta',
    subtitle: 'Valide ou altere a divisão de responsabilidade pela oferta de matrículas.',
    key: FunctionalityKey.collaborationScheme,
    route: FunctionalityRoute.collaborationScheme,
    previousStep: FunctionalityRoute.resultEnrollmentProjection,
    nextStep: FunctionalityRoute.schoolDayPerWeek,
    requiredData: new Array<FunctionalityInfo>(
      Functionality.selectLocation,
      Functionality.simulationTime,
      // Functionality.viewEnrollmentByStageAndSeries,
      // Functionality.enrollmentByStageAndSeries,
      Functionality.resultEnrollmentProjection,
      Functionality.enrollmentByStageAndSeriesBySchool,
      Functionality.enrollmentProjection
    )
  });

  static qualityconditions: FunctionalityInfo = new FunctionalityInfo({
    name: 'Condições de qualidade',
    route: FunctionalityRoute.qualityConditions,
    mainStep: true
  });

  static schoolDayPerWeek: FunctionalityInfo = new FunctionalityInfo({
    name: 'Número de dias letivos',
    subtitle: 'Informe o número de dias letivos por semana em cada etapa/modalidade.',
    key: FunctionalityKey.schoolDayPerWeek,
    route: FunctionalityRoute.schoolDayPerWeek,
    previousStep: FunctionalityRoute.collaborationScheme,
    nextStep: FunctionalityRoute.dailyTeachingLoad,
    hasPqr: true
  });

  static dailyTeachingLoad: FunctionalityInfo = new FunctionalityInfo({
    name: 'Carga horária de ensino',
    subtitle: 'Informe a duração dos turnos das aulas.',
    key: FunctionalityKey.dailyTeachingLoad,
    route: FunctionalityRoute.dailyTeachingLoad,
    previousStep: FunctionalityRoute.schoolDayPerWeek,
    nextStep: FunctionalityRoute.numberStudentClass,
    hasPqr: true
  });

  static numberStudentClass: FunctionalityInfo = new FunctionalityInfo({
    name: 'Tamanho das turmas',
    subtitle: 'Informe o número de alunos por turma em cada etapa/modalidade.',
    key: FunctionalityKey.numberStudentClass,
    route: FunctionalityRoute.numberStudentClass,
    previousStep: FunctionalityRoute.dailyTeachingLoad,
    nextStep: FunctionalityRoute.careerAndRemunerationTeachers,
    hasPqr: true
  });

  static careerAndRemunerationTeachers: FunctionalityInfo = new FunctionalityInfo({
    name: 'Formação, carreira e remuneração dos professores',
    subtitle: 'Informe os níveis do plano de carreira e o percentual de professores em cada nível com a respectiva formação e remuneração.',
    key: FunctionalityKey.careerAndRemunerationTeachers,
    route: FunctionalityRoute.careerAndRemunerationTeachers,
    previousStep: FunctionalityRoute.numberStudentClass,
    nextStep: FunctionalityRoute.teacherNumber,
    // requiredData: new Array<FunctionalityInfo>(Functionality.simulationTime, Functionality.teacherNumber),
    requiredData: new Array<FunctionalityInfo>(Functionality.simulationTime),
    hasPqr: true
  });

  static teacherNumber: FunctionalityInfo = new FunctionalityInfo({
    name: 'Número de professores por etapa',
    subtitle: 'Valores calculados pelo simulador a partir de parâmetros informados.',
    key: FunctionalityKey.teacherNumber,
    route: FunctionalityRoute.teacherNumber,
    previousStep: FunctionalityRoute.careerAndRemunerationTeachers,
    nextStep: FunctionalityRoute.schoolsStaff,
    requiredData: new Array<FunctionalityInfo>(
      Functionality.selectLocation,
      Functionality.simulationTime,
      Functionality.enrollmentByStageAndSeries,
      Functionality.schoolDayPerWeek,
      Functionality.dailyTeachingLoad,
      // Functionality.offerGoalEnrollmentFullTime,
      Functionality.numberStudentClass,
      Functionality.careerAndRemunerationTeachers
    )
  });

  static schoolsStaff: FunctionalityInfo = new FunctionalityInfo({
    name: 'Quadro de funcionários e remuneração',
    subtitle: 'Informe a composição do quadro de funcionário das escolas.',
    key: FunctionalityKey.schoolsStaff,
    route: FunctionalityRoute.schoolsStaff,
    previousStep: FunctionalityRoute.teacherNumber,
    nextStep: FunctionalityRoute.staffNumber,
    hasPqr: true
  });

  static staffNumber: FunctionalityInfo = new FunctionalityInfo({
    name: 'Número de funcionários por função',
    subtitle: 'Valores calculados pelo simulador a partir de parâmetros informados.',
    key: FunctionalityKey.staffNumber,
    route: FunctionalityRoute.staffNumber,
    previousStep: FunctionalityRoute.schoolsStaff,
    nextStep: FunctionalityRoute.infrastructureSchoolBuildings,
    requiredData: new Array<FunctionalityInfo>(
      Functionality.selectLocation,
      Functionality.simulationTime,
      Functionality.enrollmentProjection,
      Functionality.schoolsStaff
    )
  });

  static infrastructureSchoolBuildings: FunctionalityInfo = new FunctionalityInfo({
    name: 'Infraestrutura dos prédios escolares',
    subtitle: 'Diagnóstico e necessidade de adequação das dependências e espaços dos prédios escolares.',
    key: FunctionalityKey.infrastructureSchoolBuildings,
    route: FunctionalityRoute.infrastructureSchoolBuildings,
    previousStep: FunctionalityRoute.staffNumber,
    nextStep: FunctionalityRoute.newRoomBuilding,
    requiredData: new Array<FunctionalityInfo>(Functionality.selectLocation),
    hasPqr: true
  });

  static newRoomBuilding: FunctionalityInfo = new FunctionalityInfo({
    name: 'Novas salas de aulas e prédios escolares',
    subtitle: 'Demanda por novas salas calculada a partir de parâmetros informados. Informe o número de construções de acordo com o tamanho de prédio pretendido.',
    key: FunctionalityKey.newRoomBuilding,
    route: FunctionalityRoute.newRoomBuilding,
    previousStep: FunctionalityRoute.infrastructureSchoolBuildings,
    nextStep: FunctionalityRoute.administrativeAreaCosting,
    requiredData: new Array<FunctionalityInfo>(
      Functionality.selectLocation,
      Functionality.simulationTime,
      Functionality.enrollmentByStageAndSeries,
      Functionality.enrollmentProjection,
      // Functionality.offerGoalEnrollmentFullTime,
      Functionality.numberStudentClass
    ),
    hasPqr: true
  });

  static administrativeAreaCosting: FunctionalityInfo = new FunctionalityInfo({
    name: 'Manutenção das escolas, formação,  materiais didáticos e outros',
    subtitle: 'Estimativa das despesas com os departamentos administrativos da rede de ensino (em relação à despesa total da rede).',
    key: FunctionalityKey.administrativeAreaCosting,
    route: FunctionalityRoute.administrativeAreaCosting,
    previousStep: FunctionalityRoute.newRoomBuilding,
    nextStep: FunctionalityRoute.schoolFeeding,
    hasPqr: true
  });

  static schoolFeeding: FunctionalityInfo = new FunctionalityInfo({
    name: 'Alimentação escolar',
    subtitle: 'Insira os parâmetros para custeio da alimentação escolar.',
    key: FunctionalityKey.schoolFeeding,
    route: FunctionalityRoute.schoolFeeding,
    previousStep: FunctionalityRoute.administrativeAreaCosting,
    nextStep: FunctionalityRoute.socialCharges,
    hasPqr: true
  });

  static offerGoalEnrollmentFullTime: FunctionalityInfo = new FunctionalityInfo({
    name: 'Tempo integral',
    subtitle: 'Informe o percentual de matrículas em turmas com turno/tempo integral.',
    key: FunctionalityKey.offerGoalEnrollmentFullTime,
    route: FunctionalityRoute.offerGoalEnrollmentFullTime,
    previousStep: FunctionalityRoute.dailyTeachingLoad,
    nextStep: FunctionalityRoute.numberStudentClass,
    requiredData: new Array<FunctionalityInfo>(Functionality.enrollmentProjection),
    hasPqr: true,
    disabled: true
  });

  static workJourneyTeacher: FunctionalityInfo = new FunctionalityInfo({
    name: 'Jornada de trabalho docente',
    subtitle: 'Informe a carga horária semanal de trabalho dos professores e o percentual sem interação com discentes.',
    key: FunctionalityKey.workJourneyTeacher,
    route: FunctionalityRoute.workJourneyTeacher,
    previousStep: FunctionalityRoute.numberStudentClass,
    nextStep: FunctionalityRoute.careerAndRemunerationTeachers,
    hasPqr: true,
    disabled: true
  });

  static teacherTraining: FunctionalityInfo = new FunctionalityInfo({
    name: 'Formação continuada',
    subtitle: 'Custos com formação continuada dos profissionais da educação.',
    key: FunctionalityKey.teacherTraining,
    route: FunctionalityRoute.teacherTraining,
    previousStep: FunctionalityRoute.newRoomBuilding,
    nextStep: FunctionalityRoute.schoolsOperation,
    requiredData: new Array<FunctionalityInfo>(
      Functionality.teacherNumber,
      Functionality.staffNumber
    ),
    hasPqr: true,
    disabled: true
  });

  static schoolsOperation: FunctionalityInfo = new FunctionalityInfo({
    name: 'Funcionamento e manutenção das escolas',
    subtitle: 'Custeio de materiais didáticos, projetos pedagógicos; despesas com funcionamento e manutenção das escolas.',
    key: FunctionalityKey.schoolsOperation,
    route: FunctionalityRoute.schoolsOperation,
    previousStep: FunctionalityRoute.teacherTraining,
    nextStep: FunctionalityRoute.socialCharges,
    hasPqr: true,
    disabled: true
  });

  static schoolTransport: FunctionalityInfo = new FunctionalityInfo({
    name: 'Transporte escolar',
    subtitle: 'Parâmetros para custeio das linhas/trajetos do transporte escolar.',
    key: FunctionalityKey.schoolTransport,
    route: FunctionalityRoute.schoolTransport,
    requiredData: new Array<FunctionalityInfo>(
      Functionality.selectLocation
    ),
    disabled: true
  });

  static equipmentAndFurniture: FunctionalityInfo = new FunctionalityInfo({
    name: 'Equipamentos e mobiliário',
    key: FunctionalityKey.equipmentAndFurniture,
    route: FunctionalityRoute.equipmentAndFurniture,
    disabled: true
  });

  static chargesandadditionals: FunctionalityInfo = new FunctionalityInfo({
    name: 'Preços, encargos e adicionais',
    route: FunctionalityRoute.chargesAndAdditionals,
    mainStep: true
  });

  static socialCharges: FunctionalityInfo = new FunctionalityInfo({
    name: 'Encargos sociais',
    subtitle: 'Percentual relativo a encargos tributários incidente sobre a folha de pagamento.',
    key: FunctionalityKey.socialCharges,
    route: FunctionalityRoute.socialCharges,
    previousStep: FunctionalityRoute.schoolFeeding,
    nextStep: FunctionalityRoute.additionals,
    hasPqr: true
  });

  static additionals: FunctionalityInfo = new FunctionalityInfo({
    name: 'Adicionais',
    subtitle: 'Custos adicionais para atuação em contextos específicos.',
    key: FunctionalityKey.additionals,
    route: FunctionalityRoute.additionals,
    previousStep: FunctionalityRoute.socialCharges,
    nextStep: FunctionalityRoute.priceIndices,
    hasPqr: true
  });

  static priceIndices: FunctionalityInfo = new FunctionalityInfo({
    name: 'Índices de preços',
    subtitle: 'Índices relativos à variação anual de preços no período.',
    key: FunctionalityKey.priceIndices,
    route: FunctionalityRoute.priceIndices,
    previousStep: FunctionalityRoute.additionals,
    nextStep: FunctionalityRoute.fullPriceList,
    requiredData: new Array<FunctionalityInfo>(Functionality.simulationTime)
  });

  static fullPriceList: FunctionalityInfo = new FunctionalityInfo({
    name: 'Tabela de preços',
    subtitle: 'Resumo dos preços dos insumos educacionais informados em etapas anteriores.',
    key: FunctionalityKey.fullPriceList,
    route: FunctionalityRoute.fullPriceList,
    previousStep: FunctionalityRoute.priceIndices,
    requiredData: new Array<FunctionalityInfo>(
      Functionality.careerAndRemunerationTeachers,
      Functionality.schoolsStaff,
      Functionality.infrastructureSchoolBuildings,
      Functionality.newRoomBuilding,
      Functionality.schoolFeeding,
      // Functionality.schoolTransport,
      Functionality.schoolsOperation
    )
  });

  static results: FunctionalityInfo = new FunctionalityInfo({
    name: 'Resultado'
  });

  static caqReport: FunctionalityInfo = new FunctionalityInfo({
    name: 'Custo-aluno qualidade',
    key: FunctionalityKey.caqReport,
    route: FunctionalityRoute.caqReport,
    requiredData: Functionality.getAllFunctionalities().filter(functionality =>
      functionality.key !== Functionality.teacherNumber.key &&
      functionality.key !== Functionality.newRoomBuilding.key &&
      functionality.key !== Functionality.staffNumber.key &&
      functionality.key !== Functionality.infrastructureSchoolBuildings.key &&
      functionality.key !== Functionality.schoolTransport.key &&
      functionality.key !== Functionality.fullPriceList.key &&
      functionality.key !== Functionality.enrollmentByStageAndSeriesBySchool.key &&
      functionality.key !== Functionality.resultEnrollmentProjection.key &&
      functionality.key !== Functionality.collaborationScheme.key &&
      functionality.key !== Functionality.selectLocation.key
    )
  });

  static budgetForecastReport: FunctionalityInfo = new FunctionalityInfo({
    name: 'Previsão orçamentária',
    key: FunctionalityKey.budgetForecastReport,
    route: FunctionalityRoute.budgetForecastReport,
    requiredData: Functionality.getAllFunctionalities().filter(functionality =>
      functionality.key !== Functionality.teacherNumber.key &&
      functionality.key !== Functionality.staffNumber.key &&
      functionality.key !== Functionality.schoolTransport.key &&
      functionality.key !== Functionality.fullPriceList.key /*&&
      functionality.key !== Functionality.newRoomBuilding.key
      /*&&
      functionality.key !== Functionality.enrollmentByStageAndSeriesBySchool.key &&
      functionality.key !== Functionality.resultEnrollmentProjection.key*/)
  });

  static financingFundsReport: FunctionalityInfo = new FunctionalityInfo({
    name: 'Fundos de financiamento',
    key: FunctionalityKey.financingFundsReport,
    route: FunctionalityRoute.financingFundsReport,
    requiredData: Functionality.getAllFunctionalities().filter(functionality =>
      functionality.key !== Functionality.teacherNumber.key &&
      functionality.key !== Functionality.newRoomBuilding.key &&
      functionality.key !== Functionality.staffNumber.key &&
      functionality.key !== Functionality.infrastructureSchoolBuildings.key &&
      functionality.key !== Functionality.schoolTransport.key &&
      functionality.key !== Functionality.fullPriceList.key)
  });

  static financingFederatedEntitiesReport: FunctionalityInfo = new FunctionalityInfo({
    name: 'Financiamento atual x necessário',
    key: FunctionalityKey.financingFederatedEntitiesReport,
    route: FunctionalityRoute.financingFederatedEntitiesReport,
    requiredData: new Array<FunctionalityInfo>(
      Functionality.simulationTime,
      Functionality.enrollmentByStageAndSeriesBySchool,
      Functionality.resultEnrollmentProjection,
      Functionality.enrollmentByStageAndSeries,
      Functionality.enrollmentProjection,
      Functionality.schoolDayPerWeek,
      Functionality.dailyTeachingLoad,
      Functionality.offerGoalEnrollmentFullTime,
      Functionality.numberStudentClass,
      Functionality.workJourneyTeacher,
      Functionality.careerAndRemunerationTeachers,
      Functionality.schoolsStaff,
      Functionality.teacherTraining,
      Functionality.schoolsOperation,
      Functionality.schoolFeeding,
      Functionality.socialCharges,
      Functionality.administrativeAreaCosting,
      Functionality.additionals,
      Functionality.priceIndices)
  });

  static itemCostExpenseReport: FunctionalityInfo = new FunctionalityInfo({
    name: 'Custos por item de despesa',
    key: FunctionalityKey.itemCostExpenseReport,
    route: FunctionalityRoute.itemCostExpenseReport,
    previousStep: FunctionalityRoute.results,
    requiredData: Functionality.getAllFunctionalities().filter(functionality =>
      functionality.key !== Functionality.teacherNumber.key &&
      functionality.key !== Functionality.newRoomBuilding.key &&
      functionality.key !== Functionality.staffNumber.key &&
      functionality.key !== Functionality.infrastructureSchoolBuildings.key &&
      functionality.key !== Functionality.schoolTransport.key &&
      functionality.key !== Functionality.fullPriceList.key &&
      functionality.key !== Functionality.enrollmentByStageAndSeriesBySchool.key &&
      functionality.key !== Functionality.resultEnrollmentProjection.key)
  });

  static enrollmentProjectionByLocation: FunctionalityInfo = new FunctionalityInfo({
    name: 'Resultado da projeção de matrículas por localidade',
    key: FunctionalityKey.enrollmentProjectionByLocation,
    requiredData: new Array<FunctionalityInfo>(Functionality.selectLocation, Functionality.simulationTime, Functionality.enrollmentByStageAndSeries)
  });

  static enrollmentAndClassAndClassroom: FunctionalityInfo = new FunctionalityInfo({
    name: 'Resultado do cálculo de turmas e salas realizado pela rota',
    key: FunctionalityKey.enrollmentAndClassAndClassroom,
    requiredData: new Array<FunctionalityInfo>(
      Functionality.selectLocation,
      Functionality.simulationTime,
      Functionality.enrollmentByStageAndSeries,
      Functionality.numberStudentClass,
      Functionality.offerGoalEnrollmentFullTime
    )
  });

  static higherDemandClassroom: FunctionalityInfo = new FunctionalityInfo({
    name: 'Total do número de salas por localidade',
    key: FunctionalityKey.higherDemandClassroom,
    requiredData: new Array<FunctionalityInfo>(
      Functionality.selectLocation,
      Functionality.simulationTime,
      Functionality.enrollmentByStageAndSeries,
      Functionality.numberStudentClass,
      Functionality.offerGoalEnrollmentFullTime
    )
  });

  static calculatedClassNumber: FunctionalityInfo = new FunctionalityInfo({
    name: 'Cálculo - Número de salas',
    key: FunctionalityKey.calculatedClassNumber
  });

  static calculatedClassroomNumber: FunctionalityInfo = new FunctionalityInfo({
    name: 'Cálculo - Número de salas de aulas',
    key: FunctionalityKey.calculatedClassroomNumber
  });

  static calculatedTeacherNumber: FunctionalityInfo = new FunctionalityInfo({
    name: 'Cálculo - Número de professores',
    key: FunctionalityKey.calculatedTeacherNumber
  });

  static calculatedEmployeeEstimate: FunctionalityInfo = new FunctionalityInfo({
    name: 'Cálculo - Estimativa de funcionários',
    key: FunctionalityKey.calculatedEmployeeEstimate
  });

  static calculatedCostDrive: FunctionalityInfo = new FunctionalityInfo({
    name: 'Cálculo - Custo dirigido',
    key: FunctionalityKey.calculatedCostDrive
  });

  static getRequireMyData(functionalityKey: string): Array<FunctionalityInfo> {
    const requireMyData: Array<FunctionalityInfo> = new Array<FunctionalityInfo>();

    Object.keys(Functionality).map(memberName => {
      if (Functionality[memberName] instanceof FunctionalityInfo) {
        const member: FunctionalityInfo = Functionality[memberName];

        if (member.requiredData && member.requiredData.find(item => item.key === functionalityKey)) {
          requireMyData.push(member);
        }
      }
    });

    return requireMyData;
  }

  static getAllFunctionalities(): Array<FunctionalityInfo> {
    const functionalities: Array<FunctionalityInfo> = new Array<FunctionalityInfo>();

    Object.keys(Functionality).map(memberName => {
      const member: FunctionalityInfo = Functionality[memberName];

      if (member instanceof FunctionalityInfo && member.route && !member.disabled && !member.mainStep && member.key.indexOf('report') === -1) {
        functionalities.push(member);
      }
    });

    return functionalities;
  }

}
