import { Observable, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { CalculateClassNumberService } from '../../../shared/services/calculate-class-number/calculate-class-number.service';
import { CalculateEmployeeEstimateService } from '../../../shared/services/calculate-employee-estimate/calculate-employee-estimate.service';
import { CalculateCostDriveService } from '../../../shared/services/calculate-cost-drive/calculate-cost-drive.service';
import { CalculateCostSharingService } from '../../../shared/services/calculate-cost-sharing/calculate-cost-sharing.service';
import { StudentCostService } from '../../../shared/services/calculate-student-cost/services/student-cost.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { ItemCostService } from '../../../shared/services/calculate-student-cost/services/item-cost.service';
import { CsvService } from '../../../../shared/services/csv/csv.service';
import { Csv } from '../../../../shared/services/csv/entities/csv';
import { CsvHeader } from '../../../../shared/services/csv/entities/csv-header';
import { StudentValueFundeb } from '../entities/student-value-fundeb';
import { TotalizerStudentCostByStage } from '../../../shared/services/calculate-student-cost/entities/totalizer-student-cost-by-stage';
import { LocationEnum } from '../../../../shared/entities/enums/location.enum';
import { StageEnum } from '../../../../shared/entities/enums/stage.enum';
import { ShiftEnum } from '../../../../shared/entities/enums/shift-enum';
import { StudentExpense } from '../entities/student-expense';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { DatasCsvEnum } from './../../../../shared/entities/enums/datas-csv.enum';
import { SessionService } from './../../../../shared/services/session/session.service';
import { EnrollmentAndClassAndClassroom } from './../entities/enrollment-and-class-and-classroom';
import { CurrentYearService } from './../../../shared/services/current-year/current-year.service';
import { Location } from './../../../../shared/entities/location';
import { SchoolByCity } from './../../financing-federated-entities-report/entities/school_by_city';
import { Stage } from '../../../../shared/entities/stage';
import { FinancingFederatedEntitiesReportService } from './../../financing-federated-entities-report/services/financing-federated-entities-report.service';
import { SchoolToBeBuiltByLocation } from './../../../shared/services/calculate-class-number/entities/school-to-be-built-by-location';
import { DemandClassRoom } from './../../../quality-conditions/new-room-building/entities/demand-class-room';
import { DemandsClassroomsEnum } from './../../../quality-conditions/new-room-building/entities/enums/demandsClassrooms.enum';
import { DemandClassRoomLocation } from './../../../quality-conditions/new-room-building/entities/demand-class-room-location';
import { CaqReport } from './../entities/caq-report';
import { TotalizerStudentCostByYear } from './../../../shared/services/calculate-student-cost/entities/totalizer-student-cost-by-year';
import { TotalizerStudentCostByShift } from './../../../shared/services/calculate-student-cost/entities/totalizer-student-cost-by-shift';
import { TotalizerStudentCostByLocation } from './../../../shared/services/calculate-student-cost/entities/totalizer-student-cost-by-location';
import { School } from '../../financing-federated-entities-report/entities/school';
import { EnrollmentProjectionByLocation } from './../../../access-and-offer/enrollment-projection/entities/enrollment-projection-by-location';
import { EnrollmentProjection } from 'app/simulator/access-and-offer/enrollment-projection/entities/enrollment-projection';
import { NumberStudentClass } from 'app/simulator/quality-conditions/number-student-class/entities/number-student-class';
import { Functionality } from 'app/shared/entities/functionality/functionality';
import { RegionEnum } from 'app/shared/entities/enums/region.enum';
import { ClassReferenceEnum } from 'app/shared/entities/enums/class-reference.enum';
import { EnrollmentReferenceEnum } from 'app/shared/entities/enums/enrollment-reference.enum';

@Injectable({
  providedIn: 'root'
})
export class CaqReportService {

  constructor(
    private calculateEmployeeEstimateService: CalculateEmployeeEstimateService,
    private calculateCostDriveService: CalculateCostDriveService,
    private calculateCostSharingService: CalculateCostSharingService,
    private studentCostService: StudentCostService,
    private utilitiesService: UtilitiesService,
    private itemCostService: ItemCostService,
    private csvService: CsvService,
    private sessionService: SessionService,
    private currentYearService: CurrentYearService,
    private financingFederatedEntitiesReportService: FinancingFederatedEntitiesReportService
  ) { }

  getCAQ(): Observable<CaqReport> {

    let data: CaqReport = new CaqReport();
    const observables: Array<Observable<any>> = new Array<Observable<any>>();
    const observables2: Array<Observable<any>> = new Array<Observable<any>>();
    /// const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();

    let locations: Array<Location>;
    let stages: Array<Stage>;
    let enrollmentsAndClassesAndClassroomsBySchool: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool1: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool2: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool3: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool4: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool5: Array<EnrollmentAndClassAndClassroom>;
    let demandConstruction: Array<DemandClassRoom>;

    observables.push(this.utilitiesService.getLocations().pipe(
      map(resultLocations => locations = resultLocations)));

    observables.push(this.utilitiesService.getStages().pipe(
      map(resultStages => stages = resultStages)));

    observables.push(this.utilitiesService.getDemandConstruction().pipe(
      map(resultDemandConstruction => demandConstruction = resultDemandConstruction)));

    /* observables.push(this.financingFederatedEntitiesReportService.getEnrollmentSchool(enrollmentCurrentYear).pipe(
     map(resultEnrollmentSchool => enrollmentsSchool = resultEnrollmentSchool)));*/

    /*observables.push(this.financingFederatedEntitiesReportService.getEnrollmentAndClassAndClassroom().pipe(
      map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool = resultEnrollmentAndClassAndClassroom)));*/

    observables.push(this.financingFederatedEntitiesReportService.getEnrollmentAndClassAndClassroom(RegionEnum.norte).pipe(
      map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool1 = resultEnrollmentAndClassAndClassroom)));

    observables.push(this.financingFederatedEntitiesReportService.getEnrollmentAndClassAndClassroom(RegionEnum.nordeste).pipe(
      map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool2 = resultEnrollmentAndClassAndClassroom)));

    observables.push(this.financingFederatedEntitiesReportService.getEnrollmentAndClassAndClassroom(RegionEnum.centroOeste).pipe(
      map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool3 = resultEnrollmentAndClassAndClassroom)));

    observables.push(this.financingFederatedEntitiesReportService.getEnrollmentAndClassAndClassroom(RegionEnum.sudeste).pipe(
      map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool4 = resultEnrollmentAndClassAndClassroom)));

    observables.push(this.financingFederatedEntitiesReportService.getEnrollmentAndClassAndClassroom(RegionEnum.sul).pipe(
      map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool5 = resultEnrollmentAndClassAndClassroom)));

    return forkJoin(observables).pipe(
      mergeMap((t) => {

        this.setQuantityDemandClassRoomLocation(locations, demandConstruction);

        // AGRUPA O RESULTADO EM UM ÚNICO ARRAY
        enrollmentsAndClassesAndClassroomsBySchool = [...enrollmentsAndClassesAndClassroomsBySchool1,
        ...enrollmentsAndClassesAndClassroomsBySchool2,
        ...enrollmentsAndClassesAndClassroomsBySchool3,
        ...enrollmentsAndClassesAndClassroomsBySchool4,
        ...enrollmentsAndClassesAndClassroomsBySchool5];
        // LIMPA OS ARRAYS
        enrollmentsAndClassesAndClassroomsBySchool1 = [];
        enrollmentsAndClassesAndClassroomsBySchool2 = [];
        enrollmentsAndClassesAndClassroomsBySchool3 = [];
        enrollmentsAndClassesAndClassroomsBySchool4 = [];
        enrollmentsAndClassesAndClassroomsBySchool5 = [];

        for (let i = 0; i < enrollmentsAndClassesAndClassroomsBySchool.length; i++) {

          const enrollmentsAndClasseAndClassroomBySchool = enrollmentsAndClassesAndClassroomsBySchool[i];
          const ufId = enrollmentsAndClasseAndClassroomBySchool.state_id;
          const ufDescription = enrollmentsAndClasseAndClassroomBySchool.state_description;
          const cityId = enrollmentsAndClasseAndClassroomBySchool.city_id;
          const cityDescription = enrollmentsAndClasseAndClassroomBySchool.city_description;
          const admDependencyName = enrollmentsAndClasseAndClassroomBySchool.adm_dependency_name;
          const schoolId = enrollmentsAndClasseAndClassroomBySchool.school_id;
          const schoolDescription = enrollmentsAndClasseAndClassroomBySchool.school_description;
          const enrollmentProjection = enrollmentsAndClasseAndClassroomBySchool.enrollmentProjection;
          const enrollmentSchoolCity = this.getEnrollmentSchool(cityId, schoolId, enrollmentsAndClasseAndClassroomBySchool.enrollmentProjection.enrollmentsProjectionsByLocations);
          // const enrollmentSchoolCity = this.financingFederatedEntitiesReportService.getEnrollmentSchoolBySchool(schoolId, enrollmentsSchool);

          if (enrollmentSchoolCity && enrollmentsAndClasseAndClassroomBySchool.hasEnrollment) {

            const schoolsToBeBuilt: Array<SchoolToBeBuiltByLocation> = undefined;
            const demandClassRoom: DemandClassRoom = _.find(demandConstruction, dC => dC.id === DemandsClassroomsEnum.ClassroomsExisting);
            const classroomExisting = this.financingFederatedEntitiesReportService.getClassroomExisting(demandClassRoom, enrollmentsAndClasseAndClassroomBySchool);
            // schoolsToBeBuilt = this.calculateClassNumberService.getSchoolsToBeBuilt(enrollmentsAndClasseAndClassroomBySchool.classNumber.classesNumberByLocations, classroomExisting);
            const calculatedEmployeeEstimate = this.calculateEmployeeEstimateService.calculateEmployeeEstimateByEntity(locations, enrollmentSchoolCity, schoolsToBeBuilt);

            observables2.push(
              this.calculateCostSharingService.calculateCostSharing(locations, calculatedEmployeeEstimate).pipe(
                mergeMap(calculateCostSharing => {
                  return this.calculateCostDriveService.calculateCostDrive(enrollmentProjection).pipe(
                    mergeMap(calculateCostDrive => {
                      return this.itemCostService.calculateItemCost(locations, stages, enrollmentsAndClasseAndClassroomBySchool.teacherNumber, calculatedEmployeeEstimate, calculateCostSharing,
                        calculateCostDrive, enrollmentProjection, true, true, false, true, true, undefined, true).pipe(
                          mergeMap(itemCostTotal => {
                            return this.studentCostService.calculateStudentCost(locations, stages, itemCostTotal, undefined, enrollmentProjection, true).pipe(
                              map(totalizerStudentsCost => {

                                const caqReport: CaqReport = new CaqReport();
                                caqReport.ufId = ufId;
                                caqReport.ufDescription = ufDescription;
                                caqReport.cityId = cityId;
                                caqReport.cityDescription = cityDescription;
                                caqReport.entityId = parseFloat(schoolId);
                                caqReport.entityDescription = schoolDescription;
                                caqReport.admDependencyName = admDependencyName;
                                caqReport.locationEntity = this.getLocationEntity(enrollmentProjection.enrollmentsProjectionsByLocations);
                                caqReport.totalizersStudentsCostByStages = totalizerStudentsCost.totalizersStudentsCostByStages;
                                caqReport.totalizersStudentsCostByYearTotal = totalizerStudentsCost.totalizersStudentsCostByYearTotal;
                                this.setStudentValueFundeb(caqReport.totalizersStudentsCostByStages);
                                this.setEnrollment(caqReport.totalizersStudentsCostByStages, enrollmentProjection);
                                return caqReport;
                              }));
                          }));
                    }));
                })));
          }
        }

        return forkJoin(observables2).pipe(
          map(result => {
            const caqReport: CaqReport = this.getCaqResultEmpty(stages, locations);
            caqReport.datasByEntity = result;
            caqReport.years = this.utilitiesService.getSimulationYears();
            caqReport.studentsExpenses = this.getStudentsExpenses();
            caqReport.sourceInformations = this.getSourceInformations();
            caqReport.sourceInformation = this.getSourceInformation();
            caqReport.sourceInformationPQR = this.getSourceInformationPQR();
            this.setArrayAllCAQ(caqReport, result);
            this.setOrderAndCAQ(caqReport);
            this.seekCityAllCAQ(caqReport, result);
            this.setTotalEnrollment(caqReport);
            data = caqReport;
            return data;
          }));
      }));
  }

  downloadCsv(caq: CaqReport, datas: Number): void {

    const header: Array<CsvHeader> = new Array<CsvHeader>();
    const data: Array<any> = new Array<any>();
    let csv: Csv = new Csv();

    if (datas === DatasCsvEnum.Caq) {
      header.push(new CsvHeader({ key: 'stage', label: 'Etapa' }));
      header.push(new CsvHeader({ key: 'location', label: 'Área da localidade' }));
      header.push(new CsvHeader({ key: 'shift', label: 'Turno' }));

      for (const year of caq.years) {
        // header.push(new CsvHeader({ key: `${year} + min`, label: `CAQ 10% Menor ${year} (R$)` }));
        header.push(new CsvHeader({ key: `${year} + med`, label: `CAQ Mediana ${year} (R$)` }));
        // header.push(new CsvHeader({ key: `${year} + max`, label: `CAQ 10% Maior ${year} (R$)` }));
        header.push(new CsvHeader({ key: `${year} + fator`, label: `Fator de ponderação` }));
        header.push(new CsvHeader({ key: `${year} + matriculas`, label: `Número de matrículas - ${caq.enrollmentCurrentYear}` }));
        header.push(new CsvHeader({ key: `${year} + escolas`, label: `Número de escolas - ${caq.enrollmentCurrentYear}` }));
      }

      for (const totalizerStudentCostByStage of caq.totalizersStudentsCostByStages) {
        for (const location of totalizerStudentCostByStage.totalizersStudentsCostByLocations) {
          for (const shift of location.totalizersStudentsCostByShifts) {
            for (const studentCostByYear of shift.totalizersStudentsCostByYear) {

              if (!(totalizerStudentCostByStage.id === StageEnum.eja && shift.id === ShiftEnum.Integral)) {
                const caqData: any = {
                  stage: totalizerStudentCostByStage.description,
                  location: location.description,
                  shift: shift.description
                };

                //  caqData[`${studentCostByYear.year} + min`] = this.utilitiesService.roundNumber(studentCostByYear.costMinimum, 0);
                caqData[`${studentCostByYear.year} + med`] = this.utilitiesService.roundNumber(studentCostByYear.costMedian, 0);
                //  caqData[`${studentCostByYear.year} + max`] = this.utilitiesService.roundNumber(studentCostByYear.costMaximum, 0);
                caqData[`${studentCostByYear.year} + fator`] = this.utilitiesService.roundNumber(studentCostByYear.weightingFactor, 2);
                caqData[`${studentCostByYear.year} + matriculas`] = this.utilitiesService.roundNumber(studentCostByYear.enrollmentQuantity, 0);
                caqData[`${studentCostByYear.year} + escolas`] = this.utilitiesService.roundNumber(studentCostByYear.schoolQuantityCost, 0);
                data.push(caqData);
              }
            }
          }
        }
      }

      for (const totalizerStudentCostByYear of caq.totalizersStudentsCostByYearTotal) {
        const caqTotal: any = { stage: 'TOTAL' };
        caqTotal[`${totalizerStudentCostByYear.year} + matriculas`] = this.utilitiesService.roundNumber(totalizerStudentCostByYear.enrollmentQuantity, 0);

        data.push(caqTotal);
      }

      /*const caqTotal: any = { stage: 'TOTAL' };
      caqTotal[`${totalizerStudentCostByYear.year}`] = this.utilitiesService.roundNumber(caq.enrollmentTotal, 0);

      data.push(caqTotal);  */

      /*for (const totalizerStudentCostByYear of caq.totalizersStudentsCostByYearTotal) {
        const caqTotal: any = { stage: 'CAQ médio' };
        caqTotal[`${totalizerStudentCostByYear.year}`] = this.utilitiesService.roundNumber(totalizerStudentCostByYear.cost, 0);

        data.push(caqTotal);
      }*/

      csv = new Csv({ header: header, data: data, name: 'Custo-aluno qualidade' });
      this.csvService.download(csv);
    } else if (datas === DatasCsvEnum.StudentExpenses) {

      // Student Expenses
      header.push(new CsvHeader({ key: 'studentExpense', label: 'Gasto-Aluno(2019)' }));
      header.push(new CsvHeader({ key: 'value', label: 'Valor' }));

      for (const studentExpense of caq.studentsExpenses) {
        const studentExpenseData: any = {
          studentExpense: studentExpense.description,
          value: this.utilitiesService.roundNumber(studentExpense.value, 0)
        };

        data.push(studentExpenseData);
      }

      csv = new Csv({ header: header, data: data, name: 'Gasto-Aluno' });
      this.csvService.download(csv);
    } else if (datas === DatasCsvEnum.CaqByEntity) {

      header.push(new CsvHeader({ key: 'ufDescription', label: 'UF' }));
      header.push(new CsvHeader({ key: 'cityId', label: 'Código município' }));
      header.push(new CsvHeader({ key: 'cityDescription', label: 'Nome município' }));
      header.push(new CsvHeader({ key: 'entityId', label: 'Código escola' }));
      header.push(new CsvHeader({ key: 'entityDescription', label: 'Nome escola' }));
      header.push(new CsvHeader({ key: 'admDependency', label: 'Dependência Administrativa' }));
      header.push(new CsvHeader({ key: 'locationSchool', label: 'Localização' }));

      for (let i = 0; i < caq.totalizersStudentsCostByStages.length; i++) {
        const stage = caq.totalizersStudentsCostByStages[i];
        for (let j = 0; j < stage.totalizersStudentsCostByLocations.length; j++) {
          const location = stage.totalizersStudentsCostByLocations[j];
          header.push(new CsvHeader({
            key: `mat_stage${stage.id}_location${location.id}_shift${ShiftEnum.Parcial}`,
            label: `Matrículas ${stage.description} ${location.description} ${ShiftEnum[ShiftEnum.Parcial]} (N)`
          }));

          header.push(new CsvHeader({
            key: `caq_stage${stage.id}_location${location.id}_shift${ShiftEnum.Parcial}`,
            label: `CAQ ${stage.description} ${location.description} ${ShiftEnum[ShiftEnum.Parcial]} (R$)`
          }));

          if (stage.id !== StageEnum.eja) {
            header.push(new CsvHeader({
              key: `mat_stage${stage.id}_location${location.id}_shift${ShiftEnum.Integral}`,
              label: `Matrículas ${stage.description} ${location.description} ${ShiftEnum[ShiftEnum.Integral]} (N)`
            }));
            header.push(new CsvHeader({
              key: `caq_stage${stage.id}_location${location.id}_shift${ShiftEnum.Integral}`,
              label: `CAQ ${stage.description} ${location.description} ${ShiftEnum[ShiftEnum.Integral]} (R$)`
            }));
          }
        }
      }

      for (let i = 0; i < caq.datasByEntity.length; i++) {

        const totalizersStudentsCostByStages = caq.datasByEntity[i].totalizersStudentsCostByStages;

        const caqData: any = {
          ufDescription: caq.datasByEntity[i].ufDescription,
          cityId: caq.datasByEntity[i].cityId,
          cityDescription: caq.datasByEntity[i].cityDescription,
          entityId: caq.datasByEntity[i].entityId,
          entityDescription: caq.datasByEntity[i].entityDescription,
          admDependency: caq.datasByEntity[i].admDependencyName,
          locationSchool: caq.datasByEntity[i].locationEntity
        };

        for (let j = 0; j < totalizersStudentsCostByStages.length; j++) {

          const totalizersStudentsCostByLocations = totalizersStudentsCostByStages[j].totalizersStudentsCostByLocations;
          for (let k = 0; k < totalizersStudentsCostByLocations.length; k++) {
            const totalizersStudentsCostByShifts = totalizersStudentsCostByLocations[k].totalizersStudentsCostByShifts;
            for (let l = 0; l < totalizersStudentsCostByShifts.length; l++) {
              const totalizersStudentsCostByYear = totalizersStudentsCostByShifts[l].totalizersStudentsCostByYear;
              for (let m = 0; m < totalizersStudentsCostByYear.length; m++) {
                if (!(totalizersStudentsCostByStages[j].id === StageEnum.eja && totalizersStudentsCostByShifts[l].id === ShiftEnum.Integral)) {
                  const totalizerEnrollmentByYear = totalizersStudentsCostByShifts[l].totalizersStudentsCostByYear[m].enrollmentQuantity;
                  const totalizerStudentCostByYear = totalizersStudentsCostByShifts[l].totalizersStudentsCostByYear[m].cost;

                  caqData[`mat_stage${totalizersStudentsCostByStages[j].id}_location${totalizersStudentsCostByLocations[k].id}_shift${totalizersStudentsCostByShifts[l].id}`] =
                    this.utilitiesService.roundNumber(totalizerEnrollmentByYear, 0);
                  caqData[`caq_stage${totalizersStudentsCostByStages[j].id}_location${totalizersStudentsCostByLocations[k].id}_shift${totalizersStudentsCostByShifts[l].id}`] =
                    this.utilitiesService.roundNumber(totalizerStudentCostByYear, 0);
                  break;
                }
              }
            }
          }
        }
        data.push(caqData);
      }
      csv = new Csv({ header: header, data: data, name: 'Custo-aluno qualidade por ente federativo' });
      this.csvService.download(csv);
    }
  }

  getStudentValueFundeb(): Array<StudentValueFundeb> {

    const studentsValueFundeb: Array<StudentValueFundeb> = new Array<StudentValueFundeb>();

    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.creche, location_id: LocationEnum.urban, shift_id: ShiftEnum.Parcial, minor: 364, major: 484 }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.creche, location_id: LocationEnum.urban, shift_id: ShiftEnum.Integral, minor: 395, major: 524 }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.creche, location_id: LocationEnum.rural, shift_id: ShiftEnum.Parcial, minor: 364, major: 484 }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.creche, location_id: LocationEnum.rural, shift_id: ShiftEnum.Integral, minor: 395, major: 524 }));

    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.preEscola, location_id: LocationEnum.urban, shift_id: ShiftEnum.Parcial, minor: 334, major: 444 }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.preEscola, location_id: LocationEnum.urban, shift_id: ShiftEnum.Integral, minor: 395, major: 524 }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.preEscola, location_id: LocationEnum.rural, shift_id: ShiftEnum.Parcial, minor: 334, major: 444 }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.preEscola, location_id: LocationEnum.rural, shift_id: ShiftEnum.Integral, minor: 395, major: 524 }));

    studentsValueFundeb.push(new StudentValueFundeb({
      stage_id: StageEnum.ensinoFundamentalAnosIniciais, location_id: LocationEnum.urban,
      shift_id: ShiftEnum.Parcial, minor: 304, major: 403
    }));
    studentsValueFundeb.push(new StudentValueFundeb({
      stage_id: StageEnum.ensinoFundamentalAnosIniciais, location_id: LocationEnum.urban,
      shift_id: ShiftEnum.Integral, minor: 395, major: 524
    }));
    studentsValueFundeb.push(new StudentValueFundeb({
      stage_id: StageEnum.ensinoFundamentalAnosIniciais, location_id: LocationEnum.rural,
      shift_id: ShiftEnum.Parcial, minor: 349, major: 464
    }));
    studentsValueFundeb.push(new StudentValueFundeb({
      stage_id: StageEnum.ensinoFundamentalAnosIniciais, location_id: LocationEnum.rural,
      shift_id: ShiftEnum.Integral, minor: 395, major: 524
    }));
    studentsValueFundeb.push(new StudentValueFundeb({
      stage_id: StageEnum.ensinoFundamentalAnosFinais, location_id: LocationEnum.urban,
      shift_id: ShiftEnum.Parcial, minor: 334, major: 444
    }));
    studentsValueFundeb.push(new StudentValueFundeb({
      stage_id: StageEnum.ensinoFundamentalAnosFinais, location_id: LocationEnum.urban,
      shift_id: ShiftEnum.Integral, minor: 395, major: 524
    }));
    studentsValueFundeb.push(new StudentValueFundeb({
      stage_id: StageEnum.ensinoFundamentalAnosFinais, location_id: LocationEnum.rural,
      shift_id: ShiftEnum.Parcial, minor: 364, major: 484
    }));
    studentsValueFundeb.push(new StudentValueFundeb({
      stage_id: StageEnum.ensinoFundamentalAnosFinais, location_id: LocationEnum.rural,
      shift_id: ShiftEnum.Integral, minor: 395, major: 524
    }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.ensinoMedio, location_id: LocationEnum.urban, shift_id: ShiftEnum.Parcial, minor: 380, major: 504 }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.ensinoMedio, location_id: LocationEnum.urban, shift_id: ShiftEnum.Integral, minor: 395, major: 524 }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.ensinoMedio, location_id: LocationEnum.rural, shift_id: ShiftEnum.Parcial, minor: 395, major: 524 }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.ensinoMedio, location_id: LocationEnum.rural, shift_id: ShiftEnum.Integral, minor: 395, major: 524 }));

    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.eja, location_id: LocationEnum.urban, shift_id: ShiftEnum.Parcial, minor: 243, major: 323 }));
    studentsValueFundeb.push(new StudentValueFundeb({ stage_id: StageEnum.eja, location_id: LocationEnum.rural, shift_id: ShiftEnum.Parcial, minor: 243, major: 323 }));

    return studentsValueFundeb;
  }

  getStudentsExpenses(): Array<StudentExpense> {

    const studentsExpenses: Array<StudentExpense> = new Array<StudentExpense>();

    studentsExpenses.push(new StudentExpense({
      description: 'Média (R$)',
      value: 672
    }));

    studentsExpenses.push(new StudentExpense({
      description: '5% Menores (R$)',
      value: 387
    }));

    studentsExpenses.push(new StudentExpense({
      description: '20% menores (R$)',
      value: 480
    }));

    studentsExpenses.push(new StudentExpense({
      description: 'Mediana (R$)',
      value: 609
    }));

    studentsExpenses.push(new StudentExpense({
      description: '20% maiores (R$)',
      value: 768
    }));

    studentsExpenses.push(new StudentExpense({
      description: '5% Maiores (R$)',
      value: 1128
    }));

    studentsExpenses.push(new StudentExpense({
      description: 'Número de redes analisadas',
      value: 5329
    }));

    return studentsExpenses;
  }

  private getSourceInformations(): Array<Footnote> {

    const footNotes: Array<Footnote> = new Array<Footnote>();
    footNotes.push(new Footnote(
      {
        indice: 1,
        note: 'Intervalo de valores do CAQ por etapa-modalidade/turno/área da localidade com base na distribuição de valores estimados para cada rede estadual e ' +
          'municipal do país. O limite inferior é o 10º percentil e o limite superior é o 90º percentil da distribuição.'
      }));
    footNotes.push(new Footnote(
      {
        indice: 2,
        note: 'Fator de ponderação = [valor do CAQ de cada etapa/modalidade, turno e área de localização] / [CAQ dos anos iniciais do ensino fundamental parcial urbano]. ' +
          'Indica as diferenças de custos entre os contextos de oferta (etapas/modalidades, turnos e áreas de localicação) na forma de número índice (que permite a análise ' +
          'em termos relativos ou percentuais).'
      }
    ));

    footNotes.push(new Footnote(
      {
        indice: 3,
        note: 'Número de matrículas por etapa/modalidade contado pelo Censo Escolar/INEP; e número de matrícula por turmo (parcial e integral) projetado pelo SimCAQ com base ' +
          'nos parâmetros do PQR para o percentual de oferta de ensino em tempo integral utilizado para a simulação (consultar parâmetros em simcaq.c3sl.ufpr.br/pqr).'
      }
    ));

    return footNotes;
  }

  private getSourceInformation(): Footnote {

    const footNote: Footnote = new Footnote({
      indice: 3,
      note: 'Gasto-aluno mensal = [Despesa total c/ Educação Básica do ano t dividido por 12 e pelo número de matrículas da rede própria + conveniada do ano t]. ' +
        'Calculado a partir dos dados analíticos do RREO / Finbra / STN e microdados do Censo Escolar/ INEP.'
    });

    return footNote;
  }

  private getSourceInformationPQR(): Footnote {

    const footNote: Footnote = new Footnote({
      note: 'A proposta de valores do PQR foi realizada pela equipe do projeto. Desse modo, não são decisões do governo federal, ' +
        'dos governos estaduais ou municipais. Por consequência, os resultados do SimCAQ decorrem dos parâmetros de qualidade propostos ' +
        'não vinculam responsabilidade de repasses de recursos financeiros por parte dos entes federativos.'
    });
    return footNote;
  }

  private getCaqResultEmpty(stages: Array<Stage>, locations: Array<Location>): CaqReport {

    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();

    const caqRangeReport = new CaqReport();
    caqRangeReport.enrollmentCurrentYear = enrollmentCurrentYear;
    caqRangeReport.totalizersStudentsCostByStages = this.getTotalizersStudentsCostByStages(stages, locations);
    caqRangeReport.totalizersStudentsCostByYearTotal = this.getTotalizersStudentsCostByYear();
    return caqRangeReport;
  }

  private getTotalizersStudentsCostByStages(stages: Array<Stage>, locations: Array<Location>): Array<TotalizerStudentCostByStage> {

    const totalizersStudentsCostByStages: Array<TotalizerStudentCostByStage> = new Array<TotalizerStudentCostByStage>();

    for (let i = 0; i < stages.length; i++) {
      totalizersStudentsCostByStages.push(new TotalizerStudentCostByStage({
        id: stages[i].id,
        description: stages[i].description,
        totalizersStudentsCostByLocations: this.getTotalizersStudentsCostByLocations(locations)
      }));
    }
    return totalizersStudentsCostByStages;
  }

  private getTotalizersStudentsCostByLocations(locations: Array<Location>): Array<TotalizerStudentCostByLocation> {

    const totalizerStudentCostByLocation: Array<TotalizerStudentCostByLocation> = new Array<TotalizerStudentCostByLocation>();

    for (let i = 0; i < locations.length; i++) {
      totalizerStudentCostByLocation.push(new TotalizerStudentCostByLocation({
        id: locations[i].id,
        description: locations[i].description,
        totalizersStudentsCostByShifts: this.getTotalizersStudentsCostByShifts()
      }));
    }
    return totalizerStudentCostByLocation;
  }

  private getTotalizersStudentsCostByShifts(): Array<TotalizerStudentCostByShift> {

    return new Array<TotalizerStudentCostByShift>(
      new TotalizerStudentCostByShift({ id: ShiftEnum.Parcial, description: 'Parcial', totalizersStudentsCostByYear: this.getTotalizersStudentsCostByYear() }),
      new TotalizerStudentCostByShift({ id: ShiftEnum.Integral, description: 'Integral', totalizersStudentsCostByYear: this.getTotalizersStudentsCostByYear() })
    );
  }

  private getTotalizersStudentsCostByYear(): Array<TotalizerStudentCostByYear> {

    const yearsSimulation: Array<number> = this.utilitiesService.getSimulationYears();
    const totalizerStudentCostByYear: Array<TotalizerStudentCostByYear> = new Array<TotalizerStudentCostByYear>();

    for (let i = 0; i < yearsSimulation.length; i++) {
      totalizerStudentCostByYear.push(new TotalizerStudentCostByYear({
        year: yearsSimulation[i],
        costs: new Array<number>(),
        cityCostMinimum: new Array<string>(),
        cityCostMedia: new Array<string>(),
        cityCostMaximum: new Array<string>(),
        enrollmentQuantity: undefined
      }));
    }

    return totalizerStudentCostByYear;
  }

  private getMedianaEnsinoFundamentalAnosIniciaisParcialUrbano(caqRangeReport: CaqReport): number {

    const stage = caqRangeReport.totalizersStudentsCostByStages.find(tStudentCostByShift =>
      tStudentCostByShift.id === StageEnum.ensinoFundamentalAnosIniciais);
    const location = stage.totalizersStudentsCostByLocations.find(l => l.id === LocationEnum.urban);
    const shift = location.totalizersStudentsCostByShifts.find(s => s.id === ShiftEnum.Parcial);
    const tamArrayCost = _.first(shift.totalizersStudentsCostByYear).costs.length;
    _.first(shift.totalizersStudentsCostByYear).costs.sort((a, b) => a - b);
    const posMedianaEnsinoFundamentalAnosIniciaisParcialUrbano: number = tamArrayCost > 2 ?
      Math.trunc((tamArrayCost * 50) / 100) : tamArrayCost - 1;
    const caqEnsinoFundamentalAnosIniciaisParcialUrbano: number = _.first(shift.totalizersStudentsCostByYear).costs[posMedianaEnsinoFundamentalAnosIniciaisParcialUrbano];

    return caqEnsinoFundamentalAnosIniciaisParcialUrbano;

  }

  private getEnrollmentSchool(city_id: number, school_id: string, enrollmentProjectionByLocation: Array<EnrollmentProjectionByLocation>): SchoolByCity {

    const schoolsByCity: SchoolByCity = new SchoolByCity({ cityId: city_id, schools: new Array<School>() });

    for (let i = 0; i < enrollmentProjectionByLocation.length; i++) {
      schoolsByCity.schools.push(new School({
        id: school_id,
        locationId: enrollmentProjectionByLocation[i].id,
        enrollmentQuantity: _.first(enrollmentProjectionByLocation[i].totalsEnrollmentProjection).quantity
      }));

    }

    return schoolsByCity;

  }

  private getNumberStudentClassByStageLocation(stage_id: number, location_id: number): number {

    const resultForNumberStudentClass: NumberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.pqrKey);
    let numberStudentClass: number = 0;

    for (let i = 0; i < resultForNumberStudentClass.studentClasses.length; i++) {
      if (resultForNumberStudentClass.studentClasses[i].id === stage_id) {
        for (let j = 0; j < resultForNumberStudentClass.studentClasses[i].studentsTeachersClass.length; j++) {
          if (resultForNumberStudentClass.studentClasses[i].studentsTeachersClass[j].id === location_id) {
            numberStudentClass += resultForNumberStudentClass.studentClasses[i].studentsTeachersClass[j].numberStudentClass;
          }
        }
      }
    }
    return numberStudentClass;
  }

  private getLocationEntity(enrollmentsProjectionsByLocations: Array<EnrollmentProjectionByLocation>): string {
    return _.first(enrollmentsProjectionsByLocations).description;
  }

  private setStudentValueFundeb(totalizersStudentsCostByStages: Array<TotalizerStudentCostByStage>): void {

    const studentsValueFundeb: Array<StudentValueFundeb> = this.getStudentValueFundeb();

    for (let i = 0; i < totalizersStudentsCostByStages.length; i++) {
      const totalizerStudentCostByStage = totalizersStudentsCostByStages[i];
      for (let j = 0; j < totalizerStudentCostByStage.totalizersStudentsCostByLocations.length; j++) {
        const totalizerStudentCostByLocation = totalizerStudentCostByStage.totalizersStudentsCostByLocations[j];
        for (let k = 0; k < totalizerStudentCostByLocation.totalizersStudentsCostByShifts.length; k++) {
          const totalizerStudentCostByShift = totalizerStudentCostByLocation.totalizersStudentsCostByShifts[k];

          const studentValueFundeb: StudentValueFundeb = _.find(studentsValueFundeb, sVF => sVF.stage_id === totalizerStudentCostByStage.id &&
            sVF.location_id === totalizerStudentCostByLocation.id && sVF.shift_id === totalizerStudentCostByShift.id);

          if (studentValueFundeb) {
            totalizerStudentCostByShift.studentMinorValueFundeb = studentValueFundeb.minor;
            totalizerStudentCostByShift.studentMajorValueFundeb = studentValueFundeb.major;
          }
        }
      }
    }
  }

  private setQuantityDemandClassRoomLocation(locations: Array<Location>, demandsClassRooms: Array<DemandClassRoom>): void {

    for (let i = 0; i < demandsClassRooms.length; i++) {
      for (let j = 0; j < locations.length; j++) {
        demandsClassRooms[i].quantityDemandClassRoomLocation.push(new DemandClassRoomLocation({ location: locations[j], value: 0 }));
      }
    }
  }

  private setArrayAllCAQ(caqRangeReport: CaqReport, result: Array<CaqReport>) {

    for (let i = 0; i < result.length; i++) {
      for (let j = 0; j < result[i].totalizersStudentsCostByStages.length; j++) {
        const totalizerStudentCostByStage: TotalizerStudentCostByStage = result[i].totalizersStudentsCostByStages[j];
        const totalizerStudentCostByStageFinal = caqRangeReport.totalizersStudentsCostByStages.find(tStudentCostByStage => tStudentCostByStage.id === totalizerStudentCostByStage.id);
        for (let k = 0; k < totalizerStudentCostByStage.totalizersStudentsCostByLocations.length; k++) {
          const totalizerStudentCostByLocation: TotalizerStudentCostByLocation = totalizerStudentCostByStage.totalizersStudentsCostByLocations[k];
          const totalizerStudentCostByLocationFinal = totalizerStudentCostByStageFinal.totalizersStudentsCostByLocations.find(tStudentsCostByLocations =>
            tStudentsCostByLocations.id === totalizerStudentCostByLocation.id);
          for (let l = 0; l < totalizerStudentCostByLocation.totalizersStudentsCostByShifts.length; l++) {
            const totalizerStudentCostByShift: TotalizerStudentCostByShift = totalizerStudentCostByLocation.totalizersStudentsCostByShifts[l];
            const totalizerStudentCostByShiftFinal = totalizerStudentCostByLocationFinal.totalizersStudentsCostByShifts.find(tStudentCostByShift =>
              tStudentCostByShift.id === totalizerStudentCostByShift.id);
            for (let m = 0; m < totalizerStudentCostByShift.totalizersStudentsCostByYear.length; m++) {
              const totalizerStudentCostByYear = totalizerStudentCostByShift.totalizersStudentsCostByYear[m];
              const totalizerStudentCostByYearFinal = totalizerStudentCostByShiftFinal.totalizersStudentsCostByYear.find(tStudentCostByYear =>
                tStudentCostByYear.year === totalizerStudentCostByYear.year);

              if (totalizerStudentCostByYearFinal && totalizerStudentCostByYear.cost > 0) {
                if (totalizerStudentCostByShift.considerCAQ) {
                  totalizerStudentCostByYearFinal.costs.push(totalizerStudentCostByYear.cost);
                  totalizerStudentCostByYearFinal.cost = totalizerStudentCostByYear.cost;
                }
                if (totalizerStudentCostByYearFinal.schoolQuantityCost === undefined) {
                  totalizerStudentCostByYearFinal.schoolQuantityCost = 1;
                } else {
                  totalizerStudentCostByYearFinal.schoolQuantityCost++;
                }
              }

              if (totalizerStudentCostByYear.enrollmentQuantity) {
                if (totalizerStudentCostByYearFinal.enrollmentQuantity !== undefined) {
                  totalizerStudentCostByYearFinal.enrollmentQuantity += totalizerStudentCostByYear.enrollmentQuantity;
                } else {
                  totalizerStudentCostByYearFinal.enrollmentQuantity = totalizerStudentCostByYear.enrollmentQuantity;
                }
              }
            }
          }
        }
      }
    }
  }

  private setOrderAndCAQ(caqRangeReport: CaqReport) {

    const caqEnsinoFundamentalAnosIniciaisParcialUrbano: number = this.getMedianaEnsinoFundamentalAnosIniciaisParcialUrbano(caqRangeReport);

    for (let i = 0; i < caqRangeReport.totalizersStudentsCostByStages.length; i++) {
      const totalizerStudentCostByStage: TotalizerStudentCostByStage = caqRangeReport.totalizersStudentsCostByStages[i];
      for (let j = 0; j < totalizerStudentCostByStage.totalizersStudentsCostByLocations.length; j++) {
        const totalizerStudentCostByLocation: TotalizerStudentCostByLocation = totalizerStudentCostByStage.totalizersStudentsCostByLocations[j];
        for (let k = 0; k < totalizerStudentCostByLocation.totalizersStudentsCostByShifts.length; k++) {
          const totalizerStudentCostByShift: TotalizerStudentCostByShift = totalizerStudentCostByLocation.totalizersStudentsCostByShifts[k];
          for (let l = 0; l < totalizerStudentCostByShift.totalizersStudentsCostByYear.length; l++) {
            const totalizerStudentCostByYear = totalizerStudentCostByShift.totalizersStudentsCostByYear[l];
            totalizerStudentCostByYear.costs.sort((a, b) => a - b);
            const posPercentilMinimum: number = totalizerStudentCostByYear.costs.length > 2 ? Math.ceil(((totalizerStudentCostByYear.costs.length * 10) / 100)) : 0;
            const posPercentilMaximum: number = totalizerStudentCostByYear.costs.length > 2 ? Math.trunc(((totalizerStudentCostByYear.costs.length * 90) / 100) - 1) :
              totalizerStudentCostByYear.costs.length - 1;
            const posMediana: number = totalizerStudentCostByYear.costs.length > 2 ? Math.trunc((totalizerStudentCostByYear.costs.length * 50) / 100) : totalizerStudentCostByYear.costs.length - 1;

            totalizerStudentCostByYear.costMinimum = totalizerStudentCostByYear.costs[posPercentilMinimum];
            totalizerStudentCostByYear.costMaximum = totalizerStudentCostByYear.costs[posPercentilMaximum];
            totalizerStudentCostByYear.costMedian = totalizerStudentCostByYear.costs[posMediana];
            totalizerStudentCostByYear.weightingFactor = totalizerStudentCostByYear.costMedian / caqEnsinoFundamentalAnosIniciaisParcialUrbano;

            // totalizerStudentCostByYear.schoolQuantityCost = totalizerStudentCostByYear.costs.length;
          }
        }
      }
    }
  }

  private seekCityAllCAQ(caqRangeReport: CaqReport, result: Array<CaqReport>) {

    for (let i = 0; i < result.length; i++) {
      for (let j = 0; j < result[i].totalizersStudentsCostByStages.length; j++) {
        const totalizerStudentCostByStage: TotalizerStudentCostByStage = result[i].totalizersStudentsCostByStages[j];
        const totalizerStudentCostByStageFinal = caqRangeReport.totalizersStudentsCostByStages.find(tStudentCostByStage => tStudentCostByStage.id === totalizerStudentCostByStage.id);
        for (let k = 0; k < totalizerStudentCostByStage.totalizersStudentsCostByLocations.length; k++) {
          const totalizerStudentCostByLocation: TotalizerStudentCostByLocation = totalizerStudentCostByStage.totalizersStudentsCostByLocations[k];
          const totalizerStudentCostByLocationFinal = totalizerStudentCostByStageFinal.totalizersStudentsCostByLocations.find(tStudentsCostByLocations =>
            tStudentsCostByLocations.id === totalizerStudentCostByLocation.id);
          for (let l = 0; l < totalizerStudentCostByLocation.totalizersStudentsCostByShifts.length; l++) {
            const totalizerStudentCostByShift: TotalizerStudentCostByShift = totalizerStudentCostByLocation.totalizersStudentsCostByShifts[l];
            const totalizerStudentCostByShiftFinal = totalizerStudentCostByLocationFinal.totalizersStudentsCostByShifts.find(tStudentCostByShift =>
              tStudentCostByShift.id === totalizerStudentCostByShift.id);
            for (let m = 0; m < totalizerStudentCostByShift.totalizersStudentsCostByYear.length; m++) {
              const totalizerStudentCostByYear = totalizerStudentCostByShift.totalizersStudentsCostByYear[m];
              const totalizerStudentCostByYearFinal = totalizerStudentCostByShiftFinal.totalizersStudentsCostByYear.find(tStudentCostByYear =>
                tStudentCostByYear.year === totalizerStudentCostByYear.year);

              if (totalizerStudentCostByYearFinal.costMinimum === totalizerStudentCostByYear.cost) {
                totalizerStudentCostByYearFinal.cityCostMinimum.push(result[i].entityId + ' - ' + result[i].entityDescription);
              }

              if (totalizerStudentCostByYearFinal.costMedian === totalizerStudentCostByYear.cost) {
                totalizerStudentCostByYearFinal.cityCostMedia.push(result[i].entityId + ' - ' + result[i].entityDescription);
              }

              if (totalizerStudentCostByYearFinal.costMaximum === totalizerStudentCostByYear.cost) {
                totalizerStudentCostByYearFinal.cityCostMaximum.push(result[i].entityId + ' - ' + result[i].entityDescription);
              }
            }
          }
        }
      }
    }
  }

  private setEnrollment(totalizerStudentCostByStages: Array<TotalizerStudentCostByStage>, enrollmentProjection: EnrollmentProjection) {

    for (let i = 0; i < totalizerStudentCostByStages.length; i++) {
      const totalizerStudentCostByStage: TotalizerStudentCostByStage = totalizerStudentCostByStages[i];

      for (let j = 0; j < totalizerStudentCostByStage.totalizersStudentsCostByLocations.length; j++) {
        const totalizerStudentCostByLocation: TotalizerStudentCostByLocation = totalizerStudentCostByStage.totalizersStudentsCostByLocations[j];

        const enrollmentProjectionLocation = enrollmentProjection.enrollmentsProjectionsByLocations.find(tEnrollmentByLocation => tEnrollmentByLocation.id === totalizerStudentCostByLocation.id);
        const enrollmentProjectionStage = enrollmentProjectionLocation.stagesEnrollments.find(tEnrollmentByStage => tEnrollmentByStage.id === totalizerStudentCostByStage.id);

        if (enrollmentProjectionStage) {
          const enrollmentBystage = _.first(enrollmentProjectionStage.totalEnrollments);

          const totalEnrollment = enrollmentBystage.quantity;
          const totalEnrollmentNocturnal = enrollmentBystage.quantityNocturnal;
          // const totalEnrollmentIntegral = ((totalEnrollment - totalEnrollmentNocturnal) * (enrollmentProjectionStage.integralPercentage / 100));
          const totalEnrollmentIntegral = (totalEnrollment * (enrollmentProjectionStage.integralPercentage / 100));
          const totalEnrollmentPartial = (totalEnrollment - totalEnrollmentIntegral);

          const numberStudentClass = this.getNumberStudentClassByStageLocation(enrollmentProjectionStage.id, enrollmentProjectionLocation.id);
          let enrollmentReference: number;

          if (totalizerStudentCostByStage.id === StageEnum.creche) {
            enrollmentReference = EnrollmentReferenceEnum.enrollmentNumberCreche;
          } else if (totalizerStudentCostByStage.id === StageEnum.preEscola) {
            enrollmentReference = EnrollmentReferenceEnum.enrollmentNumberPreEscola;
          } else if (totalizerStudentCostByStage.id === StageEnum.ensinoFundamentalAnosIniciais) {
            enrollmentReference = EnrollmentReferenceEnum.enrollmentNumberEFAI;
          } else if (totalizerStudentCostByStage.id === StageEnum.ensinoFundamentalAnosFinais) {
            enrollmentReference = EnrollmentReferenceEnum.enrollmentNumberEFAF;
          } else if (totalizerStudentCostByStage.id === StageEnum.ensinoMedio) {
            enrollmentReference = EnrollmentReferenceEnum.enrollmentNumberEM;
          } else if (totalizerStudentCostByStage.id === StageEnum.eja) {
            enrollmentReference = EnrollmentReferenceEnum.enrollmentNumberEJA;
          }

          // totalizerStudentCostByLocation.considerCAQ = (totalEnrollment < (numberStudentClass * 2)) ? false : true;
          // totalizerStudentCostByLocation.considerCAQ = true;

          // totalizerStudentCostByLocation.considerCAQ = true; /*(totalEnrollment < enrollmentReference) ? false : true;*/

          for (let k = 0; k < totalizerStudentCostByLocation.totalizersStudentsCostByShifts.length; k++) {
            const totalizerStudentCostByShift: TotalizerStudentCostByShift = totalizerStudentCostByLocation.totalizersStudentsCostByShifts[k];

            for (let l = 0; l < totalizerStudentCostByShift.totalizersStudentsCostByYear.length; l++) {
              const totalizerStudentCostByYear = totalizerStudentCostByShift.totalizersStudentsCostByYear[l];

              if (totalizerStudentCostByShift.id === ShiftEnum.Parcial) {
                totalizerStudentCostByYear.enrollmentQuantity = totalEnrollmentPartial > 0 ? totalEnrollmentPartial : undefined;
                // totalizerStudentCostByShift.considerCAQ = (totalEnrollmentPartial < enrollmentReference) ? false : true;
                totalizerStudentCostByShift.considerCAQ = true;
              } else if (totalizerStudentCostByShift.id === ShiftEnum.Integral) {
                totalizerStudentCostByYear.enrollmentQuantity = totalEnrollmentIntegral > 0 ? totalEnrollmentIntegral : undefined;
                // totalizerStudentCostByShift.considerCAQ = (totalEnrollmentIntegral < enrollmentReference) ? false : true;
                totalizerStudentCostByShift.considerCAQ = true;
              }
            }
          }
        }
      }
    }
  }

  private setTotalEnrollment(caqRangeReport: CaqReport) {

    for (let i = 0; i < caqRangeReport.totalizersStudentsCostByStages.length; i++) {
      const totalizerStudentCostByStage: TotalizerStudentCostByStage = caqRangeReport.totalizersStudentsCostByStages[i];
      for (let j = 0; j < totalizerStudentCostByStage.totalizersStudentsCostByLocations.length; j++) {
        const totalizerStudentCostByLocation: TotalizerStudentCostByLocation = totalizerStudentCostByStage.totalizersStudentsCostByLocations[j];
        for (let k = 0; k < totalizerStudentCostByLocation.totalizersStudentsCostByShifts.length; k++) {
          const totalizerStudentCostByShift: TotalizerStudentCostByShift = totalizerStudentCostByLocation.totalizersStudentsCostByShifts[k];
          for (let l = 0; l < totalizerStudentCostByShift.totalizersStudentsCostByYear.length; l++) {
            const totalizerStudentCostByYear = totalizerStudentCostByShift.totalizersStudentsCostByYear[l];

            if (totalizerStudentCostByYear.enrollmentQuantity) {
              if (caqRangeReport.totalizersStudentsCostByYearTotal[l].enrollmentQuantity) {
                caqRangeReport.totalizersStudentsCostByYearTotal[l].enrollmentQuantity += totalizerStudentCostByYear.enrollmentQuantity;
              } else {
                caqRangeReport.totalizersStudentsCostByYearTotal[l].enrollmentQuantity = totalizerStudentCostByYear.enrollmentQuantity;
              }
            }
          }
        }
      }
    }
  }
}
