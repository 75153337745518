import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil, map } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { SimulationType } from '../simulator/simulator/entities/enums/simulation-type.enum';
import { SimulatorService } from '../simulator/simulator/services/simulator.service';
import { AuthService } from '../shared/services/auth/auth.service';
import { BaseUnsubscribe } from '../shared/entities/base/base-unsubscribe';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent extends BaseUnsubscribe implements OnInit {

  financingSimulationIsEnable: boolean = false;

  readonly caq: SimulationType = SimulationType.caq;
  readonly planning: SimulationType = SimulationType.planning;
  readonly financing: SimulationType = SimulationType.financing;

  bsModalRef: BsModalRef;

  counter = 10; 

  constructor(private simulatorService: SimulatorService, private authService: AuthService, private bsModalService: BsModalService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    setInterval( () => this.numDecrement(this.counter), 1000);    
  }

  numDecrement(numRecieved : number) {
    while(numRecieved > 0) {
      this.counter = numRecieved - 1;
      return numRecieved;
    }
  }
 
  startSimulation(simulationType: SimulationType): void {
    this.simulatorService.startSimulation(simulationType);
  }

}
