import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { NavigableComponentService } from '../../shared/entities/base/navigable-component-service';
import { CurrentYearService } from '../../shared/services/current-year/current-year.service';
import { HttpService } from '../../../shared/services/http/http.service';
import { SessionService } from '../../../shared/services/session/session.service';
import { UtilitiesService } from '../../../shared/services/utilities/utilities.service';
import { DescriptionValue } from '../entities/description-value';
import { Sociodemographic } from '../entities/sociodemographic';
import { State } from '../entities/state';
import { City } from '../entities/city';
import { PibPerCapita } from '../entities/pib-per-capita';
import { Idhm } from '../entities/idhm';
import { SelectedLocationInfo } from '../entities/selected-location-info';
import { SelectLocation } from '../entities/select-location';
import { LocationEnum } from '../../../shared/entities/enums/location.enum';
import { AdmDependencyAndLocation } from '../entities/adm-dependency-and-location';
import { QuantityByAdmDependencyAndLocation } from '../entities/quantity-by-adm-dependency-and-location';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { Footnote } from '../../../shared/components/footnote/entities/footnote';
import { SourceInformationEnum } from '../../../shared/entities/enums/source-information.enum';

@Injectable({
  providedIn: 'root'
})
export class SelectLocationService implements NavigableComponentService {

  selectedLocationObserver: Observable<string>;
  selectedSimulatioTypeObserver: Observable<string>;

  private selectedLocationMessenger: Subject<string> = new Subject<string>();
  private selectedSimulatioTypeMessenger: Subject<string> = new Subject<string>();

  private readonly pibPerCapitaPartialUrl: string = 'pibpercapita';
  private readonly idhmPartialUrl: string = 'idhm';

  constructor(private httpService: HttpService, private utilitiesService: UtilitiesService, private sessionService: SessionService, private currentYearService: CurrentYearService) {
    this.selectedLocationObserver = this.selectedLocationMessenger.asObservable();
    this.selectedSimulatioTypeObserver = this.selectedSimulatioTypeMessenger.asObservable();
  }

  getData(): Observable<SelectLocation> {
    const selectLocation: SelectLocation = new SelectLocation();
    return of(selectLocation);
  }

  getSelectedLocationInfo(selectLocation: SelectLocation): Observable<SelectedLocationInfo> {

    const selectedLocationInfo: SelectedLocationInfo = new SelectedLocationInfo();
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const schoolCurrentYear: number = this.currentYearService.getSchoolCurrentYear();
    const populationCurrentYear: number = this.currentYearService.getPopulationCurrentYear();
    const pibPerCapitaCurrentYear: number = this.currentYearService.getPibPerCapitaCurrentYear();
    const idhmCurrentYear: number = this.currentYearService.getIdhmCurrentYear();
    const state: State = selectLocation.selectedState;
    const city: City = selectLocation.selectedCity;
    const selectLocationObservables: Array<Observable<any>> = new Array<Observable<any>>();

    // Sociodemographic info.
    selectLocationObservables.push(this.getSociodemographic(state, city, populationCurrentYear, pibPerCapitaCurrentYear, idhmCurrentYear).pipe(
      map(sociodemographic => selectedLocationInfo.sociodemographic = sociodemographic)));

    // Sociodemographic info. (Pib per capita by level).
    selectLocationObservables.push(this.getPerCapitaByLevel(state, city, pibPerCapitaCurrentYear).pipe(
      map(pibPerCapitaByLevel => selectedLocationInfo.pibPerCapitaByLevel = pibPerCapitaByLevel)));

    // Sociodemographic info. (IDHM by level).
    selectLocationObservables.push(this.getIdhmByLevel(state, city, idhmCurrentYear).pipe(
      map(idhmByLevel => selectedLocationInfo.idhmByLevel = idhmByLevel)));

    // School by adm. dependency and location.
    const schoolRequestOptions: any = this.getRequestOptionsWithParams(schoolCurrentYear, state, city);
    const schoolSourceInfo: Footnote = new Footnote({ sourceInformation: SourceInformationEnum.school, indice: state || city ? 5 : 4 });

    const schoolInfoNote: Footnote = new Footnote({
      indice: state || city ? 6 : 5,
      note: 'A contagem do número de estabelecimentos considera apenas aqueles informados como ‘em atividade’ no ano do Censo e ' +
        'que tinham pelo menos uma matrícula de Ensino Regular, Educação de Jovens e Adultos (EJA) e/ou Educação Profissional. '
    });

    selectLocationObservables.push(this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.getQuantityByAdmDependencyAndLocation(`${apiEndpoint}/school/count`, schoolRequestOptions, schoolSourceInfo, schoolInfoNote).pipe(
          map(schoolByAdmDependencyAndLocation => selectedLocationInfo.schoolByAdmDependencyAndLocation = schoolByAdmDependencyAndLocation));
      })));

    // Enrollment by adm. dependency and location.
    const enrollmentRequestOptions: any = this.getRequestOptionsWithParams(enrollmentCurrentYear, state, city);
    const enrollmentSourceInfo: Footnote = new Footnote({ sourceInformation: SourceInformationEnum.enrollment, indice: state || city ? 7 : 6 });

    const enrollmentInfoNote: Footnote = new Footnote({
      indice: state || city ? 8 : 7,
      note: 'A contagem de matrículas segue os mesmos critérios adotados pelo INEP: (a) O mesmo aluno pode ter mais de uma ' +
        'matrícula; (b) Não inclui matrículas de turmas de Atendimento Complementar e Atendimento Educacional Especializado (AEE); (c) Inclui ' +
        'matrículas do Ensino Regular, Especial e/ ou Educação de Jovens e Adultos(EJA).'
    });

    selectLocationObservables.push(this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.getQuantityByAdmDependencyAndLocation(`${apiEndpoint}/enrollment`, enrollmentRequestOptions, enrollmentSourceInfo, enrollmentInfoNote).pipe(
          map(enrollmentByAdmDependencyAndLocation => selectedLocationInfo.enrollmentByAdmDependencyAndLocation = enrollmentByAdmDependencyAndLocation));
      })));

    return forkJoin(selectLocationObservables)
      .pipe(map(() => selectedLocationInfo));
  }

  getStates(): Observable<Array<State>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/state`).pipe(
          map(states => {
            return states.map(state => new State({ value: state.id.toString(), label: state.name.toUpperCase() })).sort((s1, s2) => s1.label > s2.label ? 1 : -1);
          }));
      }));
  }

  getCities(state?: State): Observable<Array<City>> {
    const requestOptions = state
      ? this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['filter', `state:"${state.value}"`]]))
      : undefined;

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/city`, requestOptions).pipe(
          map(cities => cities.map(city => new City({ value: city.id.toString(), label: city.name }))));
      }));
  }

  notifySelectedLocationObservers(selectLocation: SelectLocation): void {
    if (selectLocation === undefined) {
      selectLocation = this.sessionService.getItem(Functionality.selectLocation.key);
    }

    const selectedLocation = this.processSelectedLocationNotificationText(selectLocation);

    this.selectedLocationMessenger.next(selectedLocation);
  }

  notifySelectedSimulationTyleObservers(simulationType: number): void {
    this.selectedSimulatioTypeMessenger.next(this.processSimulationTypeNotificationText(simulationType));
  }

  private processSelectedLocationNotificationText(selectLocation: SelectLocation): string {
    return this.utilitiesService.getSelectLocationName(selectLocation);
  }

  private processSimulationTypeNotificationText(simulationType: number): string {
    return this.utilitiesService.getSimulationTypeName(simulationType);
  }

  private getSociodemographic(state: State, city: City, populationCurrentYear: number, pibPerCapitaCurrentYear: number, idhmCurrentYear: number): Observable<Array<Sociodemographic>> {
    const sociodemographicObservables: Array<Observable<Sociodemographic>> = new Array<Observable<Sociodemographic>>();
    // const populationSourceInfo: Footnote = new Footnote({ sourceInformation: SourceInformationEnum.population, indice: 1 });
    const pibPercapitaSourceInfo: Footnote = new Footnote({ sourceInformation: SourceInformationEnum.pibPerCapita, indice: 1 });

    const pibPerCapitaNote: Footnote = new Footnote({
      indice: state || city ? 3 : 2,
      note: 'O PIB per capita é calculado pelo IBGE em nível municipal. Portanto, o PIB per capita em nível nacional é resultado da soma dos PIB municipais ' +
        'dividido pela soma da população de todos os municípios. Da mesma forma, o PIB per capita no nível estadual é resultado da soma do PIB dos municípios de ' +
        'cada estado dividido pela soma das respectivas populações.'
    });

    sociodemographicObservables.push(this.getSociodemographicData(state, city, 'population', populationCurrentYear, 'População estimada', pibPercapitaSourceInfo));
    sociodemographicObservables.push(this.getSociodemographicData(state, city, this.pibPerCapitaPartialUrl, pibPerCapitaCurrentYear, 'PIB per capita', pibPerCapitaNote, true));

    if (state || city) {
      const idhmSourceInfo: Footnote = new Footnote({ sourceInformation: SourceInformationEnum.idhmPnud, indice: 2 });
      sociodemographicObservables.push(this.getSociodemographicData(state, city, this.idhmPartialUrl, idhmCurrentYear, 'IDHM', idhmSourceInfo));
    }

    return forkJoin(sociodemographicObservables);
  }

  private getSociodemographicData(state: State, city: City, urlSufix: string, currentYear: number, description: string, footnote: Footnote, round: boolean = false): Observable<Sociodemographic> {
    const requestOptions: any = this.getRequestOptionsWithParams(currentYear, state, city);

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/${urlSufix}`, requestOptions).pipe(
          map(arrayData => {
            const data: any = _.first(arrayData);
            let value: number = this.utilitiesService.toNumber(data.total);

            if (round) {
              value = this.utilitiesService.roundNumber(value, 0);
            }

            return new Sociodemographic({ description: description, value: value, footnote: footnote });
          }));
      }));
  }

  private getPerCapitaByLevel(state: State, city: City, pibPerCapitaCurrentYear: number): Observable<PibPerCapita> {

    //Não estava retornando para os municípios, pois o ultimo ano era 2017
    const requestOptions: any = this.getRequestOptionsWithParams(city === undefined ? pibPerCapitaCurrentYear : 2017, state, city);
    requestOptions.params = requestOptions.params.set('dims', 'income_level');

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/${this.pibPerCapitaPartialUrl}`, requestOptions).pipe(
          map(pibsPerCapita => {
            const values = pibsPerCapita.map(pibPerCapita => new DescriptionValue({ description: pibPerCapita.income_level_name, value: this.utilitiesService.toNumber(pibPerCapita.total) }));

            const pibPerCapitaNote = new Footnote({
              indice: state || city ? 4 : 3,
              note: 'Quando a localidade selecionada é o Brasil, são exibidos os quintis considerando-se os municípios brasileiros. Quando uma UF é ' +
                'selecionada, os quintis exibidos referem-se aos municípios da UF escolhida. Quando um município é selecionado, é exibido apenas o quintil à ' +
                'que ele pertence, considerando-se todos os municípios brasileiros.'
            });

            return new PibPerCapita({ levels: values, footnote: pibPerCapitaNote });
          }));
      }));
  }

  private getIdhmByLevel(state: State, city: City, idhmCurrentYear: number): Observable<Idhm> {
    if (!state && !city) {
      return of(null);
    }

    const requestOptions: any = this.getRequestOptionsWithParams(idhmCurrentYear, state, city);
    requestOptions.params = requestOptions.params.set('dims', 'idhm_level');

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/${this.idhmPartialUrl}`, requestOptions).pipe(
          map(idhms => {
            const values = idhms.map(pibPerCapita => new DescriptionValue({ description: pibPerCapita.idhm_level_name, value: pibPerCapita.total }));
            return new Idhm({ levels: values });
          }));
      }));
  }

  private getQuantityByAdmDependencyAndLocation(url: string, requestOptions: any, source: Footnote, note: Footnote): Observable<QuantityByAdmDependencyAndLocation> {
    const quantityByAdmDependencyAndLocation: QuantityByAdmDependencyAndLocation = new QuantityByAdmDependencyAndLocation();

    requestOptions.params = requestOptions.params.set('dims', 'adm_dependency_detailed,location');

    return this.httpService.get<Array<any>>(url, requestOptions).pipe(
      map(totalOfQuantityByAdmDependencyAndLocation => {
        const totalOfQuantityByAdmDependencyAndLocationGroup = _.groupBy(totalOfQuantityByAdmDependencyAndLocation, 'adm_dependency_detailed_name');
        const admDependencies: Array<string> = Object.keys(totalOfQuantityByAdmDependencyAndLocationGroup);

        quantityByAdmDependencyAndLocation.source = source;
        quantityByAdmDependencyAndLocation.note = note;

        for (const admDependency of admDependencies) {
          const admDependencyLocations: Array<any> = totalOfQuantityByAdmDependencyAndLocationGroup[admDependency];
          const admDependencyAndLocations = new AdmDependencyAndLocation({ admDependencyDescription: admDependency });

          for (const location of admDependencyLocations) {
            if (location.location_id === LocationEnum.urban) {
              admDependencyAndLocations.urbanQuantity = location.total;
            } else {
              admDependencyAndLocations.ruralQuantity = location.total;
            }
          }

          admDependencyAndLocations.totalQuantity = admDependencyAndLocations.urbanQuantity + admDependencyAndLocations.ruralQuantity;

          quantityByAdmDependencyAndLocation.admDependenciesAndLocations.push(admDependencyAndLocations);
        }

        const urbanTotal: AdmDependencyAndLocation = quantityByAdmDependencyAndLocation.admDependenciesAndLocations.reduce((previous, current) => new AdmDependencyAndLocation({
          urbanQuantity: previous.urbanQuantity + current.urbanQuantity
        }));

        const ruralTotal: AdmDependencyAndLocation = quantityByAdmDependencyAndLocation.admDependenciesAndLocations.reduce((previous, current) => new AdmDependencyAndLocation({
          ruralQuantity: previous.ruralQuantity + current.ruralQuantity
        }));

        const grandTotal: AdmDependencyAndLocation = new AdmDependencyAndLocation({
          admDependencyDescription: 'Total',
          urbanQuantity: urbanTotal.urbanQuantity,
          ruralQuantity: ruralTotal.ruralQuantity
        });

        grandTotal.totalQuantity = grandTotal.urbanQuantity + grandTotal.ruralQuantity;

        quantityByAdmDependencyAndLocation.admDependenciesAndLocations.push(grandTotal);

        return quantityByAdmDependencyAndLocation;
      }));
  }

  private getRequestOptionsWithParams(currentYear: number, state: State, city: City): any {
    const filters: Array<string> = new Array<string>(`min_year:"${currentYear}"`, `max_year:"${currentYear}"`);
    const searchParams: Map<string, string> = new Map<string, string>();

    if (city) {
      searchParams.set('dims', 'city');
      filters.push(`city:"${city.value}"`);
    } else if (state) {
      searchParams.set('dims', 'state');
      filters.push(`state:"${state.value}"`);
    }

    searchParams.set('filter', filters.join(','));

    return this.httpService.getRequestOptionsWithSearchParams(searchParams);
  }

}
