import { Functionality } from '../../../entities/functionality/functionality';
import { FunctionalityInfo } from '../../../entities/functionality/functionality-info';
import { ItemMenu } from './item-menu';

export class Menu {
    static getMenuItems(menu: string): Array<ItemMenu> {
        const menuItems: Array<ItemMenu> = new Array<ItemMenu>();

        Object.keys(Functionality).map(memberName => {
            if (Functionality[memberName] instanceof FunctionalityInfo) {
                const member: FunctionalityInfo = Functionality[memberName];

                if (!member.mainStep && member.route && member.route.find(item => item === menu)) {
                    menuItems.push(new ItemMenu({ text: member.name, routerLink: member.route, disabled: member.disabled }));
                }
            }
        });

        return menuItems;
    }
}
