import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { SessionService } from '../../../../shared/services/session/session.service';
import { SimulatorService } from '../../../simulator/services/simulator.service';

@Injectable({
  providedIn: 'root'
})
export class SimulationNotPerformedGuard implements CanActivate {

  constructor(private sessionService: SessionService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const simulationPerformed: boolean = this.sessionService.getItem<boolean>(SimulatorService.simulationPerformedSessionKey);
    return !simulationPerformed;
  }

}
