import { ItemCostEnum } from '../../../../shared/entities/enums/item-cost.enum';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Location } from '../../../../shared/entities/location';
import { PriceIndice } from '../../../charges-and-additionals/price-indices/entities/price-indice';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { EmployeeEstimateByYear } from '../calculate-employee-estimate/entities/employee-estimate-by-year';
import { CostSharingByYear } from './entities/cost-sharing-by-year';
import { CostSharingBySubitem } from './entities/cost-sharing-by-subitem';
import { EmployeeEstimate } from '../calculate-employee-estimate/entities/employee-estimate';
import { CostSharing } from './entities/cost-sharing';
import { SessionService } from '../../../../shared/services/session/session.service';
import { TeacherTraining } from '../../../quality-conditions/teacher-training/entities/teacher-training';
import { EmployeeEstimateByRole } from '../calculate-employee-estimate/entities/employee-estimate-by-role';
import { EmployeeEstimateByLocation } from '../calculate-employee-estimate/entities/employee-estimate-by-location';
import { CostSharingByLocation } from './entities/cost-sharing-by-location';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { PricesIndices } from '../../../charges-and-additionals/price-indices/entities/prices-indices';
import { SchoolsStaff } from '../../../quality-conditions/schools-staff/entities/schools-staff';

@Injectable({
  providedIn: 'root'
})
export class CalculateCostSharingService {

  constructor(private sessionService: SessionService, private utilitiesService: UtilitiesService) { }

  calculateCostSharing(locations: Array<Location>, resultsForEmployeeEstimate: Array<EmployeeEstimate>, isFinancingFundsReport: boolean = false): Observable<Array<CostSharing>> {

    const costsSharing: Array<CostSharing> = new Array<CostSharing>();
    const resultForPriceIndice: PricesIndices = this.sessionService.getItem<PricesIndices>(Functionality.priceIndices.key);
    const resultForTeacherTraining: TeacherTraining = this.sessionService.getItem<TeacherTraining>(Functionality.teacherTraining.key);
    const employeeEstimateTotal: EmployeeEstimate = new EmployeeEstimate({ employeesEstimateByLocations: new Array<EmployeeEstimateByLocation>() });

    for (let i = 0; i < locations.length; i++) {
      employeeEstimateTotal.employeesEstimateByLocations.push(
        new EmployeeEstimateByLocation({
          id: locations[i].id,
          description: locations[i].description,
          employeesEstimateByRoles: this.getTotalEmployeeEstimate(resultsForEmployeeEstimate, locations[i].id, isFinancingFundsReport)
        }));
    }

    const costsSharingByLocations: Array<CostSharingByLocation> = new Array<CostSharingByLocation>();

    for (let i = 0; i < employeeEstimateTotal.employeesEstimateByLocations.length; i++) {

      const employeeEstimateByLocation: EmployeeEstimateByLocation = employeeEstimateTotal.employeesEstimateByLocations[i];

      costsSharingByLocations.push(new CostSharingByLocation({
        id: employeeEstimateByLocation.id,
        description: employeeEstimateByLocation.description,
        costsSharingBySubitems: this.getCostSharingBySubitems(resultForPriceIndice, employeeEstimateByLocation, resultForTeacherTraining)
      }));

    }

    costsSharing.push(new CostSharing({
      costsSharingByLocations: costsSharingByLocations
    }));

    return of(costsSharing);
  }

  private getTotalEmployeeEstimate(resultsForEmployeeEstimate: Array<EmployeeEstimate>, location_id: number, isFinancingFundsReport: boolean): Array<EmployeeEstimateByRole> {

    const employeesEstimateByLocations: Array<EmployeeEstimateByLocation> = new Array<EmployeeEstimateByLocation>();
    const employeesEstimateByRoles: Array<EmployeeEstimateByRole> = new Array<EmployeeEstimateByRole>();
    const resultForSchoolStaff: SchoolsStaff = this.sessionService.getItem<SchoolsStaff>(Functionality.schoolsStaff.key);

    for (let i = 0; i < resultsForEmployeeEstimate.length; i++) {
      const resultForEmployeeEstimate = resultsForEmployeeEstimate[i];

      for (let j = 0; j < resultForEmployeeEstimate.employeesEstimateByLocations.length; j++) {
        const employeeEstimateByLocation = resultsForEmployeeEstimate[i].employeesEstimateByLocations[j];
        if (employeeEstimateByLocation.id === location_id) {
          employeesEstimateByLocations.push(employeeEstimateByLocation);
        }
      }
    }

    for (let i = 0; i < employeesEstimateByLocations.length; i++) {
      const employeeEstimateByLocation = employeesEstimateByLocations[i];

      for (let j = 0; j < employeeEstimateByLocation.employeesEstimateByRoles.length; j++) {
        const employeeEstimateByRole = employeeEstimateByLocation.employeesEstimateByRoles[j];
        employeesEstimateByRoles.push(employeeEstimateByRole);
      }
    }

    const employeesEstimateByRolesTotal: Array<EmployeeEstimateByRole> = new Array<EmployeeEstimateByRole>();
    const simulationYears = this.utilitiesService.getSimulationYears(isFinancingFundsReport ? 1 : 0);

    for (let i = 0; i < resultForSchoolStaff.staffs.length; i++) {
      const staff = resultForSchoolStaff.staffs[i];

      const employeeEstimateByRoleTotal = new EmployeeEstimateByRole({
        id: staff.sequence,
        description: staff.denomination,
        isEducationAssistant: staff.isEducationAssistant,
        employeesEstimatesByYear: simulationYears.map(year => new EmployeeEstimateByYear({ year: year, quantity: 0 }))
      });

      for (let j = 0; j < employeesEstimateByRoles.length; j++) {
        if (employeesEstimateByRoles[j].id === staff.sequence) {
          employeeEstimateByRoleTotal.priceIndice_id = employeesEstimateByRoles[j].priceIndice_id;
          employeeEstimateByRoleTotal.expenseType_id = employeesEstimateByRoles[j].expenseType_id;
          employeeEstimateByRoleTotal.expenseType_description = employeesEstimateByRoles[j].expenseType_description;
          employeeEstimateByRoleTotal.grossMonthlyRemuneration = employeesEstimateByRoles[j].grossMonthlyRemuneration;
          employeeEstimateByRoleTotal.multiplier = employeesEstimateByRoles[j].multiplier;
          for (let k = 0; k < employeesEstimateByRoles[j].employeesEstimatesByYear.length; k++) {
            employeeEstimateByRoleTotal.employeesEstimatesByYear[k].quantity += employeesEstimateByRoles[j].employeesEstimatesByYear[k].quantity;
          }
        }
      }
      employeesEstimateByRolesTotal.push(employeeEstimateByRoleTotal);
    }

    return employeesEstimateByRolesTotal;
  }

  private getCostSharingBySubitems(resultForPriceIndice: PricesIndices, employeeEstimateByLocation: EmployeeEstimateByLocation,
    resultForTeacherTraining: TeacherTraining): Array<CostSharingBySubitem> {

    const costsSharingBySubitems: Array<CostSharingBySubitem> = new Array<CostSharingBySubitem>();
    const employeesEstimatesByYear: Array<EmployeeEstimateByYear> = new Array<EmployeeEstimateByYear>();
    let priceIndiceRole: number = 0;

    for (let i = 0; i < employeeEstimateByLocation.employeesEstimateByRoles.length; i++) {

      priceIndiceRole = employeeEstimateByLocation.employeesEstimateByRoles[i].priceIndice_id;

      const employeeEstimateByRole = employeeEstimateByLocation.employeesEstimateByRoles[i];
      costsSharingBySubitems.push(new CostSharingBySubitem({
        id: employeeEstimateByRole.id,
        description: employeeEstimateByRole.description,
        isEducationAssistant: employeeEstimateByRole.isEducationAssistant,
        costsSharingByYears: this.getCostSalaryByYears(employeeEstimateByRole, resultForPriceIndice)
      }));

      for (let j = 0; j < employeeEstimateByRole.employeesEstimatesByYear.length; j++) {
        employeesEstimatesByYear.push(employeeEstimateByRole.employeesEstimatesByYear[j]);
      }

    }

    let priceIndice: PriceIndice = new PriceIndice();
    for (let j = 0; j < resultForPriceIndice.indices.length; j++) {
      if (resultForPriceIndice.indices[j].id === priceIndiceRole) {
        priceIndice = resultForPriceIndice.indices[j];
        break;
      }
    }

    costsSharingBySubitems.push(new CostSharingBySubitem({
      id: ItemCostEnum.FormacaoNaoDocentes.toString(),
      description: 'Employee training',
      costsSharingByYears: this.getCostTrainingByYears(employeesEstimatesByYear, priceIndice, resultForTeacherTraining)
    }));

    return costsSharingBySubitems;

  }

  private getCostSalaryByYears(employeeEstimateByRole: EmployeeEstimateByRole, resultForPriceIndice: PricesIndices): Array<CostSharingByYear> {

    const costsSharingByYears: Array<CostSharingByYear> = new Array<CostSharingByYear>();
    let price_subitem = employeeEstimateByRole.grossMonthlyRemuneration;
    const multiplier = employeeEstimateByRole.multiplier;

    let priceIndice: PriceIndice;

    for (let i = 0; i < resultForPriceIndice.indices.length; i++) {
      if (resultForPriceIndice.indices[i].id === employeeEstimateByRole.priceIndice_id) {
        priceIndice = resultForPriceIndice.indices[i];
        break;
      }
    }

    for (let i = 0; i < priceIndice.years.length; i++) {

      const priceIndiceByYear = priceIndice.years[i];

      let totalEmployeesYear: EmployeeEstimateByYear = new EmployeeEstimateByYear();
      for (let j = 0; j < employeeEstimateByRole.employeesEstimatesByYear.length; j++) {
        if (employeeEstimateByRole.employeesEstimatesByYear[j].year === priceIndiceByYear.year) {
          totalEmployeesYear = employeeEstimateByRole.employeesEstimatesByYear[j];
          break;
        }
      }

      const indiceValue = priceIndiceByYear.value;
      price_subitem = price_subitem * (1 + (indiceValue / 100));

      costsSharingByYears.push(new CostSharingByYear({
        year: priceIndiceByYear.year,
        cost: totalEmployeesYear.quantity * price_subitem * multiplier
      }));

    }
    return costsSharingByYears;
  }

  private getCostTrainingByYears(employeesEstimatesByYear: Array<EmployeeEstimateByYear>, priceIndice: PriceIndice, resultForTeacherTraining: TeacherTraining): Array<CostSharingByYear> {

    const costsSharingByYears: Array<CostSharingByYear> = new Array<CostSharingByYear>();
    const price_subitem = resultForTeacherTraining ? resultForTeacherTraining.employeeTrainingValue : 0;

    for (let i = 0; i < priceIndice.years.length; i++) {

      const priceIndiceByYear = priceIndice.years[i];
      const indiceValue = priceIndiceByYear.value;
      let totalEmployees: number = 0;

      for (let j = 0; j < employeesEstimatesByYear.length; j++) {
        if (employeesEstimatesByYear[j].year === priceIndiceByYear.year) {
          totalEmployees += employeesEstimatesByYear[j].quantity;
        }
      }

      const price_subitem_indexed = price_subitem * (1 + (indiceValue / 100));

      costsSharingByYears.push(new CostSharingByYear({
        year: priceIndiceByYear.year,
        cost: totalEmployees * price_subitem_indexed
      }));

    }
    return costsSharingByYears;
  }

}
