import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SimulationsComponent } from './simulations/simulations.component';

const routes: Routes = [
  {
    path: 'simulations',
    component: SimulationsComponent
    // children: [
    //   { path: 'new', component: SimulationFormComponent },
    //   { path: ':id', component: SimulationViewComponent },
    //   { path: ':id/edit', component: SimulationFormComponent },
    // ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SimulationsRoutingModule { }
