import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseUnsubscribe } from '../../../entities/base/base-unsubscribe';
import { TutorialsService } from './services/tutorials.service';
import { Tutorial } from './entities/tutorial';
import { SimulationType } from '../../../../simulator/simulator/entities/enums/simulation-type.enum';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent extends BaseUnsubscribe implements OnInit {

  readonly videoTutorialPlatformView: number = -2;
  readonly videoTutorialView: number = -1;
  readonly tutorialView: number = 0;
  readonly firstStepView: number = 1;

  simulationType: SimulationType;
  tutorial: Tutorial;
  videoTutorialSource: SafeResourceUrl;
  startSimulation: Observable<boolean>;
  lastStep: boolean;
  skipTutorialJustHide: boolean;
  currentView: number = 0;

  private startSimulationMessenger: Subject<boolean> = new Subject<boolean>();

  constructor(public bsModalRef: BsModalRef, private tutorialsService: TutorialsService, private domSanitizer: DomSanitizer) {
    super();
    this.startSimulation = this.startSimulationMessenger.asObservable();
  }

  ngOnInit() {
    if (this.currentView === this.tutorialView) {
      this.tutorialsService.getTutorial(this.simulationType).pipe(
        takeUntil(this.unsubscribe))
        .subscribe(tutorial => {
          this.videoTutorialSource = this.domSanitizer.bypassSecurityTrustResourceUrl(tutorial.videoSource);
          this.tutorial = tutorial;
        });
    } else if (this.currentView === this.videoTutorialPlatformView) {
      this.videoTutorialSource = this.domSanitizer.bypassSecurityTrustResourceUrl(this.tutorial.videoSource);
    }
  }

  startSimulationNow(): void {
    if (this.skipTutorialJustHide) {
      this.hide();
    } else {
      this.startSimulationMessenger.next(true);
      this.bsModalRef.hide();
    }
  }

  previousStep(): void {
    this.currentView--;
    this.lastStep = false;
  }

  nextStep(): void {
    this.currentView++;

    if (this.currentView === this.tutorial.steps.length) {
      this.lastStep = true;
    }
  }

  hide(): void {
    this.startSimulationMessenger.next(false);
    this.bsModalRef.hide();
  }

}
