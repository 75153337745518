import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { ViewEnrollmentByStageSeriesService } from './services/view-enrollment-by-stage-series.service';
import { ViewEnrollmentByStageSeries } from './entities/view-enrollment-by-stage-series';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { OutOfSchoolPopulationService } from '../../shared/services/out-of-school-population/out-of-school-population.service';
import { SerieEnum } from '../../../shared/entities/enums/serie.enum';
import { SimulationType } from '../../simulator/entities/enums/simulation-type.enum';
import { UtilitiesService } from '../../../shared/services/utilities/utilities.service';
import { Footnote } from '../../../shared/components/footnote/entities/footnote';

@Component({
  selector: 'app-view-enrollment-by-stage-series',
  templateUrl: './view-enrollment-by-stage-series.component.html',
  styleUrls: ['./view-enrollment-by-stage-series.component.scss']
})
export class ViewEnrollmentByStageSeriesComponent extends BaseNavigableComponent<ViewEnrollmentByStageSeries> {

  data: ViewEnrollmentByStageSeries = new ViewEnrollmentByStageSeries();
  functionality: FunctionalityInfo = Functionality.viewEnrollmentByStageAndSeries;
  inconsistencies: Array<Inconsistency>;

  constructor(private viewEnrollmentByStageSeriesService: ViewEnrollmentByStageSeriesService, private injector: Injector, private outOfSchoolPopulationService: OutOfSchoolPopulationService) {
    super(injector);
  }

  processData(): Observable<ViewEnrollmentByStageSeries> {
    return this.viewEnrollmentByStageSeriesService.getData();
  }

  processSpecificBehaviors(): void {
    super.processSpecificBehaviors();

    this.viewEnrollmentByStageSeriesService.setOutOfSchoolPopulation(this.data).pipe(
      takeUntil(this.unsubscribe))
      .subscribe();
  }

  serieIsVisibleOnOutOfSchoolColumn(serieId: number): boolean {
    return this.outOfSchoolPopulationService.serieIsVisibleOnOutOfSchoolColumn(serieId);
  }

  getRowspanOutOfSchoolValue(serieId: number, serieQuantity: number): number {
    if (serieId === SerieEnum.ensinoFundamentalPrimeiroAno || serieId === SerieEnum.ensinoFundamentalQuintaSerieSextoAno || serieId === SerieEnum.ensinoMedioPrimeiraSerie) {
      return serieQuantity;
    }

    return 1;
  }

  getPreviousStepRoute(): Array<string> {
    const simulationType: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);

    return undefined;
    /*if (simulationType !== SimulationType.planning && simulationType !== SimulationType.planningByStateSphereAdm && simulationType !== SimulationType.planningByCitySphereAdm) {
      return undefined;
    } else {
      return this.functionality.previousStep;
    }*/
  }

  dataHasChanged(): boolean {
    return false;
  }

  getViewEnrollmentByStageSeriesNotes(): Array<Footnote> {
    const viewEnrollmentByStageSeries = this.data;

    if (viewEnrollmentByStageSeries.notesOutOfSchoolPopulation) {
      return new Array<Footnote>(...viewEnrollmentByStageSeries.notesOutOfSchoolPopulation, viewEnrollmentByStageSeries.enrollmentNote);
    } else {
      return new Array<Footnote>(viewEnrollmentByStageSeries.enrollmentNote);
    }
  }

}
