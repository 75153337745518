import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { MatomoInjector } from 'ngx-matomo';
import { BsModalService } from 'ngx-bootstrap/modal';

import { BaseUnsubscribe } from './shared/entities/base/base-unsubscribe';
import { SessionService } from './shared/services/session/session.service';
import { UtilitiesService } from './shared/services/utilities/utilities.service';
import { SimulationType } from './simulator/simulator/entities/enums/simulation-type.enum';
import { SimulatorService } from './simulator/simulator/services/simulator.service';
import { AuthService } from './shared/services/auth/auth.service';
import { UserInfo } from './shared/services/auth/entities/user-info';
import { environment } from '../environments/environment';
import { TutorialsComponent } from './shared/components/modal/tutorials/tutorials.component';
import { Tutorial } from './shared/components/modal/tutorials/entities/tutorial';
import { ViewEnum } from './shared/components/modal/tutorials/entities/enums/view.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseUnsubscribe implements OnInit {

  navBarIsCollapsed: boolean = true;
  isHome: boolean;
  isQualityPage: boolean;
  showFooter: boolean;
  isInverseColor: boolean = false;
  userInfo: UserInfo = new UserInfo();

  readonly caq: SimulationType = SimulationType.caq;
  readonly caqText: string = UtilitiesService.caqText;
  readonly planning: SimulationType = SimulationType.planning;
  readonly planningByStateSphereAdm: SimulationType = SimulationType.planningByStateSphereAdm;
  readonly planningByCitySphereAdm: SimulationType = SimulationType.planningByCitySphereAdm;
  readonly planningText: string = UtilitiesService.planningText;
  readonly financingFederatedEntitiesText: string = UtilitiesService.financingFederatedEntitiesText;
  readonly financingFederatedEntitiesByStateSphereAdm: SimulationType = SimulationType.financingFederatedEntitiesByStateSphereAdm;
  readonly financingFederatedEntitiesByCitySphereAdm: SimulationType = SimulationType.financingFederatedEntitiesByCitySphereAdm;
  readonly financingFederatedEntitiesGroupByCityOrState: SimulationType = SimulationType.financingFederatedEntitiesGroupByCityOrState;

  readonly videosLinks = {
    'about': 'https://www.youtube.com/embed/wWhQob6Efhw',
    'caq': 'https://www.youtube.com/embed/_mt3H6b4IG0',
    'planning': 'https://www.youtube.com/embed/tj9grxy-gd0'
  };

  @ViewChild('navbarMenuToggle', { static: false }) navbarMenuToggle: ElementRef;
  @ViewChild('navbarContent', { static: false }) navbarContent: ElementRef;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private utilitiesService: UtilitiesService,
    private simulatorService: SimulatorService,
    private authService: AuthService,
    private matomoInjector: MatomoInjector,
    private bsModalService: BsModalService) {
    super();

    this.matomoInjector.init('https://piwik.c3sl.ufpr.br/', environment.piwikId);

    // Clear 'has error' flag.
    this.sessionService.removeItem(UtilitiesService.hasErrorSessionKey);
  }

  ngOnInit(): void {
    this.listenRouteEvents();

    this.verifyUserInfo();

    this.authService.userLoggedObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userIsLoggedIn => {
        if (userIsLoggedIn) {
          this.verifyUserInfo();
        } else {
          this.userInfo = new UserInfo();
        }
      });
  }

  startSimulation(simulationType: SimulationType): void {
    this.simulatorService.startSimulation(simulationType);
  }

  openTutorial(tutorialType: string): void {
    const tutorial: Tutorial = new Tutorial({ videoSource: this.videosLinks[tutorialType] });
    this.bsModalService.show(TutorialsComponent, { ignoreBackdropClick: false, class: 'modal-lg modal-tutorial', initialState: { currentView: ViewEnum.VideoTutorialPlatform, tutorial } });
  }

  private listenRouteEvents(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe))
      .subscribe((event: NavigationEnd) => {
        this.isHome = this.utilitiesService.isHome(event.url);
        this.isQualityPage = event.url.endsWith('/quality');
        // this.isInverseColor = !this.isQualityPage;
        this.showFooter = true;

        // Close the navbar menu (mobile screen).
        // if (this.navbarContent.nativeElement.classList.contains('show')) {
        //   this.navbarMenuToggle.nativeElement.click();
        // }
      });
  }

  private verifyUserInfo(): void {
    this.authService.getUserInfo().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userInfo => this.userInfo = userInfo);
  }

}
