import { NavigableComponentData } from "app/simulator/shared/entities/base/navigable-component-data";
import { ResultStageEnrollments } from "./stage-enrollments-by-school";

export class ResultEnrollmentProjection extends NavigableComponentData {

    resultEnrollmentProjection: Array<ResultStageEnrollments> = new Array<ResultStageEnrollments>();
    year: number;
    
    constructor(init?: Partial<ResultEnrollmentProjection>) {
        super();
        Object.assign(this, init);
    }
}
