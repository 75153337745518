import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { EnrollmentProjection } from '../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { ColumnReportByShift } from '../entities/column-report-by-shifts';
import { ColumnReportByLocation } from '../entities/column-report-by-location';
import { ColumnReport } from '../entities/column-report';
import { State } from '../../../../user/entities/state';
import { RevenueByState } from '../entities/revenue-by-state';
import { OfferGoalEnrollmentFullTime } from '../../../quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { NumberStudentClass } from '../../../quality-conditions/number-student-class/entities/number-student-class';
import { SessionService } from '../../../../shared/services/session/session.service';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { EnrollmentAndClassAndClassroom } from '../entities/enrollment-and-class-and-classroom';
import { CreateProjectionsService } from '../../../shared/services/create-projections/create-projections.service';
import { CalculateClassNumberService } from '../../../shared/services/calculate-class-number/calculate-class-number.service';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { FinancingsFundsReport } from '../entities/financings-funds-report';
import { SchoolByState } from '../../../shared/services/calculate-employee-estimate/entities/school_by_state';
import { FinancingFundsReport } from '../entities/financing-funds-report';
import { InfrastructureSchoolBuildingsService } from '../../../quality-conditions/infrastructure-school-buildings/services/infrastructure-school-buildings.service';
import { NewRoomBuildingService } from '../../../quality-conditions/new-room-building/services/new-room-building.service';
import { CalculateCapitalExpenseService } from '../../../shared/services/calculate-capital-expense/calculate-capital-expense.service';
import { BudgetForecastService } from '../../shared/services/budget-forecast.service';
import { ItemCostService } from '../../../shared/services/calculate-student-cost/services/item-cost.service';
import { StudentCostService } from '../../../shared/services/calculate-student-cost/services/student-cost.service';
import { CalculateCostSharingService } from '../../../shared/services/calculate-cost-sharing/calculate-cost-sharing.service';
import { CalculateCostDriveService } from '../../../shared/services/calculate-cost-drive/calculate-cost-drive.service';
import { CalculateEmployeeEstimateService } from '../../../shared/services/calculate-employee-estimate/calculate-employee-estimate.service';
import { CsvService } from '../../../../shared/services/csv/csv.service';
import { CsvHeader } from '../../../../shared/services/csv/entities/csv-header';
import { Csv } from '../../../../shared/services/csv/entities/csv';
import { FinancialDataTypeEnum } from '../../../../shared/entities/enums/financial-data-type.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { ShiftEnum } from './../../../../shared/entities/enums/shift-enum';
import { StageEnum } from './../../../../shared/entities/enums/stage.enum';
import { CareerAndRemunerationTeachersService } from './../../../quality-conditions/career-and-remuneration-teachers/services/career-and-remuneration-teachers.service';
import { CareerAndRemunerationTeachers } from './../../../quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { WorkJourneyTeacher } from './../../../quality-conditions/work-journey-teacher/entities/work-journey-teacher';
import { DailyTeachingLoad } from './../../../quality-conditions/daily-teaching-load/entities/daily-teaching-load';
import { SchoolDayPerWeek } from './../../../quality-conditions/school-day-per-week/entities/school-day-per-week';
import { CreateTeacherNumberCalcService } from './../../../shared/services/create-teacher-number-calc/create-teacher-number-calc.service';

@Injectable({
  providedIn: 'root'
})
export class FinancingFundsReportService {

  constructor(
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService,
    private csvService: CsvService,
    private calculateClassNumberService: CalculateClassNumberService,
    private createProjectionsService: CreateProjectionsService,
    private currentYearService: CurrentYearService,
    private calculateEmployeeEstimateService: CalculateEmployeeEstimateService,
    private calculateCostDriveService: CalculateCostDriveService,
    private calculateCostSharingService: CalculateCostSharingService,
    private studentCostService: StudentCostService,
    private itemCostService: ItemCostService,
    private budgetForecastService: BudgetForecastService,
    private calculateCapitalExpenseService: CalculateCapitalExpenseService,
    private newRoomBuildingService: NewRoomBuildingService,
    private infrastructureSchoolBuildingsService: InfrastructureSchoolBuildingsService,
    private careerAndRemunerationTeachersService: CareerAndRemunerationTeachersService,
    private createTeacherNumberCalcService: CreateTeacherNumberCalcService
  ) { }

  getFinancingsFundsReport(): Observable<FinancingsFundsReport> {

    const observables: Array<Observable<any>> = new Array<Observable<any>>();
    const observables2: Array<Observable<any>> = new Array<Observable<any>>();
    let states: Array<State> = new Array<State>();
    let priceCub;
    let demandConstruction;
    let locations;
    let stages;
    let revenues;
    let infrastructuresSchools;
    let enrollmentsAndClassesAndClassroomsByState;
    let enrollmentsSchoolState;
    let diagnosticsTeachers;
    const financialCurrentYear: number = this.currentYearService.getFinancialCurrentYear();
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const simulationYearInitial: Array<number> = this.utilitiesService.getSimulationYears();
    const data: FinancingsFundsReport = new FinancingsFundsReport({ financingsFundsReport: new Array<FinancingFundsReport>() });
    const resultForCareerAndRemunerationTeacher: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.key);

    observables.push(this.utilitiesService.getCub(true).pipe(
      map(resultPriceCub => priceCub = resultPriceCub)));

    observables.push(this.utilitiesService.getDemandConstruction().pipe(
      map(resultDemandConstruction => demandConstruction = resultDemandConstruction)));

    observables.push(this.utilitiesService.getLocations().pipe(
      map(resultLocations => locations = resultLocations)));

    observables.push(this.utilitiesService.getStages().pipe(
      map(resultStages => stages = resultStages)));

    observables.push(this.calculateEmployeeEstimateService.getEnrollmentSchoolState().pipe(
      map(resultEnrollmentSchoolState => enrollmentsSchoolState = resultEnrollmentSchoolState)));

    observables.push(this.getEnrollmentAndClassAndClassroom().pipe(
      map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsByState = resultEnrollmentAndClassAndClassroom)));

    observables.push(this.careerAndRemunerationTeachersService.getDiagnostic(true).pipe(
      map(resultDiagnosticTeacher => diagnosticsTeachers = resultDiagnosticTeacher)));

    observables.push(this.utilitiesService.getStates().pipe(
      mergeMap(resultStates => {
        states = resultStates;
        return this.getRevenue(resultStates).pipe(
          mergeMap(resultRevenue => {
            revenues = resultRevenue;
            return this.infrastructureSchoolBuildingsService.getInfrastructureSchoolsBuildingsAllStates(resultStates).pipe(
              map(resultInfrastructureSchoolsBuilding => {
                infrastructuresSchools = resultInfrastructureSchoolsBuilding;
                return infrastructuresSchools;
              }));
          }));
      })));

    return forkJoin(observables).pipe(
      mergeMap(() => {

        data.financialYear = financialCurrentYear;
        data.enrollmentYear = enrollmentCurrentYear;
        data.simulationYearInitial = _.first(simulationYearInitial);

        for (let i = 0; i < states.length; i++) {
          const revenue = this.getRevenueByState(states[i].id, revenues);
          const enrollmentAndClassAndClassroomByState = this.getEnrollmentAndClassAndClassroomByState(states[i].id, enrollmentsAndClassesAndClassroomsByState);
          const enrollmentTotal: number = this.getEnrollmentTotalByState(enrollmentAndClassAndClassroomByState.enrollmentProjection);
          const infrastructureSchools = this.infrastructureSchoolBuildingsService.getInfrastructureSchoolsBuildingsByState(states[i].id, infrastructuresSchools);
          const enrollmentSchoolState = this.getEnrollmentSchoolByState(states[i].id, enrollmentsSchoolState);
          const calculatedTeacherNumber = enrollmentAndClassAndClassroomByState.teacherNumber;

          observables2.push(
            this.newRoomBuildingService.getNewRoomsBuildingsByState(locations, demandConstruction, enrollmentAndClassAndClassroomByState).pipe(
              mergeMap(newRoomsBuilding => {
                const priceCubState = this.getPriceCubByState(states[i].id, priceCub);
                return this.calculateCapitalExpenseService.calculateCapitalExpense(newRoomsBuilding, infrastructureSchools, priceCubState).pipe(
                  mergeMap(capitalExpense => {
                    const calculatedEmployeeEstimate = this.calculateEmployeeEstimateService.calculateEmployeeEstimateByEntity(locations, enrollmentSchoolState);
                    return this.calculateCostSharingService.calculateCostSharing(locations, calculatedEmployeeEstimate, true).pipe(
                      mergeMap(calculateCostSharing => {
                        return this.calculateCostDriveService.calculateCostDrive(enrollmentAndClassAndClassroomByState.enrollmentProjection).pipe(
                          mergeMap(calculateCostDrive => {
                            return this.itemCostService.calculateItemCost(locations, stages, calculatedTeacherNumber, calculatedEmployeeEstimate, calculateCostSharing,
                              calculateCostDrive, enrollmentAndClassAndClassroomByState.enrollmentProjection, true, true, false, true, true, resultForCareerAndRemunerationTeacher, true).pipe(
                                mergeMap(itemCostTotal => {
                                  return this.itemCostService.calculateItemCost(locations, stages, calculatedTeacherNumber, calculatedEmployeeEstimate,
                                    calculateCostSharing, calculateCostDrive, enrollmentAndClassAndClassroomByState.enrollmentProjection, true, false, false, true, true,
                                    resultForCareerAndRemunerationTeacher, true).pipe(
                                      mergeMap(itemCost => {
                                        return this.itemCostService.calculateItemCost(locations, stages, calculatedTeacherNumber, calculatedEmployeeEstimate,
                                          calculateCostSharing, calculateCostDrive, enrollmentAndClassAndClassroomByState.enrollmentProjection,
                                          true, true, true, true, true, resultForCareerAndRemunerationTeacher, true).pipe(
                                            mergeMap(costOfAssetsAndServices => {
                                              return this.budgetForecastService.getExpense(itemCostTotal, capitalExpense).pipe(
                                                mergeMap(expense => {
                                                  return this.studentCostService.calculateStudentCost(locations, stages, itemCost, costOfAssetsAndServices,
                                                    enrollmentAndClassAndClassroomByState.enrollmentProjection).pipe(
                                                      map(studentsCost => {

                                                        /*const unionComplementation = (expense.capital + expense.current) > revenue.potential ?
                                                          (expense.capital + expense.current) - revenue.potential : 0;

                                                        const percentComplementation = unionComplementation > 0 ?
                                                          (((expense.capital + expense.current) / revenue.potential) - 1) * 100 : 0;*/

                                                        const unionComplementation = (expense.current) > revenue.potential ?
                                                          (expense.current) - revenue.potential : 0;

                                                        const percentComplementation = unionComplementation > 0 ?
                                                          (((expense.current) / revenue.potential) - 1) * 100 : 0;

                                                        data.enrollmentTotal += enrollmentTotal;
                                                        data.expenseCapitalTotal += expense.capital;
                                                        data.expenseCurrentTotal += expense.current;
                                                        data.potentialTotal += revenue.potential;
                                                        data.potentialPlusAgreementTotal += revenue.potentialPlusAgreement;
                                                        data.fundebTotal += revenue.fundeb;
                                                        data.expenseTotal += expense.capital + expense.current;
                                                        data.unionComplementationTotal += unionComplementation;
                                                        data.percentUnionComplementationTotal = data.unionComplementationTotal > 0 ?
                                                          (data.unionComplementationTotal / data.potentialTotal) * 100 : undefined;
                                                        data.caqBR = data.expenseCurrentTotal / data.enrollmentTotal;

                                                        return new FinancingFundsReport({
                                                          stateId: states[i].id,
                                                          stateDescription: states[i].description,
                                                          stateAbbreviation: states[i].abbreviation,
                                                          enrollmentTotal: enrollmentTotal,
                                                          totalizersStudentsCostByStages: studentsCost.totalizersStudentsCostByStages,
                                                          totalizersStudentsCostByYearTotal: studentsCost.totalizersStudentsCostByYearTotal,
                                                          caqUf: expense.current / enrollmentTotal,
                                                          revenue: revenue,
                                                          expense: expense,
                                                          unionComplementation: unionComplementation > 0 ? unionComplementation : undefined,
                                                          percentUnionComplementation: percentComplementation > 0 ? percentComplementation : undefined
                                                        });
                                                      }));
                                                }));
                                            }));
                                      }));
                                }));
                          }));
                      }));
                  }));
              })));
        }

        return forkJoin(observables2).pipe(
          map(result => {
            for (let i = 0; i < result.length; i++) {
              data.financingsFundsReport.push(result[i]);
            }
            data.sourceInformations = this.getSourceInformations();
            data.sourceInformationPQR = this.getSourceInformationPQR();
            return data;
          }));
      }));
  }

  getEnrollmentAndClassAndClassroom(): Observable<Array<EnrollmentAndClassAndClassroom>> {

    const resultForOffergoalenrollmentfulltime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.key);
    const resultForNumberstudentclass: NumberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.key);
    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();
    const resultForSchoolDayPerWeek: SchoolDayPerWeek = this.sessionService.getItem<SchoolDayPerWeek>(Functionality.schoolDayPerWeek.key);
    const resultForDailyTeachingLoad: DailyTeachingLoad = this.sessionService.getItem<DailyTeachingLoad>(Functionality.dailyTeachingLoad.key);
    const resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.key);
    const resultForWorkJourneyTeacher: WorkJourneyTeacher = this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.key);

    return this.utilitiesService.getClassAndClassroomsAndEnrollment(resultForNumberstudentclass, resultForOffergoalenrollmentfulltime, resultForSchoolDayPerWeek, resultForDailyTeachingLoad,
      resultForCareerAndRemunerationTeachers, resultForWorkJourneyTeacher, true).pipe(
        map(classAndClassroomNumberAndEnrollment => {

          for (let i = 0; i < classAndClassroomNumberAndEnrollment.length; i++) {

            const classAndClassroomNumberAndEnrollmentByLocations = classAndClassroomNumberAndEnrollment[i].locations;

            const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
              state_id: classAndClassroomNumberAndEnrollment[i].state_id,
              state_description: classAndClassroomNumberAndEnrollment[i].state_name,
              enrollmentProjection: this.createProjectionsService.getEnrollmentProjection(classAndClassroomNumberAndEnrollment[i]),
              classNumber: this.calculateClassNumberService.getClassNumberCalculated(classAndClassroomNumberAndEnrollmentByLocations),
              teacherNumber: this.createTeacherNumberCalcService.getTeacherNumberCalculated(classAndClassroomNumberAndEnrollmentByLocations)
            });

            enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);
          }
          return enrollmentAndClassAndClassrooms;
        }));
  }

  getEnrollmentAndClassAndClassroomByState(state_id: number, enrollmentsAndClassesAndClassrooms: Array<EnrollmentAndClassAndClassroom>): EnrollmentAndClassAndClassroom {

    for (let i = 0; i < enrollmentsAndClassesAndClassrooms.length; i++) {
      if (enrollmentsAndClassesAndClassrooms[i].state_id === state_id) {
        return enrollmentsAndClassesAndClassrooms[i];
      }
    }
  }

  getRevenueByState(state_id: number, revenuesByStates: Array<RevenueByState>): RevenueByState {

    for (let i = 0; i < revenuesByStates.length; i++) {
      if (revenuesByStates[i].stateId === state_id) {
        return revenuesByStates[i];
      }
    }
  }

  getRevenue(states: Array<State>): Observable<Array<RevenueByState>> {

    const revenuesByStates: Array<RevenueByState> = new Array<RevenueByState>();
    const offerYearFinancial: number = this.currentYearService.getFinancialCurrentYear();
    const filtersFinancialDataType: Array<string> = new Array<string>(`"${FinancialDataTypeEnum.potentialRevenue}"`, `"${FinancialDataTypeEnum.potentialRevenuePlusAgreement}"`,
      `"${FinancialDataTypeEnum.revenueFUNDEB}"`);

    return this.utilitiesService.getFinancialsDatas(offerYearFinancial, filtersFinancialDataType).pipe(
      map(revenues => {

        for (let i = 0; i < states.length; i++) {

          let potentialRevenue: number = 0;
          let potentialRevenuePlusAgreement: number = 0;
          let revenueFUNDEB: number = 0;

          const revenueByState: RevenueByState = new RevenueByState({
            stateId: states[i].id
          });

          for (let j = 0; j < revenues.length; j++) {

            if (revenues[j].stateId === states[i].id) {
              if (revenues[j].financialDataId === FinancialDataTypeEnum.potentialRevenue) {
                potentialRevenue += revenues[j].value;
              } else if (revenues[j].financialDataId === FinancialDataTypeEnum.potentialRevenuePlusAgreement) {
                potentialRevenuePlusAgreement += revenues[j].value;
              } else if (revenues[j].financialDataId === FinancialDataTypeEnum.revenueFUNDEB) {
                revenueFUNDEB += revenues[j].value;
              }
            }
          }
          revenueByState.potential = potentialRevenue;
          revenueByState.potentialPlusAgreement = potentialRevenuePlusAgreement;
          revenueByState.fundeb = revenueFUNDEB;

          revenuesByStates.push(revenueByState);
        }
        return revenuesByStates;
      }));
  }

  getPriceCubByState(state_id: number, priceCubStates: Array<any>): number {

    for (let i = 0; i < priceCubStates.length; i++) {
      if (priceCubStates[i].cod_uf === state_id) {
        return priceCubStates[i].preco;
      }
    }
  }

  getColumnsReport(): Observable<Array<ColumnReport>> {

    const columnsReport: Array<ColumnReport> = new Array<ColumnReport>();

    return this.utilitiesService.getStages().pipe(
      switchMap(stages => {
        return this.utilitiesService.getLocations().pipe(
          map(locations => {

            for (let i = 0; i < stages.length; i++) {
              const stage = stages[i];

              const columnReport: ColumnReport = new ColumnReport({
                stage_id: stage.id,
                stage_description: stage.description,
                columnsReportByLocations: new Array<ColumnReportByLocation>()
              });

              for (let j = 0; j < locations.length; j++) {
                const location = locations[j];
                columnReport.columnsReportByLocations.push(new ColumnReportByLocation({
                  id: location.id,
                  description: location.description,
                  columnsReportByShifts: this.getShiftsReport()
                }));
              }
              columnsReport.push(columnReport);
            }
            return columnsReport;
          }));
      }));
  }

  getEnrollmentTotalByState(enrollmentProjection: EnrollmentProjection): number {

    let enrollmentsTotal: number = 0;

    for (let i = 0; i < enrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {
      const enrollmentProjectionByLocation = enrollmentProjection.enrollmentsProjectionsByLocations[i];
      for (let j = 0; j < enrollmentProjectionByLocation.totalsEnrollmentProjection.length; j++) {
        enrollmentsTotal += enrollmentProjectionByLocation.totalsEnrollmentProjection[j].quantity;
      }

    }
    return enrollmentsTotal;
  }

  getEnrollmentSchoolByState(state_id: number, enrollmentsSchoolsByState: Array<SchoolByState>): SchoolByState {

    for (let i = 0; i < enrollmentsSchoolsByState.length; i++) {
      if (enrollmentsSchoolsByState[i].id === state_id) {
        return enrollmentsSchoolsByState[i];
      }
    }
    return;
  }

  downloadCsv(financingsFunds: FinancingsFundsReport): Observable<void> {
    return this.getColumnsReport().pipe(
      map(columnsReport => {
        const header: Array<CsvHeader> = new Array<CsvHeader>();
        const data: Array<any> = new Array<any>();

        header.push(new CsvHeader({ key: 'stateAbbreviation', label: 'UF' }));
        header.push(new CsvHeader({ key: 'enrollmentTotal', label: 'Número de matrículas total' }));
        header.push(new CsvHeader({ key: 'caqUf', label: 'CAQ UF' }));
        header.push(new CsvHeader({ key: 'potential', label: 'Receitas vinculadas' }));
        header.push(new CsvHeader({ key: 'potentialPlusAgreement', label: 'Receitas vinculadas + programas e convênios' }));
        header.push(new CsvHeader({ key: 'fundeb', label: 'Fundeb' }));
        header.push(new CsvHeader({ key: 'current', label: 'Correntes' }));
        // header.push(new CsvHeader({ key: 'capital', label: 'Capital' }));
        // header.push(new CsvHeader({ key: 'total', label: 'Total' }));
        header.push(new CsvHeader({ key: 'unionComplementation', label: 'Complementação da União' }));
        header.push(new CsvHeader({ key: 'percentUnionComplementation', label: 'Percentual de Complementação' }));

        for (const totalizerStudentCost of financingsFunds.financingsFundsReport) {
          const financingsFundsData: any = { stateAbbreviation: totalizerStudentCost.stateAbbreviation, enrollmentTotal: this.utilitiesService.roundNumber(totalizerStudentCost.enrollmentTotal, 0) };

          for (const totalizerStudentCostByStage of totalizerStudentCost.totalizersStudentsCostByStages) {
            for (const totalizerStudentCostByLocation of totalizerStudentCostByStage.totalizersStudentsCostByLocations) {
              for (const totalizerStudentCostByShift of totalizerStudentCostByLocation.totalizersStudentsCostByShifts) {
                if (!(totalizerStudentCostByStage.id === StageEnum.eja && totalizerStudentCostByShift.id === ShiftEnum.Integral)) {
                  const totalizerStudentCostByYear = _.first(totalizerStudentCostByShift.totalizersStudentsCostByYear);
                  financingsFundsData[`${totalizerStudentCostByStage.id}#${totalizerStudentCostByLocation.id}#${totalizerStudentCostByShift.id}`] =
                    this.utilitiesService.roundNumber(totalizerStudentCostByYear.cost, 0);
                }
              }
            }
          }

          financingsFundsData.caqUf = this.utilitiesService.roundNumber(totalizerStudentCost.caqUf, 0);
          financingsFundsData.potential = this.utilitiesService.roundNumber(totalizerStudentCost.revenue.potential, 0);
          financingsFundsData.potentialPlusAgreement = this.utilitiesService.roundNumber(totalizerStudentCost.revenue.potentialPlusAgreement, 0);
          financingsFundsData.fundeb = this.utilitiesService.roundNumber(totalizerStudentCost.revenue.fundeb, 0);
          financingsFundsData.current = this.utilitiesService.roundNumber(totalizerStudentCost.expense.current, 0);
          // financingsFundsData.capital = this.utilitiesService.roundNumber(totalizerStudentCost.expense.capital, 0);
          // financingsFundsData.total = this.utilitiesService.roundNumber(totalizerStudentCost.expense.total, 0);
          financingsFundsData.unionComplementation = this.utilitiesService.roundNumber(totalizerStudentCost.unionComplementation, 0);
          financingsFundsData.percentUnionComplementation = totalizerStudentCost.percentUnionComplementation > 0 ?
            this.utilitiesService.roundNumber(totalizerStudentCost.percentUnionComplementation, 1) : '';

          data.push(financingsFundsData);
        }

        data.push({
          stateAbbreviation: 'TOTAL',
          enrollmentTotal: this.utilitiesService.roundNumber(financingsFunds.enrollmentTotal, 0),
          caqUf: this.utilitiesService.roundNumber(financingsFunds.caqBR, 0),
          potential: this.utilitiesService.roundNumber(financingsFunds.potentialTotal, 0),
          potentialPlusAgreement: this.utilitiesService.roundNumber(financingsFunds.potentialPlusAgreementTotal, 0),
          fundeb: this.utilitiesService.roundNumber(financingsFunds.fundebTotal, 0),
          current: this.utilitiesService.roundNumber(financingsFunds.expenseCurrentTotal, 0),
          // capital: this.utilitiesService.roundNumber(financingsFunds.expenseCapitalTotal, 0),
          // total: this.utilitiesService.roundNumber(financingsFunds.expenseCurrentTotal + financingsFunds.expenseCapitalTotal, 0),
          unionComplementation: this.utilitiesService.roundNumber(financingsFunds.unionComplementationTotal, 0),
          percentUnionComplementation: financingsFunds.unionComplementationTotal > 0 ?
            this.utilitiesService.roundNumber(financingsFunds.percentUnionComplementationTotal, 1) : ''
        });

        const csv = new Csv({ header: header, data: data, name: 'Fundos de financiamento' });
        this.csvService.download(csv);
      })
    );
  }

  getDiagnosticTeacherByState(stateName: String, diagnostics: Array<any>): Array<any> {

    const diagnosticTeacher: Array<any> = new Array<any>();

    for (let i = 0; i < diagnostics.length; i++) {
      if (diagnostics[i].state_name === stateName) {
        diagnosticTeacher.push(diagnostics[i]);
      }
    }
    return diagnosticTeacher;
  }

  private getSourceInformations(): Array<Footnote> {

    const footNotes: Array<Footnote> = new Array<Footnote>();

    footNotes.push(new Footnote({
      indice: 1,
      note: 'Considera a receita vinculada para MDE (25% da receita líquida de impostos) + o ' +
        'resultado das transferências para o Fundeb + recursos do Salário Educação'
    }));

    footNotes.push(new Footnote({
      indice: 2,
      note: 'Além dos recursos vinculados (vide nota 1), adiciona os recursos oriundos de programas ' +
        'do FNDE (PDDE, PNAE, PNATE etc.) e receitas decorrentes de convênios'
    }));

    footNotes.push(new Footnote({
      indice: 3,
      note: 'Somatório das receitas do Fundeb (resultado das transferências entre os entes em cada UF)'
    }));

    footNotes.push(new Footnote({
      indice: 4,
      note: 'Total de despesas de capital divido por quatro (considera que construção da infraestrutura necessária será realizada em quatro anos)'
    }));

    footNotes.push(new Footnote({
      indice: 5,
      note: 'Valor adicional ao complemento da União previsto no Fundeb. Fórmula: [Despesa total ano t] - [Receitas vinculadas ano t-1]'
    }));

    return footNotes;
  }

  private getShiftsReport(): Array<ColumnReportByShift> {

    return new Array<ColumnReportByShift>(
      new ColumnReportByShift({ id: 1, description: 'Parcial' }),
      new ColumnReportByShift({ id: 2, description: 'Integral' })
    );
  }

  private clearPercTeacherDistributionCareerLevel(data: CareerAndRemunerationTeachers) {

    for (let i = 0; i < data.teacherFormationLevels.levels.length; i++) {
      for (let j = 0; j < data.teacherFormationLevels.levels[i].percTeacherDistributionCareerLevel.length; j++) {
        data.teacherFormationLevels.levels[i].percTeacherDistributionCareerLevel[j].percentage = 0;
      }
    }
  }

  private getSourceInformationPQR(): Footnote {

    const footNote: Footnote = new Footnote({
      note: 'A proposta de valores do PQR foi realizada pela equipe do projeto. Desse modo, não são decisões do governo federal, ' +
        'dos governos estaduais ou municipais. Por consequência, os resultados do SimCAQ decorrem dos parâmetros de qualidade propostos ' +
        'não vinculam responsabilidade de repasses de recursos financeiros por parte dos entes federativos.'
    });
    return footNote;
  }
}
