import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormGroup } from '@angular/forms';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserNewComponent } from './user-new/user-new.component';
import { FieldControlErrorComponent } from './field-control-error/field-control-error.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    UserRoutingModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    UserComponent,
    UserEditComponent,
    UserNewComponent,
    FieldControlErrorComponent
  ]
})
export class UserModule { }
