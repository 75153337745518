import { Routes } from '@angular/router';

import { InconsistencyGuard } from '../shared/guards/inconsistency/inconsistency.guard';
import { HasRequiredDataGuard } from '../shared/guards/required-data/has-required-data.guard';
import { HasErrorGuard } from '../../shared/guards/has-error/has-error.guard';
import { SchoolDayPerWeekComponent } from './school-day-per-week/school-day-per-week.component';
import { InfrastructureSchoolBuildingsComponent } from './infrastructure-school-buildings/infrastructure-school-buildings.component';
import { NewRoomBuildingComponent } from './new-room-building/new-room-building.component';
import { StaffNumberComponent } from './staff-number/staff-number.component';
import { SchoolsStaffComponent } from './schools-staff/schools-staff.component';
import { SchoolTransportComponent } from './school-transport/school-transport.component';
import { SchoolsOperationComponent } from './schools-operation/schools-operation.component';
import { CareerAndRemunerationTeachersComponent } from './career-and-remuneration-teachers/career-and-remuneration-teachers.component';
import { TeacherNumberComponent } from './teacher-number/teacher-number.component';
import { SchoolFeedingComponent } from './school-feeding/school-feeding.component';
import { TeacherTrainingComponent } from './teacher-training/teacher-training.component';
import { WorkJourneyTeacherComponent } from './work-journey-teacher/work-journey-teacher.component';
import { NumberStudentClassComponent } from './number-student-class/number-student-class.component';
import { OfferGoalEnrollmentFullTimeComponent } from './offer-goal-enrollment-full-time/offer-goal-enrollment-full-time.component';
import { DailyTeachingLoadComponent } from './daily-teaching-load/daily-teaching-load.component';
import { AdministrativeAreaCostingComponent } from './administrative-area-costing/administrative-area-costing.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'schooldayperweek' },
    { path: 'schooldayperweek', component: SchoolDayPerWeekComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'dailyteachingload', component: DailyTeachingLoadComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'offergoalenrollmentfulltime', component: OfferGoalEnrollmentFullTimeComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'numberstudentclass', component: NumberStudentClassComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'workjourneyteacher', component: WorkJourneyTeacherComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'teachertraining', component: TeacherTrainingComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'schoolfeeding', component: SchoolFeedingComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'teachernumber', component: TeacherNumberComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'careerandremunerationteachers', component: CareerAndRemunerationTeachersComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'schoolsoperation', component: SchoolsOperationComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'schooltransport', component: SchoolTransportComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'schoolsstaff', component: SchoolsStaffComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'staffnumber', component: StaffNumberComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'schoolsoperation', component: SchoolsOperationComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'newroombuilding', component: NewRoomBuildingComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'administrativeareacosting', component: AdministrativeAreaCostingComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'infrastructureschoolbuildings', component: InfrastructureSchoolBuildingsComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] }
];

export const QualityConditionsChildrenRoutes: Routes = routes;
