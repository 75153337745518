import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { BudgetForecastReportByItem } from './budget-forecast-report-by-item';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { EmployeeEstimateByRole } from 'app/simulator/shared/services/calculate-employee-estimate/entities/employee-estimate-by-role';

export class BudgetForecastReport extends NavigableComponentData {

  stateId: number;
  stateDescription: string;
  cityId: number;
  cityDescription: string;
  admDependencyId: number;
  years: Array<number>;
  offerYear: number;
  offerYearFinancial: number;
  offersDimensions: Array<BudgetForecastReportByItem>;
  enrollmentsBasicEducation: Array<BudgetForecastReportByItem>;
  expensesBasicEducation: Array<BudgetForecastReportByItem>;
  expenseTotal: BudgetForecastReportByItem;
  expensesFederativeEntity: Array<BudgetForecastReportByItem>;
  expensesByStage: Array<BudgetForecastReportByItem>;
  totalExpenseWithInfrastructure: number;
  totalExpenseWithNewroom: number;
  percentExpenseWithInfrastructure: number;
  percentExpenseWithNewroom: number;
  sourceInformationsExpensesFederativeEntity: Array<Footnote>;
  sourceInformationsOfferDimension: Array<Footnote>;
  sourceInformationsExpensesBasicEducation: Array<Footnote>;
  sourceInformationsExpenseTotal: Array<Footnote>;
  sourceInformationsExpensesByStage: Array<Footnote>;
  sourceInformationPQR: Footnote;
  employeesEstimateByRole: Array<EmployeeEstimateByRole>;

  constructor(init?: Partial<BudgetForecastReport>) {
    super();
    Object.assign(this, init);
  }
}
