export class TeacherNumberByYear {

  year: number;
  value: number;

  constructor(init?: Partial<TeacherNumberByYear>) {
    Object.assign(this, init);
  }

}
