import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { map, concatMap, finalize, mergeMap } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { NavigableComponentService } from '../../shared/entities/base/navigable-component-service';
import { PqrService } from '../../pqr/services/pqr.service';
import { CurrentYearService } from '../../shared/services/current-year/current-year.service';
import { SimulationsService } from '../../../simulations/simulations/services/simulations.service';
import { SimulatorItem } from '../entities/simulator-item';
import { SimulatorItemData } from '../entities/simulator-item-data';
import { AdditionalsService } from '../../charges-and-additionals/additionals/services/additionals.service';
import { AdministrativeAreaCostingService } from '../../quality-conditions/administrative-area-costing/services/administrative-area-costing.service';
import { SocialChargesService } from '../../charges-and-additionals/social-charges/services/social-charges.service';
import { TeacherTrainingService } from '../../quality-conditions/teacher-training/services/teacher-training.service';
import { SchoolsOperationService } from '../../quality-conditions/schools-operation/services/schools-operation.service';
import { SchoolFeedingService } from '../../quality-conditions/school-feeding/services/school-feeding.service';
import { SchoolsStaffService } from '../../quality-conditions/schools-staff/services/schools-staff.service';
import { WorkJourneyTeacherService } from '../../quality-conditions/work-journey-teacher/services/work-journey-teacher.service';
import { NumberStudentClassService } from '../../quality-conditions/number-student-class/services/number-student-class.service';
import { OfferGoalEnrollmentFullTimeService } from '../../quality-conditions/offer-goal-enrollment-full-time/services/offer-goal-enrollment-full-time.service';
import { DailyTeachingLoadService } from '../../quality-conditions/daily-teaching-load/services/daily-teaching-load.service';
import { SchoolDayPerWeekService } from '../../quality-conditions/school-day-per-week/services/school-day-per-week.service';
import { SchoolTransportService } from '../../quality-conditions/school-transport/services/school-transport.service';
import { CareerAndRemunerationTeachersService } from '../../quality-conditions/career-and-remuneration-teachers/services/career-and-remuneration-teachers.service';
import { InfrastructureSchoolBuildingsService } from '../../quality-conditions/infrastructure-school-buildings/services/infrastructure-school-buildings.service';
import { FullPriceListService } from '../../charges-and-additionals/full-price-list/services/full-price-list.service';
import { PriceIndicesService } from '../../charges-and-additionals/price-indices/services/price-indices.service';
import { NewRoomBuildingService } from '../../quality-conditions/new-room-building/services/new-room-building.service';
import { StaffNumbersService } from '../../quality-conditions/staff-number/service/staff-numbers.service';
import { TeacherNumberService } from '../../quality-conditions/teacher-number/service/teacher-number.service';
import { CollaborationSchemeService } from '../../access-and-offer/collaboration-scheme/services/collaboration-scheme.service';
import { EnrollmentProjectionService } from '../../access-and-offer/enrollment-projection/services/enrollment-projection.service';
import { EnrollmentByStageSeriesService } from '../../access-and-offer/enrollment-by-stage-series/services/enrollment-by-stage-series.service';
import { EnrollmentByStageSeriesBySchoolService } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/services/enrollment-by-stage-series-by-school.service';
import { ResultEnrollmentProjectionService } from 'app/simulator/access-and-offer/result-enrollment-projection/services/result-enrollment-projection.service';
import { ViewEnrollmentByStageSeriesService } from '../../access-and-offer/view-enrollment-by-stage-series/services/view-enrollment-by-stage-series.service';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { SimulationTimeService } from '../../simulation-time/services/simulation-time.service';
import { SelectLocationService } from '../../select-location/services/select-location.service';
import { SessionService } from '../../../shared/services/session/session.service';
import { SimulationType } from '../entities/enums/simulation-type.enum';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { FunctionalityRoute } from '../../../shared/entities/functionality/functionality-route';
import { CurrentYearMonthService } from '../../shared/services/current-year-month/current-year-month.service';
import { SourceInformationService } from '../../shared/services/source-information/source-information.service';
import { SimulationTime } from '../../simulation-time/entities/simulation-time';
import { UtilitiesService } from '../../../shared/services/utilities/utilities.service';
import { TutorialsComponent } from '../../../shared/components/modal/tutorials/tutorials.component';
import { State } from 'app/simulator/select-location/entities/state';
import { City } from 'app/simulator/select-location/entities/city';
import { SelectLocation } from 'app/simulator/select-location/entities/select-location';

@Injectable({
  providedIn: 'root'
})
export class SimulatorService {

  static readonly simulationEditModeSessionKey: string = `${FunctionalityInfo.simulationNamespace}.editmode`;
  static readonly simulationPerformedSessionKey: string = `${FunctionalityInfo.simulationNamespace}.performed`;

  simulationTypeObserver: Observable<SimulationType>;
  simulationPerformedObserver: Observable<boolean>;
  simulationEditModeObserver: Observable<boolean>;

  private simulationTypeMessenger: Subject<SimulationType> = new Subject<SimulationType>();
  private simulationPerformedMessenger: Subject<boolean> = new Subject<boolean>();
  private simulationEditModeMessenger: Subject<boolean> = new Subject<boolean>();

  private readonly homeRoute: Array<string> = new Array<string>(FunctionalityRoute.homeRoute);
  private simulationInitialized: boolean = true;
  private bsModalRef: BsModalRef;

  private readonly functionalityService: Map<string, NavigableComponentService> = new Map<string, NavigableComponentService>([
    [Functionality.selectLocation.key, this.selectLocationService],
    [Functionality.simulationTime.key, this.simulationTimeService],
    [Functionality.viewEnrollmentByStageAndSeries.key, this.viewEnrollmentByStageSeriesService],
    [Functionality.enrollmentByStageAndSeries.key, this.enrollmentByStageSeriesService],
    [Functionality.enrollmentProjection.key, this.enrollmentProjectionService],
    [Functionality.collaborationScheme.key, this.collaborationSchemeService],
    [Functionality.schoolDayPerWeek.key, this.schoolDayPerWeekService],
    [Functionality.dailyTeachingLoad.key, this.dailyTeachingLoadService],
    [Functionality.offerGoalEnrollmentFullTime.key, this.offerGoalEnrollmentFullTimeService],
    [Functionality.numberStudentClass.key, this.numberStudentClassService],
    [Functionality.workJourneyTeacher.key, this.workJourneyTeacherService],
    [Functionality.teacherNumber.key, this.teacherNumberService],
    [Functionality.careerAndRemunerationTeachers.key, this.careerAndRemunerationTeachersService],
    [Functionality.schoolsStaff.key, this.schoolsStaffService],
    [Functionality.staffNumber.key, this.staffNumbersService],
    [Functionality.infrastructureSchoolBuildings.key, this.infrastructureSchoolBuildingsService],
    [Functionality.newRoomBuilding.key, this.newRoomBuildingService],
    [Functionality.schoolFeeding.key, this.schoolFeedingService],
    [Functionality.schoolTransport.key, this.schoolTransportService],
    [Functionality.schoolsOperation.key, this.schoolsOperationService],
    [Functionality.teacherTraining.key, this.teacherTrainingService],
    [Functionality.socialCharges.key, this.socialChargesService],
    [Functionality.administrativeAreaCosting.key, this.administrativeAreaCostingService],
    [Functionality.additionals.key, this.additionalsService],
    [Functionality.priceIndices.key, this.priceIndicesService],
    [Functionality.fullPriceList.key, this.fullPriceListService],
    [Functionality.enrollmentByStageAndSeriesBySchool.key, this.enrollmentByStageSeriesBySchoolService],
    [Functionality.resultEnrollmentProjection.key, this.resultEnrollmentProjectionService]
  ]);

  constructor(
    private router: Router,
    private bsModalService: BsModalService,
    private pqrService: PqrService,
    private currentYearService: CurrentYearService,
    private currentYearMonthService: CurrentYearMonthService,
    private sourceInformationService: SourceInformationService,
    private simulationsService: SimulationsService,
    private sessionService: SessionService,
    private selectLocationService: SelectLocationService,
    private simulationTimeService: SimulationTimeService,
    private viewEnrollmentByStageSeriesService: ViewEnrollmentByStageSeriesService,
    private enrollmentByStageSeriesService: EnrollmentByStageSeriesService,
    private enrollmentProjectionService: EnrollmentProjectionService,
    private enrollmentByStageSeriesBySchoolService: EnrollmentByStageSeriesBySchoolService,
    private resultEnrollmentProjectionService: ResultEnrollmentProjectionService,
    private collaborationSchemeService: CollaborationSchemeService,
    private schoolDayPerWeekService: SchoolDayPerWeekService,
    private dailyTeachingLoadService: DailyTeachingLoadService,
    private offerGoalEnrollmentFullTimeService: OfferGoalEnrollmentFullTimeService,
    private numberStudentClassService: NumberStudentClassService,
    private workJourneyTeacherService: WorkJourneyTeacherService,
    private teacherNumberService: TeacherNumberService,
    private careerAndRemunerationTeachersService: CareerAndRemunerationTeachersService,
    private schoolsStaffService: SchoolsStaffService,
    private staffNumbersService: StaffNumbersService,
    private infrastructureSchoolBuildingsService: InfrastructureSchoolBuildingsService,
    private newRoomBuildingService: NewRoomBuildingService,
    private schoolFeedingService: SchoolFeedingService,
    private schoolTransportService: SchoolTransportService,
    private schoolsOperationService: SchoolsOperationService,
    private teacherTrainingService: TeacherTrainingService,
    private socialChargesService: SocialChargesService,
    private administrativeAreaCostingService: AdministrativeAreaCostingService,
    private additionalsService: AdditionalsService,
    private priceIndicesService: PriceIndicesService,
    private fullPriceListService: FullPriceListService) {

    this.simulationTypeObserver = this.simulationTypeMessenger.asObservable();
    this.simulationPerformedObserver = this.simulationPerformedMessenger.asObservable();
    this.simulationEditModeObserver = this.simulationEditModeMessenger.asObservable();
  }

  startSimulation(simulationType: SimulationType): void {
    if (simulationType === SimulationType.caq ||
      simulationType === SimulationType.planning ||
      simulationType === SimulationType.planningByStateSphereAdm ||
      simulationType === SimulationType.planningByCitySphereAdm ||
      simulationType === SimulationType.financing ||
      simulationType === SimulationType.financingFederatedEntitiesByCitySphereAdm ||
      simulationType === SimulationType.financingFederatedEntitiesByStateSphereAdm ||
      simulationType === SimulationType.financingFederatedEntitiesGroupByCityOrState
    ) {
      this.bsModalRef = this.bsModalService.show(TutorialsComponent, { ignoreBackdropClick: true, class: 'modal-lg modal-tutorial', initialState: { simulationType } });
      this.bsModalRef.content.startSimulation
        .subscribe((startSimulation: Boolean) => {
          if (startSimulation) {
            this.runSimulation(simulationType);
          }
        });
    } else {
      this.runSimulation(simulationType);
    }
  }

  initializeSimulation(simulationType: SimulationType): Observable<void | any> {
    if (simulationType) {
      if (!this.simulationInitialized) {
        return this.simulationsService.discardSimulation(true, false).pipe(
          mergeMap(() => {
            return this.pqrService.loadPqr().pipe(
              mergeMap(() => {
                return this.currentYearService.loadCurrentYears().pipe(
                  mergeMap(() => {
                    return this.currentYearMonthService.loadCurrentYearMonths().pipe(
                      mergeMap(() => {
                        return this.sourceInformationService.loadSourceInformations().pipe(
                          mergeMap(() => {
                            this.sessionService.setItem(UtilitiesService.simulationTypeSessionKey, simulationType);
                            this.sessionService.setItem(SimulatorService.simulationPerformedSessionKey, false);
                            this.sessionService.setItem(SimulatorService.simulationEditModeSessionKey, false);

                            //// const selectLocation: SelectLocation = new SelectLocation();
                            ///// selectLocation.selectedCity = new City({ value: '3205309', label: 'VITÓRIA' });
                            ///// selectLocation.selectedState = new State({ value: '32', label: 'ESPÍRITO SANTO' });
                            ///// selectLocation.selectedCityId = '3205309';

                            //// this.sessionService.setItem(Functionality.selectLocation.key, selectLocation);

                            this.notifySimulationTypeObservers(simulationType);
                            this.notifySimulationPerformedObservers(false);
                            this.notifySimulationEditModeObservers(false);

                            this.selectLocationService.notifySelectedLocationObservers(undefined);
                            this.simulationTimeService.notifySimulationTimeObservers(undefined);

                            this.simulationInitialized = true;

                            if (simulationType !== SimulationType.planning &&
                              simulationType !== SimulationType.planningByStateSphereAdm &&
                              simulationType !== SimulationType.planningByCitySphereAdm &&
                              simulationType !== SimulationType.financing &&
                              simulationType !== SimulationType.financingFederatedEntitiesByCitySphereAdm &&
                              simulationType !== SimulationType.financingFederatedEntitiesByStateSphereAdm &&
                              simulationType !== SimulationType.financingFederatedEntitiesGroupByCityOrState
                            ) {
                              return this.processSimulatorItems(simulationType);
                            } else {
                              return this.router.navigate(Functionality.selectLocation.route, { queryParamsHandling: 'merge' });
                            }
                          }));
                      }));
                  }));
              }));
          }));
      } else {
        return this.processSimulatorItems(simulationType);
      }
    } else {
      return of(null);
    }
  }

  navigateToResultSimulation(): void {
    const route: Array<string> = this.getResultSimulationRoute();
    this.router.navigate(route, { queryParamsHandling: 'merge' });
  }

  getResultSimulationRoute(): Array<string> {
    const simulationType: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);
    let route: Array<string>;

    switch (simulationType) {
      case SimulationType.caq:
        route = Functionality.caqReport.route;
        break;

      case SimulationType.planning:
      case SimulationType.planningByStateSphereAdm:
      case SimulationType.planningByCitySphereAdm:
        const simulationTimeData: SimulationTime = this.sessionService.getItem<SimulationTime>(Functionality.simulationTime.key);

        // Checa se tem dados de 'simulation time' na session para distinguir entre clicar no
        // passo resultado antes de executar a simulação e depois de executar a simulação.
        route = simulationTimeData ? Functionality.budgetForecastReport.route : FunctionalityRoute.processingSimulation;
        break;

      case SimulationType.financing:
      case SimulationType.financingFederatedEntitiesByStateSphereAdm:
      case SimulationType.financingFederatedEntitiesByCitySphereAdm:
      case SimulationType.financingFederatedEntitiesGroupByCityOrState:
        route = Functionality.financingFederatedEntitiesReport.route;
        break;

      default:
        route = this.homeRoute;
        break;
    }

    return route;
  }

  navigateToEditModeSimulation(): void {
    const simulationType: SimulationType = this.sessionService.getItem(UtilitiesService.simulationTypeSessionKey);
    let route: Array<string>;

    switch (simulationType) {
      case SimulationType.caq:
        route = Functionality.qualityconditions.route;
        break;

      case SimulationType.planning:
      case SimulationType.planningByStateSphereAdm:
      case SimulationType.planningByCitySphereAdm:
        route = Functionality.accessandoffer.route;
        break;

      case SimulationType.financing:
      case SimulationType.financingFederatedEntitiesByCitySphereAdm:
      case SimulationType.financingFederatedEntitiesByStateSphereAdm:
      case SimulationType.financingFederatedEntitiesGroupByCityOrState:
        route = Functionality.accessandoffer.route;
        break;
      default:
        route = this.homeRoute;
        break;
    }

    this.router.navigate(route, { queryParamsHandling: 'merge' });
  }

  notifySimulationTypeObservers(simulationType: SimulationType): void {
    this.simulationTypeMessenger.next(simulationType);
  }

  notifySimulationPerformedObservers(performed: boolean): void {
    this.simulationPerformedMessenger.next(performed);
  }

  notifySimulationEditModeObservers(editMode: boolean): void {
    this.simulationEditModeMessenger.next(editMode);
  }

  private runSimulation(simulationType: SimulationType) {

    this.sessionService.setItem(UtilitiesService.simulationTypeSessionKey, simulationType);
    this.simulationInitialized = false;
    this.router.navigate(FunctionalityRoute.processingSimulation, { queryParamsHandling: 'merge' });
  }

  private processSimulatorItems(simulationType: SimulationType): Observable<void | any> {
    const simulatorItems: Array<SimulatorItem> = this.getSimulatorItems(simulationType);

    return of(...simulatorItems).pipe(
      concatMap(simulatorItem => {
        return simulatorItem.service.getData().pipe(
          map(data => new SimulatorItemData({ key: simulatorItem.key, data: data }))
        );
      }),
      map(simulatorItemData => this.sessionService.setItem(simulatorItemData.key, simulatorItemData.data)),
      finalize(() => {
        this.navigateToResultSimulation();
      }));
  }

  private getSimulatorItems(simulationType: SimulationType): Array<SimulatorItem> {
    let functionalities: Array<FunctionalityInfo> = new Array<FunctionalityInfo>();

    switch (simulationType) {
      case SimulationType.caq:
        functionalities = Functionality.caqReport.requiredData.filter(functionality => functionality.key !== Functionality.selectLocation.key);
        break;

      case SimulationType.planning:
      case SimulationType.planningByStateSphereAdm:
      case SimulationType.planningByCitySphereAdm:
        functionalities = Functionality.budgetForecastReport.requiredData.filter(functionality => functionality.key !== Functionality.selectLocation.key);
        break;

      case SimulationType.financing:
      case SimulationType.financingFederatedEntitiesByStateSphereAdm:
      case SimulationType.financingFederatedEntitiesByCitySphereAdm:
      case SimulationType.financingFederatedEntitiesGroupByCityOrState:
        functionalities = Functionality.financingFederatedEntitiesReport.requiredData.filter(functionality => functionality.key !== Functionality.selectLocation.key);
        break;
    }

    const simulatorItems: Array<SimulatorItem> = functionalities.map(functionality => new SimulatorItem({
      key: functionality.key,
      service: this.functionalityService.get(functionality.key)
    }));

    return simulatorItems;
  }
}
