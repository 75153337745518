import { DescriptionValue } from './description-value';
import { Footnote } from '../../../shared/components/footnote/entities/footnote';

export class PibPerCapita {
    levels: Array<DescriptionValue>;
    footnote: Footnote;

    constructor(init?: Partial<PibPerCapita>) {
        Object.assign(this, init);
    }
}
