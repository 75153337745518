import { TotalizerStudentCostByYear } from './totalizer-student-cost-by-year';
import { TotalizerStudentCostBySubitem } from './totalizer-student-cost-by-subitem';

export class TotalizerStudentCostByShift {

  id: number;
  description: string;
  totalizersStudentsCostBySubitems: Array<TotalizerStudentCostBySubitem>;
  totalizersStudentsCostByYear: Array<TotalizerStudentCostByYear>;
  studentMinorValueFundeb: number;
  studentMajorValueFundeb: number;
  considerCAQ: boolean;

  constructor(init?: Partial<TotalizerStudentCostByShift>) {
    Object.assign(this, init);
  }

}
