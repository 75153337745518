import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { CollaborationSchemesByStage } from './collaboration-schemes-by-stage';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class CollaborationScheme extends NavigableComponentData {
  yearCurrentPublicEnrollment: number;
  yearProposalPublicEnrollment: number;
  totalCurrentPublicEnrollment: number;
  totalProposalPublicEnrollment: number;
  collaborationSchemeByStage: Array<CollaborationSchemesByStage>;
  sourceInformations: Array<Footnote>;
  infoNote: Footnote;

  constructor(init?: Partial<CollaborationScheme>) {
    super();
    Object.assign(this, init);
  }
}
