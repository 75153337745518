export class DailyTeachingLoadDiagnostic {

  stageId: number;
  shiftId: number;
  diagnostic: number;

  constructor(init?: Partial<DailyTeachingLoadDiagnostic>) {
    Object.assign(this, init);
  }

}
