import { Location } from '../../../../../shared/entities/location';

export class HigherDemandClassroomByLocation {

  location: Location;
  demandClassroomExisting: number;
  demandClassroomRequired: number;
  demandNewClassroom: number;

  constructor(init?: Partial<HigherDemandClassroomByLocation>) {
    Object.assign(this, init);
  }

}
