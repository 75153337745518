import { NavigableComponentData } from './../../../shared/entities/base/navigable-component-data';

export class PercentageTeacherCareer extends NavigableComponentData {

  formationLevelId: number;
  percentage: number;

  constructor(init?: Partial<PercentageTeacherCareer>) {
    super();
    Object.assign(this, init);
  }
}
