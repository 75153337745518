import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { AdditionalsService } from './services/additionals.service';
import { Additional } from './entities/additional';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

@Component({
  selector: 'app-additionals',
  templateUrl: './additionals.component.html',
  styleUrls: ['./additionals.component.scss']
})
export class AdditionalsComponent extends BaseNavigableComponent<Additional> implements PqrComponentData {

  data: Additional = new Additional();
  functionality: FunctionalityInfo = Functionality.additionals;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = AdditionalsComponent;

  constructor(private additionalsService: AdditionalsService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<Additional> {
    return this.additionalsService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  processValidation(): void {
    super.processValidation();

    for (const additional of this.data.additionals) {

      if (isNaN(additional.percentage) || additional.percentage === undefined || additional.percentage === null || additional.percentage < 0 || additional.percentage > 100) {
        this.inconsistencies.push(new Inconsistency({ message: `A porcentagem do item ${additional.item.description} não é valido. Informe um valor de 0% a 100%.` }));
      }
    }
  }
}
