import { Component, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Inconsistency } from 'app/shared/components/inconsistency/entities/inconsistency';
import { BaseNavigableComponent } from 'app/shared/entities/base/base-navigable-component';
import { Functionality } from 'app/shared/entities/functionality/functionality';
import { FunctionalityInfo } from 'app/shared/entities/functionality/functionality-info';
import { ResultEnrollmentProjection } from './entities/result-enrollment-projection';
import { ResultEnrollmentProjectionService } from './services/result-enrollment-projection.service';

@Component({
  selector: 'app-result-enrollment-projection',
  templateUrl: './result-enrollment-projection.component.html',
  styleUrls: ['./result-enrollment-projection.component.scss']
})

export class ResultEnrollmentProjectionComponent extends BaseNavigableComponent<ResultEnrollmentProjection> implements OnInit {

  data: ResultEnrollmentProjection = new ResultEnrollmentProjection();
  functionality: FunctionalityInfo = Functionality.resultEnrollmentProjection;
  inconsistencies: Array<Inconsistency>;
  pqrMode: boolean;
  pqrModalMode: boolean;

  constructor(private resultEnrollmentProjectionService: ResultEnrollmentProjectionService, private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  processData(): Observable<ResultEnrollmentProjection> {
    return this.resultEnrollmentProjectionService.getData();
  }

}
