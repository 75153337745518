import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { SchoolsOperation } from '../entities/schools-operation';
import { SchoolOperation } from '../entities/school-operation';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolsOperationService implements NavigableComponentService {

  constructor(private sessionService: SessionService, private currentYearService: CurrentYearService) { }

  getData(): Observable<SchoolsOperation> {
    let schoolsOperation: SchoolsOperation = new SchoolsOperation();
    const schoolCurrentYear: number = this.currentYearService.getSchoolCurrentYear();
    schoolsOperation = this.sessionService.getItem<SchoolsOperation>(Functionality.schoolsOperation.pqrKey);
    return of(schoolsOperation);
  }

  getNewSchoolsOperation(currentSequence: number): SchoolOperation {
    return new SchoolOperation({
      sequence: currentSequence + 1
    });
  }

  removeSchoolsOperation(schoolsOperations: Array<SchoolOperation>, sequence: number): void {
    let schoolsOperationRemoved: boolean = false;
    let sequenceCount: number = schoolsOperations.length - 1;

    for (let i = schoolsOperations.length - 1; i >= 0; i--) {
      const schoolsOperation = schoolsOperations[i];

      if (!schoolsOperationRemoved && schoolsOperation.sequence === sequence) {
        schoolsOperations.splice(i, 1);
        schoolsOperationRemoved = true;
      } else {
        schoolsOperation.sequence = sequenceCount;
        sequenceCount--;
      }
    }
  }
}
