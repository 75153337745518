import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class SocialCharges extends NavigableComponentData {

  socialChargesPercentage: number;

  constructor(init?: Partial<SocialCharges>) {
    super();
    Object.assign(this, init);
  }

}
