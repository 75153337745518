import { SourceInformationEnum } from '../../../entities/enums/source-information.enum';

export class Footnote {

    indice: number;
    note: string;
    sourceInformation: SourceInformationEnum;
    remarks: string;

    constructor(init?: Partial<Footnote>) {
        Object.assign(this, init);
    }

}
