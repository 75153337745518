import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { StaffNumberByFunction } from './staff-number-by-function';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class StaffNumber extends NavigableComponentData {

  resultForStaffNumber: Array<StaffNumberByFunction>;
  yearCurrent: number;
  years: Array<number>;
  diagnostic: number;
  sourceInformation: Footnote;
  sourceNote: Footnote;

  constructor(init?: Partial<StaffNumber>) {
    super();
    Object.assign(this, init);
  }

}
