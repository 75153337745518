import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { InfrastructureSchoolBuildingsService } from '../../../quality-conditions/infrastructure-school-buildings/services/infrastructure-school-buildings.service';
import { CapitalExpenseByYear } from './entities/capital-expense-by-year';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { InfrastructureSchoolsBuildings } from '../../../quality-conditions/infrastructure-school-buildings/entities/infrastructure-schools-buildings';
import { NewRoomsBuildings } from '../../../quality-conditions/new-room-building/entities/new-rooms-buildings';
import { SessionService } from '../../../../shared/services/session/session.service';
import { CapitalExpense } from './entities/capital-expense';
import { Functionality } from '../../../../shared/entities/functionality/functionality';

@Injectable({
  providedIn: 'root'
})
export class CalculateCapitalExpenseService {

  constructor(
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService,
    private infrastructureSchoolBuildingsService: InfrastructureSchoolBuildingsService) { }

  calculateCapitalExpense(newRoomsBuildings: NewRoomsBuildings = undefined, infrastructureSchoolsBuildings: InfrastructureSchoolsBuildings = undefined,
    priceCub: number = undefined): Observable<CapitalExpense> {
    const simulationYears = this.utilitiesService.getSimulationYears();
    let resultForInfrastructureschoolbuildings: InfrastructureSchoolsBuildings = new InfrastructureSchoolsBuildings();
    let resultForNewRoomBuildings: NewRoomsBuildings = new NewRoomsBuildings();

    if (infrastructureSchoolsBuildings === undefined) {
      resultForInfrastructureschoolbuildings = this.sessionService.getItem<InfrastructureSchoolsBuildings>(Functionality.infrastructureSchoolBuildings.key);
    } else {
      resultForInfrastructureschoolbuildings = infrastructureSchoolsBuildings;
    }

    if (newRoomsBuildings === undefined) {
      resultForNewRoomBuildings = this.sessionService.getItem<NewRoomsBuildings>(Functionality.newRoomBuilding.key);
    } else {
      resultForNewRoomBuildings = newRoomsBuildings;
    }

    let numberAdequacy: number = 0;
    let dimension: number = 0;
    let unitPrice: number = 0;
    let totalInfrastructure: number = 0;

    for (let i = 0; i < resultForInfrastructureschoolbuildings.infrastructureSchools.infrastructureBuildings.length; i++) {

      numberAdequacy = this.infrastructureSchoolBuildingsService.getNumberAdequacy(resultForInfrastructureschoolbuildings.infrastructureSchools.infrastructureBuildings[i]);
      dimension = resultForInfrastructureschoolbuildings.infrastructureSchools.infrastructureBuildings[i].dimension;

      if (dimension === undefined) {
        dimension = 0;
      }

      unitPrice = priceCub !== undefined ? priceCub : resultForInfrastructureschoolbuildings.infrastructureSchools.infrastructureBuildings[i].unitPrice;
      if (unitPrice === undefined) {
        unitPrice = 0;
      }

      if (numberAdequacy > 0) {
        totalInfrastructure += ((numberAdequacy * dimension) * unitPrice);
      }

    }

    let numberClassroom: number = 0;
    let numberNewClassroom: number = 0;
    let totalNewroom: number = 0;

    for (let j = 0; j < resultForNewRoomBuildings.newRooms.buildings.length; j++) {
      numberClassroom = resultForNewRoomBuildings.newRooms.buildings[j].numberClassroom;
      if (numberClassroom === undefined) {
        numberClassroom = 0;
      }

      dimension = resultForNewRoomBuildings.newRooms.buildings[j].dimension;
      if (dimension === undefined) {
        dimension = 0;
      }

      unitPrice = priceCub !== undefined ? priceCub : resultForNewRoomBuildings.newRooms.buildings[j].unitPrice;
      if (unitPrice === undefined) {
        unitPrice = 0;
      }

      for (let l = 0; l < resultForNewRoomBuildings.newRooms.buildings[j].numberDemandLocation.length; l++) {
        numberNewClassroom = Math.round(resultForNewRoomBuildings.newRooms.buildings[j].numberDemandLocation[l].value);
        if (numberNewClassroom > 0) {
          totalNewroom += ((numberNewClassroom * dimension) * unitPrice);
        }
      }
    }

    const divisor: number = 1; // simulationYears.length > 4 ? simulationYears.length : 4;

    const capital = new CapitalExpense(
      {
        capitalExpenseDescription: 'Despesa com capital',
        capitalExpenseByYears: new Array<CapitalExpenseByYear>(),
        totalExpenseWithInfrastructure: totalInfrastructure / divisor,
        totalExpenseWithNewroom: totalNewroom / divisor
      }
    );

    simulationYears.map(y => {
      capital.capitalExpenseByYears.push({
        year: y,
        value: (totalInfrastructure / divisor) + (totalNewroom / divisor)
      });
    }
    );
    return of(capital);
  }
}
