export class FullTime {

  id: number;
  stageDescription: string;
  offerGoal: number;
  diagnostic: number;

  constructor(init?: Partial<FullTime>) {
    Object.assign(this, init);
  }

}
