import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { OutOfSchoolPopulation } from './entities/out-of-school-population';
import { OutOfSchoolPopulationEnum } from '../../../../shared/entities/enums/out-of-school-population.enum';
import { SerieEnum } from '../../../../shared/entities/enums/serie.enum';
import { HttpService } from '../../../../shared/services/http/http.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { CurrentYearService } from '../current-year/current-year.service';

@Injectable({
  providedIn: 'root'
})
export class OutOfSchoolPopulationService {

  private outOfSchoolPopulationBySerie: Map<number, Array<OutOfSchoolPopulation>> = new Map<number, Array<OutOfSchoolPopulation>>([
    [SerieEnum.crecheMenorDeUmAno, new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoMenorDeUmAno }))],
    [SerieEnum.crecheUmAno, new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoDeUmAno }))],
    [SerieEnum.crecheDoisAnos, new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoDeDoisAnos }))],
    [SerieEnum.crecheTresAnos, new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoDeTresAnos }))],
    [SerieEnum.preEscolaQuatroAnos, new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoDeQuatroAnos }))],
    [SerieEnum.preEscolaCincoAnos, new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoDeCincoAnos }))],
    [
      SerieEnum.ensinoFundamentalPrimeiroAno,
      new Array<OutOfSchoolPopulation>(
        new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoDeSeisADezAnosSemInstrucaoOuComEFIncompleto, noteIndice: 1 }),
        new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoDeOnzeAQuatorzeAnosSemInstrucao, noteIndice: 2 })
      )
    ],
    [
      SerieEnum.ensinoFundamentalQuintaSerieSextoAno,
      new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoDeOnzeAQuatorzeAnosComEFIncompleto, noteIndice: 3 }))
    ],
    [
      SerieEnum.ensinoMedioPrimeiraSerie,
      new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoMenorOuIgualAVinteEQuatroAnosComEFCompletoEOuEMIncompleto, noteIndice: 4 }))
    ],
    [
      SerieEnum.ejaAnosIniciaisDoEnsinoFundamental,
      new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populacaoMaiorQueQuinzeAnosSemInstrucao, noteIndice: 5 }))
    ],
    [
      SerieEnum.ejaAnosFinaisDoEnsinoFundamental,
      new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populaçãoMaiorQueQuinzeAnosComEFIncompleto, noteIndice: 6 }))
    ],
    [
      SerieEnum.ejaEnsinoMedio,
      new Array<OutOfSchoolPopulation>(new OutOfSchoolPopulation({ id: OutOfSchoolPopulationEnum.populaçãoMaiorQueVinteECincoAnosComEFCompletoEOuEMIncompleto, noteIndice: 7 }))
    ]
  ]);

  constructor(private httpService: HttpService, private utilitiesService: UtilitiesService, private currentYearService: CurrentYearService) { }

  getOutOfSchoolPopulations(): Observable<Array<any>> {
    const requestOptions: any = this.getRequestOptions();
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/out_of_school/simcaq`, requestOptions);
      }));
  }

  getOutOfSchoolPopulationBasedOnSerie(serieId: number): Array<OutOfSchoolPopulation> {
    return this.outOfSchoolPopulationBySerie.get(serieId);
  }

  serieIsVisibleOnOutOfSchoolColumn(serieId: number): boolean {
    if (serieId === SerieEnum.ensinoFundamentalPrimeiraSerieSegundoAno ||
      serieId === SerieEnum.ensinoFundamentalSegundaSerieTerceiroAno ||
      serieId === SerieEnum.ensinoFundamentalTerceiraSerieQuartoAno ||
      serieId === SerieEnum.ensinoFundamentalQuartaSerieQuintoAno ||
      serieId === SerieEnum.ensinoFundamentalSextaSerieSetimoAno ||
      serieId === SerieEnum.ensinoFundamentalSetimaSerieOitavoAno ||
      serieId === SerieEnum.ensinoFundamentalOitavaSerieNonoAno ||
      serieId === SerieEnum.ensinoMedioSegundaSerie ||
      serieId === SerieEnum.ensinoMedioTerceiraSerie ||
      serieId === SerieEnum.ensinoMedioQuartaSerie) {
      return false;
    }

    return true;
  }

  private getRequestOptions(): any {
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const filters: Array<string> = this.utilitiesService.getYearAndSelectLocationFilters(enrollmentCurrentYear);

    const searchParams: Map<string, string> = new Map<string, string>([
      ['filter', filters.join(',')]
    ]);

    searchParams.set('dims', 'pfe');

    return this.httpService.getRequestOptionsWithSearchParams(searchParams);
  }

}
