import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { FormationLevel } from '../../../../shared/entities/formation-level';
import { Location } from '../../../../shared/entities/location';
import { SchoolStaff } from './school-staff';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class SchoolsStaff extends NavigableComponentData {
  staffs: Array<SchoolStaff> = new Array<SchoolStaff>();
  formationLevels: Array<FormationLevel>;
  locations: Array<Location>;
  sourceInformationGeneral: Footnote;

  constructor(init?: Partial<SchoolsStaff>) {
    super();
    Object.assign(this, init);
  }
}
