export class FinancialDatas {

  stateId: number;
  cityId: number;
  potential: number;
  expensesIncurred: number;
  sphereAdmId: number;

  constructor(init?: Partial<FinancialDatas>) {
    Object.assign(this, init);
  }
}
