import { SchoolUnitLocation } from './school-unit-location';
import { UnitOfMeasurement } from '../../../../shared/entities/unit-of-measurement';
import { PriceList } from '../../../../shared/entities/price-list';
import { UnitOfMeasurementEnum } from '../../../../shared/entities/enums/unit-of-measurement.enum';
import { AccountPlanEnum } from '../../../../shared/entities/enums/account-plan.enum';
import { ExpenseTypeEnum } from '../../../../shared/entities/enums/expense-type.enum';
import { PriceIndiceEnum } from '../../../../shared/entities/enums/price-indice.enum';
import { StagesSpecificities } from './stages-specificities';

export class InfrastructureSchoolBuildings extends PriceList {

    id: number;
    textId: string;
    description: string;
    schoolUnitLocation: Array<SchoolUnitLocation>;
    stagesSpecificities: Array<StagesSpecificities>;
    numberRequired: number;
    dimension: number;
    unitPrice: number;
    numberAdequate: number;
    isTitle: boolean;

    constructor(init?: Partial<InfrastructureSchoolBuildings>) {
        super();

        this.unitOfMeasurements = [UnitOfMeasurementEnum.m2];
        this.accountPlans = [AccountPlanEnum.Obras_E_Instalações];
        this.expenseTypes = [ExpenseTypeEnum.Capital];
        this.priceIndices = [PriceIndiceEnum.INCC];
        this.multiplier = 12;
        this.unitOfMeasurement = new UnitOfMeasurement({ id: 4, description: 'm2 (CUB R-1)' });

        Object.assign(this, init);
    }

    toJSON(): any {
        const json = {};
        Object.keys(this).forEach(key => json[(key[0] !== '_' ? key : key.replace('_', ''))] = this[key]);
        return json;
    }
}
