import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { SchoolQuantityExisting } from './school-quantity-existing';
import { UnitOfMeasurement } from '../../../../shared/entities/unit-of-measurement';
import { Location } from '../../../../shared/entities/location';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { InfrastructureSchools } from './infraestructure-schools';
import { State } from '../../../../shared/entities/state';
import { Stage } from '../../../../shared/entities/stage';

export class InfrastructureSchoolsBuildings extends NavigableComponentData {

  state: State;
  infrastructureSchools: InfrastructureSchools = new InfrastructureSchools();
  unitsOfMeasurement: Array<UnitOfMeasurement>;
  schoolQuantityExisting: Array<SchoolQuantityExisting>;
  locations: Array<Location>;
  stages: Array<Stage>;
  currentYear: number;
  sourceInformation: Array<Footnote>;
  sourceNote: Footnote;

  constructor(init?: Partial<InfrastructureSchoolsBuildings>) {
    super();
    Object.assign(this, init);
  }
}
