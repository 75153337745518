import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CurrentYearService } from '../../../simulator/shared/services/current-year/current-year.service';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { HttpService } from '../../../shared/services/http/http.service';
import { SessionService } from '../../../shared/services/session/session.service';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Simulation } from '../entities/simulation';
import { UtilitiesService } from '../../../shared/services/utilities/utilities.service';
import { CurrentYearMonthService } from '../../../simulator/shared/services/current-year-month/current-year-month.service';

@Injectable({
  providedIn: 'root'
})
export class SimulationsService {

  private readonly currentSimulationSessionKey: string = 'currentsimulation';

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private currentYearService: CurrentYearService,
    private currentYearMonthService: CurrentYearMonthService) { }

  getSimulations(): Observable<Array<Simulation>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/simulation`).pipe(
          map(simulations => simulations.map(simulation => new Simulation({
            id: simulation._id,
            name: simulation.name,
            createdAt: simulation.createdAt,
            updatedAt: simulation.updatedAt
          }))));
      }));
  }

  getSimulation(id: string): Observable<Simulation> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<any>(`${apiEndpoint}/simulation/${id}`).pipe(
          map(simulation => new Simulation({
            id: simulation._id,
            name: simulation.name,
            content: JSON.parse(simulation.content)
          })));
      }));
  }

  getCurrentSimulation(): Simulation {
    return this.sessionService.getItem<Simulation>(this.currentSimulationSessionKey);
  }

  saveCurrentSimulation(simulationName: string): Observable<Simulation> {
    let newSimulation: boolean = false;
    let simulation: Simulation = this.getCurrentSimulation();

    if (!simulation || !simulation.id) {
      simulation = new Simulation();
      newSimulation = true;
    }

    if (simulationName) {
      simulation.name = simulationName;
    }

    simulation.content = this.getCurrentContentSimulation();

    return newSimulation ? this.insertSimulation(simulation) : this.updateSimulation(simulation);
  }

  saveSimulation(simulation: Simulation): Observable<any> {
    return this.updateSimulation(simulation);
  }

  deleteSimulation(id: string): Observable<void> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.delete<any>(`${apiEndpoint}/simulation/${id}`).pipe(
          map(
            result => {
              if (result && result.msg === 'Simulation removed') {
                this.notificationService.showSuccess('Simulação removida com sucesso');
              }

              return result;
            },
            (err: any) => this.authService.getAuthErrorHandling(err)
          ));
      }));
  }

  loadSimulation(id: string): Observable<any> {
    return this.getSimulation(id).pipe(
      switchMap(simulation => {
        return this.discardSimulation(true, false).pipe(
          map(() => {
            this.loadSimulationToSession(simulation, true);
            return empty();
          }));
      }));
  }

  resetSimulation(): Observable<void> {
    return Observable.create((observer: { next: () => void; complete: () => void; }) => {
      this.clearSimulationContent();
      this.setIsDiscardContentSimulation();

      observer.next();
      observer.complete();
    });
  }

  discardSimulation(discardContent: boolean = true, setIsDiscardContent: boolean = true): Observable<void> {
    return Observable.create((observer: { next: () => void; complete: () => void; }) => {
      this.sessionService.removeItem(this.currentSimulationSessionKey);

      if (discardContent) {
        this.clearSimulationContent();

        if (setIsDiscardContent) {
          this.setIsDiscardContentSimulation();
        }
      }

      observer.next();
      observer.complete();
    });
  }

  private insertSimulation(simulation: Simulation): Observable<Simulation> {
    const postBody: any = this.getRequestBody(simulation);

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.post<any>(`${apiEndpoint}/simulation`, postBody).pipe(
          map(
            result => {
              let savedSimulation: Simulation;

              if (result && result.msg === 'Simulation created' && result.simulation && result.simulation !== {}) {
                savedSimulation = new Simulation({ id: result.simulation._id, name: result.simulation.name });
                this.loadSimulationToSession(savedSimulation);
                this.notificationService.showSuccess('Simulação salva com sucesso');
              }

              return savedSimulation;
            },
            (err: any) => this.authService.getAuthErrorHandling(err)
          ));
      }));
  }

  private updateSimulation(simulation: Simulation): Observable<Simulation> {
    const putBody: any = this.getRequestBody(simulation);

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.put<any>(`${apiEndpoint}/simulation/${simulation.id}`, putBody).pipe(
          map(
            result => {
              let savedSimulation: Simulation;

              if (result && result !== {}) {
                const currentSimulation: Simulation = this.getCurrentSimulation();
                savedSimulation = new Simulation({ id: result._id, name: result.name });

                if (currentSimulation && currentSimulation.id === savedSimulation.id) {
                  this.loadSimulationToSession(savedSimulation);
                }

                this.notificationService.showSuccess('Simulação salva com sucesso');
              }

              return savedSimulation;
            },
            (err: any) => this.authService.getAuthErrorHandling(err)
          ));
      }));
  }

  private getRequestBody(simulation: Simulation): any {
    return {
      name: simulation.name,
      content: JSON.stringify(simulation.content)
    };
  }

  private loadSimulationToSession(simulation: Simulation, loadContent: boolean = false): void {
    if (loadContent) {
      Object.keys(simulation.content).map(key => this.sessionService.setItem(key, simulation.content[key]));
    }

    delete simulation.content;

    this.sessionService.setItem(this.currentSimulationSessionKey, simulation);
  }

  private getCurrentContentSimulation(): any {
    const functionalitiesInfo: Array<FunctionalityInfo> = Functionality.getAllFunctionalities();
    const simulationContent: any = {};

    // Get functionalities data.
    for (const functionalityInfo of functionalitiesInfo) {
      const functionalityData: any = this.sessionService.getItem<any>(functionalityInfo.key);

      if (functionalityData) {
        simulationContent[functionalityInfo.key] = functionalityData;
      }
    }

    const currentYearSessionKeys: Array<string> = this.currentYearService.getCurrentYearSessionKeys();
    const currentYearMonthSessionKeys: Array<string> = this.currentYearMonthService.getCurrentYearMonthSessionKeys();

    // Get current years.
    for (const currentYearSessionKey of currentYearSessionKeys) {
      const currentYear: number = this.sessionService.getItem<number>(currentYearSessionKey);

      if (currentYear) {
        simulationContent[currentYearSessionKey] = currentYear;
      }
    }

    for (const currentYearMonthSessionKey of currentYearMonthSessionKeys) {
      const currentYearMonth: Array<any> = this.sessionService.getItem<Array<any>>(currentYearMonthSessionKey);

      if (currentYearMonth) {
        simulationContent[currentYearMonthSessionKey] = currentYearMonth;
      }
    }

    return simulationContent;
  }

  private clearSimulationContent(): void {
    this.sessionService.clear('simulation');
  }

  private setIsDiscardContentSimulation(): void {
    this.sessionService.setItem(UtilitiesService.isDiscardContentSimulation, true);
  }

}
