import { TeacherNumberByStageCalc } from './teacher-number-by-stage-calc';

export class TeacherNumberByLocationCalc {

  id: number;
  description: string;
  teachersNumberByStagesCalc: Array<TeacherNumberByStageCalc>;

  constructor(init?: Partial<TeacherNumberByLocationCalc>) {
    Object.assign(this, init);
  }

}
