import { LocationEnrollment } from './location-enrollment';
import { AdmDependencyEnrollment } from './adm-dependency-enrollment';
import { OutOfSchoolPopulation } from '../../../shared/services/out-of-school-population/entities/out-of-school-population';

export class SerieAdmDependencyLocationEnrollments {
    id: number;
    description: string;
    outOfSchoolPopulations: Array<OutOfSchoolPopulation> = new Array<OutOfSchoolPopulation>();
    totalEnrollments: number;
    enrollmentsByAdmDependency: Array<AdmDependencyEnrollment>;
    enrollmentsByLocation: Array<LocationEnrollment>;

    constructor(init?: Partial<SerieAdmDependencyLocationEnrollments>) {
        Object.assign(this, init);
    }
}
