import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { StaffNumber } from '../../staff-number/entities/staff-number';
import { TeacherNumber } from '../../teacher-number/entities/teacher-number';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { TeacherTraining } from '../entities/teacher-training';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { UtilitiesService } from './../../../../shared/services/utilities/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class TeacherTrainingService implements NavigableComponentService {

  constructor(private sessionService: SessionService, private utilitiesService: UtilitiesService) { }

  getData(): Observable<TeacherTraining> {
    let teacherTraining: TeacherTraining = new TeacherTraining();
    teacherTraining = this.sessionService.getItem<TeacherTraining>(Functionality.teacherTraining.pqrKey);
    return of(teacherTraining);
  }

  setTotalNumberTeacherEmployee(teacherTraining: TeacherTraining): void {
    const teacherNumber: TeacherNumber = this.sessionService.getItem<TeacherNumber>(Functionality.teacherNumber.key);
    const staffNumber: StaffNumber = this.sessionService.getItem<StaffNumber>(Functionality.staffNumber.key);
    teacherTraining.year = _.first(this.utilitiesService.getSimulationYears(1));

    for (let i = 0; i < staffNumber.resultForStaffNumber.length; i++) {
      const element = staffNumber.resultForStaffNumber[i];
      if (element.id === undefined) {
        teacherTraining.totalNumberEmployee = _.first(element.staffNumberByYear).value;
        break;
      }
    }

    for (let i = 0; i < teacherNumber.resultForTeacherNumber.length; i++) {
      const element = teacherNumber.resultForTeacherNumber[i];
      if (element.id === undefined) {
        teacherTraining.totalNumberTeacher = _.first(element.teacherNumberByYear).value;
        break;
      }
    }
  }
}
