import { Component, OnInit, Input } from '@angular/core';

import { Functionality } from '../../entities/functionality/functionality';
import { Menu } from './entities/menu';
import { HttpService } from '../../services/http/http.service';
import { BaseUnsubscribe } from '../../entities/base/base-unsubscribe';
import { ItemMenu } from './entities/item-menu';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent extends BaseUnsubscribe implements OnInit {

  @Input() heading: string;
  @Input() icon: string;
  @Input() menu: string;

  menus: Array<ItemMenu>;

  constructor(private httpService: HttpService) {
    super();
  }

  ngOnInit() {
    this.menus = Menu.getMenuItems(this.menu);
  }
}
