import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { InfrastructureSchoolBuildings } from './infrastructure-school-buildings';

export class InfrastructureSchools extends NavigableComponentData {

    infrastructureBuildings: Array<InfrastructureSchoolBuildings> = new Array<InfrastructureSchoolBuildings>();

    constructor(init?: Partial<InfrastructureSchools>) {
        super();
        Object.assign(this, init);
    }
}
