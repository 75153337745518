import { Component, OnInit } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';

import { FunctionalityRoute } from '../shared/entities/functionality/functionality-route';

@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss']
})
export class QualityComponent implements OnInit {

  pqrRoute: Array<string> = FunctionalityRoute.pqr;

  constructor(private matomoTracker: MatomoTracker) {
  }

  ngOnInit() {
    this.matomoTracker.setCustomUrl('/quality');
    this.matomoTracker.trackPageView();
  }

}
