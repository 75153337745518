import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil, take } from 'rxjs/operators';

import { BaseUnsubscribe } from '../../../../shared/entities/base/base-unsubscribe';
import { StepNavigatorService } from './services/step-navigator.service';
import { StepType } from './entities/enums/step-type.enum';

@Component({
  selector: 'app-step-navigator',
  templateUrl: './step-navigator.component.html',
  styleUrls: ['./step-navigator.component.scss']
})
export class StepNavigatorComponent extends BaseUnsubscribe implements OnInit {

  previousStepLabel: string;
  nextStepLabel: string;
  previousStepRoute: Array<string>;
  nextStepRoute: Array<string>;
  previousStepMouseOver: boolean;
  nextStepMouseOver: boolean;

  private previousLabel: string = 'Anterior';
  private nextLabel: string = 'Próximo';

  constructor(private stepNavigatorService: StepNavigatorService, private router: Router) {
    super();

    stepNavigatorService.stepChangeApproved.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(stepType => {
        if (stepType === StepType.previous) {
          this.goToPreviousStep();
        } else {
          this.goToNextStep();
        }
      });

    stepNavigatorService.setPreviousStepLabelObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(previousStepLabel => this.previousStepLabel = previousStepLabel ? previousStepLabel : this.previousLabel);

    stepNavigatorService.setNextStepLabelObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(nextStepLabel => this.nextStepLabel = nextStepLabel ? nextStepLabel : this.nextLabel);

    stepNavigatorService.setPreviousStepRouteObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(previousStepRoute => this.previousStepRoute = previousStepRoute);

    stepNavigatorService.setNextStepRouteObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(nextStepRoute => this.nextStepRoute = nextStepRoute);
  }

  ngOnInit() {
  }

  requestToGoPreviousStep() {
    this.stepNavigatorService.requestStepChange(StepType.previous);
  }

  requestToGoNextStep() {
    this.stepNavigatorService.requestStepChange(StepType.next);
  }

  private goToPreviousStep(): void {
    this.router.navigate(this.previousStepRoute, { queryParamsHandling: 'merge' });
  }

  private goToNextStep(): void {
    this.router.navigate(this.nextStepRoute, { queryParamsHandling: 'merge' });
  }

}
