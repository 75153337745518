import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';

import { BlockUiService } from '../block-ui/block-ui.service';
import { NotificationService } from '../notification/notification.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { SessionService } from '../session/session.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  private blockUiService: BlockUiService = this.injector.get(BlockUiService);
  private sessionService: SessionService = this.injector.get(SessionService);

  constructor(private ngZone: NgZone, private injector: Injector) { }

  handleError(error: any): void {
    let errorMessage: string;

    if (error.message) {
      if (error.message.indexOf('TypeError:') > -1) {
        const matchResult: Array<string> = error.message.match(/TypeError: (.*)\n/g) || new Array<string>('');
        errorMessage = matchResult[0];
      } else {
        errorMessage = error.message;
      }
    }

    this.ngZone.run(() => {
      const notificationConfig: any = {
        timeOut: 0,
        extendedTimeOut: 0,
        tapToDismiss: false,
        preventDuplicates: true
      };

      this.notificationService.showInfo('', 'Recarregue a página para tentar novamente!', notificationConfig);
      this.notificationService.showError(errorMessage, 'Ocorreu um erro!', notificationConfig);

    });

    this.blockUiService.stop(true);
    this.sessionService.setItem(UtilitiesService.hasErrorSessionKey, true);

    setTimeout(() => this.blockUiService.start('Ocorreu um erro!'), 10);

    throw error;
  }

  private get notificationService(): NotificationService {
    return this.injector.get(NotificationService);
  }
}
