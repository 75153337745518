import { Component, Injector, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { EnrollmentByStageSeriesService } from './services/enrollment-by-stage-series.service';
import { EnrollmentByStageSeries } from './entities/enrollment-by-stage-series';
import { Projection } from './entities/projection';
import { Period } from '../../../shared/entities/enums/period.enum';
import { SerieEnum } from '../../../shared/entities/enums/serie.enum';
import { OutOfSchoolPopulationService } from '../../shared/services/out-of-school-population/out-of-school-population.service';
import { SelectLocation } from 'app/simulator/select-location/entities/select-location';
import { Footnote } from 'app/shared/components/footnote/entities/footnote';

@Component({
  selector: 'app-enrollment-by-stage-series',
  templateUrl: './enrollment-by-stage-series.component.html',
  styleUrls: ['./enrollment-by-stage-series.component.scss']
})
export class EnrollmentByStageSeriesComponent extends BaseNavigableComponent<EnrollmentByStageSeries> implements OnInit {

  data: EnrollmentByStageSeries = new EnrollmentByStageSeries();
  functionality: FunctionalityInfo = Functionality.enrollmentByStageAndSeries;
  inconsistencies: Array<Inconsistency>;
  disabledData: boolean = true;
  popoverTriggers: string;
  popoverText: string = 'Esses parâmetros só podem ser alterados para simulações em nível municipal. ' +
    'Para simulações em nível nacional ou estadual, são apresentados resultados dos valores municipais agregados internamente pelo simulador.';

  private calculateTotalsSubject: Subject<Projection> = new Subject<Projection>();
  private calculateTotalsObserver: Observable<Projection> = this.calculateTotalsSubject.asObservable();

  constructor(private enrollmentByStageSeriesService: EnrollmentByStageSeriesService, private injector: Injector, private outOfSchoolPopulationService: OutOfSchoolPopulationService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.processDisabledData();
  }

  processData(): Observable<EnrollmentByStageSeries> {
    return this.enrollmentByStageSeriesService.getData();
  }

  calculateTotals(projection: Projection): void {
    this.calculateTotalsSubject.next(projection);
  }

  processValidation(): void {
    super.processValidation();
  }

  processSpecificBehaviors(): void {
    super.processSpecificBehaviors();

    this.enrollmentByStageSeriesService.setOutOfSchoolPopulation(this.data).pipe(
      takeUntil(this.unsubscribe))
      .subscribe();

    this.calculateTotalsObserver.pipe(
      takeUntil(this.unsubscribe),
      debounceTime(0))
      .subscribe(projection => this.enrollmentByStageSeriesService.calculateTotals(projection));
  }

  getRowspanOutOfSchoolValue(serieId: number, serieQuantity: number): number {
    if (serieId === SerieEnum.ensinoFundamentalPrimeiroAno || serieId === SerieEnum.ensinoFundamentalQuintaSerieSextoAno || serieId === SerieEnum.ensinoMedioPrimeiraSerie) {
      return serieQuantity;
    }

    return 1;
  }

  serieIsVisibleOnOutOfSchoolColumn(serieId: number): boolean {
    return this.outOfSchoolPopulationService.serieIsVisibleOnOutOfSchoolColumn(serieId);
  }

  showEnrollmentQuantity(period: number, stage: number): boolean {
    const nursery: number = 1;
    const preSchool: number = 2;

    return period !== Period.nocturnal || (stage !== nursery && stage !== preSchool);
  }

  processDisabledData(): void {
    const selectLocationData: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    this.disabledData = !selectLocationData || !selectLocationData.selectedCity;

    if (this.disabledData) {
      this.popoverTriggers = 'mouseenter:mouseleave';
    }
  }

  getEnrollmentByStageSeriesProjectionNotes(projection: Projection): Array<Footnote> {
    const notes = new Array<Footnote>();

    if (projection.notesOutOfSchoolPopulation) {
      notes.push(...projection.notesOutOfSchoolPopulation);
    }

    notes.push(projection.currentOfferNote);

    this.data.projections.forEach((pj) => {
      if (pj.locationNote && !notes.find(n => n.indice === pj.locationNote.indice)) {
        notes.push(pj.locationNote);
      }
    });

    return notes;
  }
}
