import { Routes } from '@angular/router';

import { HasErrorGuard } from '../../shared/guards/has-error/has-error.guard';
import { HasRequiredDataGuard } from '../shared/guards/required-data/has-required-data.guard';
import { InconsistencyGuard } from '../shared/guards/inconsistency/inconsistency.guard';
import { SocialChargesComponent } from './social-charges/social-charges.component';
import { AdditionalsComponent } from './additionals/additionals.component';
import { PriceIndicesComponent } from './price-indices/price-indices.component';
import { FullPriceListComponent } from './full-price-list/full-price-list.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'socialcharges' },
    { path: 'socialcharges', component: SocialChargesComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'additionals', component: AdditionalsComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'priceindices', component: PriceIndicesComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] },
    { path: 'fullpricelist', component: FullPriceListComponent, canDeactivate: [HasErrorGuard, HasRequiredDataGuard, InconsistencyGuard] }
];

export const ChargesAndAdditionalsChildrenRoutes: Routes = routes;
