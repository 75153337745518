import { NgModule } from '@angular/core';

import { SimulationsRoutingModule } from './simulations-routing.module';
import { SimulationsComponent } from './simulations/simulations.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    SimulationsRoutingModule
  ],
  declarations: [
    SimulationsComponent
  ]
})
export class SimulationsModule { }
