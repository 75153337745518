import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { TeachingLoad } from './teaching-load';

export class DailyTeachingLoad extends NavigableComponentData {

    teachingLoads: Array<TeachingLoad> = new Array<TeachingLoad>();
    sourceInformation: Footnote;
    noteInfo: Footnote;
    currentYear: number;
    constructor(init?: Partial<DailyTeachingLoad>) {
        super();
        Object.assign(this, init);
    }

}
