import { TotalizerStudentCostByLocation } from './totalizer-student-cost-by-location';

export class TotalizerStudentCostByStage {

  id: number;
  description: string;
  totalizersStudentsCostByLocations: Array<TotalizerStudentCostByLocation>;

  constructor(init?: Partial<TotalizerStudentCostByStage>) {
    Object.assign(this, init);
  }

}
