import { Enrollment } from './enrollment';

export class SerieEnrollments {
    id: number;
    description: string;
    currentOffer: number = 0;
    enrollments: Array<Enrollment> = new Array<Enrollment>();

    constructor(init?: Partial<SerieEnrollments>) {
        Object.assign(this, init);
    }
}
