export enum OutOfSchoolPopulationEnum {
    populacaoMenorDeUmAno = 1,
    populacaoDeUmAno = 2,
    populacaoDeDoisAnos = 3,
    populacaoDeTresAnos = 4,
    populacaoDeQuatroAnos = 5,
    populacaoDeCincoAnos = 6,
    populacaoDeSeisADezAnosSemInstrucaoOuComEFIncompleto = 7,
    populacaoDeOnzeAQuatorzeAnosSemInstrucao = 8,
    populacaoDeOnzeAQuatorzeAnosComEFIncompleto = 9,
    populacaoMenorOuIgualAVinteEQuatroAnosComEFCompletoEOuEMIncompleto = 10,
    populacaoMaiorQueQuinzeAnosSemInstrucao = 11,
    populaçãoMaiorQueQuinzeAnosComEFIncompleto = 12,
    populaçãoMaiorQueVinteECincoAnosComEFCompletoEOuEMIncompleto = 13
}
