export class ExpenseByState {

  state_id: number;
  current: number;
  capital: number;
  total: number;

  constructor(init?: Partial<ExpenseByState>) {
    Object.assign(this, init);
  }
}
