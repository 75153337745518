import { StudentCostByYear } from './student-cost-by-year';

export class StudentCostBySubitem {

  itemCost_id: number;
  itemCostParent_id: number;
  priceList_subitem: string;
  expenseType_id: number;
  expenseType_description: string;
  studentsCostByYear: Array<StudentCostByYear>;

  constructor(init?: Partial<StudentCostBySubitem>) {
    Object.assign(this, init);
  }
}
