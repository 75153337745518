import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { HttpService } from '../../../../shared/services/http/http.service';
import { SessionService } from '../../../../shared/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentYearMonthService {

  private readonly namespace: string = 'simulation.currentyearmonth.';
  private readonly cub: string = `${this.namespace}cub`;

  private currentYearMonthObservables: Array<Observable<any>>;

  constructor(private httpService: HttpService, private sessionService: SessionService) { }

  loadCurrentYearMonths(): Observable<any> {
    const currentYearMonthSessionKeys: Array<string> = this.getCurrentYearMonthSessionKeys();

    this.currentYearMonthObservables = new Array<Observable<any>>();

    for (const currentYearMonthSessionKey of currentYearMonthSessionKeys) {
      const partialUrl: string = currentYearMonthSessionKey.replace(this.namespace, '');
      this.processCurrentYearMonth(currentYearMonthSessionKey, partialUrl);
    }

    if (this.currentYearMonthObservables.length === 0) {
      return of(null);
    } else {
      return forkJoin(this.currentYearMonthObservables);
    }
  }

  clearCurrentYearMonthsSession(): Observable<void> {
    return Observable.create((observer: { next: () => void; complete: () => void; }) => {
      this.sessionService.clear(this.namespace);

      observer.next();
      observer.complete();
    });
  }

  getCurrentYearMonthSessionKeys(): Array<string> {
    return new Array<string>(this.cub);
  }

  getCubCurrentYearMonth(): Array<any> {
    return this.sessionService.getItem<Array<any>>(this.cub);
  }

  private processCurrentYearMonth(currentYearMonthKey: string, partialUrl: string): void {
    const currentYearMonth: Array<any> = this.sessionService.getItem(currentYearMonthKey);

    if (!currentYearMonth || currentYearMonth.length === 0) {
      this.currentYearMonthObservables.push(this.httpService.getApiEndpoint().pipe(
        switchMap(apiEndpoint => {
          return this.httpService.get<Array<any>>(`${apiEndpoint}/${partialUrl}/years_months`).pipe(
            map(yearsMonths => {
              const lastYearMonth: Array<any> = this.getLastYearMonth(yearsMonths);
              this.sessionService.setItem(currentYearMonthKey, lastYearMonth);
            }));
        })));
    }
  }

  private getLastYearMonth(yearMonths: Array<any>): Array<any> {
    const sortedYearMonths: Array<any> = _.sortBy(yearMonths, [yearMonth => yearMonth.year], [yearMonth => yearMonth.month]);
    const resultArray: Array<any> = new Array<any>(_.last(sortedYearMonths));
    return sortedYearMonths && sortedYearMonths.length > 0 ? resultArray : undefined;
  }

}
