import { StudentCostByStage } from './student-cost-by-stage';

export class StudentCostByLocation {

  id: number;
  description: string;
  studentsCostByStages: Array<StudentCostByStage>;

  constructor(init?: Partial<StudentCostByLocation>) {
    Object.assign(this, init);
  }

}
