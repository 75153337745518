import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { NavigableComponent } from '../../entities/base/navigable-component';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { SessionService } from '../../services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class HasErrorGuard implements CanDeactivate<NavigableComponent> {

  constructor(private sessionService: SessionService) { }

  canDeactivate(
    component: NavigableComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    const hasError: boolean = this.sessionService.getItem<boolean>(UtilitiesService.hasErrorSessionKey);
    return !hasError;
  }
}
