import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { Location } from '../../../../shared/entities/location';
import { EmployeeLocation } from '../entities/employee-location';
import { SchoolStaff } from '../entities/school-staff';
import { SchoolsStaff } from '../entities/schools-staff';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';

@Injectable({
  providedIn: 'root'
})
export class SchoolsStaffService implements NavigableComponentService {

  constructor(private sessionService: SessionService, private currentYearService: CurrentYearService) { }

  getData(): Observable<SchoolsStaff> {
    let schoolsStaff: SchoolsStaff = new SchoolsStaff();
    schoolsStaff = this.sessionService.getItem<SchoolsStaff>(Functionality.schoolsStaff.pqrKey);
    schoolsStaff.sourceInformationGeneral = this.getSourceInformationGeneral();
    return of(schoolsStaff);
  }

  getNewSchoolsStaff(currentSequence: number, locations: Array<Location>): SchoolStaff {
    return new SchoolStaff(
      {
        sequence: (currentSequence + 1).toString(),
        employeeLocation: locations.map(location => new EmployeeLocation({ location: location }))
      }
    );
  }

  removeSchoolsStaff(schoolsStaff: Array<SchoolStaff>, sequence: string): void {
    let levelRemoved: boolean = false;
    let sequenceCount: number = schoolsStaff.length - 1;

    for (let i = schoolsStaff.length - 1; i >= 0; i--) {
      const schoolStaff = schoolsStaff[i];

      if (!levelRemoved && schoolStaff.sequence === sequence) {
        schoolsStaff.splice(i, 1);
        levelRemoved = true;
      } else {
        schoolStaff.sequence = sequenceCount.toString();
        sequenceCount--;
      }
    }
  }

  private getSourceInformationGeneral(): Footnote {
    const footNote: Footnote = new Footnote({
      remarks: 'A função Auxiliar de Alimentação não pode ser removida, pois é utilizada no cálculo do custo-aluno, ' +
        'para desconsiderá-la informe o valor zero na sua respectiva remuneração.'

    });
    return footNote;
  }
}
