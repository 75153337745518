import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '../../../../services/http/http.service';
import { Tutorial } from '../entities/tutorial';
import { TutorialStep } from '../entities/tutorial-step';
import { SimulationType } from '../../../../../simulator/simulator/entities/enums/simulation-type.enum';

@Injectable({
  providedIn: 'root'
})
export class TutorialsService {

  constructor(private httpService: HttpService) { }

  getTutorial(simulationType: SimulationType): Observable<Tutorial> {
    let tutorialDataPrefix: string;

    switch (simulationType) {
      case SimulationType.caq:
        tutorialDataPrefix = 'caq';
        break;

      case SimulationType.planning:
      case SimulationType.planningByStateSphereAdm:
      case SimulationType.planningByCitySphereAdm:
      case SimulationType.financingFederatedEntitiesByCitySphereAdm:
      case SimulationType.financingFederatedEntitiesGroupByCityOrState:
        tutorialDataPrefix = 'budget-forecast';
        break;
    }

    return this.httpService.get<Tutorial>(`assets/data/${tutorialDataPrefix}-tutorial.data.json`);
  }
}
