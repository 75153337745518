import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseInconsistency } from '../../../../shared/entities/base/base-inconsistency';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { InconsistencyConfirmComponent } from '../../components/modal/inconsistency-confirm/inconsistency-confirm.component';

@Injectable({
  providedIn: 'root'
})
export class InconsistencyGuard implements CanDeactivate<BaseInconsistency> {

  private bsModalRef: BsModalRef;
  private confirmedMessenger: Subject<boolean> = new Subject<boolean>();
  private confirmedObserver: Observable<boolean> = this.confirmedMessenger.asObservable();

  constructor(private bsModalService: BsModalService, private notificationService: NotificationService) { }

  canDeactivate(
    component: BaseInconsistency,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    if (!component.hasMissingRequiredData()) {
      component.processValidation();

      if (component.inconsistencies && component.inconsistencies.length > 0) {
        this.processConfirmation(component);

        return this.confirmedObserver.pipe(
          tap(confirmed => confirmed));
      }
    }

    return true;
  }

  private processConfirmation(component: BaseInconsistency): void {
    this.bsModalService.onHidden
      .subscribe(reason => this.confirmedMessenger.next(false));

    this.bsModalRef = this.bsModalService.show(InconsistencyConfirmComponent, { class: 'modal-lg' });
    this.bsModalRef.content.inconsistencies = component.inconsistencies;

    this.notificationService.showError('Verifique as inconsistências.', 'Atenção!');

    this.bsModalRef.content.confirmed
      .subscribe(confirmed => this.confirmedMessenger.next(confirmed));
  }
}
