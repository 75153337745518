import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../shared/services/session/session.service';
import { WorkJourneyTeacher } from '../entities/work-journey-teacher';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';

@Injectable({
  providedIn: 'root'
})
export class WorkJourneyTeacherService implements NavigableComponentService {

  constructor(private sessionService: SessionService) { }

  getData(): Observable<WorkJourneyTeacher> {
    return of(this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.pqrKey));
  }

}
