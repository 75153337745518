import { PriceList } from '../../../../shared/entities/price-list';
import { UnitOfMeasurementEnum } from '../../../../shared/entities/enums/unit-of-measurement.enum';
import { AccountPlanEnum } from '../../../../shared/entities/enums/account-plan.enum';
import { ExpenseTypeEnum } from '../../../../shared/entities/enums/expense-type.enum';
import { PriceIndiceEnum } from '../../../../shared/entities/enums/price-indice.enum';

export class SchoolOperation extends PriceList {
    unitPrice: number;
    denomination: string;
    sequence: number;

    constructor(init?: Partial<SchoolOperation>) {
        super();

        this.unitOfMeasurements = null;
        this.accountPlans = [AccountPlanEnum.Funcionamento_Manutencao];
        this.expenseTypes = [ExpenseTypeEnum.Corrente];
        this.priceIndices = [PriceIndiceEnum.IGP];

        Object.assign(this, init);
    }

    toJSON(): any {
        const json = {};
        Object.keys(this).forEach(key => json[(key[0] !== '_' ? key : key.replace('_', ''))] = this[key]);
        return json;
    }
}
