import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class WorkJourneyTeacher extends NavigableComponentData {

  journeyTotal: number;
  journeyNoInteraction: number;
  journeyWithInteraction: number;

  constructor(init?: Partial<WorkJourneyTeacher>) {
    super();
    Object.assign(this, init);
  }

}
