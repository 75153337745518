import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { CaqReport } from './entities/caq-report';
import { CaqReportService } from './services/caq-report.service';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { ShiftEnum } from '../../../shared/entities/enums/shift-enum';
import { StageEnum } from '../../../shared/entities/enums/stage.enum';
import { LocationEnum } from 'app/shared/entities/enums/location.enum';

@Component({
  selector: 'app-caq-report',
  templateUrl: './caq-report.component.html',
  styleUrls: ['./caq-report.component.scss'],
})
export class CaqReportComponent extends BaseNavigableComponent<CaqReport> {

  data: CaqReport = new CaqReport();
  functionality: FunctionalityInfo = Functionality.caqReport;
  stageEJA: number = StageEnum.eja;
  shiftIntegral: number = ShiftEnum.Integral;
  inconsistencies: Array<Inconsistency>;
  locationRural: number = LocationEnum.rural;

  constructor(private injector: Injector, private caqReportService: CaqReportService) {
    super(injector);
  }

  processData(): Observable<CaqReport> {
    return this.caqReportService.getCAQ();
  }

  dataHasChanged(): boolean {
    return false;
  }

  downloadCsv(datas: Number): void {
    this.caqReportService.downloadCsv(this.data, datas);
  }

  getNextStepLabel(): string {
    return 'Finalizar';
  }

}
