import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import * as _ from 'lodash';

import { HttpService } from '../../../../shared/services/http/http.service';
import { SessionService } from '../../../../shared/services/session/session.service';
import { UnitOfMeasurement } from '../../../../shared/entities/unit-of-measurement';
import { SchoolsOperation } from '../../../quality-conditions/schools-operation/entities/schools-operation';
import { AccountPlan } from '../../../../shared/entities/account-plan';
import { SchoolsTransports } from '../../../quality-conditions/school-transport/entities/schools-transports';
import { SchoolsStaff } from '../../../quality-conditions/schools-staff/entities/schools-staff';
import { CareerAndRemunerationTeachers } from '../../../quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { PriceIndice } from '../../../../shared/entities/price-indice';
import { FullPriceList } from '../entities/full-price-list';
import { SchoolsFeedings } from '../../../quality-conditions/school-feeding/entities/schools-feedings';
import { ExpenseType } from '../../../../shared/entities/expense-type';
import { InfrastructureSchoolsBuildings } from '../../../quality-conditions/infrastructure-school-buildings/entities/infrastructure-schools-buildings';
import { UnitOfMeasurementEnum } from '../../../../shared/entities/enums/unit-of-measurement.enum';
import { AccountPlanEnum } from '../../../../shared/entities/enums/account-plan.enum';
import { ExpenseTypeEnum } from '../../../../shared/entities/enums/expense-type.enum';
import { PriceIndiceEnum } from '../../../../shared/entities/enums/price-indice.enum';
import { NewRoomsBuildings } from '../../../quality-conditions/new-room-building/entities/new-rooms-buildings';
import { FullPriceListItem } from '../entities/full-price-list-item';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';

@Injectable({
  providedIn: 'root'
})
export class FullPriceListService implements NavigableComponentService {

  constructor(private sessionService: SessionService, private httpService: HttpService) { }

  getData(): Observable<FullPriceList> {

    const fullPricesList: FullPriceList = new FullPriceList();
    // fullPricesList.concat(this.getSchoolOperation());

    return this.httpService.get<Array<PriceIndice>>('assets/data/price-indices.data.json').pipe(
      mergeMap(priceIndice => {
        return this.httpService.get<Array<UnitOfMeasurement>>('assets/data/unit-of-measurement.data.json').pipe(
          mergeMap(unit => {
            return this.httpService.get<Array<AccountPlan>>('assets/data/account-plan.data.json').pipe(
              mergeMap(account => {
                return this.httpService.get<Array<AccountPlan>>('assets/data/expense-type.data.json').pipe(
                  map(expenseType => {
                    fullPricesList.fullPriceListItems = this.getCareerAndRemunerationTeachers().concat(fullPricesList.fullPriceListItems);
                    fullPricesList.fullPriceListItems = this.getSchoolOperation().concat(fullPricesList.fullPriceListItems);
                    fullPricesList.fullPriceListItems = this.getSchoolTransport().concat(fullPricesList.fullPriceListItems);
                    fullPricesList.fullPriceListItems = this.getSchoolStaff().concat(fullPricesList.fullPriceListItems);
                    fullPricesList.fullPriceListItems = this.getSchoolFeeding().concat(fullPricesList.fullPriceListItems);
                    fullPricesList.fullPriceListItems = this.getInfrastructureSchoolBuildings().concat(fullPricesList.fullPriceListItems);
                    fullPricesList.fullPriceListItems = this.getNewroomBuilding().concat(fullPricesList.fullPriceListItems);

                    if (fullPricesList.fullPriceListItems.length > 0) {
                      fullPricesList.fullPriceListItems.map(
                        item => {
                          if (item.unitOfMeasurement === undefined) {
                            item.unitOfMeasurement = _.find(unit, u => u.id === _.head(item.unitOfMeasurements));
                          }
                          item.accountPlan = _.find(account, a => a.id === _.head(item.accountPlans));
                          item.priceIndice = _.find(priceIndice, p => p.id === _.head(item.priceIndices));
                          item.expenseType = _.find(expenseType, e => e.id === _.head(item.expenseTypes));
                        }
                      );
                    }

                    //// Adiciona uma linha de título para cada accountPlan
                    account.forEach(a => {
                      fullPricesList.fullPriceListItems.push(new FullPriceListItem({
                        id: 0,
                        description: '',
                        accountPlan: a,
                        expenseType: new ExpenseType(),
                        isGroupTitle: true
                      }));
                    });

                    // Retorna a lista ordenada
                    fullPricesList.fullPriceListItems = _.sortBy(fullPricesList.fullPriceListItems, o => [o.accountPlan.order, o.expenseType.description, o.description]);

                    return fullPricesList;
                  }));
              }));
          }));
      }));
  }

  private getCareerAndRemunerationTeachers(): Array<FullPriceListItem> {

    const fullPricesList: Array<FullPriceListItem> = new Array<FullPriceListItem>();
    const resultsForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers =
      this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.key);

    if (resultsForCareerAndRemunerationTeachers != null) {
      resultsForCareerAndRemunerationTeachers.teacherFormationLevels.levels.map(careerAndRemuneration => {
        fullPricesList.push(new FullPriceListItem({
          id: 0,
          description: careerAndRemuneration.denomination,
          unitPrice: careerAndRemuneration.grossMonthlyRemuneration,
          unitOfMeasurements: careerAndRemuneration.unitOfMeasurements,
          accountPlans: careerAndRemuneration.accountPlans,
          expenseTypes: careerAndRemuneration.expenseTypes,
          priceIndices: careerAndRemuneration.priceIndices,
          referenceDate: careerAndRemuneration.referenceDate,
          multiplier: careerAndRemuneration.multiplier
        }));
      });
    }

    return fullPricesList;
  }

  private getSchoolOperation(): Array<FullPriceListItem> {

    const fullPricesList: Array<FullPriceListItem> = new Array<FullPriceListItem>();
    const resultsForSchoolsOperation: SchoolsOperation = this.sessionService.getItem<SchoolsOperation>(Functionality.schoolsOperation.key);
    if (resultsForSchoolsOperation != null) {
      resultsForSchoolsOperation.costs.map(cost => {
        fullPricesList.push(new FullPriceListItem({
          id: cost.sequence,
          description: cost.denomination,
          unitPrice: cost.unitPrice,
          unitOfMeasurement: cost.unitOfMeasurement,
          accountPlans: cost.accountPlans,
          expenseTypes: cost.expenseTypes,
          priceIndices: cost.priceIndices,
          referenceDate: cost.referenceDate,
          multiplier: cost.multiplier
        }));
      });
    }
    return fullPricesList;
  }

  private getSchoolTransport(): Array<FullPriceListItem> {

    const fullPricesList: Array<FullPriceListItem> = new Array<FullPriceListItem>();
    const resultsForSchoolTransport: SchoolsTransports = this.sessionService.getItem<SchoolsTransports>(Functionality.schoolTransport.key);

    if (resultsForSchoolTransport != null) {
      resultsForSchoolTransport.transports.map(transport => {
        fullPricesList.push(new FullPriceListItem({
          description: transport.descriptionTransport,
          unitPrice: transport.unitPrice,
          unitOfMeasurements: transport.unitOfMeasurements,
          accountPlans: transport.accountPlans,
          expenseTypes: transport.expenseTypes,
          priceIndices: transport.priceIndices,
          referenceDate: transport.referenceDate,
          multiplier: transport.multiplier
        }));
      });
    }
    return fullPricesList;
  }

  private getSchoolStaff(): Array<FullPriceListItem> {
    const fullPricesList: Array<FullPriceListItem> = new Array<FullPriceListItem>();
    const resultsForSchoolStaff: SchoolsStaff = this.sessionService.getItem<SchoolsStaff>(Functionality.schoolsStaff.key);

    if (resultsForSchoolStaff != null) {
      resultsForSchoolStaff.staffs.map(staff => {
        fullPricesList.push(new FullPriceListItem({
          id: parseInt(staff.sequence, 10),
          description: staff.denomination,
          unitPrice: staff.grossMonthlyRemuneration,
          unitOfMeasurements: staff.unitOfMeasurements,
          accountPlans: staff.accountPlans,
          expenseTypes: staff.expenseTypes,
          priceIndices: staff.priceIndices,
          referenceDate: staff.referenceDate,
          multiplier: staff.multiplier
        }));
      });
    }

    return fullPricesList;
  }

  private getSchoolFeeding(): Array<FullPriceListItem> {

    const fullPricesList: Array<FullPriceListItem> = new Array<FullPriceListItem>();
    const resultsForSchoolsOperation: SchoolsFeedings = this.sessionService.getItem<SchoolsFeedings>(Functionality.schoolFeeding.key);
    if (resultsForSchoolsOperation != null) {
      resultsForSchoolsOperation.feedings.map(feed => {
        fullPricesList.push(new FullPriceListItem({
          id: feed.sequence,
          description: feed.denomination,
          unitPrice: feed.mealValue,
          unitOfMeasurements: feed.unitOfMeasurements,
          accountPlans: feed.accountPlans,
          expenseTypes: feed.expenseTypes,
          priceIndices: feed.priceIndices,
          referenceDate: feed.referenceDate,
          multiplier: feed.multiplier
        }));
      });
    }
    return fullPricesList;
  }

  private getInfrastructureSchoolBuildings(): Array<FullPriceListItem> {

    const fullPricesList: Array<FullPriceListItem> = new Array<FullPriceListItem>();
    const resultsForInfrastructure: InfrastructureSchoolsBuildings = this.sessionService.getItem<InfrastructureSchoolsBuildings>(Functionality.infrastructureSchoolBuildings.key);
    const price: number = _.last(resultsForInfrastructure.infrastructureSchools.infrastructureBuildings).unitPrice;

    fullPricesList.push(new FullPriceListItem({
      id: 0,
      description: ' Custo unitário básico da construção civil (CUB R-1)',
      unitPrice: price,
      unitOfMeasurements: [UnitOfMeasurementEnum.m2],
      accountPlans: [AccountPlanEnum.Obras_E_Instalações],
      expenseTypes: [ExpenseTypeEnum.Capital],
      priceIndices: [PriceIndiceEnum.INCC],
      multiplier: 12
    }));

    if (resultsForInfrastructure != null) {
      resultsForInfrastructure.infrastructureSchools.infrastructureBuildings
        .map(infra => {
          if (!infra.isTitle) {
            fullPricesList.push(new FullPriceListItem({
              id: infra.id,
              description: infra.description,
              unitPrice: infra.unitPrice,
              unitOfMeasurements: infra.unitOfMeasurements,
              accountPlans: infra.accountPlans,
              expenseTypes: infra.expenseTypes,
              priceIndices: infra.priceIndices,
              referenceDate: infra.referenceDate,
              multiplier: infra.multiplier
            }));
          }
        });
    }
    return fullPricesList;
  }

  private getNewroomBuilding(): Array<FullPriceListItem> {

    const fullPricesList: Array<FullPriceListItem> = new Array<FullPriceListItem>();
    const resultsForNewroomBuilding: NewRoomsBuildings = this.sessionService.getItem<NewRoomsBuildings>(Functionality.newRoomBuilding.key);

    if (resultsForNewroomBuilding != null) {
      resultsForNewroomBuilding.newRooms.buildings
        .map(room => {
          if (room.unitPrice !== undefined && room.unitPrice > 0) {
            fullPricesList.push(new FullPriceListItem({
              id: room.id,
              description: room.denomination,
              unitPrice: room.costEquipment,
              unitOfMeasurements: room.unitOfMeasurements,
              accountPlans: room.accountPlans,
              expenseTypes: room.expenseTypes,
              priceIndices: room.priceIndices,
              referenceDate: room.referenceDate,
              multiplier: room.multiplier
            }));
          }
        });
    }
    return fullPricesList;
  }

}
