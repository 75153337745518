import { EmployeeEstimateByLocation } from './employee-estimate-by-location';

export class EmployeeEstimate {

  city_id: string;
  cityDescription: string;
  employeesEstimateByLocations: Array<EmployeeEstimateByLocation>;

  constructor(init?: Partial<EmployeeEstimate>) {
    Object.assign(this, init);
  }

}
