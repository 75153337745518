import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { ItemCostEnum } from '../../../../../shared/entities/enums/item-cost.enum';
import { CareerAndRemunerationTeachers } from '../../../../quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { Stage } from '../entities/stage';
import { StudentCostByStage } from '../entities/student-cost-by-stage';
import { StudentCostByYear } from '../entities/student-cost-by-year';
import { StudentCostByLocation } from '../entities/student-cost-by-location';
import { UtilitiesService } from '../../../../../shared/services/utilities/utilities.service';
import { Functionality } from '../../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../../shared/services/session/session.service';
import { StudentCost } from '../entities/student-cost';
import { StudentCostBySubitem } from '../entities/student-cost-by-subitem';
import { ExpenseTypeEnum } from '../../../../../shared/entities/enums/expense-type.enum';
import { EnrollmentProjectionByLocation } from '../../../../access-and-offer/enrollment-projection/entities/enrollment-projection-by-location';
import { EnrollmentProjection } from '../../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { PricesIndices } from '../../../../charges-and-additionals/price-indices/entities/prices-indices';
import { TeacherNumberCalc } from '../../calculate-teacher-number/entities/teacher-number-calc';
import { TeacherNumberByStageCalc } from '../../calculate-teacher-number/entities/teacher-number-by-stage-calc';
import { ShiftStudentCost } from '../entities/enums/shift-student-cost.enum';
import { Location } from '../../../../../shared/entities/location';
import { LocationEnum } from '../../../../../shared/entities/enums/location.enum';
import { SchoolsOperation } from '../../../../quality-conditions/schools-operation/entities/schools-operation';
import { CostSharing } from '../../calculate-cost-sharing/entities/cost-sharing';
import { CostDrive } from '../../calculate-cost-drive/entities/cost-drive';
import { CostDriveByLocation } from '../../calculate-cost-drive/entities/cost-drive-by-location';
import { SchoolsFeedings } from '../../../../quality-conditions/school-feeding/entities/schools-feedings';
import { TeacherNumberByLocationCalc } from '../../calculate-teacher-number/entities/teacher-number-by-location-calc';
import { CostSharingByLocation } from '../../calculate-cost-sharing/entities/cost-sharing-by-location';
import { EmployeeEstimateByLocation } from '../../calculate-employee-estimate/entities/employee-estimate-by-location';
import { AdministrativeAreaCosting } from '../../../../quality-conditions/administrative-area-costing/entities/administrative-area-costing';
import { EmployeeEstimateByRole } from '../../calculate-employee-estimate/entities/employee-estimate-by-role';
import { EmployeeEstimate } from '../../calculate-employee-estimate/entities/employee-estimate';
import { TeacherTraining } from '../../../../quality-conditions/teacher-training/entities/teacher-training';
import { SocialCharges } from '../../../../charges-and-additionals/social-charges/entities/social-charges';
import { CostSharingBySubitem } from '../../calculate-cost-sharing/entities/cost-sharing-by-subitem';
import { CostDriveByStage } from '../../calculate-cost-drive/entities/cost-drive-by-stage';
import { OfferGoalEnrollmentFullTime } from '../../../../quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { FullTime } from '../../../../quality-conditions/offer-goal-enrollment-full-time/entities/full-time';
import { Additional } from '../../../../charges-and-additionals/additionals/entities/additional';
import { StageEnrollments } from '../../../../access-and-offer/enrollment-projection/entities/stage-enrollments';
import { AdministrativeItemCosting } from 'app/simulator/quality-conditions/administrative-area-costing/entities/administrative-item-costing';
import { PercentageTeacherCareer } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/percentage_teacher_career';

@Injectable({
  providedIn: 'root'
})
export class ItemCostService {

  constructor(private sessionService: SessionService, private utilitiesService: UtilitiesService) { }

  calculateItemCost(
    locations: Array<Location>,
    stages: Array<Stage>,
    calculatedTeachersNumber: Array<TeacherNumberCalc>,
    calculatedEmployeesEstimate: Array<EmployeeEstimate>,
    calculatedCostsSharing: Array<CostSharing>,
    calculatedCostsDrives: Array<CostDrive>,
    enrollmentProjection: EnrollmentProjection,
    isFinancingFundsReport: boolean = false,
    calculateCostOfAssetsAndServicesByStages: boolean = false,
    calculateJustCostOfAssetsAndServicesByStages: boolean = false,
    calculateItemsCostMDE: boolean = true,
    includeAdministrationSupervisionNetworkSchoolFeeding: boolean = true,
    careerAndRemunerationTeacher: CareerAndRemunerationTeachers = undefined,
    considerQuantityTeacherCareer = false,
    percentagesTeachersCareer: Array<PercentageTeacherCareer> = undefined
  ): Observable<Array<StudentCost>> {

    const studentsCost: Array<StudentCost> = new Array<StudentCost>();
    const resultForPriceIndice: PricesIndices = this.sessionService.getItem<PricesIndices>(Functionality.priceIndices.key);
    const resultForCareerAndRemunerationTeacher: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.key);
    const resultForAdditional: Additional = this.sessionService.getItem<Additional>(Functionality.additionals.key);
    const resultForSchoolOperation: SchoolsOperation = this.sessionService.getItem<SchoolsOperation>(Functionality.schoolsOperation.key);
    const studentsCostByLocations: Array<StudentCostByLocation> = new Array<StudentCostByLocation>();

    let resultForEnrollmentProjectionByLocation: EnrollmentProjection = new EnrollmentProjection();

    if (!enrollmentProjection) {
      resultForEnrollmentProjectionByLocation = this.sessionService.getItem<EnrollmentProjection>(Functionality.enrollmentProjectionByLocation.key);
    } else {
      resultForEnrollmentProjectionByLocation = enrollmentProjection;
    }

    for (let i = 0; i < locations.length; i++) {

      let teachersNumberLocation: TeacherNumberByLocationCalc = new TeacherNumberByLocationCalc();
      for (let j = 0; j < calculatedTeachersNumber.length; j++) {
        for (let k = 0; k < calculatedTeachersNumber[j].teachersNumberByLocationsCalc.length; k++) {
          if (calculatedTeachersNumber[j].teachersNumberByLocationsCalc[k].id === locations[i].id) {
            teachersNumberLocation = calculatedTeachersNumber[j].teachersNumberByLocationsCalc[k];
          }
        }
      }

      let employeesEstimateLocation: EmployeeEstimateByLocation = new EmployeeEstimateByLocation();
      for (let j = 0; j < calculatedEmployeesEstimate.length; j++) {
        for (let k = 0; k < calculatedEmployeesEstimate[j].employeesEstimateByLocations.length; k++) {
          if (calculatedEmployeesEstimate[j].employeesEstimateByLocations[k].id === locations[i].id) {
            employeesEstimateLocation = calculatedEmployeesEstimate[j].employeesEstimateByLocations[k];
          }
        }
      }

      let costDriveLocation: CostDriveByLocation = new CostDriveByLocation();
      for (let j = 0; j < calculatedCostsDrives.length; j++) {
        for (let k = 0; k < calculatedCostsDrives[j].costDrivesByLocations.length; k++) {
          if (calculatedCostsDrives[j].costDrivesByLocations[k].id === locations[i].id) {
            costDriveLocation = calculatedCostsDrives[j].costDrivesByLocations[k];
          }
        }
      }

      let costSharingLocation: CostSharingByLocation = new CostSharingByLocation();
      for (let j = 0; j < calculatedCostsSharing.length; j++) {
        for (let k = 0; k < calculatedCostsSharing[j].costsSharingByLocations.length; k++) {
          if (calculatedCostsSharing[j].costsSharingByLocations[k].id === locations[i].id) {
            costSharingLocation = calculatedCostsSharing[j].costsSharingByLocations[k];
          }
        }
      }

      let enrollmentsLocation: EnrollmentProjectionByLocation = new EnrollmentProjectionByLocation();
      for (let k = 0; k < resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations.length; k++) {
        if (resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations[k].id === locations[i].id) {
          enrollmentsLocation = resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations[k];
        }
      }

      let percentageAdditional: number = 0;
      if (resultForAdditional) {
        percentageAdditional = locations[i].id === LocationEnum.rural ? resultForAdditional.additionals[0].percentage : 0;
      }

      studentsCostByLocations.push(new StudentCostByLocation({
        id: locations[i].id,
        description: locations[i].description,
        studentsCostByStages: this.getCostsByStage(teachersNumberLocation.teachersNumberByStagesCalc,
          resultForPriceIndice, resultForCareerAndRemunerationTeacher, percentageAdditional, stages,
          employeesEstimateLocation.employeesEstimateByRoles, costDriveLocation.costDrivesByStages,
          costSharingLocation.costsSharingBySubitems, enrollmentsLocation.stagesEnrollments, resultForSchoolOperation,
          isFinancingFundsReport, calculateCostOfAssetsAndServicesByStages, calculateJustCostOfAssetsAndServicesByStages,
          calculateItemsCostMDE, includeAdministrationSupervisionNetworkSchoolFeeding, careerAndRemunerationTeacher, considerQuantityTeacherCareer, percentagesTeachersCareer)
      }));

    }
    studentsCost.push(new StudentCost({
      studentsCostByLocations: studentsCostByLocations
    }));
    return of(studentsCost);

  }

  private getCostsByStage(
    teachersNumberByStagesCalc: Array<TeacherNumberByStageCalc>,
    resultForPriceIndice: PricesIndices,
    resultForCareerAndRemunerationTeacher: CareerAndRemunerationTeachers,
    percentageAdditional: number,
    stages: Array<Stage>,
    calculatedEmployeesEstimateByRoles: Array<EmployeeEstimateByRole>,
    costsDrivesByStages: Array<CostDriveByStage>,
    costsSharingBySubitems: Array<CostSharingBySubitem>,
    enrollmentsStages: Array<StageEnrollments>,
    schoolOperation: SchoolsOperation,
    isFinancingFundsReport: boolean,
    calculateCostOfAssetsAndServicesByStages: boolean,
    calculateJustCostOfAssetsAndServicesByStages: boolean,
    calculateItemsCostMDE: boolean,
    includeAdministrationSupervisionNetworkSchoolFeeding: boolean = true,
    careerAndRemunerationTeacher: CareerAndRemunerationTeachers = undefined,
    considerQuantityTeacherNumberCareer: boolean,
    percentagesTeachersCareer: Array<PercentageTeacherCareer> = undefined
  ): Array<StudentCostByStage> {

    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();

    if (enrollmentsStages) {

      const yearsSimulation = this.utilitiesService.getSimulationYears();
      const resultForadministrativeAreaCosting: AdministrativeAreaCosting = this.sessionService.getItem<AdministrativeAreaCosting>(Functionality.administrativeAreaCosting.key);
      const costTeacherByStage: Array<StudentCostByStage> = this.getCostTeachersByStage(teachersNumberByStagesCalc, resultForPriceIndice, resultForCareerAndRemunerationTeacher,
        percentageAdditional, isFinancingFundsReport, careerAndRemunerationTeacher, considerQuantityTeacherNumberCareer, percentagesTeachersCareer);
      // const costAssetsAndServicesByStages: Array<StudentCostByStage> = this.getCostAssetsAndServicesByStage(enrollmentsStages, schoolOperation);
      const costFoodsAndCookingGasByStages: Array<StudentCostByStage> = this.getCostFoodAndCookingGasByStage(stages, enrollmentsStages);
      // const costTeachersTrainingByStages: Array<StudentCostByStage> = this.getCostTeacherTrainingByStage(stages, teachersNumberByStagesCalc);
      const costEmployeesByStages: Array<StudentCostByStage> = this.getCostEmployeesByStage(stages, costsDrivesByStages, costsSharingBySubitems, isFinancingFundsReport);
      /* const costSchoolsFeedingsAssistantByStages: Array<StudentCostByStage> = this.getCostSchoolFeedingAssistantByStage(stages, costsDrivesByStages, enrollmentsStages,
        costsSharingBySubitems, isFinancingFundsReport);
      /* const costEmployeesTrainingByStages: Array<StudentCostByStage> = this.getCostEmployeeTrainingByStage(stages, teachersNumberByStagesCalc, schoolOperation,
        costsDrivesByStages, costsSharingBySubitems, isFinancingFundsReport);*/

      for (let i = 0; i < stages.length; i++) {

        const studentCostByStage: StudentCostByStage = new StudentCostByStage({
          id: stages[i].id,
          description: stages[i].description,
          studentsCostBySubitems: new Array<StudentCostBySubitem>(),
          studentsCostByYearTotal: new Array<StudentCostByYear>()
        });

        const costsTotalsByYear: Array<StudentCostByYear> = yearsSimulation.map(yearSimulation => {
          return new StudentCostByYear({ year: yearSimulation, costTotal: 0, costShiftIntegral: 0, costShiftPartial: 0 });
        });

        // custo de bens e serviços
        /*if (calculateCostOfAssetsAndServicesByStages) {
          for (let j = 0; j < costAssetsAndServicesByStages.length; j++) {
            if (costAssetsAndServicesByStages[j].id === stages[i].id) {
              for (let k = 0; k < costAssetsAndServicesByStages[j].studentsCostBySubitems.length; k++) {
                studentCostByStage.studentsCostBySubitems.push(costAssetsAndServicesByStages[j].studentsCostBySubitems[k]);
              }
              for (let k = 0; k < costAssetsAndServicesByStages[j].studentsCostByYearTotal.length; k++) {
                costsTotalsByYear[k].costShiftIntegral += costAssetsAndServicesByStages[j].studentsCostByYearTotal[k].costShiftIntegral;
                costsTotalsByYear[k].costShiftPartial += costAssetsAndServicesByStages[j].studentsCostByYearTotal[k].costShiftPartial;
                costsTotalsByYear[k].costTotal += costAssetsAndServicesByStages[j].studentsCostByYearTotal[k].costShiftIntegral +
                  costAssetsAndServicesByStages[j].studentsCostByYearTotal[k].costShiftPartial;
              }
              break;
            }
          }
        }*/

        if (!calculateItemsCostMDE) {
          // custo do Auxiliar de alimentação
          /*for (let j = 0; j < costSchoolsFeedingsAssistantByStages.length; j++) {
            if (costSchoolsFeedingsAssistantByStages[j].id === stages[i].id) {
              for (let k = 0; k < costSchoolsFeedingsAssistantByStages[j].studentsCostBySubitems.length; k++) {
                studentCostByStage.studentsCostBySubitems.push(costSchoolsFeedingsAssistantByStages[j].studentsCostBySubitems[k]);
              }
              // Item comentado para atender à tarefa 24 - futuramente o if será retirado
              if (includeAdministrationSupervisionNetworkSchoolFeeding) {
                for (let k = 0; k < costSchoolsFeedingsAssistantByStages[j].studentsCostByYearTotal.length; k++) {
                  costsTotalsByYear[k].costShiftIntegral += costSchoolsFeedingsAssistantByStages[j].studentsCostByYearTotal[k].costShiftIntegral;
                  costsTotalsByYear[k].costShiftPartial += costSchoolsFeedingsAssistantByStages[j].studentsCostByYearTotal[k].costShiftPartial;
                  costsTotalsByYear[k].costTotal += costsTotalsByYear[k].costTotal;
                }
              }
              break;
            }
          }*/

          // custo do item alimentos e gás de cozinha
          for (let j = 0; j < costFoodsAndCookingGasByStages.length; j++) {
            if (costFoodsAndCookingGasByStages[j].id === stages[i].id) {
              for (let k = 0; k < costFoodsAndCookingGasByStages[j].studentsCostBySubitems.length; k++) {
                studentCostByStage.studentsCostBySubitems.push(costFoodsAndCookingGasByStages[j].studentsCostBySubitems[k]);
              }
              // Item comentado para atender à tarefa 24 - futuramente o if será retirado
              /* if (includeAdministrationSupervisionNetworkSchoolFeeding) {
                 for (let k = 0; k < costFoodsAndCookingGasByStages[j].studentsCostByYearTotal.length; k++) {
                   costsTotalsByYear[k].costShiftIntegral += costFoodsAndCookingGasByStages[j].studentsCostByYearTotal[k].costShiftIntegral;
                   costsTotalsByYear[k].costShiftPartial += costFoodsAndCookingGasByStages[j].studentsCostByYearTotal[k].costShiftPartial;
                   costsTotalsByYear[k].costTotal += costsTotalsByYear[k].costTotal;
                 }
               }*/
              break;
            }
          }
        }

        if (!calculateJustCostOfAssetsAndServicesByStages) {
          // custo de professores
          for (let j = 0; j < costTeacherByStage.length; j++) {
            if (costTeacherByStage[j].id === stages[i].id) {
              for (let k = 0; k < costTeacherByStage[j].studentsCostBySubitems.length; k++) {
                studentCostByStage.studentsCostBySubitems.push(costTeacherByStage[j].studentsCostBySubitems[k]);
              }
              for (let k = 0; k < costTeacherByStage[j].studentsCostByYearTotal.length; k++) {
                costsTotalsByYear[k].costShiftIntegral += costTeacherByStage[j].studentsCostByYearTotal[k].costShiftIntegral;
                costsTotalsByYear[k].costShiftPartial += costTeacherByStage[j].studentsCostByYearTotal[k].costShiftPartial;
                costsTotalsByYear[k].costTotal += costsTotalsByYear[k].costTotal;
              }
              break;
            }
          }

          // custo de funcionários
          for (let j = 0; j < costEmployeesByStages.length; j++) {
            if (costEmployeesByStages[j].id === stages[i].id) {
              for (let k = 0; k < costEmployeesByStages[j].studentsCostBySubitems.length; k++) {
                studentCostByStage.studentsCostBySubitems.push(costEmployeesByStages[j].studentsCostBySubitems[k]);
              }
              for (let k = 0; k < costEmployeesByStages[j].studentsCostByYearTotal.length; k++) {
                costsTotalsByYear[k].costShiftIntegral += costEmployeesByStages[j].studentsCostByYearTotal[k].costShiftIntegral;
                costsTotalsByYear[k].costShiftPartial += costEmployeesByStages[j].studentsCostByYearTotal[k].costShiftPartial;
                costsTotalsByYear[k].costTotal += costEmployeesByStages[j].studentsCostByYearTotal[k].costShiftIntegral + costEmployeesByStages[j].studentsCostByYearTotal[k].costShiftPartial;
              }
              break;
            }
          }

          // custo de formação de professores
          /*for (let j = 0; j < costTeachersTrainingByStages.length; j++) {
            if (costTeachersTrainingByStages[j].id === stages[i].id) {
              for (let k = 0; k < costTeachersTrainingByStages[j].studentsCostBySubitems.length; k++) {
                studentCostByStage.studentsCostBySubitems.push(costTeachersTrainingByStages[j].studentsCostBySubitems[k]);
              }
              for (let k = 0; k < costTeachersTrainingByStages[j].studentsCostByYearTotal.length; k++) {
                costsTotalsByYear[k].costShiftIntegral += costTeachersTrainingByStages[j].studentsCostByYearTotal[k].costShiftIntegral;
                costsTotalsByYear[k].costShiftPartial += costTeachersTrainingByStages[j].studentsCostByYearTotal[k].costShiftPartial;
                costsTotalsByYear[k].costTotal += costsTotalsByYear[k].costTotal;
              }
              break;
            }
          }*/

          // custo de formação de funcionários
          /*for (let j = 0; j < costEmployeesTrainingByStages.length; j++) {
            if (costEmployeesTrainingByStages[j].id === stages[i].id) {
              for (let k = 0; k < costEmployeesTrainingByStages[j].studentsCostBySubitems.length; k++) {
                studentCostByStage.studentsCostBySubitems.push(costEmployeesTrainingByStages[j].studentsCostBySubitems[k]);
              }
              for (let k = 0; k < costEmployeesTrainingByStages[j].studentsCostByYearTotal.length; k++) {
                costsTotalsByYear[k].costShiftIntegral += costEmployeesTrainingByStages[j].studentsCostByYearTotal[k].costShiftIntegral;
                costsTotalsByYear[k].costShiftPartial += costEmployeesTrainingByStages[j].studentsCostByYearTotal[k].costShiftPartial;
                costsTotalsByYear[k].costTotal += costsTotalsByYear[k].costTotal;
              }
              break;
            }
          }*/
        }

        // custo da Administração e supervisão da rede na etapa
        /*for (let j = 0; j < costsTotalsByYear.length; j++) {

          const divisor: number = (1 - (resultForadministrativeAreaCosting.administrativeAreaCostingPercentage / 100));
          const costAdministrationAndSupervisionIntegral: number = (costsTotalsByYear[j].costShiftIntegral / divisor) - costsTotalsByYear[j].costShiftIntegral;
          const costAdministrationAndSupervisionPartial: number = (costsTotalsByYear[j].costShiftPartial / divisor) - costsTotalsByYear[j].costShiftPartial;

          costAdministrationAndSupervisionEducationalByYear.push(new StudentCostByYear({
            year: costsTotalsByYear[j].year,
            costShiftIntegral: costAdministrationAndSupervisionIntegral,
            costShiftPartial: costAdministrationAndSupervisionPartial,
            costTotal: costAdministrationAndSupervisionIntegral + costAdministrationAndSupervisionPartial
          }));

          costsTotalsByYear[j].costShiftIntegral += costAdministrationAndSupervisionIntegral;
          costsTotalsByYear[j].costShiftPartial += costAdministrationAndSupervisionPartial;
          costsTotalsByYear[j].costTotal += costAdministrationAndSupervisionIntegral + costAdministrationAndSupervisionPartial;
        }*/

        // console.log(costsTotalsByYear);

        /*for (let j = 0; j < resultForadministrativeAreaCosting.administrativesItensCosting.length; j++) {

          studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
            itemCost_id: resultForadministrativeAreaCosting.administrativesItensCosting[j].sequence,
            itemCostParent_id: ItemCostEnum.AdministrativeCosts,
            priceList_subitem: resultForadministrativeAreaCosting.administrativesItensCosting[j].denomination,
            expenseType_id: ExpenseTypeEnum.Corrente,
            studentsCostByYear: this.getCostAdministrativeArea(costsTotalsByYear, resultForadministrativeAreaCosting.administrativesItensCosting[j])
          }));
        }*/

        studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
          itemCostParent_id: ItemCostEnum.AdministrativeCosts,
          priceList_subitem: 'Total custo área administrativa',
          expenseType_id: ExpenseTypeEnum.Corrente,
          studentsCostByYear: this.getCostAdministrativeAreaTotal(costsTotalsByYear, resultForadministrativeAreaCosting.totalPercentage)
        }));

        /*studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
          itemCost_id: ItemCostEnum.AdministracaoSupervisaoRede,
          itemCostParent_id: ItemCostEnum.CustosAdministracaoCentral,
          priceList_subitem: 'Administração e supervisão da rede',
          expenseType_id: ExpenseTypeEnum.Corrente,
          studentsCostByYear: this.getCostAdministrationAndSupervisionEducationalByYear(costsTotalsByYear, resultForadministrativeAreaCosting)
        }));

        studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
          itemCost_id: ItemCostEnum.DespesasMateriaisDidaticos,
          priceList_subitem: 'Despesas com materiais didáticos e ações pedagógicas nas escolas',
          expenseType_id: ExpenseTypeEnum.Corrente,
          studentsCostByYear: this.getCostTeachingMaterialByYear(costsTotalsByYear, resultForadministrativeAreaCosting)
        }));

        studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
          itemCost_id: ItemCostEnum.FormacaoContinuadaProfissionais,
          priceList_subitem: 'Formação continuada dos profissionais da educação',
          expenseType_id: ExpenseTypeEnum.Corrente,
          studentsCostByYear: this.getCostProfessionalTrainingByYear(costsTotalsByYear, resultForadministrativeAreaCosting)
        }));

        studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
          itemCost_id: ItemCostEnum.FuncionamentoManutencaoEscolas,
          priceList_subitem: 'Funcionamento e manutenção da infraestrutura das escolas, equipamentos e mobiliários',
          expenseType_id: ExpenseTypeEnum.Corrente,
          studentsCostByYear: this.getCostOperationMaintenanceSchoolsByYear(costsTotalsByYear, resultForadministrativeAreaCosting)
        }));*/

        const itensCostAdministrativeArea = studentCostByStage.studentsCostBySubitems.filter(cs => cs.itemCostParent_id === ItemCostEnum.AdministrativeCosts);
        for (let j = 0; j < itensCostAdministrativeArea.length; j++) {
          for (let k = 0; k < itensCostAdministrativeArea[j].studentsCostByYear.length; k++) {
            costsTotalsByYear[k].costShiftIntegral += itensCostAdministrativeArea[j].studentsCostByYear[k].costShiftIntegral;
            costsTotalsByYear[k].costShiftPartial += itensCostAdministrativeArea[j].studentsCostByYear[k].costShiftPartial;
            costsTotalsByYear[k].costTotal += itensCostAdministrativeArea[j].studentsCostByYear[k].costTotal;
          }
        }

        /*const subitemAdministracaoSupervisaoRede = _.find(studentCostByStage.studentsCostBySubitems, sub => sub.itemCost_id === ItemCostEnum.AdministracaoSupervisaoRede);
        for (let j = 0; j < subitemAdministracaoSupervisaoRede.studentsCostByYear.length; j++) {
          costsTotalsByYear[j].costShiftIntegral += subitemAdministracaoSupervisaoRede.studentsCostByYear[j].costShiftIntegral;
          costsTotalsByYear[j].costShiftPartial += subitemAdministracaoSupervisaoRede.studentsCostByYear[j].costShiftPartial;
          costsTotalsByYear[j].costTotal += subitemAdministracaoSupervisaoRede.studentsCostByYear[j].costTotal;
        }

        const subitemDespesasMateriaisDidaticos = _.find(studentCostByStage.studentsCostBySubitems, sub => sub.itemCost_id === ItemCostEnum.DespesasMateriaisDidaticos);
        for (let j = 0; j < subitemDespesasMateriaisDidaticos.studentsCostByYear.length; j++) {
          costsTotalsByYear[j].costShiftIntegral += subitemDespesasMateriaisDidaticos.studentsCostByYear[j].costShiftIntegral;
          costsTotalsByYear[j].costShiftPartial += subitemDespesasMateriaisDidaticos.studentsCostByYear[j].costShiftPartial;
          costsTotalsByYear[j].costTotal += subitemDespesasMateriaisDidaticos.studentsCostByYear[j].costTotal;
        }

        const subitemFormacaoContinuadaProfissionais = _.find(studentCostByStage.studentsCostBySubitems, sub => sub.itemCost_id === ItemCostEnum.FormacaoContinuadaProfissionais);
        for (let j = 0; j < subitemFormacaoContinuadaProfissionais.studentsCostByYear.length; j++) {
          costsTotalsByYear[j].costShiftIntegral += subitemFormacaoContinuadaProfissionais.studentsCostByYear[j].costShiftIntegral;
          costsTotalsByYear[j].costShiftPartial += subitemFormacaoContinuadaProfissionais.studentsCostByYear[j].costShiftPartial;
          costsTotalsByYear[j].costTotal += subitemFormacaoContinuadaProfissionais.studentsCostByYear[j].costTotal;
        }

        const subitemFuncionamentoManutencaoEscolas = _.find(studentCostByStage.studentsCostBySubitems, sub => sub.itemCost_id === ItemCostEnum.FuncionamentoManutencaoEscolas);
        for (let j = 0; j < subitemFuncionamentoManutencaoEscolas.studentsCostByYear.length; j++) {
          costsTotalsByYear[j].costShiftIntegral += subitemFuncionamentoManutencaoEscolas.studentsCostByYear[j].costShiftIntegral;
          costsTotalsByYear[j].costShiftPartial += subitemFuncionamentoManutencaoEscolas.studentsCostByYear[j].costShiftPartial;
          costsTotalsByYear[j].costTotal += subitemFuncionamentoManutencaoEscolas.studentsCostByYear[j].costTotal;
        }*/

        if (!calculateItemsCostMDE) {
          // custo do item alimentos e gás de cozinha
          for (let j = 0; j < costFoodsAndCookingGasByStages.length; j++) {
            if (costFoodsAndCookingGasByStages[j].id === stages[i].id) {

              for (let k = 0; k < costFoodsAndCookingGasByStages[j].studentsCostByYearTotal.length; k++) {
                costsTotalsByYear[k].costShiftIntegral += costFoodsAndCookingGasByStages[j].studentsCostByYearTotal[k].costShiftIntegral;
                costsTotalsByYear[k].costShiftPartial += costFoodsAndCookingGasByStages[j].studentsCostByYearTotal[k].costShiftPartial;
                costsTotalsByYear[k].costTotal += costsTotalsByYear[k].costTotal;
              }
              break;
            }
          }
        }

        // Item para atender à tarefa 24 - futuramente esse código será retirado
        /*for (let j = 0; j < costSchoolsFeedingsAssistantByStages.length; j++) {
          if (costSchoolsFeedingsAssistantByStages[j].id === stages[i].id) {
            // Item comentado para atender à tarefa 24 - futuramente o código será retirado
            if (!includeAdministrationSupervisionNetworkSchoolFeeding && !calculateItemsCostMDE) {
              for (let k = 0; k < costSchoolsFeedingsAssistantByStages[j].studentsCostByYearTotal.length; k++) {
                costsTotalsByYear[k].costShiftIntegral += costSchoolsFeedingsAssistantByStages[j].studentsCostByYearTotal[k].costShiftIntegral;
                costsTotalsByYear[k].costShiftPartial += costSchoolsFeedingsAssistantByStages[j].studentsCostByYearTotal[k].costShiftPartial;
                costsTotalsByYear[k].costTotal += costsTotalsByYear[k].costTotal;
              }
            }
            break;
          }
        }

        // Item para atender à tarefa 24 - futuramente esse código será retirado
        for (let j = 0; j < costFoodsAndCookingGasByStages.length; j++) {
          if (costFoodsAndCookingGasByStages[j].id === stages[i].id) {
            if (!includeAdministrationSupervisionNetworkSchoolFeeding && !calculateItemsCostMDE) {
              for (let k = 0; k < costFoodsAndCookingGasByStages[j].studentsCostByYearTotal.length; k++) {
                costsTotalsByYear[k].costShiftIntegral += costFoodsAndCookingGasByStages[j].studentsCostByYearTotal[k].costShiftIntegral;
                costsTotalsByYear[k].costShiftPartial += costFoodsAndCookingGasByStages[j].studentsCostByYearTotal[k].costShiftPartial;
                costsTotalsByYear[k].costTotal += costsTotalsByYear[k].costTotal;
              }
            }
            break;
          }
        }*/

        studentCostByStage.studentsCostByYearTotal = costsTotalsByYear;
        studentsCostByStages.push(studentCostByStage);
      }
    }
    return studentsCostByStages;
  }

  private getCostTeachersByStage(teachersNumberByStages: Array<TeacherNumberByStageCalc>,
    resultForPriceIndice: PricesIndices, resultForCareerAndRemunerationTeacher: CareerAndRemunerationTeachers, percentageAdditional: number,
    isFinancingFundsReport: boolean, careerAndRemunerationTeacher: CareerAndRemunerationTeachers, considerQuantityTeacherNumberCareer: boolean,
    percentagesTeachersCareer: Array<PercentageTeacherCareer> = undefined): Array<StudentCostByStage> {

    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const resultForSocialCharges: SocialCharges = this.sessionService.getItem<SocialCharges>(Functionality.socialCharges.key);

    if (teachersNumberByStages) {
      for (let i = 0; i < teachersNumberByStages.length; i++) {

        const totalTeacherNumberByStage: TeacherNumberByStageCalc = teachersNumberByStages[i];

        const studentCostByStage: StudentCostByStage = new StudentCostByStage({
          id: teachersNumberByStages[i].id,
          description: teachersNumberByStages[i].description,
          studentsCostBySubitems: this.getCostTeacherBySubitem(totalTeacherNumberByStage, resultForPriceIndice, resultForCareerAndRemunerationTeacher, percentageAdditional,
            isFinancingFundsReport, careerAndRemunerationTeacher, considerQuantityTeacherNumberCareer, percentagesTeachersCareer),
          studentsCostByYearTotal: new Array<StudentCostByYear>()
        });

        for (let k = 0; k < studentCostByStage.studentsCostBySubitems.length; k++) {
          for (let y = 0; y < studentCostByStage.studentsCostBySubitems[k].studentsCostByYear.length; y++) {
            if (!studentCostByStage.studentsCostByYearTotal[y]) {
              studentCostByStage.studentsCostByYearTotal.push(new StudentCostByYear({
                year: studentCostByStage.studentsCostBySubitems[k].studentsCostByYear[y].year,
                costTotal: studentCostByStage.studentsCostBySubitems[k].studentsCostByYear[y].costTotal,
                costShiftIntegral: studentCostByStage.studentsCostBySubitems[k].studentsCostByYear[y].costShiftIntegral,
                costShiftPartial: studentCostByStage.studentsCostBySubitems[k].studentsCostByYear[y].costShiftPartial
              }));
            } else {
              studentCostByStage.studentsCostByYearTotal[y].costTotal += studentCostByStage.studentsCostBySubitems[k].studentsCostByYear[y].costTotal;
              studentCostByStage.studentsCostByYearTotal[y].costShiftIntegral += studentCostByStage.studentsCostBySubitems[k].studentsCostByYear[y].costShiftIntegral;
              studentCostByStage.studentsCostByYearTotal[y].costShiftPartial += studentCostByStage.studentsCostBySubitems[k].studentsCostByYear[y].costShiftPartial;
            }
          }
        }

        // Cost of Encargos Sociais Teacher
        const costSocialChargesTeacherByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();
        for (let j = 0; j < studentCostByStage.studentsCostByYearTotal.length; j++) {

          const costSocialChargesIntegralByYear = studentCostByStage.studentsCostByYearTotal[j].costShiftIntegral * (resultForSocialCharges.socialChargesPercentage / 100);
          const costSocialChargesPartialByYear = studentCostByStage.studentsCostByYearTotal[j].costShiftPartial * (resultForSocialCharges.socialChargesPercentage / 100);

          studentCostByStage.studentsCostByYearTotal[j].costShiftIntegral += costSocialChargesIntegralByYear;
          studentCostByStage.studentsCostByYearTotal[j].costShiftPartial += costSocialChargesPartialByYear;

          costSocialChargesTeacherByYear.push(new StudentCostByYear({
            year: studentCostByStage.studentsCostByYearTotal[j].year,
            costShiftIntegral: costSocialChargesIntegralByYear,
            costShiftPartial: costSocialChargesPartialByYear,
            costTotal: costSocialChargesIntegralByYear + costSocialChargesPartialByYear
          }));

        }

        studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
          itemCostParent_id: ItemCostEnum.EncargosSociaisAdministracaoCentral,
          priceList_subitem: 'Encargos Sociais Docentes',
          expenseType_id: _.head(resultForCareerAndRemunerationTeacher.teacherFormationLevels.levels).expenseTypes[0],
          studentsCostByYear: costSocialChargesTeacherByYear
        }));

        studentsCostByStages.push(studentCostByStage);
      }
    }
    return studentsCostByStages;
  }

  private getCostTeacherBySubitem(totalTeacherNumberByStage: TeacherNumberByStageCalc, resultForPriceIndice: PricesIndices,
    resultForCareerAndRemunerationTeacher: CareerAndRemunerationTeachers, percentageAdditional: number, isFinancingFundsReport: boolean,
    careerAndRemunerationTeacher: CareerAndRemunerationTeachers, considerQuantityTeacherNumberCareer: boolean,
    percentagesTeachersCareer: Array<PercentageTeacherCareer> = undefined): Array<StudentCostBySubitem> {

    const studentsCostBySubitems: Array<StudentCostBySubitem> = new Array<StudentCostBySubitem>();
    const simulationYears: Array<number> = this.utilitiesService.getSimulationYears(isFinancingFundsReport ? 1 : 0);

    const careerAndRemunerationTeacherCalc = careerAndRemunerationTeacher !== undefined ? careerAndRemunerationTeacher : resultForCareerAndRemunerationTeacher;

    for (let i = 0; i < careerAndRemunerationTeacherCalc.teacherFormationLevels.levels.length; i++) {

      const studentsCostByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();
      let grossMonthlyRemunerationIndexed: number = careerAndRemunerationTeacherCalc.teacherFormationLevels.levels[i].grossMonthlyRemuneration;
      grossMonthlyRemunerationIndexed += grossMonthlyRemunerationIndexed * (percentageAdditional / 100);
      const multiplier: number = careerAndRemunerationTeacherCalc.teacherFormationLevels.levels[i].multiplier;
      let percTeacherDistribution, percTeacherDistributionCareerLevel = 0;

      for (let j = 0; j < simulationYears.length; j++) {

        let priceIndiceValue: number = 0;

        for (let k = 0; k < resultForPriceIndice.indices.length; k++) {
          if (resultForPriceIndice.indices[k].id === careerAndRemunerationTeacherCalc.teacherFormationLevels.levels[i].priceIndices[0]) {
            priceIndiceValue = resultForPriceIndice.indices[k].years[j].value;
            break;
          }
        }

        grossMonthlyRemunerationIndexed += (grossMonthlyRemunerationIndexed * (priceIndiceValue / 100));

        if (percentagesTeachersCareer === undefined) {
          percTeacherDistribution = careerAndRemunerationTeacherCalc.teacherFormationLevels.levels[i].percTeacherDistributionCareerLevel[j];
          percTeacherDistributionCareerLevel = percTeacherDistribution.percentage !== undefined ? percTeacherDistribution.percentage : 0;
        } else {
          for (let l = 0; l < percentagesTeachersCareer.length; l++) {
            if (percentagesTeachersCareer[l].formationLevelId === careerAndRemunerationTeacherCalc.teacherFormationLevels.levels[i].formationLevel.id) {
              percTeacherDistributionCareerLevel = percentagesTeachersCareer[l].percentage;
            }
          }
        }

        let teacherNumber: number = 0;
        let teacherNumberShiftPartial: number = 0;
        let teacherNumberShiftIntegral: number = 0;
        let teacherCostTotal: number = 0;
        let teacherCostShiftPartial: number = 0;
        let teacherCostShiftIntegral: number = 0;

        if (considerQuantityTeacherNumberCareer) {

          for (let k = 0; k < totalTeacherNumberByStage.careerLevelsByYear.length; k++) {
            for (let l = 0; l < totalTeacherNumberByStage.careerLevelsByYear[k].careerLevels.length; l++) {
              const career = totalTeacherNumberByStage.careerLevelsByYear[k].careerLevels[l];
              if (career.sequence === careerAndRemunerationTeacherCalc.teacherFormationLevels.levels[i].sequence) {
                teacherNumber = career.quantity;
                teacherNumberShiftPartial = career.totalTeacherPartialCareer;
                teacherNumberShiftIntegral = career.totalTeacherFullPeriodCareer;
                break;
              }
            }
          }
          teacherCostTotal = teacherNumber * grossMonthlyRemunerationIndexed * multiplier;
          teacherCostShiftPartial = teacherNumberShiftPartial * grossMonthlyRemunerationIndexed * multiplier;
          teacherCostShiftIntegral = teacherNumberShiftIntegral * grossMonthlyRemunerationIndexed * multiplier;

        } else {
          // console.log(totalTeacherNumberByStage.description);
          // console.log(careerAndRemunerationTeacherCalc.teacherFormationLevels.levels[i].denomination);
          teacherNumber = totalTeacherNumberByStage.careerLevelsByYear[j].teacherNumberTotal;
          teacherNumberShiftPartial = totalTeacherNumberByStage.careerLevelsByYear[j].teacherNumberShiftPartial;
          teacherNumberShiftIntegral = totalTeacherNumberByStage.careerLevelsByYear[j].teacherNumberShiftIntegral;

          // console.log(teacherNumber * (percTeacherDistributionCareerLevel / 100));
          // console.log(teacherNumberShiftPartial * (percTeacherDistributionCareerLevel / 100));
          // console.log(teacherNumberShiftIntegral * (percTeacherDistributionCareerLevel / 100));

          teacherCostTotal = ((teacherNumber * (percTeacherDistributionCareerLevel / 100)) * grossMonthlyRemunerationIndexed) * multiplier;
          teacherCostShiftPartial = ((teacherNumberShiftPartial * (percTeacherDistributionCareerLevel / 100)) * grossMonthlyRemunerationIndexed) * multiplier;
          teacherCostShiftIntegral = ((teacherNumberShiftIntegral * (percTeacherDistributionCareerLevel / 100)) * grossMonthlyRemunerationIndexed) * multiplier;
        }

        studentsCostByYear.push(new StudentCostByYear({
          year: simulationYears[j],
          costTotal: teacherCostTotal,
          costShiftIntegral: teacherCostShiftIntegral,
          costShiftPartial: teacherCostShiftPartial
        }));

      }

      const studentCostBySubitem: StudentCostBySubitem = new StudentCostBySubitem({
        itemCost_id: ItemCostEnum.Docentes,
        itemCostParent_id: ItemCostEnum.Pessoal,
        priceList_subitem: careerAndRemunerationTeacherCalc.teacherFormationLevels.levels[i].denomination,
        expenseType_id: careerAndRemunerationTeacherCalc.teacherFormationLevels.levels[i].expenseTypes[0],
        studentsCostByYear: studentsCostByYear
      });

      studentsCostBySubitems.push(studentCostBySubitem);
    }

    return studentsCostBySubitems;
  }

  private getCostEmployeesByStage(
    stages: Array<Stage>,
    costsDrivesByStages: Array<CostDriveByStage>,
    costsSharingBySubitems: Array<CostSharingBySubitem>,
    isFinancingFundsReport: boolean): Array<StudentCostByStage> {

    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const resultForSocialCharges: SocialCharges = this.sessionService.getItem<SocialCharges>(Functionality.socialCharges.key);
    const simulationYears: Array<number> = this.utilitiesService.getSimulationYears();

    if (costsDrivesByStages) {

      for (let i = 0; i < costsDrivesByStages.length; i++) {

        const costDriveByStage: CostDriveByStage = costsDrivesByStages[i];

        const studentCostByStage: StudentCostByStage = new StudentCostByStage({
          id: costDriveByStage.id,
          description: costDriveByStage.description,
          studentsCostBySubitems: new Array<StudentCostBySubitem>(),
          studentsCostByYearTotal: new Array<StudentCostByYear>()
        });

        let costEmployeeIntegral: number = 0;
        let costEmployeePartial: number = 0;
        for (let j = 0; j < costsSharingBySubitems.length; j++) {
          const costEmployeesByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();
          if (costsSharingBySubitems[j].id !== ItemCostEnum.FormacaoNaoDocentes.toString() && !costsSharingBySubitems[j].isEducationAssistant) {
            for (let k = 0; k < simulationYears.length; k++) {
              const costSharingBySubItem = costsSharingBySubitems[j].costsSharingByYears[k];
              costEmployeeIntegral = costSharingBySubItem.cost * costDriveByStage.costDrivesByYears[k].value_shift_integral;
              costEmployeePartial = costSharingBySubItem.cost * costDriveByStage.costDrivesByYears[k].value_shift_partial;

              costEmployeesByYear.push(new StudentCostByYear({
                year: costSharingBySubItem.year,
                costShiftIntegral: costEmployeeIntegral,
                costShiftPartial: costEmployeePartial,
                costTotal: costEmployeeIntegral + costEmployeePartial
              }));
            }

            studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
              itemCost_id: ItemCostEnum.NaoDocentes,
              itemCostParent_id: ItemCostEnum.Pessoal,
              priceList_subitem: costsSharingBySubitems[j].description,
              expenseType_id: ExpenseTypeEnum.Corrente,
              studentsCostByYear: costEmployeesByYear
            }));
          }
        }

        // Somatório dos custos de todos os subitens calculados acima
        for (let j = 0; j < studentCostByStage.studentsCostBySubitems.length; j++) {
          for (let k = 0; k < studentCostByStage.studentsCostBySubitems[j].studentsCostByYear.length; k++) {
            if (!studentCostByStage.studentsCostByYearTotal[k]) {
              studentCostByStage.studentsCostByYearTotal.push(new StudentCostByYear({
                year: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].year,
                costShiftIntegral: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral,
                costShiftPartial: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftPartial,
                costTotal: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral + studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral
              }));
            } else {
              studentCostByStage.studentsCostByYearTotal[k].costShiftIntegral += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral;
              studentCostByStage.studentsCostByYearTotal[k].costShiftPartial += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftPartial;
              studentCostByStage.studentsCostByYearTotal[k].costTotal += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral +
                studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftPartial;
            }
          }
        }

        // Cost of Encargos Sociais Employees
        const costSocialChargesByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();
        for (let j = 0; j < studentCostByStage.studentsCostByYearTotal.length; j++) {

          const costSocialChargesIntegralByYear = studentCostByStage.studentsCostByYearTotal[j].costShiftIntegral * (resultForSocialCharges.socialChargesPercentage / 100);
          const costSocialChargesPartialByYear = studentCostByStage.studentsCostByYearTotal[j].costShiftPartial * (resultForSocialCharges.socialChargesPercentage / 100);

          studentCostByStage.studentsCostByYearTotal[j].costShiftIntegral += costSocialChargesIntegralByYear;
          studentCostByStage.studentsCostByYearTotal[j].costShiftPartial += costSocialChargesPartialByYear;
          studentCostByStage.studentsCostByYearTotal[j].costTotal += costSocialChargesPartialByYear + costSocialChargesIntegralByYear;

          costSocialChargesByYear.push(new StudentCostByYear({
            year: studentCostByStage.studentsCostByYearTotal[j].year,
            costShiftIntegral: costSocialChargesIntegralByYear,
            costShiftPartial: costSocialChargesPartialByYear,
            costTotal: costSocialChargesIntegralByYear + costSocialChargesPartialByYear
          }));

        }

        studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
          itemCostParent_id: ItemCostEnum.EncargosSociaisAdministracaoCentral,
          priceList_subitem: 'Encargos Sociais Não Docentes',
          expenseType_id: ExpenseTypeEnum.Corrente,
          studentsCostByYear: costSocialChargesByYear
        }));

        studentsCostByStages.push(studentCostByStage);
      }
    }

    return studentsCostByStages;
  }

  private getCostAssetsAndServicesByStage(enrollmentsStages: Array<StageEnrollments>, schoolOperation: SchoolsOperation): Array<StudentCostByStage> {

    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const resultsForOfferGoalEnrollmentFullTime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.key);

    for (let i = 0; i < enrollmentsStages.length; i++) {

      const studentCostByStage: StudentCostByStage = new StudentCostByStage({
        id: enrollmentsStages[i].id,
        description: enrollmentsStages[i].description,
        studentsCostBySubitems: new Array<StudentCostBySubitem>(),
        studentsCostByYearTotal: new Array<StudentCostByYear>()
      });

      let offerGoalEnrollmentFullTime: FullTime = new FullTime({
        id: enrollmentsStages[i].id,
        stageDescription: enrollmentsStages[i].description,
        offerGoal: 0
      });
      for (let l = 0; l < resultsForOfferGoalEnrollmentFullTime.fullTime.length; l++) {
        if (resultsForOfferGoalEnrollmentFullTime.fullTime[l].id === enrollmentsStages[i].id) {
          offerGoalEnrollmentFullTime = resultsForOfferGoalEnrollmentFullTime.fullTime[l];
        }
      }

      for (let j = 0; j < schoolOperation.costs.length; j++) {

        const studentsCostByYearTotal: Array<StudentCostByYear> = new Array<StudentCostByYear>();

        for (let k = 0; k < enrollmentsStages[i].totalEnrollments.length; k++) {
          const enrollmentYearIntegral = enrollmentsStages[i].totalEnrollments[k].quantity * (offerGoalEnrollmentFullTime.offerGoal / 100);
          const enrollmentYearParcial = enrollmentsStages[i].totalEnrollments[k].quantity - enrollmentYearIntegral;

          const costYearIntegral = enrollmentYearIntegral * (schoolOperation.costs[j].unitPrice * schoolOperation.costs[j].multiplier);
          const costYearParcial = enrollmentYearParcial * (schoolOperation.costs[j].unitPrice * schoolOperation.costs[j].multiplier);

          studentsCostByYearTotal.push(new StudentCostByYear({
            year: enrollmentsStages[i].totalEnrollments[k].year,
            costShiftIntegral: costYearIntegral,
            costShiftPartial: costYearParcial,
            costTotal: costYearIntegral + costYearParcial
          }));
        }

        studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
          itemCostParent_id: ItemCostEnum.BensServicos,
          priceList_subitem: schoolOperation.costs[j].denomination,
          expenseType_id: schoolOperation.costs[j].expenseType.id,
          expenseType_description: schoolOperation.costs[j].expenseType.description,
          studentsCostByYear: studentsCostByYearTotal
        }));
      }

      for (let j = 0; j < studentCostByStage.studentsCostBySubitems.length; j++) {
        for (let k = 0; k < studentCostByStage.studentsCostBySubitems[j].studentsCostByYear.length; k++) {
          if (!studentCostByStage.studentsCostByYearTotal[k]) {
            studentCostByStage.studentsCostByYearTotal.push(new StudentCostByYear({
              year: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].year,
              costTotal: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costTotal,
              costShiftIntegral: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral,
              costShiftPartial: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftPartial
            }));
          } else {
            studentCostByStage.studentsCostByYearTotal[k].costTotal += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costTotal;
            studentCostByStage.studentsCostByYearTotal[k].costShiftIntegral += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral;
            studentCostByStage.studentsCostByYearTotal[k].costShiftPartial += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftPartial;
          }
        }
      }
      studentsCostByStages.push(studentCostByStage);
    }
    return studentsCostByStages;
  }

  private getCostFoodAndCookingGasByStage(stages: Array<Stage>, enrollmentsStages: Array<StageEnrollments>): Array<StudentCostByStage> {

    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const resultsForOfferGoalEnrollmentFullTime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.key);
    const resultsForSchoolFeeding: SchoolsFeedings = this.sessionService.getItem<SchoolsFeedings>(Functionality.schoolFeeding.key);

    for (let i = 0; i < enrollmentsStages.length; i++) {

      const enrollmentByStage: StageEnrollments = enrollmentsStages[i];

      const studentCostByStage: StudentCostByStage = new StudentCostByStage({
        id: enrollmentByStage.id,
        description: enrollmentByStage.description,
        studentsCostBySubitems: new Array<StudentCostBySubitem>(),
        studentsCostByYearTotal: new Array<StudentCostByYear>()
      });

      // Cost of Alimentos e Gás de Cozinha
      const costSchoolsFeedingsByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();
      const offerGoalEnrollmentFullTime: FullTime = new FullTime({
        id: enrollmentByStage.id,
        stageDescription: enrollmentByStage.description,
        offerGoal: 0
      });

      if (enrollmentsStages[i].integralPercentage !== undefined) {
        offerGoalEnrollmentFullTime.offerGoal = enrollmentsStages[i].integralPercentage;
      }

      /*for (let l = 0; l < resultsForOfferGoalEnrollmentFullTime.fullTime.length; l++) {
         if (resultsForOfferGoalEnrollmentFullTime.fullTime[l].id === enrollmentByStage.id) {
           offerGoalEnrollmentFullTime = resultsForOfferGoalEnrollmentFullTime.fullTime[l];
           break;
         }
       }*/

      let costSchoolFeedingIntegral: number = 0;
      let costSchoolFeedingPartial: number = 0;
      let expenseTypeId = 0;

      for (let j = 0; j < resultsForSchoolFeeding.feedings.length; j++) {
        expenseTypeId = resultsForSchoolFeeding.feedings[j].expenseTypes[0];

        if (resultsForSchoolFeeding.feedings[j].sequence === ShiftStudentCost.Partial) {
          costSchoolFeedingPartial = resultsForSchoolFeeding.feedings[j].dailyMeal * resultsForSchoolFeeding.feedings[j].mealValue * resultsForSchoolFeeding.feedings[j].multiplier;
        } else if (resultsForSchoolFeeding.feedings[j].sequence === ShiftStudentCost.Integral) {
          costSchoolFeedingIntegral = resultsForSchoolFeeding.feedings[j].dailyMeal * resultsForSchoolFeeding.feedings[j].mealValue * resultsForSchoolFeeding.feedings[j].multiplier;
        }
      }

      for (let j = 0; j < enrollmentByStage.totalEnrollments.length; j++) {

        const enrollmentYearIntegral = enrollmentByStage.totalEnrollments[j].quantity * (offerGoalEnrollmentFullTime.offerGoal / 100);
        const enrollmentYearPartial = enrollmentByStage.totalEnrollments[j].quantity - enrollmentYearIntegral;

        const costYearIntegral = enrollmentYearIntegral * costSchoolFeedingIntegral;
        const costYearPartial = enrollmentYearPartial * costSchoolFeedingPartial;

        costSchoolsFeedingsByYear.push(new StudentCostByYear({
          year: enrollmentByStage.totalEnrollments[j].year,
          costShiftIntegral: costYearIntegral,
          costShiftPartial: costYearPartial,
          costTotal: costYearIntegral + costYearPartial
        }));
      }

      studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
        itemCost_id: ItemCostEnum.AlimentosGasCozinha,
        itemCostParent_id: ItemCostEnum.AlimentacaoEscolar,
        priceList_subitem: 'Alimentos e gás de cozinha',
        expenseType_id: expenseTypeId,
        studentsCostByYear: costSchoolsFeedingsByYear
      }));

      // Somatório dos custos de todos os subitens calculados acima
      for (let j = 0; j < studentCostByStage.studentsCostBySubitems.length; j++) {
        for (let k = 0; k < studentCostByStage.studentsCostBySubitems[j].studentsCostByYear.length; k++) {
          if (!studentCostByStage.studentsCostByYearTotal[k]) {
            studentCostByStage.studentsCostByYearTotal.push(new StudentCostByYear({
              year: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].year,
              costTotal: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costTotal,
              costShiftIntegral: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral,
              costShiftPartial: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftPartial
            }));
          } else {
            studentCostByStage.studentsCostByYearTotal[k].costTotal += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costTotal;
            studentCostByStage.studentsCostByYearTotal[k].costShiftIntegral += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral;
            studentCostByStage.studentsCostByYearTotal[k].costShiftPartial += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftPartial;
          }
        }
      }
      studentsCostByStages.push(studentCostByStage);
    }
    return studentsCostByStages;
  }

  private getCostSchoolFeedingAssistantByStage(
    stages: Array<Stage>,
    costsDrivesByStages: Array<CostDriveByStage>,
    enrollmentsStages: Array<StageEnrollments>,
    costsSharingBySubitems: Array<CostSharingBySubitem>,
    isFinancingFundsReport: boolean): Array<StudentCostByStage> {

    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const resultForSocialCharges: SocialCharges = this.sessionService.getItem<SocialCharges>(Functionality.socialCharges.key);
    const simulationYears: Array<number> = this.utilitiesService.getSimulationYears();

    for (let i = 0; i < costsDrivesByStages.length; i++) {

      const costDriveByStage: CostDriveByStage = costsDrivesByStages[i];

      const studentCostByStage: StudentCostByStage = new StudentCostByStage({
        id: costDriveByStage.id,
        description: costDriveByStage.description,
        studentsCostBySubitems: new Array<StudentCostBySubitem>(),
        studentsCostByYearTotal: new Array<StudentCostByYear>()
      });

      let costSchoolFeedingAssistantIntegral: number = 0;
      let costSchoolFeedingAssistantPartial: number = 0;
      const costSchoolsFeedingAssistantsByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();
      for (let j = 0; j < costsSharingBySubitems.length; j++) {
        if (costsSharingBySubitems[j].isEducationAssistant) {
          for (let k = 0; k < simulationYears.length; k++) {
            const costSharingBySubitem = costsSharingBySubitems[j].costsSharingByYears[k];
            costSchoolFeedingAssistantIntegral = costSharingBySubitem.cost * costDriveByStage.costDrivesByYears[k].value_shift_integral;
            costSchoolFeedingAssistantPartial = costSharingBySubitem.cost * costDriveByStage.costDrivesByYears[k].value_shift_partial;

            costSchoolsFeedingAssistantsByYear.push(new StudentCostByYear({
              year: costSharingBySubitem.year,
              costShiftIntegral: costSchoolFeedingAssistantIntegral,
              costShiftPartial: costSchoolFeedingAssistantPartial,
              costTotal: costSchoolFeedingAssistantIntegral + costSchoolFeedingAssistantPartial
            }));
          }

          studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
            itemCost_id: ItemCostEnum.AuxiliarAlimentacao,
            itemCostParent_id: ItemCostEnum.AlimentacaoEscolar,
            priceList_subitem: costsSharingBySubitems[j].description,
            expenseType_id: ExpenseTypeEnum.Corrente,
            studentsCostByYear: costSchoolsFeedingAssistantsByYear
          }));
        }
      }

      // Cost of Encargos Sociais
      const costSocialChargesByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();
      for (let j = 0; j < costSchoolsFeedingAssistantsByYear.length; j++) {

        const costSocialChargesIntegralByYear = costSchoolsFeedingAssistantsByYear[j].costShiftIntegral * (resultForSocialCharges.socialChargesPercentage / 100);
        const costSocialChargesPartialByYear = costSchoolsFeedingAssistantsByYear[j].costShiftPartial * (resultForSocialCharges.socialChargesPercentage / 100);

        costSocialChargesByYear.push(new StudentCostByYear({
          year: costSchoolsFeedingAssistantsByYear[j].year,
          costShiftIntegral: costSocialChargesIntegralByYear,
          costShiftPartial: costSocialChargesPartialByYear,
          costTotal: costSocialChargesIntegralByYear + costSocialChargesPartialByYear
        }));

      }

      studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
        itemCost_id: ItemCostEnum.EncargosSociaisAuxiliarAlimentacao,
        itemCostParent_id: ItemCostEnum.AlimentacaoEscolar,
        priceList_subitem: 'Encargos Sociais Auxiliar Alimentação',
        expenseType_id: ExpenseTypeEnum.Corrente,
        studentsCostByYear: costSocialChargesByYear
      }));

      // Somatório dos custos de todos os subitens calculados acima
      for (let j = 0; j < studentCostByStage.studentsCostBySubitems.length; j++) {
        for (let k = 0; k < studentCostByStage.studentsCostBySubitems[j].studentsCostByYear.length; k++) {
          if (!studentCostByStage.studentsCostByYearTotal[k]) {
            studentCostByStage.studentsCostByYearTotal.push(new StudentCostByYear({
              year: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].year,
              costTotal: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costTotal,
              costShiftIntegral: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral,
              costShiftPartial: studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftPartial
            }));
          } else {
            studentCostByStage.studentsCostByYearTotal[k].costTotal += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral +
              studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftPartial;
            studentCostByStage.studentsCostByYearTotal[k].costShiftIntegral += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftIntegral;
            studentCostByStage.studentsCostByYearTotal[k].costShiftPartial += studentCostByStage.studentsCostBySubitems[j].studentsCostByYear[k].costShiftPartial;
          }
        }
      }
      studentsCostByStages.push(studentCostByStage);
    }
    return studentsCostByStages;
  }

  private getCostTeacherTrainingByStage(stages: Array<Stage>, teachersNumberByStagesCalc: Array<TeacherNumberByStageCalc>): Array<StudentCostByStage> {

    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const resultForTeacherTraining: TeacherTraining = this.sessionService.getItem<TeacherTraining>(Functionality.teacherTraining.key);

    for (let i = 0; i < teachersNumberByStagesCalc.length; i++) {

      const teacherNumberByStageCalc: TeacherNumberByStageCalc = teachersNumberByStagesCalc[i];

      const studentCostByStage: StudentCostByStage = new StudentCostByStage({
        id: teacherNumberByStageCalc.id,
        description: teacherNumberByStageCalc.description,
        studentsCostBySubitems: new Array<StudentCostBySubitem>(),
        studentsCostByYearTotal: new Array<StudentCostByYear>()
      });

      // Cost of Teacher training
      const costTeacherTrainingByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();

      for (let j = 0; j < teacherNumberByStageCalc.careerLevelsByYear.length; j++) {

        const costTeacherTrainingIntegralByYear = teacherNumberByStageCalc.careerLevelsByYear[j].teacherNumberShiftIntegral * resultForTeacherTraining.teacherTrainingValue;
        const costTeacherTrainingPartialByYear = teacherNumberByStageCalc.careerLevelsByYear[j].teacherNumberShiftPartial * resultForTeacherTraining.teacherTrainingValue;
        costTeacherTrainingByYear.push(new StudentCostByYear({
          year: teacherNumberByStageCalc.careerLevelsByYear[j].year,
          costShiftIntegral: costTeacherTrainingIntegralByYear,
          costShiftPartial: costTeacherTrainingPartialByYear,
          costTotal: costTeacherTrainingIntegralByYear + costTeacherTrainingPartialByYear
        }));
      }

      studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
        itemCost_id: ItemCostEnum.FormacaoDocentes,
        itemCostParent_id: ItemCostEnum.CustosAdministracaoCentral,
        priceList_subitem: 'Teacher Training',
        expenseType_id: ExpenseTypeEnum.Corrente,
        studentsCostByYear: costTeacherTrainingByYear
      }));

      studentCostByStage.studentsCostByYearTotal = costTeacherTrainingByYear;
      studentsCostByStages.push(studentCostByStage);
    }
    return studentsCostByStages;
  }

  private getCostEmployeeTrainingByStage(
    stages: Array<Stage>,
    teachersNumberByStagesCalc: Array<TeacherNumberByStageCalc>,
    schoolOperation: SchoolsOperation,
    costsDrivesByStages: Array<CostDriveByStage>,
    costsSharingBySubitems: Array<CostSharingBySubitem>,
    isFinancingFundsReport: boolean): Array<StudentCostByStage> {

    const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const simulationYears: Array<number> = this.utilitiesService.getSimulationYears(isFinancingFundsReport ? 1 : 0);

    for (let i = 0; i < costsDrivesByStages.length; i++) {

      const costDriveByStage: CostDriveByStage = costsDrivesByStages[i];

      const studentCostByStage: StudentCostByStage = new StudentCostByStage({
        id: costDriveByStage.id,
        description: costDriveByStage.description,
        studentsCostBySubitems: new Array<StudentCostBySubitem>(),
        studentsCostByYearTotal: new Array<StudentCostByYear>()
      });

      // Cost of Employee training
      let costEmployeeTrainingIntegral: number = 0;
      let costEmployeeTrainingPartial: number = 0;
      const costEmployeesTrainingByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();
      for (let j = 0; j < costsSharingBySubitems.length; j++) {
        if (costsSharingBySubitems[j].description === 'Employee training') {
          for (let k = 0; k < simulationYears.length; k++) {
            costEmployeeTrainingIntegral = costsSharingBySubitems[j].costsSharingByYears[k].cost * costDriveByStage.costDrivesByYears[k].value_shift_integral;
            costEmployeeTrainingPartial = costsSharingBySubitems[j].costsSharingByYears[k].cost * costDriveByStage.costDrivesByYears[k].value_shift_partial;

            costEmployeesTrainingByYear.push(new StudentCostByYear({
              year: costsSharingBySubitems[j].costsSharingByYears[k].year,
              costShiftIntegral: costEmployeeTrainingIntegral,
              costShiftPartial: costEmployeeTrainingPartial,
              costTotal: costEmployeeTrainingIntegral + costEmployeeTrainingPartial
            }));
          }

          studentCostByStage.studentsCostBySubitems.push(new StudentCostBySubitem({
            itemCost_id: ItemCostEnum.FormacaoNaoDocentes,
            itemCostParent_id: ItemCostEnum.CustosAdministracaoCentral,
            priceList_subitem: costsSharingBySubitems[j].description,
            expenseType_id: ExpenseTypeEnum.Corrente,
            studentsCostByYear: costEmployeesTrainingByYear
          }));
        }
      }

      studentCostByStage.studentsCostByYearTotal = costEmployeesTrainingByYear;
      studentsCostByStages.push(studentCostByStage);
    }
    return studentsCostByStages;
  }

  // custo da área administrativa
  private getCostAdministrativeArea(costsTotalsByYear: Array<StudentCostByYear>, administrativeItemCosting: AdministrativeItemCosting): Array<StudentCostByYear> {

    const costAdministrationAndSupervisionEducationalByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();

    for (let j = 0; j < costsTotalsByYear.length; j++) {

      const divisor: number = (1 - (administrativeItemCosting.percentage / 100));
      const costAdministrationAndSupervisionIntegral: number = (costsTotalsByYear[j].costShiftIntegral / divisor) - costsTotalsByYear[j].costShiftIntegral;
      const costAdministrationAndSupervisionPartial: number = (costsTotalsByYear[j].costShiftPartial / divisor) - costsTotalsByYear[j].costShiftPartial;

      costAdministrationAndSupervisionEducationalByYear.push(new StudentCostByYear({
        year: costsTotalsByYear[j].year,
        costShiftIntegral: costAdministrationAndSupervisionIntegral,
        costShiftPartial: costAdministrationAndSupervisionPartial,
        costTotal: costAdministrationAndSupervisionIntegral + costAdministrationAndSupervisionPartial
      }));
    }
    return costAdministrationAndSupervisionEducationalByYear;
  }

  private getCostAdministrativeAreaTotal(costsTotalsByYear: Array<StudentCostByYear>, percentagemTotal: number): Array<StudentCostByYear> {

    const costAdministrationAndSupervisionEducationalByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();

    for (let j = 0; j < costsTotalsByYear.length; j++) {

      const divisor: number = (1 - (percentagemTotal / 100));
      const costAdministrationAndSupervisionIntegral: number = (costsTotalsByYear[j].costShiftIntegral / divisor) - costsTotalsByYear[j].costShiftIntegral;
      const costAdministrationAndSupervisionPartial: number = (costsTotalsByYear[j].costShiftPartial / divisor) - costsTotalsByYear[j].costShiftPartial;

      costAdministrationAndSupervisionEducationalByYear.push(new StudentCostByYear({
        year: costsTotalsByYear[j].year,
        costShiftIntegral: costAdministrationAndSupervisionIntegral,
        costShiftPartial: costAdministrationAndSupervisionPartial,
        costTotal: costAdministrationAndSupervisionIntegral + costAdministrationAndSupervisionPartial
      }));
    }
    return costAdministrationAndSupervisionEducationalByYear;
  }

  // custo da Administração e supervisão da rede na etapa
  private getCostAdministrationAndSupervisionEducationalByYear(costsTotalsByYear: Array<StudentCostByYear>, resultForadministrativeAreaCosting: AdministrativeAreaCosting): Array<StudentCostByYear> {

    const costAdministrationAndSupervisionEducationalByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();

    for (let j = 0; j < costsTotalsByYear.length; j++) {

      const divisor: number = (1 - (resultForadministrativeAreaCosting.administrativeAreaCostingPercentage / 100));
      const costAdministrationAndSupervisionIntegral: number = (costsTotalsByYear[j].costShiftIntegral / divisor) - costsTotalsByYear[j].costShiftIntegral;
      const costAdministrationAndSupervisionPartial: number = (costsTotalsByYear[j].costShiftPartial / divisor) - costsTotalsByYear[j].costShiftPartial;

      costAdministrationAndSupervisionEducationalByYear.push(new StudentCostByYear({
        year: costsTotalsByYear[j].year,
        costShiftIntegral: costAdministrationAndSupervisionIntegral,
        costShiftPartial: costAdministrationAndSupervisionPartial,
        costTotal: costAdministrationAndSupervisionIntegral + costAdministrationAndSupervisionPartial
      }));
    }

    return costAdministrationAndSupervisionEducationalByYear;
  }

  // custo com materiais didáticos
  private getCostTeachingMaterialByYear(costsTotalsByYear: Array<StudentCostByYear>, resultForadministrativeAreaCosting: AdministrativeAreaCosting): Array<StudentCostByYear> {

    const costTeachingMaterialByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();

    for (let j = 0; j < costsTotalsByYear.length; j++) {

      const divisor: number = (1 - (resultForadministrativeAreaCosting.materialActionCostingPercentage / 100));
      const costTeachingMaterialByYearIntegral: number = (costsTotalsByYear[j].costShiftIntegral / divisor) - costsTotalsByYear[j].costShiftIntegral;
      const costTeachingMaterialByYearPartial: number = (costsTotalsByYear[j].costShiftPartial / divisor) - costsTotalsByYear[j].costShiftPartial;

      costTeachingMaterialByYear.push(new StudentCostByYear({
        year: costsTotalsByYear[j].year,
        costShiftIntegral: costTeachingMaterialByYearIntegral,
        costShiftPartial: costTeachingMaterialByYearPartial,
        costTotal: costTeachingMaterialByYearIntegral + costTeachingMaterialByYearPartial
      }));
    }

    return costTeachingMaterialByYear;
  }

  // custo com formação continuada dos profissionais
  private getCostProfessionalTrainingByYear(costsTotalsByYear: Array<StudentCostByYear>, resultForadministrativeAreaCosting: AdministrativeAreaCosting): Array<StudentCostByYear> {

    const costProfessionalTrainingByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();

    for (let j = 0; j < costsTotalsByYear.length; j++) {

      const divisor: number = (1 - (resultForadministrativeAreaCosting.teacherTrainingCostingPercentage / 100));
      const costProfessionalTrainingByYearIntegral: number = (costsTotalsByYear[j].costShiftIntegral / divisor) - costsTotalsByYear[j].costShiftIntegral;
      const costProfessionalTrainingByYearPartial: number = (costsTotalsByYear[j].costShiftPartial / divisor) - costsTotalsByYear[j].costShiftPartial;

      costProfessionalTrainingByYear.push(new StudentCostByYear({
        year: costsTotalsByYear[j].year,
        costShiftIntegral: costProfessionalTrainingByYearIntegral,
        costShiftPartial: costProfessionalTrainingByYearPartial,
        costTotal: costProfessionalTrainingByYearIntegral + costProfessionalTrainingByYearPartial
      }));
    }

    return costProfessionalTrainingByYear;
  }

  // custo com funcionamento e manutenção das escolas
  private getCostOperationMaintenanceSchoolsByYear(costsTotalsByYear: Array<StudentCostByYear>, resultForadministrativeAreaCosting: AdministrativeAreaCosting): Array<StudentCostByYear> {

    const costOperationMaintenanceSchoolsByYear: Array<StudentCostByYear> = new Array<StudentCostByYear>();

    for (let j = 0; j < costsTotalsByYear.length; j++) {

      const divisor: number = (1 - (resultForadministrativeAreaCosting.schoolsOperationCostingPercentage / 100));
      const costOperationMaintenanceSchoolsByYearIntegral: number = (costsTotalsByYear[j].costShiftIntegral / divisor) - costsTotalsByYear[j].costShiftIntegral;
      const costOperationMaintenanceSchoolsByYearPartial: number = (costsTotalsByYear[j].costShiftPartial / divisor) - costsTotalsByYear[j].costShiftPartial;

      costOperationMaintenanceSchoolsByYear.push(new StudentCostByYear({
        year: costsTotalsByYear[j].year,
        costShiftIntegral: costOperationMaintenanceSchoolsByYearIntegral,
        costShiftPartial: costOperationMaintenanceSchoolsByYearPartial,
        costTotal: costOperationMaintenanceSchoolsByYearIntegral + costOperationMaintenanceSchoolsByYearPartial
      }));
    }

    return costOperationMaintenanceSchoolsByYear;
  }

  private setExpensesForMonth(studentsCostByStages: Array<StudentCostByStage>) {
    for (let i = 0; i < studentsCostByStages.length; i++) {
      const studentCostStage = studentsCostByStages[i];
      for (let j = 0; j < studentCostStage.studentsCostBySubitems.length; j++) {
        const studentCostSubitem = studentCostStage.studentsCostBySubitems[j];
        for (let k = 0; k < studentCostSubitem.studentsCostByYear.length; k++) {
          studentCostSubitem.studentsCostByYear[k].costShiftPartial /= 12;
          studentCostSubitem.studentsCostByYear[k].costShiftIntegral /= 12;
          studentCostSubitem.studentsCostByYear[k].costTotal /= 12;
        }
      }

      for (let j = 0; j < studentCostStage.studentsCostByYearTotal.length; j++) {
        const studentCostTotal = studentCostStage.studentsCostByYearTotal[j];
        studentCostTotal.costShiftPartial /= 12;
        studentCostTotal.costShiftIntegral /= 12;
        studentCostTotal.costTotal /= 12;
      }
    }
  }

}
