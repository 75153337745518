import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { EnrollmentProjection } from './entities/enrollment-projection';
import { EnrollmentProjectionService } from './services/enrollment-projection.service';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';

@Component({
  selector: 'app-enrollment-projection',
  templateUrl: './enrollment-projection.component.html',
  styleUrls: ['./enrollment-projection.component.scss']
})
export class EnrollmentProjectionComponent extends BaseNavigableComponent<EnrollmentProjection> {

  data: EnrollmentProjection = new EnrollmentProjection();
  functionality: FunctionalityInfo = Functionality.enrollmentProjection;
  inconsistencies: Array<Inconsistency>;

  constructor(private enrollmentProjectionService: EnrollmentProjectionService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<EnrollmentProjection> {
    return this.enrollmentProjectionService.getData();
  }

}
