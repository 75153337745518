import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '../../../../shared/services/http/http.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { PriceIndice } from '../entities/price-indice';
import { PriceIndicesByYears } from '../entities/price-indices-by-years';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { PricesIndices } from '../entities/prices-indices';

@Injectable({
  providedIn: 'root'
})
export class PriceIndicesService implements NavigableComponentService {

  constructor(private utilitiesService: UtilitiesService, private httpService: HttpService, private notificationService: NotificationService) { }

  getData(): Observable<PricesIndices> {

    return this.httpService.get<Array<PriceIndice>>('assets/data/price-indices.data.json').pipe(
      map(indices => {
        const pricesIndices: PricesIndices = new PricesIndices();

        pricesIndices.yearsSimulation = this.utilitiesService.getSimulationYears();

        pricesIndices.indices = indices.map(indice => new PriceIndice({
          id: indice.id,
          description: indice.description,
          years: this.utilitiesService.getSimulationYears().map(simulationYear => new PriceIndicesByYears({ year: simulationYear, value: 0 }))
        }));

        return pricesIndices;
      }));
  }
}
