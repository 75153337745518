import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { SchoolsOperationService } from './services/schools-operation.service';
import { SchoolOperation } from './entities/school-operation';
import { UnitOfMeasurement } from '../../../shared/entities/unit-of-measurement';
import { UnitOfMeasurementEnum } from '../../../shared/entities/enums/unit-of-measurement.enum';
import { SchoolsOperation } from './entities/schools-operation';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

@Component({
  selector: 'app-schools-operation',
  templateUrl: './schools-operation.component.html',
  styleUrls: ['./schools-operation.component.scss']
})

export class SchoolsOperationComponent extends BaseNavigableComponent<SchoolsOperation> implements PqrComponentData {

  data: SchoolsOperation;
  functionality: FunctionalityInfo = Functionality.schoolsOperation;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = SchoolsOperationComponent;
  referenceDateMask: any = this.utilitiesService.getReferenceDateMask();

  constructor(private schoolsOperationService: SchoolsOperationService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<SchoolsOperation> {
    return this.schoolsOperationService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  newSchoolsOperation(): void {
    const newCost = this.schoolsOperationService.getNewSchoolsOperation(this.data.costs.length);
    this.data.costs.push(newCost);
  }

  deleteSchoolsOperation(sequence: number): void {
    this.schoolsOperationService.removeSchoolsOperation(this.data.costs, sequence);
  }

  unitOfMeasurementChange(cost: SchoolOperation): void {
    cost.multiplier = cost.unitOfMeasurement.id === UnitOfMeasurementEnum.alunoAno
      ? 1
      : cost.unitOfMeasurement.id === UnitOfMeasurementEnum.alunoMes
        ? 12
        : 0;
  }

  unitOfMeasurementEquals(unitA: UnitOfMeasurement, unitB: UnitOfMeasurement): boolean {
    if (unitA && unitB) {
      return unitA.id === unitB.id;
    }

    return false;
  }

  processValidation(): void {
    super.processValidation();

    if (_.uniq(this.data.costs.map(d => d.denomination.toLocaleLowerCase())).length !== this.data.costs.length) {
      this.inconsistencies.push(new Inconsistency({
        message: `Existem denominações duplicadas.`
      }));
    }

    for (const cost of this.data.costs) {
      if (cost.unitOfMeasurement) {
        if (isNaN(cost.unitOfMeasurement.id) || cost.unitOfMeasurement.id === null || cost.unitOfMeasurement.id === undefined) {
          this.inconsistencies.push(new Inconsistency({
            message: `A unidade de medida do item ${cost.denomination} não é válida. Selecione um valor.`
          }));
        }
      } else {
        this.inconsistencies.push(new Inconsistency({
          message: `A unidade de medida do item ${cost.denomination} não é válida. Selecione um valor.`
        }));
      }

      if (isNaN(cost.unitPrice) || cost.unitPrice === null || cost.unitPrice === undefined) {
        this.inconsistencies.push(new Inconsistency({
          message: `O preço unitário do item ${cost.denomination} não é válido.`
        }));
      }

      if (!this.utilitiesService.isReferenceDateValid(cost.referenceDate)) {
        this.inconsistencies.push(new Inconsistency({
          message: `A data de referência do item ${cost.denomination} não é valida.`
        }));
      }
    }

  }
}
