import { UnitOfMeasurement } from './unit-of-measurement';
import { PriceIndice } from './price-indice';
import { ExpenseType } from './expense-type';
import { AccountPlan } from './account-plan';

export abstract class PriceList {

    unitOfMeasurement: UnitOfMeasurement;
    priceIndice: PriceIndice;
    multiplier: number;
    expenseType: ExpenseType;
    accountPlan: AccountPlan;
    referenceDate: string;
    unitOfMeasurements: Array<number>;
    accountPlans: Array<number>;
    expenseTypes: Array<number>;
    priceIndices: Array<number>;

    constructor(init?: Partial<PriceList>) {
        Object.assign(this, init);
    }
}
