import { Component, Injector, Type, DoCheck } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';
import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { AdministrativeAreaCostingService } from './services/administrative-area-costing.service';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { AdministrativeAreaCosting } from './entities/administrative-area-costing';

@Component({
  selector: 'app-administrative-area-costing',
  templateUrl: './administrative-area-costing.component.html',
  styleUrls: ['./administrative-area-costing.component.scss']
})
export class AdministrativeAreaCostingComponent extends BaseNavigableComponent<AdministrativeAreaCosting> implements PqrComponentData, DoCheck {

  data: AdministrativeAreaCosting = new AdministrativeAreaCosting();
  functionality: FunctionalityInfo = Functionality.administrativeAreaCosting;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = AdministrativeAreaCostingComponent;

  constructor(private administrativeAreaCostingService: AdministrativeAreaCostingService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<AdministrativeAreaCosting> {
    return this.administrativeAreaCostingService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  ngDoCheck(): void {
    this.administrativeAreaCostingService.setTotalPercentage(this.data);
  }

  processValidation(): void {
    super.processValidation();

    if (this.data.administrativesItensCosting.length === 0) {
      this.inconsistencies.push(new Inconsistency({
        message: `Nenhum item foi informado.`
      }));
    } else {
      for (const administrativeItemCosting of this.data.administrativesItensCosting) {
        if (administrativeItemCosting.denomination === null || administrativeItemCosting.denomination === undefined || administrativeItemCosting.denomination === '') {
          this.inconsistencies.push(new Inconsistency({
            message: `A denominação da sequência ${administrativeItemCosting.sequence} não foi informada.`
          }));
        }

        if (administrativeItemCosting.percentage === null || administrativeItemCosting.percentage === undefined || administrativeItemCosting.percentage === undefined) {
          this.inconsistencies.push(new Inconsistency({
            message: `O percentual da sequência ${administrativeItemCosting.sequence} não foi informado.`
          }));
        }

        if (administrativeItemCosting.percentage < 0 || administrativeItemCosting.percentage > 100) {
          this.inconsistencies.push(new Inconsistency({
            message: `O percentual do item ${administrativeItemCosting.denomination} deve ser maior ou igual a 0 e menor ou igual a 100!`
          }));
        }
      }

      if (this.inconsistencies.length === 0) {
        if (_.uniq(this.data.administrativesItensCosting.map(d => d.denomination.toLocaleLowerCase())).length !== this.data.administrativesItensCosting.length) {
          this.inconsistencies.push(new Inconsistency({
            message: `Existem denominações duplicadas.`
          }));
        }
      }
    }
  }

  newAdministrativeItemCosting(): void {
    const newAdministrativeItem = this.administrativeAreaCostingService.getNewAdministrativeItemCosting(this.data.administrativesItensCosting.length);
    this.data.administrativesItensCosting.push(newAdministrativeItem);
  }

  deleteAdministrativeItemCosting(sequence: number): void {
    this.administrativeAreaCostingService.removeAdministrativeItemCosting(this.data.administrativesItensCosting, sequence);
  }
}
