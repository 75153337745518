import { NavigableComponentData } from './../../../shared/entities/base/navigable-component-data';
import { DatasReport } from './datas-report';
import { PercentageTeacherCareer } from './percentage-teacher-career';

export class FinancingFederatedEntitiesReport extends NavigableComponentData {

  entityId: number;
  entityDescription: string;
  schoolId: string;
  schoolDescription: string;
  admDependencyId: number;
  admDependencyDescription: string;
  stateId: number;
  stateDescription: string;
  stateAbbreviation: string;
  locationId: number;
  location: string;
  enrollmentTotal: number;
  caq: number;
  potential: number;
  expensesIncurred: number;
  currentExpenses: number;
  unionComplementation: number;
  percentUnionComplementation: number;
  offerYear: number;
  simulationYearInitial: number;
  financialYear: number;
  enrollmentYear: number;
  datasByStage: Array<any>;
  percentageTeacherCareer: Array<PercentageTeacherCareer>;
  totalTeacherNumber: number;
  totalEmployeeNumber: number;
  totalClassNumberExisting: number;
  totalClassNumberNeeded: number;
  totalClassroomNumberExisting: number;
  totalClassroomNumberNeeded: number;
  datasReport: DatasReport;
  totalSchools: number;
  enrollmentTotalRural: number;
  percentageEnrollmentRural: number;
  numberEnrollmentCurrentYear: number;
  numberFinancialCurrentYear: number;
  numberEmployeeCurrentYear: number;
  numberTeacherCurrentYear: number;
  numberRoomCurrentYear: number;
  numberSchoolCurrentYear: number;
  rural: number;
  constructor(init?: Partial<FinancingFederatedEntitiesReport>) {
    super();
    Object.assign(this, init);
  }
}
