import { EmployeeLocation } from './employee-location';
import { FormationLevel } from '../../../../shared/entities/formation-level';
import { PriceList } from '../../../../shared/entities/price-list';
import { UnitOfMeasurementEnum } from '../../../../shared/entities/enums/unit-of-measurement.enum';
import { AccountPlanEnum } from '../../../../shared/entities/enums/account-plan.enum';
import { ExpenseTypeEnum } from '../../../../shared/entities/enums/expense-type.enum';
import { PriceIndiceEnum } from '../../../../shared/entities/enums/price-indice.enum';

export class SchoolStaff extends PriceList {

  employeeLocation: Array<EmployeeLocation>;
  formationLevel: FormationLevel;
  grossMonthlyRemuneration: number;
  minEnrollmentByFunction: number;
  professionalByEnrollment: number;
  professionalBySchool: number;
  minBySchool: number;
  maxBySchool: number;
  sequence: string;
  denomination: string;
  isEducationAssistant: boolean;

  constructor(
    init?: Partial<SchoolStaff>
  ) {
    super();

    this.unitOfMeasurements = [UnitOfMeasurementEnum.professorMes];
    this.accountPlans = [AccountPlanEnum.Pessoal_Encargos];
    this.expenseTypes = [ExpenseTypeEnum.Corrente];
    this.priceIndices = [PriceIndiceEnum.ICV];
    this.multiplier = 13.3;

    Object.assign(this, init);
  }

}
