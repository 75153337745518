export enum SourceInformationEnum {
    enrollment = 1,
    school = 2,
    teacher = 3,
    classroom = 4,
    population = 5,
    pibPerCapita = 6,
    idhm = 7,
    siope = 8,
    infrastructure = 9,
    class = 10,
    cub = 11,
    employees = 12,
    transport = 13,
    financial = 14,
    dailyChargeAmount = 15,
    outOfSchoolPopulation = 16,
    idhmPnud = 17
}
