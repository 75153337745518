import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Functionality } from '../../../shared/entities/functionality/functionality';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { TypeTransport } from './entities/type-transport';
import { SchoolsTransports } from './entities/schools-transports';
import { SchoolTransportService } from './services/school-transport.service';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

@Component({
  selector: 'app-school-transport',
  templateUrl: './school-transport.component.html',
  styleUrls: ['./school-transport.component.scss']
})
export class SchoolTransportComponent extends BaseNavigableComponent<SchoolsTransports> implements PqrComponentData {

  data: SchoolsTransports = new SchoolsTransports();
  functionality: FunctionalityInfo = Functionality.schoolTransport;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = SchoolTransportComponent;
  referenceDateMask: any = this.utilitiesService.getReferenceDateMask();

  constructor(private schoolTransportService: SchoolTransportService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<SchoolsTransports> {
    return this.schoolTransportService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  newSchoolsTransport(): void {
    const newTransport = this.schoolTransportService.getNewSchoolsTransport(this.data.transports.length);
    this.data.transports.push(newTransport);
  }

  deleteSchoolsTransport(sequence: string): void {
    this.schoolTransportService.removeSchoolsTransport(this.data.transports, sequence);
  }

  typeTransportsEquals(typeA: TypeTransport, typeB: TypeTransport): boolean {
    if (typeA && typeB) {
      return typeA.id === typeB.id;
    }

    return false;
  }

}
