import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ToastrModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { SelectModule } from 'ng-select';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { SourceInformationComponent } from './components/source-information/source-information.component';
import { SubmenuComponent } from './components/submenu/submenu.component';
import { InconsistencyComponent } from './components/inconsistency/inconsistency.component';
import { AlertComponent } from './components/modal/alert/alert.component';
import { ConfirmComponent } from './components/modal/confirm/confirm.component';
import { PqrInfoComponent } from '../simulator/shared/components/pqr-info/pqr-info.component';
import { ComponentHostDirective } from './directives/component-host.directive';
// import { NewsComponent } from './components/news/news.component';
import { FootnoteComponent } from './components/footnote/footnote.component';
import { DownloadComponent } from './components/download/download.component';
import { TutorialsComponent } from './components/modal/tutorials/tutorials.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      positionClass: 'toast-top-right'
    }),
    BlockUIModule.forRoot(),
    SelectModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgxPageScrollCoreModule
  ],
  declarations: [
    SourceInformationComponent,
    SubmenuComponent,
    InconsistencyComponent,
    AlertComponent,
    ConfirmComponent,
    PqrInfoComponent,
    ComponentHostDirective,
    // NewsComponent,
    FootnoteComponent,
    DownloadComponent,
    TutorialsComponent
  ],
  entryComponents: [
    AlertComponent,
    ConfirmComponent,
    TutorialsComponent
  ],
  exports: [
    FormsModule,
    CommonModule,
    RouterModule,
    TabsModule,
    TooltipModule,
    CollapseModule,
    ModalModule,
    BsDropdownModule,
    AccordionModule,
    PopoverModule,
    ToastrModule,
    BlockUIModule,
    SelectModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgxPageScrollCoreModule,
    SourceInformationComponent,
    SubmenuComponent,
    InconsistencyComponent,
    AlertComponent,
    ConfirmComponent,
    PqrInfoComponent,
    ComponentHostDirective,
    // NewsComponent,
    FootnoteComponent,
    DownloadComponent,
    TutorialsComponent
  ]
})
export class SharedModule { }
