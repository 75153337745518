import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { CalculateTeacherNumberService } from '../../../shared/services/calculate-teacher-number/calculate-teacher-number.service';
import { CalculateClassNumberService } from '../../../shared/services/calculate-class-number/calculate-class-number.service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { TeacherNumber } from '../entities/teacher-number';
import { TeacherNumberByStage } from '../entities/teacher-number-by-stage';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { TeacherNumberByYear } from '../entities/teacher-number-by-year';
import { TeacherNumberByStageCalc } from '../../../shared/services/calculate-teacher-number/entities/teacher-number-by-stage-calc';
import { CareerLevelByYear } from '../../../shared/services/calculate-teacher-number/entities/career-level-by-year';
import { TeacherNumberGroupedCalculation } from '../entities/teacher-number-grouped-calculation';
import { TeacherNumberCalc } from '../../../shared/services/calculate-teacher-number/entities/teacher-number-calc';
import { Stage } from '../../../../shared/entities/stage';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { EnrollmentProjectionByLocationService } from '../../../access-and-offer/enrollment-projection/services/enrollment-projection-by-location.service';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { SessionService } from 'app/shared/services/session/session.service';
import { EnrollmentBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/enrollment-by-school';
import { Functionality } from 'app/shared/entities/functionality/functionality';
import { EnrollmentAndClassAndClassroom } from 'app/simulator/results/caq-report/entities/enrollment-and-class-and-classroom';
import { CreateProjectionsService } from 'app/simulator/shared/services/create-projections/create-projections.service';

@Injectable({
  providedIn: 'root'
})
export class TeacherNumberService implements NavigableComponentService {

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private utilitiesService: UtilitiesService,
    private currentYearService: CurrentYearService,
    private calculateClassNumberService: CalculateClassNumberService,
    private calculateTeacherNumberService: CalculateTeacherNumberService,
    private enrollmentProjectionByLocationService: EnrollmentProjectionByLocationService,
    private createProjectionsService: CreateProjectionsService
  ) { }

  getData(): Observable<TeacherNumber> {

    const observablesCommon: Array<Observable<any>> = new Array<Observable<any>>();
    const observablesCalculation: Array<Observable<any>> = new Array<Observable<any>>();
    const resultForEnrollmentSchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);
    let locations, stages;

    observablesCommon.push(this.utilitiesService.getLocations().pipe(
      map(resultLocations => locations = resultLocations)));

    observablesCommon.push(this.utilitiesService.getStages().pipe(
      map(resultStages => stages = resultStages)));

    return forkJoin(observablesCommon).pipe(
      mergeMap(() => {

        const enrollmentsAndClassesAndClassroomsBySchool = this.getEnrollmentAndClassAndClassroom(resultForEnrollmentSchool);

        for (let i = 0; i < enrollmentsAndClassesAndClassroomsBySchool.length; i++) {

          const enrollmentAndClasseAndClassroomBySchool = enrollmentsAndClassesAndClassroomsBySchool[i];

          observablesCalculation.push(this.calculateClassNumberService.calculateClassNumber(locations, stages, enrollmentAndClasseAndClassroomBySchool).pipe(
            mergeMap(calculatedClassNumber => {
              return this.calculateTeacherNumberService.calculateTeacherNumber(calculatedClassNumber).pipe(
                map(calculatedTeacherNumber => {
                  const teacherNumber: TeacherNumber = new TeacherNumber({ years: this.utilitiesService.getSimulationYears() });
                  const teachersNumberGroupedCalculation = this.getTeacherNumberGroupedCalculation(calculatedTeacherNumber, stages);

                  teacherNumber.resultForTeacherNumber = stages.map(stage => new TeacherNumberByStage({
                    id: stage.id,
                    stageDescription: stage.description
                  }));

                  for (const stage of stages) {

                    const teacherNumberGroupCalculationByStage = _.find(teachersNumberGroupedCalculation, c => c.id === stage.id);
                    const teacherNumberByStage = _.find(teacherNumber.resultForTeacherNumber, c => c.id === stage.id);

                    if (teacherNumberGroupCalculationByStage) {
                      teacherNumberByStage.teacherNumberByYear = teacherNumberGroupCalculationByStage.teacherNumberByYear.map(
                        teacherNumberGroupedYear => new TeacherNumberByYear({ year: teacherNumberGroupedYear.year, value: teacherNumberGroupedYear.value }));
                    }
                  }
                  return teacherNumber;
                }));
            })));
        }
        return forkJoin(observablesCalculation).pipe(
          mergeMap(results => {
            const teacherNumber: TeacherNumber = this.getTotalTeacherNumberCalculated(results, stages);
            return this.getDiagnostics(teacherNumber);
          }));
      }));
  }

  private getTotalTeacherNumber(totalStages: number, totalAuxiliar: number, teacherNumberByStage: Array<TeacherNumberByStage>): TeacherNumberByStage {

    return new TeacherNumberByStage(
      {
        stageDescription: 'Total',
        currentNumber: totalStages,
        auxiliarNumber: totalAuxiliar,
        teacherNumberByYear: this.getTotalTeacherNumberByYear(teacherNumberByStage)
      });

  }

  private getDiagnostic(): Observable<any> {

    let filtersLocation: Array<string> = new Array<string>();
    const teacherCurrentYear: number = this.currentYearService.getTeacherCurrentYear();

    filtersLocation = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string> = new Array<string>(
      `min_year:"${teacherCurrentYear}"`,
      `max_year:"${teacherCurrentYear}"`
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    filters = filters.concat(filtersLocation);

    const options: any = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['filter', filters.join(',')]]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/auxiliar`, options).pipe(
          map(diagnostic => _.first(diagnostic)));
      }));
  }

  private getTotalTeacherNumberByYear(teacherNumberByStage: Array<TeacherNumberByStage>): Array<TeacherNumberByYear> {
    const simulationYears = this.utilitiesService.getSimulationYears();
    const teacherNumberByYear: Array<TeacherNumberByYear> = new Array<TeacherNumberByYear>();
    let totalValue: number;

    for (let i = 0; i < simulationYears.length; i++) {
      const simulationYear = simulationYears[i];
      totalValue = 0;
      for (let j = 0; j < teacherNumberByStage.length; j++) {
        const item = teacherNumberByStage[j];

        for (let k = 0; k < item.teacherNumberByYear.length; k++) {
          const element = item.teacherNumberByYear[k];
          if (element.year === simulationYear) {
            totalValue += element.value;
            break;
          }
        }
      }

      teacherNumberByYear.push(new TeacherNumberByYear({ year: simulationYear, value: totalValue }));
    }
    return teacherNumberByYear;
  }

  private getTeacherNumberGroupedCalculation(calculatedTeachersNumber: Array<TeacherNumberCalc>, stages: Array<Stage>): Array<TeacherNumberGroupedCalculation> {

    const teachersNumberGroupedCalculation: Array<TeacherNumberGroupedCalculation> = new Array<TeacherNumberGroupedCalculation>();
    const teachersNumberByStagesCalc: Array<TeacherNumberByStageCalc> = new Array<TeacherNumberByStageCalc>();

    for (let i = 0; i < calculatedTeachersNumber.length; i++) {
      const calculatedTeacherNumber = calculatedTeachersNumber[i];
      for (let j = 0; j < calculatedTeacherNumber.teachersNumberByLocationsCalc.length; j++) {
        const teacherNumberByLocationCalc = calculatedTeacherNumber.teachersNumberByLocationsCalc[j];
        for (let k = 0; k < teacherNumberByLocationCalc.teachersNumberByStagesCalc.length; k++) {
          teachersNumberByStagesCalc.push(teacherNumberByLocationCalc.teachersNumberByStagesCalc[k]);
        }
      }
    }

    for (const stage of stages) {

      const teacherNumberGroupedCalculation: TeacherNumberGroupedCalculation = new TeacherNumberGroupedCalculation(
        new TeacherNumberGroupedCalculation({
          id: stage.id,
          stageDescription: stage.description,
          teacherNumberByYear: new Array<TeacherNumberByYear>()
        }));

      const calculatedTeachersNumberByStages: Array<TeacherNumberByStageCalc> = new Array<TeacherNumberByStageCalc>();

      for (let i = 0; i < teachersNumberByStagesCalc.length; i++) {
        if (teachersNumberByStagesCalc[i].id === stage.id) {
          calculatedTeachersNumberByStages.push(teachersNumberByStagesCalc[i]);
        }
      }

      const careersLevelsByYear: Array<CareerLevelByYear> = new Array<CareerLevelByYear>();
      for (let i = 0; i < calculatedTeachersNumberByStages.length; i++) {
        const calculatedTeacherNumberByStage = calculatedTeachersNumberByStages[i];
        for (let j = 0; j < calculatedTeacherNumberByStage.careerLevelsByYear.length; j++) {
          careersLevelsByYear.push(calculatedTeacherNumberByStage.careerLevelsByYear[j]);
        }
      }

      this.utilitiesService.getSimulationYears().map(simulationYear => {

        const careersLevelsYear: Array<CareerLevelByYear> = new Array<CareerLevelByYear>();
        for (let i = 0; i < careersLevelsByYear.length; i++) {
          if (careersLevelsByYear[i].year === simulationYear) {
            careersLevelsYear.push(careersLevelsByYear[i]);
          }
        }

        teacherNumberGroupedCalculation.teacherNumberByYear.push(new TeacherNumberByYear({
          year: simulationYear,
          value: this.getTotal(careersLevelsYear)
        }));

        teachersNumberGroupedCalculation.push(teacherNumberGroupedCalculation);
      });
    }
    return teachersNumberGroupedCalculation;
  }

  private getTotal(carrersLevelsByYear: Array<CareerLevelByYear>): number {

    let totalTeachers: number = 0;
    for (let i = 0; i < carrersLevelsByYear.length; i++) {
      totalTeachers += carrersLevelsByYear[i].teacherNumberTotal;
    }

    return totalTeachers;

  }

  private getEnrollmentAndClassAndClassroom(enrollmentBySchool: EnrollmentBySchool): Array<EnrollmentAndClassAndClassroom> {

    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();

    for (let i = 0; i < enrollmentBySchool.enrollmentByStageSeriesBySchool.length; i++) {

      const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
        school_id: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].school_id,
        school_description: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].school_name,
        enrollmentProjection: this.createProjectionsService.getEnrollmentProjectionBySchool(enrollmentBySchool.enrollmentByStageSeriesBySchool[i]),
        hasEnrollment: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].hasEnrollment,
        percentageTeacherCareer: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].percentageTeacherCareer
      });

      enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);
    }
    return enrollmentAndClassAndClassrooms;
  }

  private getTotalTeacherNumberCalculated(teachersNumberResults: Array<TeacherNumber>, stages: Array<Stage>): TeacherNumber {

    const teacherNumberTotal: TeacherNumber = new TeacherNumber({ resultForTeacherNumber: new Array<TeacherNumberByStage>(), years: this.utilitiesService.getSimulationYears() });
    const yearSimulation = this.utilitiesService.getSimulationYears();
    const offerYear: number = this.currentYearService.getTeacherCurrentYear();

    teacherNumberTotal.sourceInformation = new Array<Footnote>();
    teacherNumberTotal.sourceInformation.push(new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.teacher }));
    ////teacherNumberTotal.sourceInformation.push(new Footnote({ indice: 2, sourceInformation: SourceInformationEnum.teacher }));
    teacherNumberTotal.noteInfo = new Array<Footnote>();

    teacherNumberTotal.noteInfo.push(new Footnote({
      indice: 2,
      note: 'A contagem do número de professores considera os profissionais informados no Censo Escolar nas funções de ‘Docente’ e ' +
        'de ‘Docente Titular’ - coordenador de tutoria (de módulo ou disciplina) – EaD. São considerados apenas os professores que ' +
        'atuam em turmas cujo tipo de atendimento não seja de Atividade Complementar ou de Atendimento Educacional Especializado (AEE).'
    }));

    /*teacherNumberTotal.noteInfo.push(new Footnote({
      indice: 4,
      note: 'A contagem dos auxiliares docentes considera os profissionais informados no Censo Escolar na função de ‘Auxiliar/Assistente Educacional’ ' +
        'exercida em escolas públicas.  São considerados apenas os auxiliares que atuam em turmas cujo tipo de atendimento não seja de Atividade Complementar ou ' +
        'Atendimento Educacional Especializado (AEE).'
    }));
    */
    teacherNumberTotal.yearCurrent = offerYear;

    for (let i = 0; i < stages.length; i++) {

      teacherNumberTotal.resultForTeacherNumber.push(new TeacherNumberByStage({
        id: stages[i].id,
        stageDescription: stages[i].description,
        teacherNumberByYear: yearSimulation.map(sy => new TeacherNumberByYear({ year: sy, value: 0 }))
      }));
    }

    for (let i = 0; i < teachersNumberResults.length; i++) {

      for (let j = 0; j < teachersNumberResults[i].resultForTeacherNumber.length; j++) {

        const teacherNumberResult = teachersNumberResults[i].resultForTeacherNumber[j];
        const teacherNumberAux = _.find(teacherNumberTotal.resultForTeacherNumber, tNt => tNt.id === teacherNumberResult.id);

        for (let k = 0; k < teacherNumberAux.teacherNumberByYear.length; k++) {

          const teacherNumberResultByYear = _.find(teacherNumberResult.teacherNumberByYear, tNy => tNy.year === teacherNumberAux.teacherNumberByYear[k].year);

          teacherNumberAux.teacherNumberByYear[k].value += teacherNumberResultByYear.value;

        }
      }
    }
    return teacherNumberTotal;
  }

  private getDiagnostics(teacherNumber: TeacherNumber): Observable<TeacherNumber> {

    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();
    const offerYear: number = this.currentYearService.getTeacherCurrentYear();

    let filters: Array<string> = new Array<string>(
      `min_year:"${offerYear}"`,
      `max_year:"${offerYear}"`
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());
    filters = filters.concat(filtersLocation);

    const options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['filter', filters.join(',')]
    ]));

    return this.httpService.getApiEndpoint().pipe(
      mergeMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/teacher`, options).pipe(
          mergeMap(teachersNumberByeducationLevel => {
            return this.getDiagnostic().pipe(
              map(auxiliar => {
                let totalAuxiliar: number = 0;
                if (auxiliar) {
                  totalAuxiliar = auxiliar.total;
                }
                teacherNumber.resultForTeacherNumber.push(this.getTotalTeacherNumber(_.first(teachersNumberByeducationLevel).total, totalAuxiliar,
                  teacherNumber.resultForTeacherNumber));
                return teacherNumber;
              }));
          }));
      }));
  }
}
