import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { BaseUnsubscribe } from '../../shared/entities/base/base-unsubscribe';
import { UserService } from '../services/user.service';
import { Role } from '../entities/role';
import { Segment } from '../entities/segment';
import { Schooling } from '../entities/schooling';
import { State } from '../entities/state';
import { City } from '../entities/city';
import { User } from '../entities/user';
import { CpfValidatorService } from '../services/cpf-validator.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { AuthService } from '../../shared/services/auth/auth.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent extends BaseUnsubscribe implements OnInit {

  form: FormGroup;
  schoolings: Array<Schooling> = new Array<Schooling>();
  segments: Array<Segment> = new Array<Segment>();
  roles: Array<Role> = new Array<Role>();
  user: User = new User();
  states: Array<State> = new Array<State>();
  cities: Array<City> = new Array<City>();

  selectedSegment: Segment;
  selectedSchooling: Schooling;
  selectedState: State = new State();
  selectedRole: Role;
  selectedCity: City = new City();

  cpfMask: any = { mask: [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/] };

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cpfValidatorService: CpfValidatorService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {

    this.createForm();
    //// this.processInformations();
    this.getUser();
  }

  createForm(): void {

    this.form = this.formBuilder.group({
      name: [undefined, Validators.required],
      cpf: [undefined, Validators.required],
      schooling: [undefined, Validators.required],
      course: [{ value: undefined, disabled: true }, Validators.required],
      segment: [undefined, Validators.required],
      segmentText: [undefined],
      role: [undefined, Validators.required],
      roleText: [undefined],
      institution: [undefined, Validators.required],
      uf: [undefined, Validators.required],
      city: [undefined, Validators.required],
      receiveEmails: [false],
      cep: [undefined, Validators.required],
      nickname: [undefined, Validators.required]
    });
  }

  getUser() {
    this.userService.getUser().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(
        result => {
          this.user._id = result._id;
          this.user.name = result.name;
          this.user.cpf = result.cpf;
          this.user.schooling = result.schooling;
          this.user.segment = result.segment;
          this.user.role = result.role;
          this.user.course = result.course;
          this.user.institutionName = result.institutionName;
          this.user.state = result.state;
          this.user.city = result.city;
          this.user.cep = result.cep;
          this.user.nickname = result.nickname;
          this.user.receive_emails = result.receive_emails;

          this.userService.getState().pipe(
            takeUntil(this.unsubscribe))
            .subscribe(state => {
              this.states = state.sort((a, b) => {
                return (a.abbreviation > b.abbreviation) ? 1 : ((b.abbreviation > a.abbreviation) ? -1 : 0);
              });
              this.states = state;
              this.selectedState = this.states.find(x => x.description === result.state);

              this.userService.getCity(this.selectedState.id).pipe(
                takeUntil(this.unsubscribe))
                .subscribe(city => {
                  this.cities = city;
                  this.selectedCity = this.cities.find(x => x.label === result.city);
                });

            });

          this.userService.getSchooling().pipe(
            takeUntil(this.unsubscribe))
            .subscribe(schooling => {
              this.schoolings = schooling;
              this.selectedSchooling = this.schoolings.find(x => x.description === result.schooling);
            });

          this.userService.getRole(result.segment).pipe(
            takeUntil(this.unsubscribe))
            .subscribe(role => {
              this.roles = role;
              if (role !== undefined) {
                this.selectedRole = this.roles.find(x => x.description === result.role);
              }
            });

          this.userService.getSegment().pipe(
            takeUntil(this.unsubscribe))
            .subscribe(segment => {
              this.segments = segment;
              this.selectedSegment = this.segments.find(x => x.description === result.segment);

              if (this.selectedSegment === undefined) {
                this.selectedSegment = this.segments.find(x => x.description === 'Outro [citar segmento]');
              }
            });
        });
  }

  //// processInformations(): void {

  //// }

  onSubmit() {

    let titleMessage: string;

    if (this.form.valid) {
      this.prepareUserForSend();

      this.userService.alterUser(this.user).pipe(
        takeUntil(this.unsubscribe))
        .subscribe(
          result => {
            titleMessage = 'CADASTRO';
            if (result) {
              this.notificationService.showSuccess('Usuário alterado com sucesso.');
              this.authService.triggerUserLogged();
            }
          },
          (err: any) => {
            if (err && err.errorBody && err.errorBody === 'Unauthorized') {
              this.notificationService.showError('Usuário não autorizado.');
            } else {
              this.notificationService.showError('Ocorreu um erro.');
            }
          }
        );

    } else {
      Object.keys(this.form.controls).forEach(fieldName => {
        const control = this.form.get(fieldName);
        if (control.enabled) {
          control.markAsTouched();
        }
      });
    }
  }

  isCPFValid(): boolean {

    if (this.form.get('cpf').valid) {
      return this.cpfValidatorService.validate(this.form.get('cpf').value);
    }
    return true;

  }

  retiraCaractereCPF(): void {
    if (this.form.get('cpf').value !== null && this.form.get('cpf').value !== undefined) {
      const valor = this.form.get('cpf').value.replace(/\D/g, '');
      this.user.cpf = valor;
    }
  }

  prepareUserForSend(): void {

    this.retiraCaractereCPF();

    this.user.schooling = this.selectedSchooling.description;
    this.user.state = this.selectedState.description;
    this.user.segment = !this.selectedSegment.flagOther ? this.selectedSegment.description : this.form.get('segmentText').value;

    if (this.selectedRole) {
      this.user.role = !this.selectedRole.flagOther ? this.selectedRole.description : this.form.get('roleText').value;
    } else {
      this.user.role = this.form.get('roleText').value;
    }

    this.user.receive_emails = this.form.get('receiveEmails').value;

  }

  verifyValidTouched(fieldName: string): boolean {
    return !this.form.get(fieldName).valid && this.form.get(fieldName).touched;
  }

  verifyInvalidEmail(fieldName: string): boolean {
    if (this.form.get(fieldName).value !== undefined && this.form.get(fieldName).value !== null) {
      if (this.form.get(fieldName).errors) {
        return this.form.get(fieldName).errors['email'] && this.form.get(fieldName).touched;
      }
    }
  }

  verifyConfirmationValue(fieldName1: string, fieldName2: string): boolean {
    if (this.form.get(fieldName1).valid && this.form.get(fieldName2).valid) {
      if (this.form.get(fieldName1).value !== this.form.get(fieldName2).value) {
        // this.form.get(fieldName2).setErrors({ 'Not-Equals': true });
        this.form.get(fieldName2).markAsTouched();
        return true;
      } else {
        return false;
      }
    }
  }

  verifyConfirmationEmail(): boolean {
    if (this.verifyValidTouched('confirmationEmail')) {
      return true;
    } else if (this.verifyInvalidEmail('confirmationEmail')) {
      return true;
    } else if (this.verifyConfirmationValue('email', 'confirmationEmail')) {
      return true;
    }
  }

  verifyConfirmationPassword(): boolean {
    if (this.verifyValidTouched('confirmationPassWord')) {
      return true;
    } else if (this.verifyInvalidEmail('confirmationPassWord')) {
      return true;
    } else if (this.verifyConfirmationValue('password', 'confirmationPassWord')) {
      return true;
    }
  }

  verifySuccess(fieldName: string): boolean {

    if (this.form.get(fieldName).value !== null && this.form.get(fieldName).value !== undefined) {
      if (fieldName === 'confirmationEmail') {
        if (this.form.get(fieldName).value === this.form.get('email').value) {
          return true;
        }
      } else if (fieldName === 'confirmationPassWord') {
        if (this.form.get(fieldName).value === this.form.get('password').value) {
          return true;
        }
      }
    }
    return false;
  }

  segmentChange(): void {

    if (typeof this.selectedSegment === 'string') {
      this.form.get('segment').patchValue(undefined);
      this.form.get('segment').markAsUntouched();
    } else {

      if (this.selectedSegment !== undefined && this.selectedSegment !== null) {

        this.form.get('role').patchValue(undefined);
        this.form.get('role').markAsUntouched();

        if (this.selectedSegment.flagOther) {
          this.form.get('role').setErrors(null);
          this.form.get('segmentText').setValidators([Validators.required]);
          this.form.get('roleText').setValidators([Validators.required]);
        } else {
          this.form.get('segmentText').markAsUntouched();
          this.form.get('segmentText').setErrors(null);
          this.form.get('roleText').markAsUntouched();
          this.form.get('roleText').setErrors(null);
        }

        this.userService.getRole(this.selectedSegment.description).pipe(
          takeUntil(this.unsubscribe))
          .subscribe(role => {
            this.roles = role;
          });

      }
    }
  }

  roleChange(): void {

    if (typeof this.selectedRole === 'string') {
      this.form.get('role').patchValue(undefined);
      this.form.get('role').markAsUntouched();
    } else {
      if (this.selectedRole !== undefined && this.selectedRole !== null) {

        if (this.selectedRole.flagOther) {
          this.form.get('roleText').setValidators([Validators.required]);
        } else {
          this.form.get('roleText').markAsUntouched();
          this.form.get('roleText').setErrors(null);
        }
      }
    }
  }

  schoolingChange(): void {

    if (typeof this.selectedSchooling === 'string') {
      this.form.get('schooling').patchValue(undefined);
      this.form.get('schooling').markAsUntouched();
    } else {
      if (this.selectedSchooling !== undefined && this.selectedSchooling !== null) {
        if (this.selectedSchooling.flagOther) {
          this.form.get('course').enable();
        } else {
          this.form.get('course').reset();
          this.form.get('course').disable();
        }
      }
    }
  }

  cityChange(selectedCity: City): void {
    // this.selectedCity = selectedCity;
    this.user.city = selectedCity.label;
  }

  stateChange(): void {

    if (typeof this.selectedState === 'string') {
      this.form.get('uf').patchValue(undefined);
      this.form.get('uf').markAsUntouched();
    } else {
      if (this.selectedState !== undefined && this.selectedState !== null) {

        this.userService.getCity(this.selectedState.id).pipe(
          takeUntil(this.unsubscribe))
          .subscribe(city => {
            this.cities = city;
          });
      }
    }
  }

  clearForm(): void {
  }

}
