import { CsvHeader } from './csv-header';
import { File } from '../../../entities/file';

export class Csv extends File {

    header: Array<CsvHeader>;
    data: Array<any>;

    constructor(init?: Partial<Csv>) {
        super();
        Object.assign(this, init);
    }

}
