import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ResultsComponent } from './results/results.component';
import { CaqReportComponent } from './caq-report/caq-report.component';
import { BudgetForecastReportComponent } from './budget-forecast-report/budget-forecast-report.component';
import { FinancingFundsReportComponent } from './financing-funds-report/financing-funds-report.component';
import { ItemCostExpenseReportComponent } from './item-cost-expense-report/item-cost-expense-report.component';
import { AdditionalResultsComponent } from './shared/components/additional-results/additional-results.component';
import { EditModeComponent } from '../shared/components/edit-mode/edit-mode.component';
import { FinancingFederatedEntitiesReportComponent } from './financing-federated-entities-report/financing-federated-entities-report.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ResultsComponent,
    CaqReportComponent,
    BudgetForecastReportComponent,
    FinancingFundsReportComponent,
    ItemCostExpenseReportComponent,
    AdditionalResultsComponent,
    EditModeComponent,
    FinancingFederatedEntitiesReportComponent
  ]
})
export class ResultsModule { }
