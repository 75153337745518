import { Location } from '../../../../shared/entities/location';

export class DemandClassRoomLocation {

  location: Location;
  value: number;

  constructor(init?: Partial<DemandClassRoomLocation>) {
    Object.assign(this, init);
  }
}
