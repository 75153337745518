export class SourceInformation {
    private _year: number;
    private _source: string;
    private _subtitleId: string;
    private _sourceInfo: string;

    get year(): number {
        return this._year;
    }

    set year(year: number) {
        this._year = year;
        this._sourceInfo = this.getSourceInfo();
    }

    get source(): string {
        return this._source;
    }

    set source(source: string) {
        this._source = source;
        this._sourceInfo = this.getSourceInfo();
    }

    get subtitleId(): string {
        return this._subtitleId;
    }

    set subtitleId(subtitleId: string) {
        this._subtitleId = `(${subtitleId})`;
    }

    get sourceInfo(): string {
        return this._sourceInfo;
    }

    constructor(init?: Partial<SourceInformation>) {
        Object.assign(this, init);
    }

    toJSON(): any {
        const json = {};
        Object.keys(this).forEach(key => json[(key[0] !== '_' ? key : key.replace('_', ''))] = this[key]);
        return json;
    }

    private getSourceInfo(): string {
        return `${this.source}${(this.year ? ` ${this.year}` : '')}`;
    }
}
