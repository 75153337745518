import { Component, OnInit, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseUnsubscribe } from '../../../../shared/entities/base/base-unsubscribe';
import { SimulatorService } from '../../../simulator/services/simulator.service';
import { SessionService } from '../../../../shared/services/session/session.service';

@Component({
  selector: 'app-edit-mode',
  templateUrl: './edit-mode.component.html',
  styleUrls: ['./edit-mode.component.scss']
})
export class EditModeComponent extends BaseUnsubscribe implements OnInit {

  simulationEditMode: boolean = this.sessionService.getItem<boolean>(SimulatorService.simulationEditModeSessionKey);
  @Input() edit: boolean;

  constructor(private simulatorService: SimulatorService, private sessionService: SessionService) {
    super();

    this.simulatorService.simulationEditModeObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(editMode => this.simulationEditMode = editMode);

    this.simulatorService.notifySimulationPerformedObservers(true);
  }

  ngOnInit() {
  }

  setSimulationEditMode(): void {
    this.simulatorService.notifySimulationEditModeObservers(true);
    this.simulatorService.navigateToEditModeSimulation();
  }

}
