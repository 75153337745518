import { Component, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { Functionality } from '../../../shared/entities/functionality/functionality';
import { FunctionalityInfo } from '../../../shared/entities/functionality/functionality-info';
import { BaseNavigableComponent } from '../../../shared/entities/base/base-navigable-component';
import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { Inconsistency } from '../../../shared/components/inconsistency/entities/inconsistency';
import { SchoolFeedingService } from './services/school-feeding.service';
import { SchoolsFeedings } from './entities/schools-feedings';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

@Component({
  selector: 'app-school-feeding',
  templateUrl: './school-feeding.component.html',
  styleUrls: ['./school-feeding.component.scss']
})
export class SchoolFeedingComponent extends BaseNavigableComponent<SchoolsFeedings> implements PqrComponentData {

  data: SchoolsFeedings = new SchoolsFeedings();
  functionality: FunctionalityInfo = Functionality.schoolFeeding;
  inconsistencies: Array<Inconsistency>;
  pqrAdminMode: boolean;
  pqrMode: boolean;
  pqrModalMode: boolean;
  componentType: Type<PqrComponentData> = SchoolFeedingComponent;
  referenceDateMask: any = this.utilitiesService.getReferenceDateMask();

  constructor(private schoolFeedingService: SchoolFeedingService, private injector: Injector) {
    super(injector);
  }

  processData(): Observable<SchoolsFeedings> {
    return this.schoolFeedingService.getData();
  }

  getPqrData(): NavigableComponentData {
    return this.data;
  }

  setPqrData(pqrData: any) {
    this.data = pqrData;
  }

  processSpecificBehaviors(): void {
    if (!this.pqrMode) {
      this.schoolFeedingService.setTotalStudent(this.data);
    }
  }

  processValidation(): void {
    super.processValidation();

    for (const feed of this.data.feedings) {
      if (isNaN(feed.dailyMeal) || feed.dailyMeal === 0 || feed.dailyMeal === undefined || feed.dailyMeal === null) {
        this.inconsistencies.push(new Inconsistency({
          message: `O número de refeições diárias para a jornada discente ${feed.denomination} deve ser informado de 1 a 10 refeições. Sugerimos 1 para parcial e 2 para integral!`
        }));

      }

      if (isNaN(feed.multiplier) || feed.multiplier === 0 || feed.multiplier === undefined || feed.multiplier === null || feed.multiplier < 200) {
        this.inconsistencies.push(new Inconsistency({
          message: `O número de dias letivos para a jornada discente ${feed.denomination} deve ser de 100 a 300 dias por ano (número mínimo, segundo a LDB)!`
        }));

      }

      if (isNaN(feed.mealValue) || feed.mealValue === undefined || feed.mealValue === null) {

        this.inconsistencies.push(new Inconsistency({
          message: `O valor por refeição para a jornada discente ${feed.denomination} deve ser informado!`
        }));
      }

      if (!this.utilitiesService.isReferenceDateValid(feed.referenceDate)) {
        this.inconsistencies.push(new Inconsistency({
          message: `A data de referência para a jornada discente ${feed.denomination} não é valida.`
        }));
      }

    }

  }
}
