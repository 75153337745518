import { TutorialStep } from './tutorial-step';

export class Tutorial {

    videoSource: string;
    labelStartReport: string;
    steps: Array<TutorialStep>;

    constructor(init?: Partial<Tutorial>) {
        Object.assign(this, init);
    }
}
