import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UtilitiesService } from '../../../../../shared/services/utilities/utilities.service';
import { SessionService } from '../../../../../shared/services/session/session.service';
import { FunctionalityInfo } from '../../../../../shared/entities/functionality/functionality-info';

@Component({
  selector: 'app-required-data-alert',
  templateUrl: './required-data-alert.component.html',
  styleUrls: ['./required-data-alert.component.scss']
})
export class RequiredDataAlertComponent implements OnInit {

  missingRequiredData: Array<FunctionalityInfo>;

  constructor(private router: Router, private bsModalRef: BsModalRef, private sessionService: SessionService) { }

  ngOnInit() {
  }

  hideAlert(functionalityRoute: Array<string>): void {
    this.bsModalRef.hide();
    this.sessionService.removeItem(UtilitiesService.hasRequiredDataSessionKey);
    this.router.navigate(functionalityRoute, { queryParamsHandling: 'merge' });
  }

}
