import { ItemCostExpenseReportByLocation } from './item-cost-expense-report-by-location';

export class ItemCostExpenseReportByStage {

  id: number;
  description: string;
  itemsCostExpenseReportByLocations: Array<ItemCostExpenseReportByLocation>;

  constructor(init?: Partial<ItemCostExpenseReportByStage>) {
    Object.assign(this, init);
  }

}
