import { ExpenseByItem } from './expense-by-item';

export class Expense {

  years: Array<number>;
  offerYear: number;
  expensesBasicEducation: Array<ExpenseByItem>;

  constructor(init?: Partial<Expense>) {
    Object.assign(this, init);
  }
}
