import { Inconsistency } from './entities/inconsistency';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-inconsistency',
  templateUrl: './inconsistency.component.html',
  styleUrls: ['./inconsistency.component.scss']
})
export class InconsistencyComponent implements OnInit {

  @Input() inconsistencies: Array<Inconsistency>;

  constructor() { }

  ngOnInit() {
  }

}
