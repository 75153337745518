import { DescriptionValue } from './description-value';
import { Footnote } from '../../../shared/components/footnote/entities/footnote';

export class Idhm {
    levels: Array<DescriptionValue>;
    // footnote: Footnote;

    constructor(init?: Partial<Idhm>) {
        Object.assign(this, init);
    }
}
