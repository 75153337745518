import { SchoolFeeding } from './school-feeding';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class SchoolsFeedings extends NavigableComponentData {

  feedings: Array<SchoolFeeding> = new Array<SchoolFeeding>();
  year: number;

  constructor(init?: Partial<SchoolsFeedings>) {
    super();
    Object.assign(this, init);
  }
}
