import { City } from './city';
import { State } from './state';
import { NavigableComponentData } from '../../shared/entities/base/navigable-component-data';

export class SelectLocation extends NavigableComponentData {

    selectedState: State;
    selectedCity: City;
    selectedCityId: string;
    selectedSimulationType: number;

    constructor(init?: Partial<SelectLocation>) {
        super();
        Object.assign(this, init);
    }
}
