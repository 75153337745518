export class UserInfo {

    email: string;
    name: string;
    nickname: string;
    admin: boolean;
    researcher: boolean;

    constructor(init?: Partial<UserInfo>) {
        Object.assign(this, init);
    }
}
