import { PriceIndicesByYears } from './price-indices-by-years';

export class PriceIndice {

    id: number;
    description: string;
    years: Array<PriceIndicesByYears>;

    constructor(init?: Partial<PriceIndice>) {
        Object.assign(this, init);
    }
}
