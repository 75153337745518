import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { FinancingFundsReport } from './financing-funds-report';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class FinancingsFundsReport extends NavigableComponentData {

  financingsFundsReport: Array<FinancingFundsReport>;
  financialYear: number;
  enrollmentYear: number;
  simulationYearInitial: number;
  enrollmentTotal: number = 0;
  caqBR: number = 0;
  potentialTotal: number = 0;
  potentialPlusAgreementTotal: number = 0;
  fundebTotal: number = 0;
  expenseCurrentTotal: number = 0;
  expenseCapitalTotal: number = 0;
  expenseTotal: number = 0;
  unionComplementationTotal: number = 0;
  percentUnionComplementationTotal: number = 0;
  sourceInformations: Array<Footnote>;
  sourceInformationPQR: Footnote;

  constructor(init?: Partial<FinancingsFundsReport>) {
    super();
    Object.assign(this, init);
  }
}
