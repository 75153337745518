import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { Stage } from '../entities/stage';
import { StudentCostByStage } from '../entities/student-cost-by-stage';
import { StudentCostByYear } from '../entities/student-cost-by-year';
import { SelectLocation } from '../../../../select-location/entities/select-location';
import { UtilitiesService } from '../../../../../shared/services/utilities/utilities.service';
import { Functionality } from '../../../../../shared/entities/functionality/functionality';
import { SessionService } from '../../../../../shared/services/session/session.service';
import { StudentCost } from '../entities/student-cost';
import { Enrollment } from '../../../../access-and-offer/enrollment-projection/entities/enrollment';
import { EnrollmentProjectionByLocation } from '../../../../access-and-offer/enrollment-projection/entities/enrollment-projection-by-location';
import { EnrollmentProjection } from '../../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { ShiftStudentCost } from '../entities/enums/shift-student-cost.enum';
import { TotalizerStudentCostByYear } from '../entities/totalizer-student-cost-by-year';
import { TotalizerStudentCostByShift } from '../entities/totalizer-student-cost-by-shift';
import { TotalizerStudentCostByLocation } from '../entities/totalizer-student-cost-by-location';
import { Location } from '../../../../../shared/entities/location';
import { LocationEnum } from '../../../../../shared/entities/enums/location.enum';
import { TotalizerStudentCostByStage } from '../entities/totalizer-student-cost-by-stage';
import { TotalizerStudentCost } from '../entities/totalizer-student-cost';
import { OfferGoalEnrollmentFullTime } from '../../../../quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';

@Injectable({
  providedIn: 'root'
})
export class StudentCostService {

  constructor(private sessionService: SessionService, private utilitiesService: UtilitiesService) { }

  calculateStudentCost(
    locations: Array<Location>,
    stages: Array<Stage>,
    studentsCost: Array<StudentCost>,
    costOfAssetsAndServices: Array<StudentCost> = undefined,
    enrollmentProjection: EnrollmentProjection = undefined,
    caqMonth: boolean = false
  ): Observable<TotalizerStudentCost> {

    let resultForEnrollmentProjectionByLocation: EnrollmentProjection = new EnrollmentProjection();
    let totalizerStudentCostOfAssetsAndServices: TotalizerStudentCost = new TotalizerStudentCost();

    if (!enrollmentProjection) {
      resultForEnrollmentProjectionByLocation = this.sessionService.getItem<EnrollmentProjection>(Functionality.enrollmentProjectionByLocation.key);
    } else {
      resultForEnrollmentProjectionByLocation = enrollmentProjection;
    }

    const totalizerStudentCost: TotalizerStudentCost = this.totalizerStudentCost(studentsCost, stages, locations, resultForEnrollmentProjectionByLocation, false, caqMonth);

    if (costOfAssetsAndServices) {
      totalizerStudentCostOfAssetsAndServices = this.totalizerStudentCost(costOfAssetsAndServices, stages, locations, resultForEnrollmentProjectionByLocation, true, caqMonth);
      this.getSumStudentCost(totalizerStudentCost, totalizerStudentCostOfAssetsAndServices);
    }

    return of(totalizerStudentCost);
  }

  private totalizerStudentCost(studentsCost: Array<StudentCost>, stages: Array<Stage>, locations: Array<Location>,
    resultForEnrollmentProjectionByLocation: EnrollmentProjection, calculateStudentsCostOfAssetsAndServices: boolean,
    caqMonth: boolean = false
  ): TotalizerStudentCost {

    const studentsCostByStagesUrban: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const studentsCostByStagesRural: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const studentsCostByStagesTotal: Array<StudentCostByStage> = new Array<StudentCostByStage>();
    const totalizersStudentsCostByStages: Array<TotalizerStudentCostByStage> = new Array<TotalizerStudentCostByStage>();
    const resultsForOfferGoalEnrollmentFullTime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.key);

    for (let i = 0; i < studentsCost.length; i++) {
      const studentCost = studentsCost[i];

      for (let j = 0; j < studentCost.studentsCostByLocations.length; j++) {
        const studentCostByLocation = studentCost.studentsCostByLocations[j];

        for (let k = 0; k < studentCostByLocation.studentsCostByStages.length; k++) {
          const studentCostByStage = studentCostByLocation.studentsCostByStages[k];
          studentsCostByStagesTotal.push(studentCostByStage);

          if (studentCostByLocation.id === LocationEnum.urban) {
            studentsCostByStagesUrban.push(studentCostByStage);
          } else if (studentCostByLocation.id === LocationEnum.rural) {
            studentsCostByStagesRural.push(studentCostByStage);
          }
        }
      }
    }

    let enrollmentProjectionByLocationUrban: EnrollmentProjectionByLocation = new EnrollmentProjectionByLocation();
    let enrollmentProjectionByLocationRural: EnrollmentProjectionByLocation = new EnrollmentProjectionByLocation();
    const enrollmentTotal: Array<Enrollment> = new Array<Enrollment>();

    for (let k = 0; k < resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations.length; k++) {

      for (let y = 0; y < resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations[k].totalsEnrollmentProjection.length; y++) {
        enrollmentTotal.push(resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations[k].totalsEnrollmentProjection[y]);
      }

      if (resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations[k].id === LocationEnum.urban) {
        enrollmentProjectionByLocationUrban = resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations[k];
      } else if (resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations[k].id === LocationEnum.rural) {
        enrollmentProjectionByLocationRural = resultForEnrollmentProjectionByLocation.enrollmentsProjectionsByLocations[k];
      }
    }

    for (let i = 0; i < stages.length; i++) {

      let studentCostByStage: Array<StudentCostByStage> = new Array<StudentCostByStage>();
      const totalizerStudentCostByLocation: Array<TotalizerStudentCostByLocation> = new Array<TotalizerStudentCostByLocation>();

      let offerGoalEnrollmentIntegralByStage: number = 0;
      /*for (let k = 0; k < resultsForOfferGoalEnrollmentFullTime.fullTime.length; k++) {
        if (resultsForOfferGoalEnrollmentFullTime.fullTime[k].id === stages[i].id) {
          offerGoalEnrollmentIntegralByStage = resultsForOfferGoalEnrollmentFullTime.fullTime[k].offerGoal;
          break;
        }
      }*/

      for (let j = 0; j < locations.length; j++) {

        const studentsCostByStages: Array<StudentCostByStage> = new Array<StudentCostByStage>();

        if (locations[j].id === LocationEnum.urban) {
          for (let k = 0; k < studentsCostByStagesUrban.length; k++) {
            if (studentsCostByStagesUrban[k].id === stages[i].id) {
              studentsCostByStages.push(studentsCostByStagesUrban[k]);
            }
          }
        } else if (locations[j].id === LocationEnum.rural) {
          for (let k = 0; k < studentsCostByStagesRural.length; k++) {
            if (studentsCostByStagesRural[k].id === stages[i].id) {
              studentsCostByStages.push(studentsCostByStagesRural[k]);
            }
          }
        }

        const enrollmentProjectionByLocation = locations[j].id === LocationEnum.urban ? enrollmentProjectionByLocationUrban : enrollmentProjectionByLocationRural;

        const enrollmentProjectionStage = _.find(enrollmentProjectionByLocation.stagesEnrollments, stE => stE.id === stages[i].id);

        if (enrollmentProjectionStage) {
          offerGoalEnrollmentIntegralByStage = enrollmentProjectionStage.integralPercentage;
        }

        if (enrollmentProjectionByLocation.stagesEnrollments) {
          let totalEnrollmentStage: Array<Enrollment> = new Array<Enrollment>();
          for (let k = 0; k < enrollmentProjectionByLocation.stagesEnrollments.length; k++) {
            if (stages[i].id === enrollmentProjectionByLocation.stagesEnrollments[k].id) {
              totalEnrollmentStage = enrollmentProjectionByLocation.stagesEnrollments[k].totalEnrollments;
            }
          }

          studentCostByStage = locations[j].id === LocationEnum.urban ? studentsCostByStagesUrban : studentsCostByStagesRural;
          totalizerStudentCostByLocation.push(new TotalizerStudentCostByLocation({
            id: locations[j].id,
            description: locations[j].description,
            totalizersStudentsCostByShifts: this.getStudentCostByShift(studentsCostByStages, totalEnrollmentStage, offerGoalEnrollmentIntegralByStage,
              calculateStudentsCostOfAssetsAndServices, caqMonth)
          }));
        }

      }

      const totalizerStudentCostByStage: TotalizerStudentCostByStage = new TotalizerStudentCostByStage({
        id: stages[i].id,
        description: stages[i].description,
        totalizersStudentsCostByLocations: totalizerStudentCostByLocation
      });

      totalizersStudentsCostByStages.push(totalizerStudentCostByStage);

    }

    const totalizerStudentCost: TotalizerStudentCost = new TotalizerStudentCost({
      totalizersStudentsCostByYearTotal: this.getTotalizersStudentsCostByYearTotal(studentsCostByStagesTotal, enrollmentTotal, caqMonth),
      totalizersStudentsCostByStages: totalizersStudentsCostByStages
    });

    return totalizerStudentCost;
  }

  private getStudentCostByShift(studentsCostByStages: Array<StudentCostByStage>, totalEnrollmentStage: Array<Enrollment>,
    offerGoalEnrollmentIntegralByStage: number, calculateStudentsCostOfAssetsAndServices: boolean, caqMonth: boolean = false): Array<TotalizerStudentCostByShift> {

    return new Array<TotalizerStudentCostByShift>(new TotalizerStudentCostByShift({
      id: 1,
      description: 'Parcial',
      totalizersStudentsCostByYear: this.getStudentCostByYear(studentsCostByStages, ShiftStudentCost.Partial, totalEnrollmentStage, offerGoalEnrollmentIntegralByStage,
        calculateStudentsCostOfAssetsAndServices, caqMonth)
    }), new TotalizerStudentCostByShift({
      id: 2,
      description: 'Integral',
      totalizersStudentsCostByYear: this.getStudentCostByYear(studentsCostByStages, ShiftStudentCost.Integral, totalEnrollmentStage, offerGoalEnrollmentIntegralByStage,
        calculateStudentsCostOfAssetsAndServices, caqMonth)
    }));

  }

  private getStudentCostByYear(studentsCostByStages: Array<StudentCostByStage>, shiftStudentCost_id: number, totalEnrollmentStage: Array<Enrollment>,
    offerGoalEnrollmentIntegralByStage: number, calculateStudentsCostOfAssetsAndServices: boolean, caqMonth: boolean = false): Array<TotalizerStudentCostByYear> {

    const studentsCostByYearTotal: Array<StudentCostByYear> = new Array<StudentCostByYear>();
    const totalizersStudentsCostByYears: Array<TotalizerStudentCostByYear> = new Array<TotalizerStudentCostByYear>();

    for (let j = 0; j < studentsCostByStages.length; j++) {
      const studentCostByStage = studentsCostByStages[j];
      for (let k = 0; k < studentCostByStage.studentsCostByYearTotal.length; k++) {
        studentsCostByYearTotal.push(studentCostByStage.studentsCostByYearTotal[k]);
      }
    }

    this.utilitiesService.getSimulationYears().map(simulationYear => {

      const studentsCostByYears: Array<StudentCostByYear> = new Array<StudentCostByYear>();
      for (let j = 0; j < studentsCostByYearTotal.length; j++) {
        if (studentsCostByYearTotal[j].year === simulationYear) {
          studentsCostByYears.push(studentsCostByYearTotal[j]);
        }
      }

      let totalEnrollment: number = 0;
      let totalEnrollmentNocturnal: number = 0;
      let totalEnrollmentPartial: number = 0;
      let totalEnrollmentIntegral: number = 0;
      for (let k = 0; k < totalEnrollmentStage.length; k++) {
        if (simulationYear === totalEnrollmentStage[k].year) {
          totalEnrollment = totalEnrollmentStage[k].quantity;
          if (!calculateStudentsCostOfAssetsAndServices) {
            totalEnrollmentNocturnal = totalEnrollmentStage[k].quantityNocturnal;
          } else {
            totalEnrollmentNocturnal = 0;
          }
          // totalEnrollmentIntegral = (totalEnrollment - totalEnrollmentNocturnal) * (offerGoalEnrollmentIntegralByStage / 100);
          totalEnrollmentIntegral = totalEnrollment * (offerGoalEnrollmentIntegralByStage / 100);
          totalEnrollmentPartial = (totalEnrollment - totalEnrollmentIntegral);
        }
      }

      const enrollment = shiftStudentCost_id === ShiftStudentCost.Partial ? totalEnrollmentPartial : totalEnrollmentIntegral;

      totalizersStudentsCostByYears.push(new TotalizerStudentCostByYear({
        year: simulationYear,
        cost: this.getStudentCost(studentsCostByYears, shiftStudentCost_id, enrollment, caqMonth)
      }));
    });

    return totalizersStudentsCostByYears;
  }

  private getStudentCost(studentsCostByYears: Array<StudentCostByYear>, shiftStudentCost_id: number, enrollment: number, caqMonth: boolean = false): number {

    let costTotal: number = 0;
    for (let i = 0; i < studentsCostByYears.length; i++) {
      costTotal += shiftStudentCost_id === ShiftStudentCost.Partial ? studentsCostByYears[i].costShiftPartial : studentsCostByYears[i].costShiftIntegral;
    }

    const studentCost = enrollment > 0 ? parseFloat((costTotal / enrollment).toFixed(2)) : 0;

    return caqMonth ? parseFloat((studentCost / 12).toFixed(2)) : studentCost;
  }

  private getTotalizersStudentsCostByYearTotal(studentsCostByStagesTotal: Array<StudentCostByStage>, enrollmentTotal: Array<Enrollment>, caqMonth: boolean = false): Array<TotalizerStudentCostByYear> {

    const totalizersStudentsCostByYearsTotal: Array<TotalizerStudentCostByYear> = new Array<TotalizerStudentCostByYear>();

    this.utilitiesService.getSimulationYears().map(simulationYear => {

      let costTotalYear: number = 0;
      for (let i = 0; i < studentsCostByStagesTotal.length; i++) {
        for (let j = 0; j < studentsCostByStagesTotal[i].studentsCostByYearTotal.length; j++) {
          if (studentsCostByStagesTotal[i].studentsCostByYearTotal[j].year === simulationYear) {
            costTotalYear += studentsCostByStagesTotal[i].studentsCostByYearTotal[j].costShiftPartial + studentsCostByStagesTotal[i].studentsCostByYearTotal[j].costShiftIntegral;
          }
        }
      }

      let totalEnrollmentYear: number = 0;
      for (let i = 0; i < enrollmentTotal.length; i++) {
        if (simulationYear === enrollmentTotal[i].year) {
          totalEnrollmentYear += enrollmentTotal[i].quantity;
        }
      }

      const cost: number = totalEnrollmentYear > 0 ? parseFloat((costTotalYear / totalEnrollmentYear).toFixed(2)) : 0;

      totalizersStudentsCostByYearsTotal.push(new TotalizerStudentCostByYear({
        year: simulationYear,
        cost: caqMonth ? parseFloat((cost / 12).toFixed(2)) : cost
      }));
    });

    return totalizersStudentsCostByYearsTotal;
  }

  private getSumStudentCost(totalizerStudentCost: TotalizerStudentCost, totalizerStudentCostOfAssetsAndServices: TotalizerStudentCost): TotalizerStudentCost {

    for (let i = 0; i < totalizerStudentCost.totalizersStudentsCostByStages.length; i++) {

      const totalizerStudentCostByLocation: Array<TotalizerStudentCostByLocation> = totalizerStudentCost.totalizersStudentsCostByStages[i].totalizersStudentsCostByLocations;
      for (let j = 0; j < totalizerStudentCostByLocation.length; j++) {

        const totalizersStudentsCostByShifts: Array<TotalizerStudentCostByShift> = totalizerStudentCostByLocation[j].totalizersStudentsCostByShifts;
        for (let k = 0; k < totalizersStudentsCostByShifts.length; k++) {

          const totalizersStudentsCostByYear: Array<TotalizerStudentCostByYear> = totalizersStudentsCostByShifts[k].totalizersStudentsCostByYear;
          for (let l = 0; l < totalizersStudentsCostByYear.length; l++) {

            totalizersStudentsCostByYear[l].cost += totalizerStudentCostOfAssetsAndServices.totalizersStudentsCostByStages[i].
              totalizersStudentsCostByLocations[j].totalizersStudentsCostByShifts[k].totalizersStudentsCostByYear[l].cost;
          }
        }
      }
    }

    for (let i = 0; i < totalizerStudentCost.totalizersStudentsCostByYearTotal.length; i++) {
      totalizerStudentCost.totalizersStudentsCostByYearTotal[i].cost += totalizerStudentCostOfAssetsAndServices.totalizersStudentsCostByYearTotal[i].cost;
    }

    return totalizerStudentCost;
  }
}
