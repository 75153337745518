import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { ItemCostService } from '../../../shared/services/calculate-student-cost/services/item-cost.service';
import { CalculateCostSharingService } from '../../../shared/services/calculate-cost-sharing/calculate-cost-sharing.service';
import { CalculateCostDriveService } from '../../../shared/services/calculate-cost-drive/calculate-cost-drive.service';
import { CalculateEmployeeEstimateService } from '../../../shared/services/calculate-employee-estimate/calculate-employee-estimate.service';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { CreateProjectionsService } from '../../../shared/services/create-projections/create-projections.service';
import { CalculateClassNumberService } from '../../../shared/services/calculate-class-number/calculate-class-number.service';
import { SessionService } from '../../../../shared/services/session/session.service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { EnrollmentAndClassAndClassroom } from '../entities/enrollment-and-class-and-classroom';
import { FinancialDatas } from '../entities/financial-datas';
import { FinancialDataTypeEnum } from '../../../../shared/entities/enums/financial-data-type.enum';
import { NumberStudentClass } from '../../../quality-conditions/number-student-class/entities/number-student-class';
import { OfferGoalEnrollmentFullTime } from '../../../quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { FinancingFederatedEntitiesReport } from '../entities/financing_federated-entities-report';
import { SchoolByCity } from '../entities/school_by_city';
import { School } from '../entities/school';
import { SchoolToBeBuiltByLocation } from './../../../shared/services/calculate-class-number/entities/school-to-be-built-by-location';
import { ClassroomExistingByLocation } from './../../../quality-conditions/new-room-building/entities/classroom-existing-by-location';
import { ClassroomExistingByCity } from './../../../quality-conditions/new-room-building/entities/classroom-existing-by-city';
import { DemandClassRoom } from './../../../quality-conditions/new-room-building/entities/demand-class-room';
import { BudgetForecastService } from './../../shared/services/budget-forecast.service';
import { FinancingsFederatedsEntitiesReport } from './../entities/financings_federateds-entities-report';
import { EnrollmentProjection } from './../../../access-and-offer/enrollment-projection/entities/enrollment-projection';
import { Csv } from 'app/shared/services/csv/entities/csv';
import { CreateTeacherNumberCalcService } from './../../../shared/services/create-teacher-number-calc/create-teacher-number-calc.service';
import { WorkJourneyTeacher } from './../../../quality-conditions/work-journey-teacher/entities/work-journey-teacher';
import { CareerAndRemunerationTeachers } from './../../../quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { DailyTeachingLoad } from './../../../quality-conditions/daily-teaching-load/entities/daily-teaching-load';
import { SchoolDayPerWeek } from './../../../quality-conditions/school-day-per-week/entities/school-day-per-week';
import { CsvService } from './../../../../shared/services/csv/csv.service';
import { CsvHeader } from './../../../../shared/services/csv/entities/csv-header';
import { DemandClassRoomLocation } from './../../../quality-conditions/new-room-building/entities/demand-class-room-location';
import { Location } from './../../../../shared/entities/location';
import { DemandsClassroomsEnum } from './../../../quality-conditions/new-room-building/entities/enums/demandsClassrooms.enum';
import { AdmDependencyEnum } from 'app/shared/entities/enums/adm-dependency.enum';
import { SphereAdmEnum } from './../../../../shared/entities/enums/sphere-adm.enum';
import { State } from './../../../../shared/entities/state';
import { Stage } from '../../../../shared/entities/stage';
import { SimulationType } from '../../../simulator/entities/enums/simulation-type.enum';
import { EnrollmentProjectionByLocation } from './../../../access-and-offer/enrollment-projection/entities/enrollment-projection-by-location';
import { StageEnrollments } from 'app/simulator/access-and-offer/enrollment-projection/entities/stage-enrollments';
import { StageEnum } from 'app/shared/entities/enums/stage.enum';
import { SerieEnum } from 'app/shared/entities/enums/serie.enum';
import { FormationLevelEnum } from 'app/shared/entities/enums/formation-level.enum';
import { EmployeeEstimate } from 'app/simulator/shared/services/calculate-employee-estimate/entities/employee-estimate';
import { ClassNumber } from 'app/simulator/shared/services/calculate-class-number/entities/class-number';
import { HigherDemandClassroom } from 'app/simulator/shared/services/calculate-class-number/entities/higher-demand-classroom';
import { PercentageTeacherCareer } from '../entities/percentage-teacher-career';
import { DatasReport } from '../entities/datas-report';
import { CalculateTeacherNumberService } from 'app/simulator/shared/services/calculate-teacher-number/calculate-teacher-number.service';
import { EnrollmentBySchool } from 'app/simulator/access-and-offer/enrollment-by-stage-series-by-school/entities/enrollment-by-school';
import { LocationEnum } from 'app/shared/entities/enums/location.enum';
import { SelectLocation } from 'app/simulator/select-location/entities/select-location';
import { RegionEnum } from 'app/shared/entities/enums/region.enum';
import { SimulatorService } from 'app/simulator/simulator/services/simulator.service';
import { EntityEnum } from 'app/shared/entities/enums/entity.enum';

@Injectable({
  providedIn: 'root'
})
export class FinancingFederatedEntitiesReportService {

  constructor(
    private utilitiesService: UtilitiesService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private calculateClassNumberService: CalculateClassNumberService,
    private createProjectionsService: CreateProjectionsService,
    private currentYearService: CurrentYearService,
    private calculateEmployeeEstimateService: CalculateEmployeeEstimateService,
    private calculateCostDriveService: CalculateCostDriveService,
    private calculateCostSharingService: CalculateCostSharingService,
    private itemCostService: ItemCostService,
    private budgetForecastService: BudgetForecastService,
    private csvService: CsvService,
    private createTeacherNumberCalcService: CreateTeacherNumberCalcService,
    private calculateTeacherNumberService: CalculateTeacherNumberService,
  ) { }

  getFinancingFederatedEntitiesReport(): Observable<FinancingsFederatedsEntitiesReport> {
    const simulationType: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);
    let sphereAdm: number;
    const observables: Array<Observable<any>> = new Array<Observable<any>>();
    const observables2: Array<Observable<any>> = new Array<Observable<any>>();
    const financialCurrentYear: number = this.currentYearService.getFinancialCurrentYear();
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const simulationYearInitial: number = _.first(this.utilitiesService.getSimulationYears());
    const data: FinancingsFederatedsEntitiesReport = new FinancingsFederatedsEntitiesReport({ financingsFederatedsEntitiesReport: new Array<FinancingFederatedEntitiesReport>() });
    const resultForEnrollmentSchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);

    let locations: Array<Location>;
    let stages: Array<Stage>;
    let financialDatas: Array<FinancialDatas>;
    let states: Array<State>;
    let enrollmentsAndClassesAndClassroomsBySchool: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool1: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool2: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool3: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool4: Array<EnrollmentAndClassAndClassroom>;
    let enrollmentsAndClassesAndClassroomsBySchool5: Array<EnrollmentAndClassAndClassroom>;
    let demandConstruction: Array<DemandClassRoom>;
    let schools: Array<School>;

    if (simulationType === SimulationType.financingFederatedEntitiesByStateSphereAdm) {
      sphereAdm = AdmDependencyEnum.State;
    } else if (simulationType === SimulationType.financingFederatedEntitiesByCitySphereAdm) {
      sphereAdm = AdmDependencyEnum.Municipal;
    } else if (simulationType === SimulationType.financingFederatedEntitiesGroupByCityOrState) {
      if (resultForSelectLocation.selectedCity) {
        sphereAdm = AdmDependencyEnum.Municipal;
      }
    }

    observables.push(this.utilitiesService.getLocations().pipe(
      map(resultLocations => locations = resultLocations)));

    observables.push(this.utilitiesService.getStages().pipe(
      map(resultStages => stages = resultStages)));

    observables.push(this.getClassBySchool().pipe(
      map(resultSchools => schools = resultSchools)));

    observables.push(this.utilitiesService.getDemandConstruction().pipe(
      map(resultDemandConstruction => demandConstruction = resultDemandConstruction)));

    if (resultForSelectLocation.selectedCity) {
      enrollmentsAndClassesAndClassroomsBySchool = this.getEnrollmentAndClassAndClassroomSchool(resultForEnrollmentSchool);
    } else if (resultForSelectLocation.selectedState) {
      observables.push(this.getEnrollmentAndClassAndClassroomStateOrRegion().pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool = resultEnrollmentAndClassAndClassroom)));
    } else { // Brasil por região

      observables.push(this.getEnrollmentAndClassAndClassroomStateOrRegion(RegionEnum.norte).pipe(

        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool1 = resultEnrollmentAndClassAndClassroom)));

      observables.push(this.getEnrollmentAndClassAndClassroomStateOrRegion(RegionEnum.nordeste).pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool2 = resultEnrollmentAndClassAndClassroom)));

      observables.push(this.getEnrollmentAndClassAndClassroomStateOrRegion(RegionEnum.centroOeste).pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool3 = resultEnrollmentAndClassAndClassroom)));

      observables.push(this.getEnrollmentAndClassAndClassroomStateOrRegion(RegionEnum.sudeste).pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool4 = resultEnrollmentAndClassAndClassroom)));


      observables.push(this.getEnrollmentAndClassAndClassroomStateOrRegion(RegionEnum.sul).pipe(
        map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool5 = resultEnrollmentAndClassAndClassroom)));

    }

    /*observables.push(this.getEnrollmentAndClassAndClassroom().pipe(
      map(resultEnrollmentAndClassAndClassroom => enrollmentsAndClassesAndClassroomsBySchool = resultEnrollmentAndClassAndClassroom)));*/

    observables.push(this.getFinancialDatas(true, sphereAdm).pipe(
      map(resultFinancialDatas => financialDatas = resultFinancialDatas)));

    observables.push(this.utilitiesService.getStates().pipe(
      map(resultStates => states = resultStates)));

    /*if (sphereAdm === AdmDependencyEnum.State) {
      observables.push(this.utilitiesService.getStates().pipe(
        map(resultStates => this.setDataStates(resultStates, data))));
    }*/

    return forkJoin(observables).pipe(
      mergeMap((t) => {

        if (!resultForSelectLocation.selectedCity && !resultForSelectLocation.selectedState) {
          // AGRUPA O RESULTADO EM UM ÚNICO ARRAY

          //para testar brasil descomentar aqui
          ////enrollmentsAndClassesAndClassroomsBySchool = enrollmentsAndClassesAndClassroomsBySchool5,

          enrollmentsAndClassesAndClassroomsBySchool = [...enrollmentsAndClassesAndClassroomsBySchool1,
          ...enrollmentsAndClassesAndClassroomsBySchool2,
          ...enrollmentsAndClassesAndClassroomsBySchool3,
          ...enrollmentsAndClassesAndClassroomsBySchool4,
          ...enrollmentsAndClassesAndClassroomsBySchool5];

          // LIMPA OS ARRAYS
          enrollmentsAndClassesAndClassroomsBySchool1 = [];
          enrollmentsAndClassesAndClassroomsBySchool2 = [];
          enrollmentsAndClassesAndClassroomsBySchool3 = [];
          enrollmentsAndClassesAndClassroomsBySchool4 = [];
          enrollmentsAndClassesAndClassroomsBySchool5 = [];
        }

        /*if (sphereAdm === AdmDependencyEnum.State) {
          this.setFinancialDatasStates(data, financialDatas);
        }*/

        this.setQuantityDemandClassRoomLocation(locations, demandConstruction);

        // enrollmentsAndClassesAndClassroomsBySchool = this.getEnrollmentAndClassAndClassroomSchool(resultForEnrollmentSchool);

        for (let i = 0; i < enrollmentsAndClassesAndClassroomsBySchool.length; i++) {

          let calculatedClassNumber, calculatedTeacherNumber;
          const enrollmentsAndClasseAndClassroomBySchool = enrollmentsAndClassesAndClassroomsBySchool[i];
          const cityId = enrollmentsAndClasseAndClassroomBySchool.city_id;
          const cityDescription = enrollmentsAndClasseAndClassroomBySchool.city_description;
          const schoolId = enrollmentsAndClasseAndClassroomBySchool.school_id;
          const schoolDescription = enrollmentsAndClasseAndClassroomBySchool.school_description;
          const admDependencyId = enrollmentsAndClasseAndClassroomBySchool.adm_dependency_id;
          const admDependencyDescription = enrollmentsAndClasseAndClassroomBySchool.adm_dependency_name;
          const stateId = enrollmentsAndClasseAndClassroomBySchool.state_id;
          const stateDescription = enrollmentsAndClasseAndClassroomBySchool.state_description;
          const enrollmentProjection = enrollmentsAndClasseAndClassroomBySchool.enrollmentProjection;
          const enrollmentTotal: number = this.getEnrollmentTotalBySchool(enrollmentProjection);
          const enrollmentSchoolCity = this.getEnrollmentSchool(cityId, schoolId, enrollmentsAndClasseAndClassroomBySchool.enrollmentProjection.enrollmentsProjectionsByLocations);

          if (enrollmentSchoolCity && enrollmentsAndClasseAndClassroomBySchool.hasEnrollment) {

            const financingFederatedEntityReport: FinancingFederatedEntitiesReport = new FinancingFederatedEntitiesReport();
            const editMode: boolean = this.sessionService.getItem<boolean>(SimulatorService.simulationEditModeSessionKey);
            const percentageTeacherCareer = editMode === true ? undefined : enrollmentsAndClasseAndClassroomBySchool.percentageTeacherCareer;

            financingFederatedEntityReport.financialYear = financialCurrentYear;
            financingFederatedEntityReport.enrollmentYear = enrollmentCurrentYear;
            financingFederatedEntityReport.simulationYearInitial = simulationYearInitial;

            // let schoolsToBeBuilt: Array<SchoolToBeBuiltByLocation> = undefined;
            // const demandClassRoom: DemandClassRoom = _.find(demandConstruction, dC => dC.id === DemandsClassroomsEnum.ClassroomsExisting);
            // const classroomExisting = this.getClassroomExisting(demandClassRoom, enrollmentsAndClasseAndClassroomBySchool);
            // schoolsToBeBuilt = this.calculateClassNumberService.getSchoolsToBeBuilt(enrollmentsAndClasseAndClassroomBySchool.classNumber.classesNumberByLocations, classroomExisting);
            const calculatedEmployeeEstimate = this.calculateEmployeeEstimateService.calculateEmployeeEstimateByEntity(locations, enrollmentSchoolCity, undefined);

            observables2.push(
              this.calculateClassNumberService.calculateClassNumber(locations, stages, enrollmentsAndClasseAndClassroomBySchool).pipe(
                mergeMap(resultCalcClassNumber => {
                  calculatedClassNumber = resultCalcClassNumber;
                  enrollmentsAndClasseAndClassroomBySchool.classNumber = calculatedClassNumber;
                  return this.calculateTeacherNumberService.calculateTeacherNumber(calculatedClassNumber, true, percentageTeacherCareer).pipe(
                    mergeMap(resultCalcTeacherNumber => {
                      calculatedTeacherNumber = resultCalcTeacherNumber;
                      enrollmentsAndClasseAndClassroomBySchool.teacherNumber = calculatedTeacherNumber;
                      return this.calculateCostSharingService.calculateCostSharing(locations, calculatedEmployeeEstimate).pipe(
                        mergeMap(calculateCostSharing => {
                          return this.calculateCostDriveService.calculateCostDrive(enrollmentProjection).pipe(
                            mergeMap(calculateCostDrive => {
                              const classroomExistingSchool = this.getClassroomExistingSchool(enrollmentProjection);
                              return this.calculateClassNumberService.calculateClassroomNumber(locations, stages, undefined, classroomExistingSchool,
                                enrollmentsAndClasseAndClassroomBySchool).pipe(
                                  mergeMap(higherDemandClassroom => {
                                    return this.itemCostService.calculateItemCost(locations, stages, calculatedTeacherNumber, calculatedEmployeeEstimate, calculateCostSharing, calculateCostDrive,
                                      enrollmentProjection, false, true, false, true, true, undefined, false,
                                      percentageTeacherCareer)
                              /*return this.itemCostService.calculateItemCost(locations, stages, calculatedTeacherNumber, calculatedEmployeeEstimate, calculateCostSharing,
                                calculateCostDrive, enrollmentProjection, true, true, false, true, true, undefined, true)*/.pipe(
                                        mergeMap(itemCostTotal => {
                                          return this.budgetForecastService.getExpense(itemCostTotal).pipe(
                                            map(expense => {

                                              /* let potential: number;
                                               let expensesIncurred: number;
                                               let unionComplementation: number;
                                               let percentComplementation: number;
 
                                               let financialData: FinancialDatas;
                                               if (sphereAdm === AdmDependencyEnum.Municipal) {
                                                 financialData = this.getFinancialDataByCity(cityId, financialDatas);
                                               } else {
                                                 financialData = this.getFinancialDataByState(stateId, financialDatas);
                                               }
 
                                               potential = financialData ? financialData.potential : 0;
                                               expensesIncurred = financialData ? financialData.expensesIncurred : 0;
 
                                               unionComplementation = expense.current > potential ?
                                                 expense.current - potential : 0;
 
                                               if (potential > 0) {
                                                 percentComplementation = unionComplementation > 0 ?
                                                   (((expense.current) / potential) - 1) * 100 : 0;
                                               } else {
                                                 percentComplementation = 100;
                                               }*/

                                              const resultCity: FinancingFederatedEntitiesReport = new FinancingFederatedEntitiesReport({
                                                entityId: cityId,
                                                entityDescription: cityDescription,
                                                schoolId: schoolId,
                                                schoolDescription: schoolDescription,
                                                admDependencyId: admDependencyId,
                                                admDependencyDescription: admDependencyDescription,
                                                stateId: stateId,
                                                stateDescription: stateDescription,
                                                locationId: enrollmentProjection.enrollmentsProjectionsByLocations[0].id,
                                                location: enrollmentProjection.enrollmentsProjectionsByLocations[0].description,
                                                enrollmentTotal: enrollmentTotal,
                                                caq: this.utilitiesService.roundNumber((expense.current / enrollmentTotal), 2),
                                                potential: undefined,
                                                expensesIncurred: undefined,
                                                currentExpenses: expense.current,
                                                unionComplementation: undefined,
                                                percentUnionComplementation: undefined,
                                                datasByStage: this.getDatasByStage(enrollmentProjection),
                                                percentageTeacherCareer: enrollmentsAndClasseAndClassroomBySchool.percentageTeacherCareer,
                                                totalTeacherNumber: this.getTotalTeacherNumber(enrollmentsAndClasseAndClassroomBySchool),
                                                totalEmployeeNumber: this.getTotalEmployeeNumber(calculatedEmployeeEstimate),
                                                totalClassNumberExisting: this.getClassNumberSchool(schoolId, schools),
                                                totalClassNumberNeeded: this.getTotalClassNumberNeeded(enrollmentsAndClasseAndClassroomBySchool.classNumber),
                                                totalClassroomNumberExisting: this.getTotalClassroomNumberExisting(higherDemandClassroom),
                                                totalClassroomNumberNeeded: this.getTotalClassroomNumberNeeded(higherDemandClassroom)
                                              });

                                              resultCity.datasReport = this.getDatasReport(resultCity.datasByStage, resultCity.percentageTeacherCareer);

                                              /*if (sphereAdm === AdmDependencyEnum.State) {
                                                this.setResultStates(resultCity, data);
                                              }*/
                                              return resultCity;
                                            }));
                                        }));
                                  }));
                            }));
                        }));
                    }));
                })));
          }
        }

        return forkJoin(observables2).pipe(
          map(result => {
            ////if (sphereAdm === AdmDependencyEnum.Municipal && simulationType !== SimulationType.financingFederatedEntitiesGroupByCityOrState) {
            if (simulationType !== SimulationType.financingFederatedEntitiesGroupByCityOrState) {
              for (let i = 0; i < result.length; i++) {
                data.financingsFederatedsEntitiesReport.push(result[i]);
              }
            } else {
              const resultGroup = this.getResultByEntity(result, stages, states, financialDatas);

              //console.log("resultGroup", resultGroup);

              const resultTotalGroup = this.getResultTotalByEntity(resultGroup, stages);

              //console.log("resultTotalGroup", resultTotalGroup);

              for (let i = 0; i < resultGroup.length; i++) {
                resultGroup[i].datasReport = this.getDatasReport(resultGroup[i].datasByStage);
                data.financingsFederatedsEntitiesReport.push(resultGroup[i]);
              }

              for (let i = 0; i < resultTotalGroup.length; i++) {
                resultTotalGroup[i].datasReport = this.getDatasReportTotal(resultTotalGroup[i].datasByStage);
                data.financingsFederatedsEntitiesReport.push(resultTotalGroup[i]);
              }

            }
            return data;
          }));
      }));
  }

  getEnrollmentAndClassAndClassroom(filterRegion: number = undefined): Observable<Array<EnrollmentAndClassAndClassroom>> {
    const resultForOffergoalenrollmentfulltime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.pqrKey);
    const resultForNumberstudentclass: NumberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.key);
    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();
    const resultForSchoolDayPerWeek: SchoolDayPerWeek = this.sessionService.getItem<SchoolDayPerWeek>(Functionality.schoolDayPerWeek.key);
    const resultForDailyTeachingLoad: DailyTeachingLoad = this.sessionService.getItem<DailyTeachingLoad>(Functionality.dailyTeachingLoad.key);
    const resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.pqrKey);
    const resultForWorkJourneyTeacher: WorkJourneyTeacher = this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.pqrKey);

    return this.utilitiesService.getClassAndClassroomsAndEnrollment(resultForNumberstudentclass, resultForOffergoalenrollmentfulltime, resultForSchoolDayPerWeek, resultForDailyTeachingLoad,
      resultForCareerAndRemunerationTeachers, resultForWorkJourneyTeacher, false, true, filterRegion).pipe(
        map(classAndClassroomNumberAndEnrollment => {

          for (let i = 0; i < classAndClassroomNumberAndEnrollment.length; i++) {

            const classAndClassroomNumberAndEnrollmentByLocations = classAndClassroomNumberAndEnrollment[i].locations;

            const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
              state_id: classAndClassroomNumberAndEnrollment[i].state_id,
              state_description: classAndClassroomNumberAndEnrollment[i].state_name,
              city_id: classAndClassroomNumberAndEnrollment[i].city_id,
              city_description: classAndClassroomNumberAndEnrollment[i].city_name,
              school_id: classAndClassroomNumberAndEnrollment[i].school_id,
              school_description: classAndClassroomNumberAndEnrollment[i].school_name,
              adm_dependency_id: classAndClassroomNumberAndEnrollment[i].adm_dependency_id,
              adm_dependency_name: classAndClassroomNumberAndEnrollment[i].adm_dependency_name,
              enrollmentProjection: this.createProjectionsService.getEnrollmentProjection(classAndClassroomNumberAndEnrollment[i]),
              classNumber: this.calculateClassNumberService.getClassNumberCalculated(classAndClassroomNumberAndEnrollmentByLocations),
              teacherNumber: this.createTeacherNumberCalcService.getTeacherNumberCalculated(classAndClassroomNumberAndEnrollmentByLocations),
              hasEnrollment: this.existEnrollment(classAndClassroomNumberAndEnrollmentByLocations),
              percentageTeacherCareer: this.createTeacherNumberCalcService.getPercentageTeacherCareer(classAndClassroomNumberAndEnrollment[i])
            });

            enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);
          }
          return enrollmentAndClassAndClassrooms;
        }));
  }

  getFinancialDatas(dimscity: boolean = false, sphereAdm: number): Observable<Array<FinancialDatas>> {

    const offerYearFinancial: number = this.currentYearService.getFinancialCurrentYear();
    const filtersFinancialDataType: Array<string> = new Array<string>(`"${FinancialDataTypeEnum.potentialRevenue}"`, `"${FinancialDataTypeEnum.expenseMDE}"`);
    const financialsDatas: Array<FinancialDatas> = new Array<FinancialDatas>();
    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();

    if (sphereAdm === AdmDependencyEnum.Municipal) {
      sphereAdm = SphereAdmEnum.municipal;
    }

    return this.utilitiesService.getFinancialsDatas(offerYearFinancial, filtersFinancialDataType, filtersLocation, dimscity, sphereAdm).pipe(
      map(resultFinancialsDatas => {

        let financialData: FinancialDatas = new FinancialDatas();

        for (let i = 0; i < resultFinancialsDatas.length; i++) {

          if (resultFinancialsDatas[i].sphereAdmId === SphereAdmEnum.municipal) {
            financialData = _.find(financialsDatas, fCity => fCity.cityId === resultFinancialsDatas[i].cityId);
          } else if (resultFinancialsDatas[i].sphereAdmId === SphereAdmEnum.state) {
            financialData = _.find(financialsDatas, fState => fState.stateId === resultFinancialsDatas[i].stateId);
          }

          if (financialData === undefined) {
            financialData = new FinancialDatas({ stateId: resultFinancialsDatas[i].stateId, cityId: resultFinancialsDatas[i].cityId, sphereAdmId: resultFinancialsDatas[i].sphereAdmId });
            financialsDatas.push(financialData);
          }

          if (resultFinancialsDatas[i].financialDataId === FinancialDataTypeEnum.potentialRevenue) {
            financialData.potential = resultFinancialsDatas[i].value;
          } else if (resultFinancialsDatas[i].financialDataId === FinancialDataTypeEnum.expenseMDE) {
            financialData.expensesIncurred = resultFinancialsDatas[i].value;
          }
        }
        return financialsDatas;
      }));
  }

  getEnrollmentTotalBySchool(enrollmentProjection: EnrollmentProjection): number {

    let enrollmentsTotal: number = 0;

    for (let i = 0; i < enrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {
      const enrollmentProjectionByLocation = enrollmentProjection.enrollmentsProjectionsByLocations[i];
      for (let j = 0; j < enrollmentProjectionByLocation.totalsEnrollmentProjection.length; j++) {
        enrollmentsTotal += enrollmentProjectionByLocation.totalsEnrollmentProjection[j].quantity;
      }

    }
    return enrollmentsTotal;
  }

  getDatasByStage(enrollmentProjection: EnrollmentProjection): Array<any> {

    const stageDatas: Array<any> = new Array<any>();

    for (let i = 0; i < enrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {
      const enrollmentProjectionByLocation = enrollmentProjection.enrollmentsProjectionsByLocations[i];
      for (let j = 0; j < enrollmentProjectionByLocation.stagesEnrollments.length; j++) {
        const enrollments = _.first(enrollmentProjectionByLocation.stagesEnrollments[j].totalEnrollments);
        let enrollmentsTotalBySerie: Array<any> = new Array<any>();

        if (enrollmentProjectionByLocation.stagesEnrollments[j].id === StageEnum.creche && enrollmentProjectionByLocation.stagesEnrollments[j].seriesEnrollments) {
          enrollmentsTotalBySerie = this.getEnrollmentTotalBySerie(enrollmentProjectionByLocation.stagesEnrollments[j]);
        }

        const data: any = {
          stageId: enrollmentProjectionByLocation.stagesEnrollments[j].id,
          stageDescription: enrollmentProjectionByLocation.stagesEnrollments[j].description,
          enrollmentsBySerie: enrollmentsTotalBySerie,
          enrollmentDay: enrollments.quantity - enrollments.quantityNocturnal,
          enrollmentNocturnal: enrollments.quantityNocturnal,
          integralPercentage: enrollmentProjectionByLocation.stagesEnrollments[j].integralPercentage,
          totalTeacherNumber: 0,
          totalEmployee: 0
        };

        stageDatas.push(data);
      }

    }
    return stageDatas;
  }

  getTotalEmployeeNumber(calculatedEmployeeEstimate: Array<EmployeeEstimate>): number {

    let totalEmployeeNumber = 0;

    for (let i = 0; i < calculatedEmployeeEstimate.length; i++) {

      const employeesEstimateByLocations = calculatedEmployeeEstimate[i].employeesEstimateByLocations;
      for (let j = 0; j < employeesEstimateByLocations.length; j++) {
        const employeesEstimateByRoles = employeesEstimateByLocations[j].employeesEstimateByRoles;

        for (let k = 0; k < employeesEstimateByRoles.length; k++) {

          totalEmployeeNumber += _.first(employeesEstimateByRoles[k].employeesEstimatesByYear).quantity;

        }
      }
    }
    return totalEmployeeNumber;
  }

  getTotalTeacherNumber(enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom): number {

    let totalTeacherNumber = 0;

    for (let i = 0; i < enrollmentAndClassAndClassroom.teacherNumber.length; i++) {

      const enrollmentAndClassAndClassroomByLocation = enrollmentAndClassAndClassroom.teacherNumber[i].teachersNumberByLocationsCalc;
      for (let j = 0; j < enrollmentAndClassAndClassroomByLocation.length; j++) {
        const enrollmentAndClassAndClassroomByStage = enrollmentAndClassAndClassroomByLocation[j].teachersNumberByStagesCalc;

        for (let k = 0; k < enrollmentAndClassAndClassroomByStage.length; k++) {

          const careerLevelByYear = _.first(enrollmentAndClassAndClassroomByStage[k].careerLevelsByYear);

          totalTeacherNumber += careerLevelByYear.teacherNumberShiftIntegral + careerLevelByYear.teacherNumberShiftPartial;

        }
      }
    }
    return totalTeacherNumber;
  }

  getEnrollmentTotalBySerie(stagesEnrollments: StageEnrollments): Array<any> {

    const serieDatas: Array<any> = new Array<any>();

    for (let i = 0; i < stagesEnrollments.seriesEnrollments.length; i++) {
      const enrollments = _.first(stagesEnrollments.seriesEnrollments[i].enrollments);

      const data: any = {
        serieId: stagesEnrollments.seriesEnrollments[i].id,
        serieDescription: stagesEnrollments.seriesEnrollments[i].description,
        enrollmentDay: enrollments.quantity - enrollments.quantityNocturnal,
        enrollmentNocturnal: enrollments.quantityNocturnal
      };

      serieDatas.push(data);
    }

    return serieDatas;
  }

  getEnrollmentSchoolByCity(city_id: number, enrollmentsSchoolsByCity: Array<SchoolByCity>): SchoolByCity {

    for (let i = 0; i < enrollmentsSchoolsByCity.length; i++) {
      if (enrollmentsSchoolsByCity[i].cityId === city_id) {
        return enrollmentsSchoolsByCity[i];
      }
    }
  }

  getFinancialDataByCity(city_id: number, financialsDatas: Array<FinancialDatas>): FinancialDatas {

    for (let i = 0; i < financialsDatas.length; i++) {
      if (financialsDatas[i].cityId === city_id) {
        return financialsDatas[i];
      }
    }
  }

  getFinancialDataByState(state_id: number, financialsDatas: Array<FinancialDatas>): FinancialDatas {

    for (let i = 0; i < financialsDatas.length; i++) {
      if (financialsDatas[i].stateId === state_id) {
        return financialsDatas[i];
      }
    }
  }

  downloadCsv(financingFederatedEntityReport: FinancingsFederatedsEntitiesReport): void {
    const simulationType: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);
    const header: Array<CsvHeader> = new Array<CsvHeader>();
    const data: Array<any> = new Array<any>();
    let csv = new Csv();

    if (simulationType !== SimulationType.financingFederatedEntitiesGroupByCityOrState) {

      header.push(new CsvHeader({ key: 'schoolId', label: 'Código escola' }));
      header.push(new CsvHeader({ key: 'schoolDescription', label: 'Escola' }));
      /*header.push(new CsvHeader({ key: `enrollmentDayCreche + ${SerieEnum.crecheMenorDeUmAno}`, label: 'Matrículas diurnas Creche Menor de 1 ano' }));
      header.push(new CsvHeader({ key: `enrollmentDayCreche + ${SerieEnum.crecheUmAno}`, label: 'Matrículas diurnas Creche 1 ano' }));
      header.push(new CsvHeader({ key: `enrollmentDayCreche + ${SerieEnum.crecheDoisAnos}`, label: 'Matrículas diurnas Creche 2 anos' }));
      header.push(new CsvHeader({ key: `enrollmentDayCreche + ${SerieEnum.crecheTresAnos}`, label: 'Matrículas diurnas Creche 3 anos' }));*/
      header.push(new CsvHeader({ key: `enrollmentDay + ${StageEnum.creche}`, label: 'Matrículas diurnas Creche' }));
      header.push(new CsvHeader({ key: `enrollmentDay + ${StageEnum.preEscola}`, label: 'Matrículas diurnas Pré-escola' }));
      header.push(new CsvHeader({ key: `enrollmentDay + ${StageEnum.ensinoFundamentalAnosIniciais}`, label: 'Matrículas diurnas Ens. Fundamental - Anos iniciais' }));
      header.push(new CsvHeader({ key: `enrollmentDay + ${StageEnum.ensinoFundamentalAnosFinais}`, label: 'Matrículas diurnas Ens. Fundamental - Anos finais' }));
      header.push(new CsvHeader({ key: `enrollmentDay + ${StageEnum.ensinoMedio}`, label: 'Matrículas diurnas Ensino Médio' }));
      header.push(new CsvHeader({ key: `enrollmentDay + ${StageEnum.eja}`, label: 'Matrículas diurnas EJA' }));
      header.push(new CsvHeader({ key: `enrollmentNight + ${StageEnum.ensinoFundamentalAnosIniciais}`, label: 'Matrículas noturnas Ens. Fundamental - Anos iniciais' }));
      header.push(new CsvHeader({ key: `enrollmentNight + ${StageEnum.ensinoFundamentalAnosFinais}`, label: 'Matrículas noturnas Ens. Fundamental - Anos finais' }));
      header.push(new CsvHeader({ key: `enrollmentNight + ${StageEnum.ensinoMedio}`, label: 'Matrículas noturnas Ensino Médio' }));
      header.push(new CsvHeader({ key: `enrollmentNight + ${StageEnum.eja}`, label: 'Matrículas noturnas EJA' }));
      header.push(new CsvHeader({ key: 'enrollmentTotal', label: 'Nº total de matrículas' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.creche}`, label: 'Percentual matrículas tempo integral Creche(%)' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.preEscola}`, label: 'Percentual matrículas tempo integral Pré-escola(%)' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.ensinoFundamentalAnosIniciais}`, label: 'Percentual matrículas tempo integral Ens. Fundamental - Anos iniciais(%)' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.ensinoFundamentalAnosFinais}`, label: 'Percentual matrículas tempo integral Ens. Fundamental - Anos finais(%)' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.ensinoMedio}`, label: 'Percentual matrículas tempo integral Ensino Médio(%)' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.eja}`, label: 'Percentual matrículas tempo integral EJA(%)' }));
      header.push(new CsvHeader({ key: 'totalTeacherNumber', label: 'Número total de docentes' }));
      header.push(new CsvHeader({ key: `percentageCareer + ${FormationLevelEnum.Superior}`, label: 'Percentual docentes com nível superior(%)' }));
      header.push(new CsvHeader({ key: `percentageCareer + ${FormationLevelEnum.Especializacao}`, label: 'Percentual docentes com especialização(%)' }));
      header.push(new CsvHeader({ key: `percentageCareer + ${FormationLevelEnum.Mestrado}`, label: 'Percentual docentes com mestrado(%)' }));
      header.push(new CsvHeader({ key: `percentageCareer + ${FormationLevelEnum.Doutorado}`, label: 'Percentual docentes com doutorado(%)' }));
      header.push(new CsvHeader({ key: 'totalEmployee', label: 'Número total de funcionários' }));
      header.push(new CsvHeader({ key: 'totalClassExisting', label: 'Total de turmas existentes' }));
      header.push(new CsvHeader({ key: 'totalClassNeeded', label: 'Total de turmas necessárias' }));
      header.push(new CsvHeader({ key: 'totalClassroomExisting', label: 'Total de salas existentes' }));
      header.push(new CsvHeader({ key: 'totalClassroomNeeded', label: 'Total de salas necessárias' }));
      header.push(new CsvHeader({ key: 'currentExpenses', label: 'Despesa corrente necessária' }));
      header.push(new CsvHeader({ key: 'caq', label: 'CAQ' }));

      for (const financingFederatedEntity of financingFederatedEntityReport.financingsFederatedsEntitiesReport) {

        const financingFederatedEntityData: any = {
          schoolId: financingFederatedEntity.schoolId,
          schoolDescription: financingFederatedEntity.schoolDescription,
          enrollmentTotal: financingFederatedEntity.enrollmentTotal,
          currentExpenses: financingFederatedEntity.currentExpenses,
          caq: financingFederatedEntity.caq / 12,
          totalTeacherNumber: this.utilitiesService.roundNumber(financingFederatedEntity.totalTeacherNumber, 0),
          totalEmployee: this.utilitiesService.roundNumber(financingFederatedEntity.totalEmployeeNumber, 0),
          totalClassExisting: this.utilitiesService.roundNumber(financingFederatedEntity.totalClassNumberExisting, 0),
          totalClassNeeded: this.utilitiesService.roundNumber(financingFederatedEntity.totalClassNumberNeeded, 0),
          totalClassroomExisting: this.utilitiesService.roundNumber(financingFederatedEntity.totalClassroomNumberExisting, 0),
          totalClassroomNeeded: this.utilitiesService.roundNumber(financingFederatedEntity.totalClassroomNumberNeeded, 0)
        };

        for (const dataByStage of financingFederatedEntity.datasByStage) {
          financingFederatedEntityData[`enrollmentDay + ${dataByStage.stageId}`] = dataByStage.enrollmentDay;
          financingFederatedEntityData[`enrollmentNight + ${dataByStage.stageId}`] = dataByStage.enrollmentNocturnal;
          financingFederatedEntityData[`integralPercentage + ${dataByStage.stageId}`] = this.utilitiesService.roundNumber(dataByStage.integralPercentage, 2);

          /*for (const dataBySerie of dataByStage.enrollmentsBySerie) {
            financingFederatedEntityData[`enrollmentDayCreche + ${dataBySerie.serieId}`] = dataBySerie.enrollmentDay;
          }*/
        }

        for (let i = 0; i < financingFederatedEntity.percentageTeacherCareer.length; i++) {
          financingFederatedEntityData[`percentageCareer + ${financingFederatedEntity.percentageTeacherCareer[i].formationLevelId}`] =
            this.utilitiesService.roundNumber(financingFederatedEntity.percentageTeacherCareer[i].percentage, 2);
        }

        data.push(financingFederatedEntityData);
      }

      csv = new Csv({
        header: header,
        data: data,
        name: `Financiamento dos entes federados pela esfera ${simulationType === SimulationType.financingFederatedEntitiesByStateSphereAdm ? 'estadual' : 'municipal'}.csv`
      });
    } else {

      header.push(new CsvHeader({ key: 'uf', label: 'UF' }));
      header.push(new CsvHeader({ key: 'entityId', label: 'Código' }));
      header.push(new CsvHeader({ key: 'entityDescription', label: 'Ente Federativo' }));
      header.push(new CsvHeader({ key: 'admDependency', label: 'Dependência Administrativa' }));
      header.push(new CsvHeader({ key: `schoolsNumber`, label: 'Número de escolas' }));
      header.push(new CsvHeader({ key: `enrollment + ${StageEnum.creche}`, label: 'Matrículas Creche' }));
      header.push(new CsvHeader({ key: `enrollment + ${StageEnum.preEscola}`, label: 'Matrículas Pré-escola' }));
      header.push(new CsvHeader({ key: `enrollment + ${StageEnum.ensinoFundamentalAnosIniciais}`, label: 'Matrículas Ens. Fundamental - Anos iniciais' }));
      header.push(new CsvHeader({ key: `enrollment + ${StageEnum.ensinoFundamentalAnosFinais}`, label: 'Matrículas Ens. Fundamental - Anos finais' }));
      header.push(new CsvHeader({ key: `enrollment + ${StageEnum.ensinoMedio}`, label: 'Matrículas Ensino Médio' }));
      header.push(new CsvHeader({ key: `enrollment + ${StageEnum.eja}`, label: 'Matrículas EJA' }));
      header.push(new CsvHeader({ key: 'enrollmentTotal', label: 'Nº total de matrículas' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.creche}`, label: 'Percentual matrículas tempo integral Creche(%)' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.preEscola}`, label: 'Percentual matrículas tempo integral Pré-escola(%)' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.ensinoFundamentalAnosIniciais}`, label: 'Percentual matrículas tempo integral Ens. Fundamental - Anos iniciais(%)' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.ensinoFundamentalAnosFinais}`, label: 'Percentual matrículas tempo integral Ens. Fundamental - Anos finais(%)' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.ensinoMedio}`, label: 'Percentual matrículas tempo integral Ensino Médio(%)' }));
      header.push(new CsvHeader({ key: `integralPercentage + ${StageEnum.eja}`, label: 'Percentual matrículas tempo integral EJA(%)' }));
      header.push(new CsvHeader({ key: `integralPercentageRuralSchools`, label: 'Percentual matrículas em escolas rurais(%)' }));
      header.push(new CsvHeader({ key: 'totalTeacherNumber', label: 'Número total de docentes(projetado)' }));
      header.push(new CsvHeader({ key: 'totalEmployee', label: 'Número total de funcionários(projetado)' }));
      header.push(new CsvHeader({ key: 'totalClassExisting', label: 'Total de turmas existentes' }));
      header.push(new CsvHeader({ key: 'totalClassNeeded', label: 'Total de turmas necessárias' }));
      header.push(new CsvHeader({ key: 'totalClassroomExisting', label: 'Total de salas existentes' }));
      header.push(new CsvHeader({ key: 'totalClassroomNeeded', label: 'Total de salas necessárias' }));
      header.push(new CsvHeader({ key: 'potential', label: 'Receita Potencial Mínima Vinculada à Educação Básica(R$)' }));
      header.push(new CsvHeader({ key: 'expensesIncurred', label: 'Despesa corrente realizada(R$)' }));
      header.push(new CsvHeader({ key: 'currentExpenses', label: 'Despesa corrente necessária(R$)' }));
      header.push(new CsvHeader({ key: 'caqAno', label: 'CAQ/ano(R$)' }));
      header.push(new CsvHeader({ key: 'caqMes', label: 'CAQ/mês(R$)' }));
      header.push(new CsvHeader({ key: 'complementation', label: 'Complementação(R$)' }));
      header.push(new CsvHeader({ key: 'complementationPercentage', label: 'Complementação(%)' }));
      header.push(new CsvHeader({ key: 'rural', label: 'Rural' }));
      header.push(new CsvHeader({ key: `integralEnrollment + ${StageEnum.creche}`, label: 'Matrículas tempo integral Creche' }));
      header.push(new CsvHeader({ key: `integralEnrollment + ${StageEnum.preEscola}`, label: 'Matrículas tempo integral Pré-escola' }));
      header.push(new CsvHeader({ key: `integralEnrollment + ${StageEnum.ensinoFundamentalAnosIniciais}`, label: 'Matrículas tempo integral Ens. Fundamental - Anos iniciais' }));
      header.push(new CsvHeader({ key: `integralEnrollment + ${StageEnum.ensinoFundamentalAnosFinais}`, label: 'Matrículas tempo integral Ens. Fundamental - Anos finais' }));
      header.push(new CsvHeader({ key: `integralEnrollment + ${StageEnum.ensinoMedio}`, label: 'Matrículas tempo integral Ensino Médio' }));
      header.push(new CsvHeader({ key: `integralEnrollment + ${StageEnum.eja}`, label: 'Matrículas tempo integral EJA' }));

      for (const financingFederatedEntity of financingFederatedEntityReport.financingsFederatedsEntitiesReport) {

        const financingFederatedEntityData: any = {
          uf: financingFederatedEntity.stateAbbreviation,
          entityId: financingFederatedEntity.entityId,
          entityDescription: financingFederatedEntity.entityDescription,
          admDependency: financingFederatedEntity.admDependencyDescription,
          schoolsNumber: financingFederatedEntity.totalSchools,
          [`enrollment + ${StageEnum.creche}`]: financingFederatedEntity.datasReport.creche,
          [`enrollment + ${StageEnum.preEscola}`]: financingFederatedEntity.datasReport.preEscola,
          [`enrollment + ${StageEnum.ensinoFundamentalAnosIniciais}`]: financingFederatedEntity.datasReport.EFAI,
          [`enrollment + ${StageEnum.ensinoFundamentalAnosFinais}`]: financingFederatedEntity.datasReport.EFAF,
          [`enrollment + ${StageEnum.ensinoMedio}`]: financingFederatedEntity.datasReport.EMED,
          [`enrollment + ${StageEnum.eja}`]: financingFederatedEntity.datasReport.EJA,
          enrollmentTotal: financingFederatedEntity.enrollmentTotal,
          [`integralPercentage + ${StageEnum.creche}`]: financingFederatedEntity.datasReport.crecheIntegralPercentageEnrollment,
          [`integralPercentage + ${StageEnum.preEscola}`]: financingFederatedEntity.datasReport.preEscolaIntegralPercentageEnrollment,
          [`integralPercentage + ${StageEnum.ensinoFundamentalAnosIniciais}`]: financingFederatedEntity.datasReport.EFAIIntegralPercentageEnrollment,
          [`integralPercentage + ${StageEnum.ensinoFundamentalAnosFinais}`]: financingFederatedEntity.datasReport.EFAFIntegralPercentageEnrollment,
          [`integralPercentage + ${StageEnum.ensinoMedio}`]: financingFederatedEntity.datasReport.EMEDIntegralPercentageEnrollment,
          [`integralPercentage + ${StageEnum.eja}`]: financingFederatedEntity.datasReport.EJAIntegralPercentageEnrollment,
          integralPercentageRuralSchools: financingFederatedEntity.percentageEnrollmentRural,
          totalTeacherNumber: this.utilitiesService.roundNumber(financingFederatedEntity.totalTeacherNumber, 0),
          totalEmployee: this.utilitiesService.roundNumber(financingFederatedEntity.totalEmployeeNumber, 0),
          totalClassExisting: this.utilitiesService.roundNumber(financingFederatedEntity.totalClassNumberExisting, 0),
          totalClassNeeded: this.utilitiesService.roundNumber(financingFederatedEntity.totalClassNumberNeeded, 0),
          totalClassroomExisting: this.utilitiesService.roundNumber(financingFederatedEntity.totalClassroomNumberExisting, 0),
          totalClassroomNeeded: this.utilitiesService.roundNumber(financingFederatedEntity.totalClassroomNumberNeeded, 0),
          potential: financingFederatedEntity.potential,
          expensesIncurred: financingFederatedEntity.expensesIncurred,
          currentExpenses: financingFederatedEntity.currentExpenses,
          caqAno: financingFederatedEntity.caq,
          caqMes: financingFederatedEntity.caq / 12,
          complementation: financingFederatedEntity.unionComplementation,
          complementationPercentage: (financingFederatedEntity.unionComplementation / financingFederatedEntity.potential),
          rural: financingFederatedEntity.rural,
          [`integralEnrollment + ${StageEnum.creche}`]: financingFederatedEntity.datasReport.crecheIntegralEnrollment / 100,
          [`integralEnrollment + ${StageEnum.preEscola}`]: financingFederatedEntity.datasReport.preEscolaIntegralEnrollment / 100,
          [`integralEnrollment + ${StageEnum.ensinoFundamentalAnosIniciais}`]: financingFederatedEntity.datasReport.EFAIIntegralEnrollment / 100,
          [`integralEnrollment + ${StageEnum.ensinoFundamentalAnosFinais}`]: financingFederatedEntity.datasReport.EFAFIntegralEnrollment / 100,
          [`integralEnrollment + ${StageEnum.ensinoMedio}`]: financingFederatedEntity.datasReport.EMEDIntegralEnrollment / 100,
          [`integralEnrollment + ${StageEnum.eja}`]: financingFederatedEntity.datasReport.EJAIntegralEnrollment / 100,
        };

        data.push(financingFederatedEntityData);
      }

      csv = new Csv({
        header: header,
        data: data,
        name: `Financiamento dos entes federados.csv`
      });

    }

    this.csvService.download(csv);
  }

  getClassroomExisting(demandClassRoom: DemandClassRoom, enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom): Array<ClassroomExistingByCity> {

    const classroomsExistingByCities: Array<ClassroomExistingByCity> = new Array<ClassroomExistingByCity>();
    const classroomsExistingByLocations: Array<ClassroomExistingByLocation> = new Array<ClassroomExistingByLocation>();

    for (let i = 0; i < demandClassRoom.quantityDemandClassRoomLocation.length; i++) {

      const quantityDemandClassRoom = demandClassRoom.quantityDemandClassRoomLocation[i];

      const higherDemandClassroomsByLocation = _.find(enrollmentAndClassAndClassroom.classNumber.higherDemandClassroom.higherDemandsClassroomsByLocations,
        hDemand => hDemand.location.id === quantityDemandClassRoom.location.id);

      classroomsExistingByLocations.push(new ClassroomExistingByLocation({
        location: quantityDemandClassRoom.location,
        quantityClassroomExisting: higherDemandClassroomsByLocation ? higherDemandClassroomsByLocation.demandClassroomExisting : 0
      }));
    }

    classroomsExistingByCities.push(new ClassroomExistingByCity({
      classroomExistingByLocations: classroomsExistingByLocations
    }));

    return classroomsExistingByCities;
  }

  isSimulationCity(): boolean {

    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    if (resultForSelectLocation.selectedCity) {
      return true;
    }
    return false;
  }

  private getEnrollmentSchool(city_id: number, school_id: string, enrollmentProjectionByLocation: Array<EnrollmentProjectionByLocation>): SchoolByCity {

    const schoolsByCity: SchoolByCity = new SchoolByCity({ cityId: city_id, schools: new Array<School>() });

    for (let i = 0; i < enrollmentProjectionByLocation.length; i++) {
      schoolsByCity.schools.push(new School({
        id: school_id,
        locationId: enrollmentProjectionByLocation[i].id,
        enrollmentQuantity: _.first(enrollmentProjectionByLocation[i].totalsEnrollmentProjection).quantity
      }));

    }

    return schoolsByCity;

  }

  private getTotalClassNumberNeeded(classNumber: ClassNumber): number {

    let totalClassNumberNeeded = 0;

    for (let i = 0; i < classNumber.classesNumberByLocations.length; i++) {
      const enrollmentAndClassAndClassroomByStages = classNumber.classesNumberByLocations[i].classesNumberByStages;
      for (let j = 0; j < enrollmentAndClassAndClassroomByStages.length; j++) {
        const enrollmentAndClassAndClassroomByYears = enrollmentAndClassAndClassroomByStages[j].classesNumberByYear;

        for (let k = 0; k < enrollmentAndClassAndClassroomByYears.length; k++) {

          const classNumberDaytimePartial = enrollmentAndClassAndClassroomByYears[k].classNumberDaytimePartial;
          const classNumberIntegral = enrollmentAndClassAndClassroomByYears[k].classNumberIntegral;
          const classNumberNocturnal = enrollmentAndClassAndClassroomByYears[k].classNumberNocturnal;

          totalClassNumberNeeded += classNumberDaytimePartial + classNumberIntegral + classNumberNocturnal;

        }
      }
    }
    if (totalClassNumberNeeded > 0 && totalClassNumberNeeded < 1) {
      totalClassNumberNeeded = 1;
    }
    return totalClassNumberNeeded;
  }

  private getTotalClassroomNumberNeeded(higherDemandClassroom: HigherDemandClassroom): number {
    let totalClassroomNumberNeeded = 0;

    for (let i = 0; i < higherDemandClassroom.higherDemandsClassroomsByLocations.length; i++) {
      const classroomNumberNeeded = higherDemandClassroom.higherDemandsClassroomsByLocations[i].demandClassroomRequired;
      totalClassroomNumberNeeded += classroomNumberNeeded;
    }
    if (totalClassroomNumberNeeded > 0 && totalClassroomNumberNeeded < 1) {
      totalClassroomNumberNeeded = 1;
    }
    return totalClassroomNumberNeeded;
  }

  private getTotalClassroomNumberExisting(higherDemandClassroom: HigherDemandClassroom): number {
    let totalClassroomNumberExisting = 0;

    for (let i = 0; i < higherDemandClassroom.higherDemandsClassroomsByLocations.length; i++) {
      const classroomNumberExisting = higherDemandClassroom.higherDemandsClassroomsByLocations[i].demandClassroomExisting;
      totalClassroomNumberExisting += classroomNumberExisting;
    }
    return totalClassroomNumberExisting;
  }

  private getClassBySchool(): Observable<Array<School>> {

    const classCurrentYear: number = this.currentYearService.getClassCurrentYear();
    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();
    const admDependency: string = this.utilitiesService.getAdmDependencyFilter();

    let filters: Array<string> = new Array<string>(
      `min_year:"${classCurrentYear}"`,
      `max_year:"${classCurrentYear}"`,
    );

    filters.push(admDependency);
    filters = filters.concat(filtersLocation);

    const options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['dims', 'school'],
      ['filter', filters.join(',')]
    ]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/class`, options).pipe(
          map(schools => schools.map(school => new School({ id: school.school_id, classNumber: school.total }))));
      }));
  }

  private getClassNumberSchool(schoolId: string, schools: Array<School>) {

    let classNumber: number;

    for (let i = 0; i < schools.length; i++) {
      if (schools[i].id === schoolId) {
        classNumber = schools[i].classNumber;
      }
    }

    return classNumber;
  }

  private getDatasReport(datasByStage: Array<any>, percentageTeacherCareer: Array<PercentageTeacherCareer> = undefined): DatasReport {

    const datasReport: DatasReport = new DatasReport({
      creche: undefined,
      crecheIntegral: undefined,
      crecheIntegralPercentageEnrollment: undefined,
      preEscola: undefined,
      preEscolaIntegral: undefined,
      preEscolaIntegralPercentageEnrollment: undefined,
      EFAI: undefined,
      EFAIIntegral: undefined,
      EFAIIntegralPercentageEnrollment: undefined,
      EFAF: undefined,
      EFAFIntegral: undefined,
      EFAFIntegralPercentageEnrollment: undefined,
      EMED: undefined,
      EMEDIntegral: undefined,
      EMEDIntegralPercentageEnrollment: undefined,
      EJA: undefined,
      EJAIntegral: undefined,
      EJAIntegralPercentageEnrollment: undefined,

      crecheIntegralEnrollment: undefined,
      preEscolaIntegralEnrollment: undefined,
      EFAIIntegralEnrollment: undefined,
      EFAFIntegralEnrollment: undefined,
      EMEDIntegralEnrollment: undefined,
      EJAIntegralEnrollment: undefined
    });

    for (let i = 0; i < datasByStage.length; i++) {
      if (datasByStage[i].enrollmentsBySerie) {
        for (let j = 0; j < datasByStage[i].enrollmentsBySerie.length; j++) {
          if (datasByStage[i].stageId === StageEnum.creche) {
            if (datasByStage[i].enrollmentsBySerie[j].serieId === SerieEnum.crecheMenorDeUmAno) {
              datasReport.crecheMenorUmAnoDiurno = datasByStage[i].enrollmentsBySerie[j].enrollmentDay;
            } else if (datasByStage[i].enrollmentsBySerie[j].serieId === SerieEnum.crecheUmAno) {
              datasReport.crecheUmAnoDiurno = datasByStage[i].enrollmentsBySerie[j].enrollmentDay;
            } else if (datasByStage[i].enrollmentsBySerie[j].serieId === SerieEnum.crecheDoisAnos) {
              datasReport.crecheDoisAnosDiurno = datasByStage[i].enrollmentsBySerie[j].enrollmentDay;
            } else if (datasByStage[i].enrollmentsBySerie[j].serieId === SerieEnum.crecheTresAnos) {
              datasReport.crecheTresAnosDiurno = datasByStage[i].enrollmentsBySerie[j].enrollmentDay;
            }
          }
        }
      }

      if (datasByStage[i].stageId === StageEnum.creche) {
        datasReport.crecheIntegralPercentage = datasByStage[i].integralPercentage > 0 ? datasByStage[i].integralPercentage : undefined;
        datasReport.creche = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReport.crecheIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReport.crecheIntegralPercentageEnrollment = datasReport.creche > 0 && datasReport.crecheIntegral > 0 ? (datasReport.crecheIntegral / datasReport.creche) * 100 : undefined;
        datasReport.crecheIntegralEnrollment = (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal) * datasReport.crecheIntegralPercentageEnrollment;
      } else if (datasByStage[i].stageId === StageEnum.preEscola) {
        datasReport.preEscolaDiurno = datasByStage[i].enrollmentDay > 0 ? datasByStage[i].enrollmentDay : undefined;
        datasReport.preEscolaIntegralPercentage = datasByStage[i].integralPercentage > 0 ? datasByStage[i].integralPercentage : undefined;
        datasReport.preEscola = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReport.preEscolaIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReport.preEscolaIntegralPercentageEnrollment = datasReport.preEscola > 0 && datasReport.preEscolaIntegral > 0 ?
          (datasReport.preEscolaIntegral / datasReport.preEscola) * 100 : undefined;
        datasReport.preEscolaIntegralEnrollment = (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal) * datasReport.preEscolaIntegralPercentageEnrollment;
      } else if (datasByStage[i].stageId === StageEnum.ensinoFundamentalAnosIniciais) {
        datasReport.EFAIDiurno = datasByStage[i].enrollmentDay > 0 ? datasByStage[i].enrollmentDay : undefined;
        datasReport.EFAINoturno = datasByStage[i].enrollmentNocturnal > 0 ? datasByStage[i].enrollmentNocturnal : undefined;
        datasReport.EFAIIntegralPercentage = datasByStage[i].integralPercentage > 0 ? datasByStage[i].integralPercentage : undefined;
        datasReport.EFAI = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReport.EFAIIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReport.EFAIIntegralPercentageEnrollment = datasReport.EFAI > 0 && datasReport.EFAIIntegral > 0 ? (datasReport.EFAIIntegral / datasReport.EFAI) * 100 : undefined;
        datasReport.EFAIIntegralEnrollment = (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal) * datasReport.EFAIIntegralPercentageEnrollment;
      } else if (datasByStage[i].stageId === StageEnum.ensinoFundamentalAnosFinais) {
        datasReport.EFAFDiurno = datasByStage[i].enrollmentDay > 0 ? datasByStage[i].enrollmentDay : undefined;
        datasReport.EFAFNoturno = datasByStage[i].enrollmentNocturnal > 0 ? datasByStage[i].enrollmentNocturnal : undefined;
        datasReport.EFAFIntegralPercentage = datasByStage[i].integralPercentage > 0 ? datasByStage[i].integralPercentage : undefined;
        datasReport.EFAF = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReport.EFAFIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReport.EFAFIntegralPercentageEnrollment = datasReport.EFAF > 0 && datasReport.EFAFIntegral > 0 ? (datasReport.EFAFIntegral / datasReport.EFAF) * 100 : undefined;
        datasReport.EFAFIntegralEnrollment = (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal) * datasReport.EFAFIntegralPercentageEnrollment;
      } else if (datasByStage[i].stageId === StageEnum.ensinoMedio) {
        datasReport.EMDiurno = datasByStage[i].enrollmentDay > 0 ? datasByStage[i].enrollmentDay : undefined;
        datasReport.EMNoturno = datasByStage[i].enrollmentNocturnal > 0 ? datasByStage[i].enrollmentNocturnal : undefined;
        datasReport.EMIntegralPercentage = datasByStage[i].integralPercentage > 0 ? datasByStage[i].integralPercentage : undefined;
        datasReport.EMED = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReport.EMEDIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReport.EMEDIntegralPercentageEnrollment = datasReport.EMED > 0 && datasReport.EMEDIntegral > 0 ? (datasReport.EMEDIntegral / datasReport.EMED) * 100 : undefined;
        datasReport.EMEDIntegralEnrollment = (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal) * datasReport.EMEDIntegralPercentageEnrollment;
      } else if (datasByStage[i].stageId === StageEnum.eja) {
        datasReport.EJADiurno = datasByStage[i].enrollmentDay > 0 ? datasByStage[i].enrollmentDay : undefined;
        datasReport.EJANoturno = datasByStage[i].enrollmentNocturnal > 0 ? datasByStage[i].enrollmentNocturnal : undefined;
        datasReport.EJA = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReport.EJAIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReport.EJAIntegralPercentageEnrollment = datasReport.EJA > 0 && datasReport.EJAIntegral > 0 ? (datasReport.EJAIntegral / datasReport.EJA) * 100 : undefined;
        datasReport.EJAIntegralEnrollment = (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal) * datasReport.EJAIntegralPercentageEnrollment;
      }
    }

    if (percentageTeacherCareer) {
      for (let i = 0; i < percentageTeacherCareer.length; i++) {
        if (percentageTeacherCareer[i].formationLevelId === FormationLevelEnum.Superior) {
          datasReport.superiorPercentage = percentageTeacherCareer[i].percentage > 0 ? percentageTeacherCareer[i].percentage : undefined;
        } else if (percentageTeacherCareer[i].formationLevelId === FormationLevelEnum.Especializacao) {
          datasReport.especializacaoPercentage = percentageTeacherCareer[i].percentage > 0 ? percentageTeacherCareer[i].percentage : undefined;
        } else if (percentageTeacherCareer[i].formationLevelId === FormationLevelEnum.Mestrado) {
          datasReport.mestradoPercentage = percentageTeacherCareer[i].percentage > 0 ? percentageTeacherCareer[i].percentage : undefined;
        } else if (percentageTeacherCareer[i].formationLevelId === FormationLevelEnum.Doutorado) {
          datasReport.doutoradoPercentage = percentageTeacherCareer[i].percentage > 0 ? percentageTeacherCareer[i].percentage : undefined;
        }
      }
    }
    return datasReport;
  }

  private getDatasReportTotal(datasByStage: Array<any>, percentageTeacherCareer: Array<PercentageTeacherCareer> = undefined): DatasReport {

    const datasReportTotal: DatasReport = new DatasReport({
      creche: undefined,
      crecheIntegral: undefined,
      crecheIntegralPercentageEnrollment: undefined,
      preEscola: undefined,
      preEscolaIntegral: undefined,
      preEscolaIntegralPercentageEnrollment: undefined,
      EFAI: undefined,
      EFAIIntegral: undefined,
      EFAIIntegralPercentageEnrollment: undefined,
      EFAF: undefined,
      EFAFIntegral: undefined,
      EFAFIntegralPercentageEnrollment: undefined,
      EMED: undefined,
      EMEDIntegral: undefined,
      EMEDIntegralPercentageEnrollment: undefined,
      EJA: undefined,
      EJAIntegral: undefined,
      EJAIntegralPercentageEnrollment: undefined,

      crecheIntegralEnrollment: undefined,
      preEscolaIntegralEnrollment: undefined,
      EFAIIntegralEnrollment: undefined,
      EFAFIntegralEnrollment: undefined,
      EMEDIntegralEnrollment: undefined,
      EJAIntegralEnrollment: undefined
    });


    for (let i = 0; i < datasByStage.length; i++) {

      if (datasByStage[i].stageId === StageEnum.creche) {
        datasReportTotal.creche = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReportTotal.crecheIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReportTotal.crecheIntegralPercentageEnrollment = datasByStage[i].calculoIntegralEnrollment / (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal);
        datasReportTotal.crecheIntegralEnrollment = datasByStage[i].calculoIntegralEnrollment;
      } else if (datasByStage[i].stageId === StageEnum.preEscola) {
        datasReportTotal.preEscola = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReportTotal.preEscolaIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReportTotal.preEscolaIntegralPercentageEnrollment = datasByStage[i].calculoIntegralEnrollment / (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal);
        datasReportTotal.preEscolaIntegralEnrollment = datasByStage[i].calculoIntegralEnrollment;
      } else if (datasByStage[i].stageId === StageEnum.ensinoFundamentalAnosIniciais) {
        datasReportTotal.EFAI = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReportTotal.EFAIIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReportTotal.EFAIIntegralPercentageEnrollment = datasByStage[i].calculoIntegralEnrollment / (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal);
        datasReportTotal.EFAIIntegralEnrollment = datasByStage[i].calculoIntegralEnrollment;
      } else if (datasByStage[i].stageId === StageEnum.ensinoFundamentalAnosFinais) {
        datasReportTotal.EFAF = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReportTotal.EFAFIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReportTotal.EFAFIntegralPercentageEnrollment = datasByStage[i].calculoIntegralEnrollment / (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal);
        datasReportTotal.EFAFIntegralEnrollment = datasByStage[i].calculoIntegralEnrollment;
      } else if (datasByStage[i].stageId === StageEnum.ensinoMedio) {
        datasReportTotal.EMED = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReportTotal.EMEDIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReportTotal.EMEDIntegralPercentageEnrollment = datasByStage[i].calculoIntegralEnrollment / (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal);
        datasReportTotal.EMEDIntegralEnrollment = datasByStage[i].calculoIntegralEnrollment;
      } else if (datasByStage[i].stageId === StageEnum.eja) {
        datasReportTotal.EJA = datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal;
        datasReportTotal.EJAIntegral = datasByStage[i].enrollmentIntegralTotal;
        datasReportTotal.EJAIntegralPercentageEnrollment = datasByStage[i].calculoIntegralEnrollment / (datasByStage[i].enrollmentDay + datasByStage[i].enrollmentNocturnal);
        datasReportTotal.EJAIntegralEnrollment = datasByStage[i].calculoIntegralEnrollment;
      }

    }

    if (percentageTeacherCareer) {
      for (let i = 0; i < percentageTeacherCareer.length; i++) {
        if (percentageTeacherCareer[i].formationLevelId === FormationLevelEnum.Superior) {
          datasReportTotal.superiorPercentage = percentageTeacherCareer[i].percentage > 0 ? percentageTeacherCareer[i].percentage : undefined;
        } else if (percentageTeacherCareer[i].formationLevelId === FormationLevelEnum.Especializacao) {
          datasReportTotal.especializacaoPercentage = percentageTeacherCareer[i].percentage > 0 ? percentageTeacherCareer[i].percentage : undefined;
        } else if (percentageTeacherCareer[i].formationLevelId === FormationLevelEnum.Mestrado) {
          datasReportTotal.mestradoPercentage = percentageTeacherCareer[i].percentage > 0 ? percentageTeacherCareer[i].percentage : undefined;
        } else if (percentageTeacherCareer[i].formationLevelId === FormationLevelEnum.Doutorado) {
          datasReportTotal.doutoradoPercentage = percentageTeacherCareer[i].percentage > 0 ? percentageTeacherCareer[i].percentage : undefined;
        }
      }
    }
    ////console.log(datasReport)
    return datasReportTotal;
  }

  private getEnrollmentAndClassAndClassroomSchool(enrollmentBySchool: EnrollmentBySchool): Array<EnrollmentAndClassAndClassroom> {

    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();

    for (let i = 0; i < enrollmentBySchool.enrollmentByStageSeriesBySchool.length; i++) {

      const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
        state_id: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].state_id,
        state_description: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].state_name,
        city_id: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].city_id,
        city_description: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].city_name,
        school_id: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].school_id,
        school_description: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].school_name,
        adm_dependency_id: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].adm_dependency_id,
        adm_dependency_name: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].adm_dependency_name,
        enrollmentProjection: this.createProjectionsService.getEnrollmentProjectionBySchool(enrollmentBySchool.enrollmentByStageSeriesBySchool[i]),
        hasEnrollment: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].hasEnrollment,
        percentageTeacherCareer: enrollmentBySchool.enrollmentByStageSeriesBySchool[i].percentageTeacherCareer
      });

      enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);
    }

    return enrollmentAndClassAndClassrooms;
  }

  private getClassroomExistingSchool(enrollmentProjection: EnrollmentProjection): ClassroomExistingByCity {

    const classroomsExistingBySchool: Array<ClassroomExistingByCity> = new Array<ClassroomExistingByCity>();
    const classroomExisting: ClassroomExistingByCity = new ClassroomExistingByCity({
      classroomExistingByLocations:
        new Array<ClassroomExistingByLocation>()
    });

    for (let i = 0; i < enrollmentProjection.enrollmentsProjectionsByLocations.length; i++) {
      const enrollmentProjectionByLocation = enrollmentProjection.enrollmentsProjectionsByLocations[i];

      classroomExisting.classroomExistingByLocations.push(new ClassroomExistingByLocation({
        location:
          new Location({ id: enrollmentProjectionByLocation.id, description: enrollmentProjectionByLocation.description }),
        quantityClassroomExisting: enrollmentProjectionByLocation.totalExistingClassrooms
      }));

    }
    return classroomExisting;
  }

  private getResultByEntity(result: Array<FinancingFederatedEntitiesReport>, stages: Array<Stage>, states: Array<State>, financialsDatas: Array<FinancialDatas>):
    Array<FinancingFederatedEntitiesReport> {

    const resultsTotals: Array<FinancingFederatedEntitiesReport> = new Array<FinancingFederatedEntitiesReport>();
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const employeeCurrentYear: number = this.currentYearService.getEmployeeCurrentYear();
    const roomCurrentYear: number = this.currentYearService.getClassroomCurrentYear();
    const financialCurrentYear: number = this.currentYearService.getFinancialCurrentYear();
    const teacherCurrentYear: number = this.currentYearService.getTeacherCurrentYear();
    const schoolCurrentYear: number = this.currentYearService.getSchoolCurrentYear();

    let entityResult: FinancingFederatedEntitiesReport;
    let entityId: number;
    let entityDescription: string;
    let filtroResult: string = EntityEnum.Brazil;

    if (resultForSelectLocation.selectedCity === undefined && resultForSelectLocation.selectedState === undefined) {
      filtroResult = EntityEnum.Brazil;
    } else if (resultForSelectLocation.selectedCity) {
      filtroResult = EntityEnum.City;
    } else if (resultForSelectLocation.selectedState) {
      filtroResult = EntityEnum.State;
    }

    //console.log(result);

    for (let i = 0; i < result.length; i++) {

      if (result[i].admDependencyId === AdmDependencyEnum.Municipal) {
        entityResult = _.find(resultsTotals, rT => rT.admDependencyId === result[i].admDependencyId && rT.entityId === result[i].entityId);
        entityId = result[i].entityId;
        entityDescription = result[i].entityDescription;
      } else if (result[i].admDependencyId === AdmDependencyEnum.State) {
        entityResult = _.find(resultsTotals, rT => rT.admDependencyId === result[i].admDependencyId && rT.stateId === result[i].stateId);
        entityId = result[i].stateId;
        entityDescription = result[i].stateDescription;
      }

      if (!entityResult) {
        const financialDatasEntity = this.getFinancialDataByEntity(filtroResult, entityId, result[i].admDependencyId, financialsDatas);

        entityResult = new FinancingFederatedEntitiesReport({
          entityId: entityId,
          entityDescription: entityDescription,
          admDependencyId: result[i].admDependencyId,
          admDependencyDescription: result[i].admDependencyDescription,
          stateAbbreviation: this.getStateAbbreviation(states, result[i].stateId),
          stateId: result[i].stateId,
          stateDescription: result[i].stateDescription,
          enrollmentTotal: 0,
          caq: 0,
          potential: financialDatasEntity.potential,
          expensesIncurred: financialDatasEntity.expensesIncurred,
          currentExpenses: 0,
          unionComplementation: undefined,
          datasByStage: this.getDatasByStageEmpty(stages),
          percentageTeacherCareer: result[i].percentageTeacherCareer,
          totalTeacherNumber: 0,
          totalEmployeeNumber: 0,
          totalClassNumberExisting: 0,
          totalClassNumberNeeded: 0,
          totalClassroomNumberExisting: 0,
          totalClassroomNumberNeeded: 0,
          totalSchools: 0,
          enrollmentTotalRural: 0,
          numberEnrollmentCurrentYear: enrollmentCurrentYear,
          numberFinancialCurrentYear: financialCurrentYear,
          numberEmployeeCurrentYear: employeeCurrentYear,
          numberTeacherCurrentYear: teacherCurrentYear,
          numberRoomCurrentYear: roomCurrentYear,
          numberSchoolCurrentYear: schoolCurrentYear,
          rural: 0
        });
        this.setSumResults(entityResult, result[i]);
        resultsTotals.push(entityResult);
      } else {
        this.setSumResults(entityResult, result[i]);
      }
    }

    this.setOrdenarResult(resultsTotals);
    return resultsTotals;

  }

  private getResultTotalByEntity(result: Array<FinancingFederatedEntitiesReport>, stages: Array<Stage>):
    Array<FinancingFederatedEntitiesReport> {

    const resultsTotals: Array<FinancingFederatedEntitiesReport> = new Array<FinancingFederatedEntitiesReport>();
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const employeeCurrentYear: number = this.currentYearService.getEmployeeCurrentYear();
    const roomCurrentYear: number = this.currentYearService.getClassroomCurrentYear();
    const financialCurrentYear: number = this.currentYearService.getFinancialCurrentYear();
    const teacherCurrentYear: number = this.currentYearService.getTeacherCurrentYear();
    const schoolCurrentYear: number = this.currentYearService.getSchoolCurrentYear();

    let entityResultTotal: FinancingFederatedEntitiesReport;
    let filtroResult: string = EntityEnum.Brazil;

    if (resultForSelectLocation.selectedCity === undefined && resultForSelectLocation.selectedState === undefined) {
      filtroResult = EntityEnum.Brazil;
    } else if (resultForSelectLocation.selectedCity) {
      filtroResult = EntityEnum.City;
    } else if (resultForSelectLocation.selectedState) {
      filtroResult = EntityEnum.State;
    }

    //criando a linha total
    entityResultTotal = new FinancingFederatedEntitiesReport({
      entityId: 0,
      entityDescription: undefined,
      admDependencyId: 0,
      admDependencyDescription: undefined,
      stateAbbreviation: undefined,
      stateId: undefined,
      stateDescription: undefined,
      enrollmentTotal: 0,
      caq: 0,
      potential: 0,
      expensesIncurred: 0,
      currentExpenses: 0,
      unionComplementation: 0,
      datasByStage: this.getDatasByStageEmpty(stages),
      percentageTeacherCareer: [],
      totalTeacherNumber: 0,
      totalEmployeeNumber: 0,
      totalClassNumberExisting: 0,
      totalClassNumberNeeded: 0,
      totalClassroomNumberExisting: 0,
      totalClassroomNumberNeeded: 0,
      totalSchools: 0,
      enrollmentTotalRural: 0,
      numberEnrollmentCurrentYear: enrollmentCurrentYear,
      numberFinancialCurrentYear: financialCurrentYear,
      numberEmployeeCurrentYear: employeeCurrentYear,
      numberTeacherCurrentYear: teacherCurrentYear,
      numberRoomCurrentYear: roomCurrentYear,
      numberSchoolCurrentYear: schoolCurrentYear,
      rural: 0
    });

    entityResultTotal.stateAbbreviation = filtroResult === EntityEnum.Brazil ? 'BR' : result[0].stateAbbreviation;
    entityResultTotal.admDependencyDescription = filtroResult === EntityEnum.Brazil || filtroResult === EntityEnum.State ? 'Estadual e Municipal' : result[0].admDependencyDescription;
    entityResultTotal.entityDescription = filtroResult === EntityEnum.Brazil ? 'Total Brasil' : 'Total ' + result[0].entityDescription;


    //calculando a linha total
    for (let i = 0; i < result.length; i++) {

      this.setSumTotalResults(entityResultTotal, result[i]);
    }

    //calcula percentual na linha total somente se o filtro for diferente de municipio
    if (filtroResult !== EntityEnum.City) {
      entityResultTotal.percentageEnrollmentRural = entityResultTotal.rural > 0 ? (entityResultTotal.rural / entityResultTotal.enrollmentTotal) * 100 : 0;
    }

    resultsTotals.push(entityResultTotal);

    //console.log(entityResultTotal);

    return resultsTotals;
  }

  private getStateAbbreviation(states: Array<State>, stateId: number): string {
    for (let i = 0; i < states.length; i++) {

      if (states[i].id === stateId) {
        return states[i].abbreviation;
      }
    }
  }

  private getEnrollmentAndClassAndClassroomStateOrRegion(filterRegion: number = undefined): Observable<Array<EnrollmentAndClassAndClassroom>> {

    const resultForOffergoalenrollmentfulltime: OfferGoalEnrollmentFullTime = this.sessionService.getItem<OfferGoalEnrollmentFullTime>(Functionality.offerGoalEnrollmentFullTime.pqrKey);
    const resultForNumberstudentclass: NumberStudentClass = this.sessionService.getItem<NumberStudentClass>(Functionality.numberStudentClass.key);
    const enrollmentAndClassAndClassrooms: Array<EnrollmentAndClassAndClassroom> = new Array<EnrollmentAndClassAndClassroom>();
    const resultForSchoolDayPerWeek: SchoolDayPerWeek = this.sessionService.getItem<SchoolDayPerWeek>(Functionality.schoolDayPerWeek.key);
    const resultForDailyTeachingLoad: DailyTeachingLoad = this.sessionService.getItem<DailyTeachingLoad>(Functionality.dailyTeachingLoad.key);
    const resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers = this.sessionService.getItem<CareerAndRemunerationTeachers>(Functionality.careerAndRemunerationTeachers.pqrKey);
    const resultForWorkJourneyTeacher: WorkJourneyTeacher = this.sessionService.getItem<WorkJourneyTeacher>(Functionality.workJourneyTeacher.pqrKey);

    return this.utilitiesService.getClassAndClassroomsAndEnrollment(resultForNumberstudentclass, resultForOffergoalenrollmentfulltime, resultForSchoolDayPerWeek, resultForDailyTeachingLoad,
      resultForCareerAndRemunerationTeachers, resultForWorkJourneyTeacher, false, true, filterRegion).pipe(
        map(classAndClassroomNumberAndEnrollment => {

          for (let i = 0; i < classAndClassroomNumberAndEnrollment.length; i++) {

            const classAndClassroomNumberAndEnrollmentByLocations = classAndClassroomNumberAndEnrollment[i].locations;

            const enrollmentAndClassAndClassroom: EnrollmentAndClassAndClassroom = new EnrollmentAndClassAndClassroom({
              state_id: classAndClassroomNumberAndEnrollment[i].state_id,
              state_description: classAndClassroomNumberAndEnrollment[i].state_name,
              city_id: classAndClassroomNumberAndEnrollment[i].city_id,
              city_description: classAndClassroomNumberAndEnrollment[i].city_name,
              school_id: classAndClassroomNumberAndEnrollment[i].school_id,
              school_description: classAndClassroomNumberAndEnrollment[i].school_name,
              adm_dependency_id: classAndClassroomNumberAndEnrollment[i].adm_dependency_id,
              adm_dependency_name: classAndClassroomNumberAndEnrollment[i].adm_dependency_name,
              enrollmentProjection: this.createProjectionsService.getEnrollmentProjection(classAndClassroomNumberAndEnrollment[i]),
              classNumber: this.calculateClassNumberService.getClassNumberCalculated(classAndClassroomNumberAndEnrollmentByLocations),
              teacherNumber: this.createTeacherNumberCalcService.getTeacherNumberCalculated(classAndClassroomNumberAndEnrollmentByLocations),
              hasEnrollment: this.existEnrollment(classAndClassroomNumberAndEnrollmentByLocations),
              percentageTeacherCareer: this.createTeacherNumberCalcService.getPercentageTeacherCareer(classAndClassroomNumberAndEnrollment[i])
            });

            enrollmentAndClassAndClassrooms.push(enrollmentAndClassAndClassroom);
          }
          // console.log(enrollmentAndClassAndClassrooms);
          return enrollmentAndClassAndClassrooms;
        }));
  }

  private getDatasByStageEmpty(stages: Array<Stage>): Array<any> {

    const stageDatas: Array<any> = new Array<any>();

    for (let i = 0; i < stages.length; i++) {

      const data: any = {
        stageId: stages[i].id,
        stageDescription: stages[i].description,
        enrollmentsBySerie: undefined,
        enrollmentDay: 0,
        enrollmentNocturnal: 0,
        enrollmentIntegralTotal: 0,
        integralPercentage: undefined,
        totalTeacherNumber: 0,
        totalEmployee: 0,
        calculoIntegralEnrollment: 0
      };

      stageDatas.push(data);
    }

    return stageDatas;
  }

  private getFinancialDataByEntity(filtroLocation: string, entityId: number, sphereAdm, financialsDatas: Array<FinancialDatas>): FinancialDatas {

    /*if (filtroLocation === EntityEnum.Brazil) {
      for (let i = 0; i < financialsDatas.length; i++) {
        return financialsDatas[i];
      }
    }
    else {*/
    for (let i = 0; i < financialsDatas.length; i++) {
      if (financialsDatas[i].sphereAdmId === SphereAdmEnum.municipal) {
        if (financialsDatas[i].cityId === entityId) {
          return financialsDatas[i];
        }
      } else {
        if (financialsDatas[i].stateId === entityId) {
          return financialsDatas[i];
        }
      }
    }

    //}
  }

  private setQuantityDemandClassRoomLocation(locations: Array<Location>, demandsClassRooms: Array<DemandClassRoom>): void {

    for (let i = 0; i < demandsClassRooms.length; i++) {
      for (let j = 0; j < locations.length; j++) {
        demandsClassRooms[i].quantityDemandClassRoomLocation.push(new DemandClassRoomLocation({ location: locations[j], value: 0 }));
      }
    }
  }

  private existEnrollment(datas: Array<any>): boolean {

    for (let i = 0; i < datas.length; i++) {
      const data = datas[i];

      if (data.education_level.length > 0) {
        return true;
      }
    }
    return false;
  }

  private setDataStates(states: Array<State>, data: FinancingsFederatedsEntitiesReport) {

    for (let i = 0; i < states.length; i++) {

      data.financingsFederatedsEntitiesReport.push(new FinancingFederatedEntitiesReport({
        stateId: states[i].id,
        stateAbbreviation: states[i].abbreviation,
        stateDescription: states[i].description,
        enrollmentTotal: 0,
        caq: 0,
        potential: 0,
        expensesIncurred: 0,
        currentExpenses: 0,
        unionComplementation: 0,
        percentUnionComplementation: 0
      }));
    }
  }

  private setResultStates(resultCity: FinancingFederatedEntitiesReport, data: FinancingsFederatedsEntitiesReport) {

    for (let i = 0; i < data.financingsFederatedsEntitiesReport.length; i++) {

      const resultState = data.financingsFederatedsEntitiesReport[i];

      if (resultState.stateId === resultCity.stateId) {
        resultState.enrollmentTotal += resultCity.enrollmentTotal;
        resultState.currentExpenses += resultCity.currentExpenses;
        resultState.caq = resultState.currentExpenses / resultState.enrollmentTotal;
        resultState.unionComplementation = resultState.currentExpenses > resultState.potential ? resultState.currentExpenses - resultState.potential : 0;

        if (resultState.potential > 0) {
          resultState.percentUnionComplementation = resultState.unionComplementation > 0 ? (((resultState.currentExpenses) / resultState.potential) - 1) * 100 : 0;
        } else {
          resultState.percentUnionComplementation = 100;
        }
      }
    }
  }

  private setFinancialDatasStates(data: FinancingsFederatedsEntitiesReport, financialsDatas: Array<FinancialDatas>) {

    for (let i = 0; i < data.financingsFederatedsEntitiesReport.length; i++) {
      const resultState = data.financingsFederatedsEntitiesReport[i];
      const financialDataState = this.getFinancialDataByState(resultState.stateId, financialsDatas);
      resultState.potential = financialDataState.potential;
      resultState.expensesIncurred = financialDataState.expensesIncurred;
    }
  }

  private setSumResults(cityResult: FinancingFederatedEntitiesReport, result: FinancingFederatedEntitiesReport) {
    for (let i = 0; i < cityResult.datasByStage.length; i++) {

      const datasByStageResult = _.find(result.datasByStage, r => r.stageId === cityResult.datasByStage[i].stageId);

      if (datasByStageResult) {
        const enrollmentTotal = datasByStageResult.enrollmentDay + datasByStageResult.enrollmentNocturnal;

        cityResult.datasByStage[i].enrollmentDay += datasByStageResult.enrollmentDay;
        cityResult.datasByStage[i].enrollmentNocturnal += datasByStageResult.enrollmentNocturnal;
        cityResult.datasByStage[i].totalEmployee += datasByStageResult.totalEmployee;
        cityResult.datasByStage[i].totalTeacherNumber += datasByStageResult.totalTeacherNumber;
        cityResult.datasByStage[i].enrollmentIntegralTotal += (datasByStageResult.integralPercentage / 100) * enrollmentTotal;
        const integralPercentage = enrollmentTotal !== 0 ? (cityResult.datasByStage[i].enrollmentIntegralTotal / enrollmentTotal) * 100 : 0;
        cityResult.datasByStage[i].integralPercentage = integralPercentage;

        const calculoIntegralEnrollment = integralPercentage !== 0 ? enrollmentTotal * integralPercentage : 0;
        if (calculoIntegralEnrollment > 0) {
          cityResult.datasByStage[i].calculoIntegralEnrollment = calculoIntegralEnrollment;
        }

        /*if (cityResult.datasByStage[i].stageId === 2) {
          console.log("stageid:", cityResult.datasByStage[i].stageId);
          console.log("enrollmentTotal:", enrollmentTotal);
          console.log("integralPercentage:", integralPercentage);
          console.log("calculo:", enrollmentTotal * integralPercentage);
        }*/
      }
    }
    cityResult.enrollmentTotal += result.enrollmentTotal;
    cityResult.totalClassNumberExisting += result.totalClassNumberExisting;
    cityResult.totalClassNumberNeeded += result.totalClassNumberNeeded;
    cityResult.totalClassroomNumberExisting += result.totalClassroomNumberExisting;
    cityResult.totalClassroomNumberNeeded += result.totalClassroomNumberNeeded;
    cityResult.totalEmployeeNumber += result.totalEmployeeNumber;
    cityResult.totalTeacherNumber += result.totalTeacherNumber;
    cityResult.currentExpenses += result.currentExpenses;
    cityResult.caq = cityResult.enrollmentTotal !== 0 ? cityResult.currentExpenses / cityResult.enrollmentTotal : undefined;
    cityResult.totalSchools += 1;

    cityResult.unionComplementation = cityResult.currentExpenses > cityResult.potential ? cityResult.currentExpenses - cityResult.potential : undefined;

    if (result.locationId === LocationEnum.rural) {
      cityResult.enrollmentTotalRural += result.enrollmentTotal;
    }
    cityResult.percentageEnrollmentRural = cityResult.enrollmentTotalRural > 0 && cityResult.enrollmentTotal > 0 ? (cityResult.enrollmentTotalRural / cityResult.enrollmentTotal) * 100 : 0;
    cityResult.rural = (cityResult.percentageEnrollmentRural / 100) * cityResult.enrollmentTotal;
  }

  private setSumTotalResults(cityResultTotal: FinancingFederatedEntitiesReport, result: FinancingFederatedEntitiesReport) {


    for (let i = 0; i < result.datasByStage.length; i++) {
      const datasByStageResult = _.find(cityResultTotal.datasByStage, r => r.stageId === result.datasByStage[i].stageId);

      if (datasByStageResult) {
        const enrollmentTotal = result.datasByStage[i].enrollmentDay + result.datasByStage[i].enrollmentNocturnal;
        datasByStageResult.enrollmentDay += result.datasByStage[i].enrollmentDay;
        datasByStageResult.enrollmentNocturnal += result.datasByStage[i].enrollmentNocturnal;
        datasByStageResult.totalEmployee += result.datasByStage[i].totalEmployee;
        datasByStageResult.totalTeacherNumber += result.datasByStage[i].totalTeacherNumber;
        datasByStageResult.enrollmentIntegralTotal += (result.datasByStage[i].integralPercentage / 100) * enrollmentTotal;
        datasByStageResult.integralPercentage = enrollmentTotal !== 0 ? (result.datasByStage[i].enrollmentIntegralTotal / enrollmentTotal) * 100 : 0;
        datasByStageResult.calculoIntegralEnrollment += result.datasByStage[i].calculoIntegralEnrollment;

      }

    }

    cityResultTotal.enrollmentTotal += result.enrollmentTotal;
    cityResultTotal.totalClassNumberExisting += result.totalClassNumberExisting;
    cityResultTotal.totalClassNumberNeeded += result.totalClassNumberNeeded;
    cityResultTotal.totalClassroomNumberExisting += result.totalClassroomNumberExisting;
    cityResultTotal.totalClassroomNumberNeeded += result.totalClassroomNumberNeeded;
    cityResultTotal.totalEmployeeNumber += result.totalEmployeeNumber;
    cityResultTotal.totalTeacherNumber += result.totalTeacherNumber;

    cityResultTotal.potential += result.potential > 0 ? result.potential : 0;
    cityResultTotal.expensesIncurred += result.expensesIncurred > 0 ? result.expensesIncurred : 0;

    cityResultTotal.currentExpenses += result.currentExpenses > 0 ? result.currentExpenses : 0;

    //console.log("result.currentExpenses: ", result.currentExpenses);

    cityResultTotal.caq = result.enrollmentTotal !== 0 ? result.currentExpenses / result.enrollmentTotal : undefined;
    cityResultTotal.totalSchools += result.totalSchools;
    cityResultTotal.rural += result.rural > 0 ? result.rural : 0;
    cityResultTotal.unionComplementation += result.unionComplementation > 0 ? result.unionComplementation : 0;

    //if (result.locationId === LocationEnum.rural) {
    cityResultTotal.enrollmentTotalRural += result.enrollmentTotal;
    cityResultTotal.percentageEnrollmentRural = result.rural > 0 ? (result.rural / result.enrollmentTotal) * 100 : 0;

    //console.log("result.rural", result.rural);
    //console.log("result.enrollmentTotal", result.enrollmentTotal);
    //console.log("cityResultTotal.percentageEnrollmentRural ", cityResultTotal.percentageEnrollmentRural);

    //}


  }



  private setOrdenarResult(resultsTotals: Array<FinancingFederatedEntitiesReport>) {

    const ord1 = resultsTotals.sort((a, b) => {
      return (a.admDependencyId > b.admDependencyId) ? 1 : -1;
    });

    const ord2 = ord1.sort((a, b) => {
      return (a.entityDescription > b.entityDescription && a.admDependencyId === b.admDependencyId) ? 1 :
        ((b.entityDescription > a.entityDescription && a.admDependencyId === b.admDependencyId) ? -1 : 0);
    });

    /*const teste = resultsTotals.sort((a, b) => {
      return (a.entityDescription > b.entityDescription) ? 1 : -1;
    });

    const teste2 = teste.sort((a, b) => {
      return (a.stateId > b.stateId && a.admDependencyId === b.admDependencyId) ? 1 :
        ((b.stateId > a.stateId && a.admDependencyId === b.admDependencyId) ? -1 : 0);
    });*/
    // console.log(teste);
    // console.log(teste2);
    // console.log(teste2);

  }
}
