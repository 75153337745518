import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { CalculateEmployeeEstimateService } from '../../../shared/services/calculate-employee-estimate/calculate-employee-estimate.service';
import { EnrollmentProjectionService } from '../../../access-and-offer/enrollment-projection/services/enrollment-projection.service';
import { StaffNumberByYear } from '../entities/staff-number-by-year';
import { StaffNumberByFunction } from '../entities/staff-number-by-function';
import { Functionality } from '../../../../shared/entities/functionality/functionality';
import { SchoolsStaff } from '../../schools-staff/entities/schools-staff';
import { StaffNumber } from '../entities/staff-number';
import { SessionService } from '../../../../shared/services/session/session.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { NewRoomBuildingService } from '../../new-room-building/services/new-room-building.service';
import { SelectLocation } from '../../../select-location/entities/select-location';
import { SchoolToBeBuiltByLocation } from '../../../shared/services/calculate-class-number/entities/school-to-be-built-by-location';
import { EmployeeEstimate } from '../../../shared/services/calculate-employee-estimate/entities/employee-estimate';
import { CalculateClassNumberService } from '../../../shared/services/calculate-class-number/calculate-class-number.service';
import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { HttpService } from '../../../../shared/services/http/http.service';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';

@Injectable({
  providedIn: 'root'
})
export class StaffNumbersService implements NavigableComponentService {

  constructor(
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService,
    private enrollmentProjectionService: EnrollmentProjectionService,
    private calculateEmployeeEstimateService: CalculateEmployeeEstimateService,
    private newRoomBuildingService: NewRoomBuildingService,
    private calculateClassNumberService: CalculateClassNumberService,
    private currentYearService: CurrentYearService,
    private httpService: HttpService
  ) { }

  public getData(): Observable<StaffNumber> {

    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);

    return this.utilitiesService.getLocations().pipe(
      mergeMap(locations => {
        return this.utilitiesService.getStages().pipe(
          mergeMap(stages => {
            return this.enrollmentProjectionService.getData(locations).pipe(
              mergeMap(enrollmentProjectionByLocation => {
                if (resultForSelectLocation.selectedCity) {
                  return this.newRoomBuildingService.getClassroomExisting().pipe(
                    mergeMap(classroomExisting => {
                      /*return this.calculateClassNumberService.calculateClassNumber(locations, stages).pipe(
                        mergeMap(classNumber => {
                          const schoolsToBeBuilt: Array<SchoolToBeBuiltByLocation> = this.calculateClassNumberService.getSchoolsToBeBuilt(classNumber.classesNumberByLocations, classroomExisting);*/
                      return this.calculateEmployeeEstimateService.calculateEmployeeEstimate(locations, null, null, undefined).pipe(
                        map(calculatedEmployeeEstimate => {
                          const staffNumber: StaffNumber = new StaffNumber({ years: this.utilitiesService.getSimulationYears(), yearCurrent: this.currentYearService.getTeacherCurrentYear() });
                          staffNumber.sourceInformation = new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.employees });

                          staffNumber.sourceNote = new Footnote({
                            indice: 2,
                            note: 'O número de funcionários é obtido pela soma das variáveis que trazem a quantidade de funcionários por ' +
                              'função (Administrativo; Bibliotecário; Saúde; Coordenador; Fonoaudiólogo; Nutricionista; Psicólogo; Alimentação; Pedagogia; ' +
                              'Secretário; Segurança; Monitores). São considerados apenas funcionários de escolas estaduais e municipais ‘em atividade’ no ano ' +
                              'do Censo e que tenham pelo menos uma matrícula de Ensino Regular, Educação de Jovens e Adultos (EJA) e/ou Educação Profissional.'
                          });

                          return this.getStaffNumber(staffNumber, calculatedEmployeeEstimate);
                        }));
                      // }));
                    }));
                } else {
                  return this.calculateEmployeeEstimateService.calculateEmployeeEstimate(locations).pipe(
                    map(calculatedEmployeeEstimate => {
                      const staffNumber: StaffNumber = new StaffNumber({ years: this.utilitiesService.getSimulationYears(), yearCurrent: this.currentYearService.getTeacherCurrentYear() });
                      staffNumber.sourceInformation = new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.employees });

                      staffNumber.sourceNote = new Footnote({
                        indice: 2,
                        note: 'O número de funcionários é obtido pela soma das variáveis que trazem a quantidade de funcionários por ' +
                          'função (Administrativo; Bibliotecário; Saúde; Coordenador; Fonoaudiólogo; Nutricionista; Psicólogo; Alimentação; Pedagogia; ' +
                          'Secretário; Segurança; Monitores). São considerados apenas funcionários de escolas estaduais e municipais ‘em atividade’ no ano ' +
                          'do Censo e que tenham pelo menos uma matrícula de Ensino Regular, Educação de Jovens e Adultos (EJA) e/ou Educação Profissional.'
                      });

                      return this.getStaffNumber(staffNumber, calculatedEmployeeEstimate);
                    }));
                }
              }));
          }));
      }));
  }

  public getDiagnostic(data: StaffNumber): Observable<any> {
    const schoolCurrentYear: number = this.currentYearService.getSchoolCurrentYear();
    const filtersLocation: Array<string> = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string>;
    let options: any;

    filters = new Array<string>(
      `min_year:"${schoolCurrentYear}"`,
      `max_year:"${schoolCurrentYear}"`
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    filters = filters.concat(filtersLocation);

    options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
      ['filter', filters.join(',')]
    ]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/employees`, options).pipe(
          map(diagnostics => data.diagnostic = diagnostics[0].total));
      }));
  }

  private getStaffNumber(staffNumber: StaffNumber, calculatedEmployeeEstimate: Array<EmployeeEstimate>): StaffNumber {
    const resultForSchoolsStaff: SchoolsStaff = this.sessionService.getItem<SchoolsStaff>(Functionality.schoolsStaff.key);
    staffNumber.resultForStaffNumber = new Array<StaffNumberByFunction>();

    for (let i = 0; i < resultForSchoolsStaff.staffs.length; i++) {
      staffNumber.resultForStaffNumber.push(
        new StaffNumberByFunction(
          {
            id: parseInt(resultForSchoolsStaff.staffs[i].sequence, 10),
            functionDescription: resultForSchoolsStaff.staffs[i].denomination,
            staffNumberByYear: new Array<StaffNumberByYear>()
          })
      );

      staffNumber.years.forEach(year => {
        staffNumber.resultForStaffNumber[i].staffNumberByYear.push(new StaffNumberByYear({ year: year, value: 0 }));
      });
    }

    for (let j = 0; j < calculatedEmployeeEstimate.length; j++) {
      for (let l = 0; l < calculatedEmployeeEstimate[j].employeesEstimateByLocations.length; l++) {
        for (let m = 0; m < calculatedEmployeeEstimate[j].employeesEstimateByLocations[l].employeesEstimateByRoles.length; m++) {
          if (calculatedEmployeeEstimate[j].employeesEstimateByLocations[l].employeesEstimateByRoles[m].id.toString === resultForSchoolsStaff.staffs[m].sequence.toString) {

            for (let n = 0; n < calculatedEmployeeEstimate[j].employeesEstimateByLocations[l].employeesEstimateByRoles[m].employeesEstimatesByYear.length; n++) {
              let resultForStaffNumberByYear: StaffNumberByYear = new StaffNumberByYear();

              resultForStaffNumberByYear = _.find(staffNumber.resultForStaffNumber[m].staffNumberByYear, y => y.year
                === calculatedEmployeeEstimate[j].employeesEstimateByLocations[l].employeesEstimateByRoles[m].employeesEstimatesByYear[n].year);

              resultForStaffNumberByYear.value +=
                calculatedEmployeeEstimate[j].employeesEstimateByLocations[l].employeesEstimateByRoles[m].employeesEstimatesByYear[n].quantity;
            }
          }
        }
      }
    }
    this.getTotalStaffNumber(staffNumber);

    return staffNumber;
  }

  private getTotalStaffNumber(staffNumber: StaffNumber): void {

    const staffNumberTotal: StaffNumberByFunction = new StaffNumberByFunction(
      {
        functionDescription: 'TOTAL',
        staffNumberByYear: this.utilitiesService.getSimulationYears().map(years => new StaffNumberByYear({ year: years, value: 0 }))
      });

    for (let i = 0; i < staffNumberTotal.staffNumberByYear.length; i++) {
      const staffNumberTotalByYear = staffNumberTotal.staffNumberByYear[i];
      for (let j = 0; j < staffNumber.resultForStaffNumber.length; j++) {
        const functionStaff = staffNumber.resultForStaffNumber[j];
        for (let k = 0; k < functionStaff.staffNumberByYear.length; k++) {
          const staffNumberByYear = functionStaff.staffNumberByYear[k];
          if (staffNumberTotalByYear.year === staffNumberByYear.year) {
            staffNumberTotalByYear.value += staffNumberByYear.value;
            break;
          }
        }
      }
    }

    staffNumber.resultForStaffNumber.push(staffNumberTotal);
  }
}
