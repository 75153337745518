export class ResultSerieEnrollments {
    id: number;
    description: string;
    totalEnrollmentDay:number;
    totalEnrollmentNigth:number;    

    constructor(init?: Partial<ResultSerieEnrollments>) {
        Object.assign(this, init);
    }
}
