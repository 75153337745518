export class DatasReport {

  creche: number;
  crecheIntegral: number;
  crecheMenorUmAnoDiurno: number;
  crecheUmAnoDiurno: number;
  crecheDoisAnosDiurno: number;
  crecheTresAnosDiurno: number;
  preEscola: number;
  preEscolaIntegral: number;
  preEscolaDiurno: number;
  EFAI: number;
  EFAIIntegral: number;
  EFAIDiurno: number;
  EFAF: number;
  EFAFIntegral: number;
  EFAFDiurno: number;
  EMED: number;
  EMEDIntegral: number;
  EMDiurno: number;
  EJA: number;
  EJAIntegral: number;
  EJADiurno: number;
  EFAINoturno: number;
  EFAFNoturno: number;
  EMNoturno: number;
  EJANoturno: number;
  crecheIntegralPercentage: number;
  preEscolaIntegralPercentage: number;
  EFAIIntegralPercentage: number;
  EFAFIntegralPercentage: number;
  EMIntegralPercentage: number;
  superiorPercentage: number;
  especializacaoPercentage: number;
  mestradoPercentage: number;
  doutoradoPercentage: number;
  crecheIntegralPercentageEnrollment: number;
  preEscolaIntegralPercentageEnrollment: number;
  EFAIIntegralPercentageEnrollment: number;
  EFAFIntegralPercentageEnrollment: number;
  EMEDIntegralPercentageEnrollment: number;
  EJAIntegralPercentageEnrollment: number;
  rural: number;

  crecheIntegralEnrollment: number;
  preEscolaIntegralEnrollment: number;
  EFAIIntegralEnrollment: number;
  EFAFIntegralEnrollment: number;
  EMEDIntegralEnrollment: number;
  EJAIntegralEnrollment: number;

  constructor(init?: Partial<DatasReport>) {
    Object.assign(this, init);
  }
}
