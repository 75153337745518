import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { BaseUnsubscribe } from '../../../../shared/entities/base/base-unsubscribe';
import { SimulatorService } from '../../../simulator/services/simulator.service';
import { SimulationType } from '../../../simulator/entities/enums/simulation-type.enum';
import { SessionService } from '../../../../shared/services/session/session.service';
import { FunctionalityRoute } from '../../../../shared/entities/functionality/functionality-route';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
import { AuthService } from '../../../../shared/services/auth/auth.service';

@Component({
  selector: 'app-processing-simulation',
  templateUrl: './processing-simulation.component.html',
  styleUrls: ['./processing-simulation.component.scss']
})
export class ProcessingSimulationComponent extends BaseUnsubscribe implements OnInit {

  constructor(private router: Router, private simulatorService: SimulatorService, private sessionService: SessionService, private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.processSimulation();
  }

  processSimulation(): void {
    const simulationType: SimulationType = this.sessionService.getItem(UtilitiesService.simulationTypeSessionKey);

    if (simulationType === SimulationType.financing) {
      this.authService.getUserInfo().pipe(
        takeUntil(this.unsubscribe))
        .subscribe(userInfo => {
          if (userInfo.admin || userInfo.researcher) {
            this.initializeSimulation(simulationType);
          } else {
            this.router.navigate([FunctionalityRoute.homeRoute], { queryParamsHandling: 'merge' });
          }
        });
    } else {
      this.initializeSimulation(simulationType);
    }

  }

  private initializeSimulation(simulationType: SimulationType): void {
    this.simulatorService.initializeSimulation(simulationType).pipe(
      takeUntil(this.unsubscribe))
      .subscribe(result => {
        if (result === null) {
          this.router.navigate([FunctionalityRoute.homeRoute], { queryParamsHandling: 'merge' });
        }
      });
  }

}
