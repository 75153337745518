import { TeacherByClass } from './teacher-by-class';
import { ClassSizeByLocation } from './class-size-by-location';

export class ClassSize {

  id: number;
  numberStudentClass: ClassSizeByLocation;
  teacherByClass: TeacherByClass;

  constructor(init?: Partial<ClassSize>) {
    Object.assign(this, init);
  }
}
