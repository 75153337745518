export enum SimulationType {
  caq = 1,
  planning = 2,
  planningByStateSphereAdm = 2.1,
  planningByCitySphereAdm = 2.2,
  planningNational = 2.3,
  planningState = 2.4,
  planningCity = 2.5,
  financing = 3,
  financingFederatedEntitiesByStateSphereAdm = 5.1,   //Por escola - rel4
  financingFederatedEntitiesByCitySphereAdm = 5.2,    //Por escola - rel4
  financingFederatedEntitiesGroupByCityOrState = 5.3, //Financiamento atual x necessário - rel3
  financingNational = 5.4,  //utilizado no relatório 3 quando não existe estado nem municipio selecionado        
  financingNationalSchool = 5.5,  //utilizado no relatório 4 quando não existe estado nem municipio selecionado
}
