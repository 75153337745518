export class Schooling {

  id: number;
  description: string;
  flagOther: boolean;

  constructor(init?: Partial<Schooling>) {
    Object.assign(this, init);
  }

}
