import { FunctionalityKey } from './functionality-key.enum';

export class FunctionalityRoute {
  static homeRoute: string = '/';
  static simulationsRoute: string = 'simulations';
  static simulatorRoute: string = 'simulator';
  static processingSimulationRoute: string = 'processingsimulation';
  static accessAndOfferRoute: string = 'accessandoffer';
  static qualityConditionsRoute: string = 'qualityconditions';
  static chargesAndAdditionalsRoute: string = 'chargesandadditionals';
  static resultsRoute: string = 'results';

  static pqr: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, 'pqr');

  static simulations: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulationsRoute);

  static selectLocation: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityKey.selectLocation);

  static simulationTime: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityKey.simulationTime);

  static processingSimulation: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.processingSimulationRoute);

  static accessAndOffer: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.accessAndOfferRoute);

  static viewEnrollmentByStageAndSeries: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.accessAndOfferRoute, FunctionalityKey.viewEnrollmentByStageAndSeries);

  static enrollmentByStageAndSeries: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.accessAndOfferRoute, FunctionalityKey.enrollmentByStageAndSeries);

  static enrollmentProjection: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.accessAndOfferRoute, FunctionalityKey.enrollmentProjection);

  static enrollmentByStageAndSeriesByShool: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.accessAndOfferRoute, FunctionalityKey.enrollmentByStageAndSeriesBySchool);

  static resultEnrollmentProjection: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.accessAndOfferRoute, FunctionalityKey.resultEnrollmentProjection);

  static collaborationScheme: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.accessAndOfferRoute, FunctionalityKey.collaborationScheme);

  static qualityConditions: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute);

  static schoolDayPerWeek: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.schoolDayPerWeek);

  static dailyTeachingLoad: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.dailyTeachingLoad);

  static offerGoalEnrollmentFullTime: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.offerGoalEnrollmentFullTime);

  static numberStudentClass: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.numberStudentClass);

  static workJourneyTeacher: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.workJourneyTeacher);

  static careerAndRemunerationTeachers =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.careerAndRemunerationTeachers);

  static teacherNumber: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.teacherNumber);

  static schoolsStaff: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.schoolsStaff);

  static staffNumber: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.staffNumber);

  static infrastructureSchoolBuildings: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.infrastructureSchoolBuildings);

  static newRoomBuilding: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.newRoomBuilding);

  static administrativeAreaCosting: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.administrativeAreaCosting);

  static equipmentAndFurniture: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.equipmentAndFurniture);

  static schoolFeeding: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.schoolFeeding);

  static schoolTransport: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.schoolTransport);

  static schoolsOperation: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.schoolsOperation);

  static teacherTraining: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.qualityConditionsRoute, FunctionalityKey.teacherTraining);

  static chargesAndAdditionals: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.chargesAndAdditionalsRoute);

  static socialCharges: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.chargesAndAdditionalsRoute, FunctionalityKey.socialCharges);

  static additionals: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.chargesAndAdditionalsRoute, FunctionalityKey.additionals);

  static priceIndices: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.chargesAndAdditionalsRoute, FunctionalityKey.priceIndices);

  static fullPriceList: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.chargesAndAdditionalsRoute, FunctionalityKey.fullPriceList);

  static results: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.resultsRoute);

  static caqReport: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.resultsRoute, FunctionalityKey.caqReport);

  static budgetForecastReport: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.resultsRoute, FunctionalityKey.budgetForecastReport);

  static financingFundsReport: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.resultsRoute, FunctionalityKey.financingFundsReport);

  static financingFederatedEntitiesReport: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.resultsRoute, FunctionalityKey.financingFederatedEntitiesReport);

  static itemCostExpenseReport: Array<string> =
    new Array<string>(FunctionalityRoute.homeRoute, FunctionalityRoute.simulatorRoute, FunctionalityRoute.resultsRoute, FunctionalityKey.itemCostExpenseReport);
}
