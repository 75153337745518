import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { TypeTransport } from './type-transport';
import { SchoolTransport } from './school-transport';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class SchoolsTransports extends NavigableComponentData {

    transports: Array<SchoolTransport> = new Array<SchoolTransport>();
    typesTransport: Array<TypeTransport>;
    total: number;
    sourceInformation: Footnote;

    constructor(init?: Partial<SchoolsTransports>) {
        super();
        Object.assign(this, init);
    }
}
