
export class StudentCostByYear {

  year: number;
  costTotal: number;
  costShiftIntegral: number;
  costShiftPartial: number;

  constructor(init?: Partial<StudentCostByYear>) {
    Object.assign(this, init);
  }
}
