import { PriceIndice } from './price-indice';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';

export class PricesIndices extends NavigableComponentData {

    indices: Array<PriceIndice>;
    yearsSimulation: Array<number>;

    constructor(init?: Partial<PricesIndices>) {
        super();
        Object.assign(this, init);
    }
}
