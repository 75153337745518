import { Component, OnInit } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'caq',
  templateUrl: './caq.component.html',
  styleUrls: ['./caq.component.scss']
})

export class CaqComponent implements OnInit {

  constructor(private matomoTracker: MatomoTracker) { }

  ngOnInit() {
    this.matomoTracker.setCustomUrl('/caq');
    this.matomoTracker.trackPageView();
  }

}
