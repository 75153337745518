import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { SchoolsTransports } from '../entities/schools-transports';
import { TypeTransport } from '../entities/type-transport';
import { SchoolTransport } from '../entities/school-transport';
import { HttpService } from '../../../../shared/services/http/http.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';

import { CurrentYearService } from '../../../shared/services/current-year/current-year.service';
import { NavigableComponentService } from '../../../shared/entities/base/navigable-component-service';
import { SourceInformationEnum } from './../../../../shared/entities/enums/source-information.enum';
import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { SessionService } from 'app/shared/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolTransportService implements NavigableComponentService {

  constructor(
    private sessionService: SessionService,
    private httpService: HttpService,
    private utilitiesService: UtilitiesService,
    private currentYearService: CurrentYearService) { }

  getData(): Observable<SchoolsTransports> {

    const schoolsTransports: SchoolsTransports = new SchoolsTransports();
    const schoolTransports: Array<SchoolTransport> = new Array<SchoolTransport>();
    schoolsTransports.sourceInformation = new Footnote({ indice: 1, sourceInformation: SourceInformationEnum.transport });

    return this.httpService.get<Array<TypeTransport>>('assets/data/type-transport.data.json').pipe(
      mergeMap(typeTransports => {
        return this.getDiagnostic().pipe(
          map(diagnostic => {

            const diagnostcRoute: Array<any> = _.head(diagnostic);
            let totalUser: number = 0;

            for (let i = 0; i < typeTransports.length; i++) {
              const typeTransport = typeTransports[i];

              if (diagnostcRoute.hasOwnProperty(typeTransport.textId)) {

                for (const transpRoute of diagnostcRoute[typeTransport.textId]) {

                  totalUser += transpRoute.partial;

                  schoolTransports.push(new SchoolTransport({
                    descriptionTransport: typeTransport.description,
                    numberStudent: transpRoute.partial
                  }));
                }

              }
            }

            schoolsTransports.transports = schoolTransports;
            schoolsTransports.total = totalUser;
            return schoolsTransports;
          }));
      }));
  }

  getNewSchoolsTransport(currentSequence: number): SchoolTransport {
    return new SchoolTransport(
      {
        sequence: (currentSequence + 1).toString()
      }
    );
  }

  removeSchoolsTransport(schoolsTransport: Array<SchoolTransport>, sequence: string): void {
    let levelRemoved: boolean = false;
    let sequenceCount: number = schoolsTransport.length - 1;

    for (let i = schoolsTransport.length - 1; i >= 0; i--) {
      const schoolStaff = schoolsTransport[i];

      if (!levelRemoved && schoolStaff.sequence === sequence) {
        schoolsTransport.splice(i, 1);
        levelRemoved = true;
      } else {
        schoolStaff.sequence = sequenceCount.toString();
        sequenceCount--;
      }
    }
  }

  private getDiagnostic(): Observable<any> {

    const transportCurrentYear: number = this.currentYearService.getTransportCurrentYear();

    let filtersLocation: Array<string> = new Array<string>();

    filtersLocation = this.utilitiesService.getSelectLocationFilter();

    let filters: Array<string> = new Array<string>(
      `min_year:"${transportCurrentYear}"`,
      `max_year:"${transportCurrentYear}"`,
      `education_level_mod:["1","2","4","5","6","8","9"]`
    );

    filters.push(this.utilitiesService.getAdmDependencyFilter());

    filters = filters.concat(filtersLocation);

    const options: any = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([['filter', filters.join(',')]]));

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/transport`, options).pipe(
          map(diagnostic => diagnostic));
      }));
  }

}
