import { HigherDemandClassroomByLocation } from './higher-demand-classroom-by-location';

export class HigherDemandClassroom {

  higherDemandsClassroomsByLocations: Array<HigherDemandClassroomByLocation>;

  constructor(init?: Partial<HigherDemandClassroom>) {
    Object.assign(this, init);
  }

}
