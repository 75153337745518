import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { StepType } from '../entities/enums/step-type.enum';

@Injectable({
  providedIn: 'root'
})
export class StepNavigatorService {

  stepChangeRequested: Observable<StepType>;
  stepChangeApproved: Observable<StepType>;
  setPreviousStepLabelObserver: Observable<string>;
  setNextStepLabelObserver: Observable<string>;
  setPreviousStepRouteObserver: Observable<Array<string>>;
  setNextStepRouteObserver: Observable<Array<string>>;

  private stepChangeRequestedMessenger: Subject<StepType> = new Subject<StepType>();
  private stepChangeApprovedMessenger: Subject<StepType> = new Subject<StepType>();
  private setPreviousStepLabelMessenger: Subject<string> = new Subject<string>();
  private setNextStepLabelMessenger: Subject<string> = new Subject<string>();
  private setPreviousStepRouteMessenger: Subject<Array<string>> = new Subject<Array<string>>();
  private setNextStepRouteMessenger: Subject<Array<string>> = new Subject<Array<string>>();
  private stepType: StepType;

  constructor() {
    this.stepChangeRequested = this.stepChangeRequestedMessenger.asObservable();
    this.stepChangeApproved = this.stepChangeApprovedMessenger.asObservable();
    this.setPreviousStepLabelObserver = this.setPreviousStepLabelMessenger.asObservable();
    this.setNextStepLabelObserver = this.setNextStepLabelMessenger.asObservable();
    this.setPreviousStepRouteObserver = this.setPreviousStepRouteMessenger.asObservable();
    this.setNextStepRouteObserver = this.setNextStepRouteMessenger.asObservable();
  }

  requestStepChange(stepType: StepType): void {
    this.stepType = stepType;
    this.stepChangeRequestedMessenger.next(this.stepType);
  }

  approveStepChange(): void {
    this.stepChangeApprovedMessenger.next(this.stepType);
  }

  setPreviousStepLabel(label: string): void {
    this.setPreviousStepLabelMessenger.next(label);
  }

  setNextStepLabel(label: string): void {
    this.setNextStepLabelMessenger.next(label);
  }

  setPreviousStepRoute(route: Array<string>): void {
    this.setPreviousStepRouteMessenger.next(route);
  }

  setNextStepRoute(route: Array<string>): void {
    this.setNextStepRouteMessenger.next(route);
  }

}
