import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Functionality } from 'app/shared/entities/functionality/functionality';
import { SessionService } from 'app/shared/services/session/session.service';
import { UtilitiesService } from 'app/shared/services/utilities/utilities.service';
import { NavigableComponentService } from 'app/simulator/shared/entities/base/navigable-component-service';
import { EnrollmentByStageSeriesBySchool } from '../../enrollment-by-stage-series-by-school/entities/enrollment-by-stage-series-by-school';
import { ResultEnrollmentProjection } from '../entities/result-enrollment-projection';
import { ResultSerieEnrollments } from '../entities/serie-enrollments-by-school';
import { ResultStageEnrollments } from '../entities/stage-enrollments-by-school';
import { EnrollmentBySchool } from '../../enrollment-by-stage-series-by-school/entities/enrollment-by-school';

@Injectable({
  providedIn: 'root'
})
export class ResultEnrollmentProjectionService implements NavigableComponentService {

  constructor(
    private utilitiesService: UtilitiesService,
    private sessionService: SessionService    
  ) { }

  getData(): Observable<ResultEnrollmentProjection> {

    let resultEnrollmentProjection: ResultEnrollmentProjection = new ResultEnrollmentProjection();
    let enrollmentBySchool: EnrollmentBySchool = this.sessionService.getItem<EnrollmentBySchool>(Functionality.enrollmentByStageAndSeriesBySchool.key);
    const yearsSimulation = this.utilitiesService.getSimulationYears();
    
    resultEnrollmentProjection.year = yearsSimulation[0];

     return this.utilitiesService.getStagesAndSeries().pipe(
       map(stagesAndSeries => {

         stagesAndSeries.map(stage => {

          let enrollmentsStageDay: number = 0;
          let enrollmentsStageNight: number = 0;
          let enrollmentStageFullTime: number = 0;
          let percentageIntegral: number;
          let enrollmentsSatageCurrent: number = 0;

          for (let school = 0; school < enrollmentBySchool.enrollmentByStageSeriesBySchool.length; school++) {
            for (let schoolStage = 0; schoolStage < enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl.length; schoolStage ++) {
              if (enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage].id === stage.id) {
                if (enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage]) {
                  
                  percentageIntegral = enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage].percentageIntegral;
                  enrollmentStageFullTime += (percentageIntegral/100) *  enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage].totalEnrollmentDay;

                  enrollmentsStageDay += 1* enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage].totalEnrollmentDay;
                  enrollmentsStageNight += 1* enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage].totalEnrollmentNight;

                  enrollmentsSatageCurrent += 1* enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage].totalEnrollmentCurrent;
                }
              }
            }
          }

          let resultStageEnrollments: ResultStageEnrollments = new ResultStageEnrollments();
          
          resultStageEnrollments.id = stage.id;
          resultStageEnrollments.description = stage.description;
          resultStageEnrollments.totalEnrollmentDay = enrollmentsStageDay ;
          resultStageEnrollments.totalEnrollmentNigth = enrollmentsStageNight;
          resultStageEnrollments.totalEnrollmentFullTime = enrollmentStageFullTime;
          resultStageEnrollments.totalEnrollmentCurrent = enrollmentsSatageCurrent;
          if (enrollmentsStageDay > 0) {
            resultStageEnrollments.percentageFullTime = (100*enrollmentStageFullTime)/enrollmentsStageDay;
          } else {
            resultStageEnrollments.percentageFullTime = 0
          }

          resultStageEnrollments.serieEnrollmentsBySchool = new Array<ResultSerieEnrollments>();

          stage.series.map((serie, i) => {
            if (stage.id===1) {
              let enrollmentsSerieDay: number = 0;
              let enrollmentsSerieNight: number = 0;

              for (let school = 0; school < enrollmentBySchool.enrollmentByStageSeriesBySchool.length; school++) {
                for (let schoolStage = 0; schoolStage < enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl.length; schoolStage++) {
                  if (enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage].id === stage.id) {
                    if (enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage].serieEnrollmentsBySchool[i]) {
                      enrollmentsSerieDay += 1 * enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage].serieEnrollmentsBySchool[i].totalEnrollmentDay;
                      enrollmentsSerieNight += 1* enrollmentBySchool.enrollmentByStageSeriesBySchool[school].enrollmentBySchoolLocation[0].stagesEnrollmentsByScholl[schoolStage].serieEnrollmentsBySchool[i].totalEnrollmentNight;
                    }
                  }
                }
              }

              let resultSerieEnrollments: ResultSerieEnrollments = new ResultSerieEnrollments();
              resultSerieEnrollments.id = serie.id;
              resultSerieEnrollments.description = serie.description;
              resultSerieEnrollments.totalEnrollmentDay = enrollmentsSerieDay;
              resultSerieEnrollments.totalEnrollmentNigth = enrollmentsSerieNight;
              resultStageEnrollments.serieEnrollmentsBySchool.push(resultSerieEnrollments);
            }
            return serie;
          });

          resultEnrollmentProjection.resultEnrollmentProjection.push(resultStageEnrollments);

         })  

         return resultEnrollmentProjection;
       }));        
  }
}
