import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MatomoTracker } from 'ngx-matomo';

import { SimulationType } from '../simulator/simulator/entities/enums/simulation-type.enum';
import { SimulatorService } from '../simulator/simulator/services/simulator.service';
import { AuthService } from '../shared/services/auth/auth.service';
import { BaseUnsubscribe } from '../shared/entities/base/base-unsubscribe';
import { UserInfo } from '../shared/services/auth/entities/user-info';
import { TutorialsComponent } from '../shared/components/modal/tutorials/tutorials.component';
import { Tutorial } from '../shared/components/modal/tutorials/entities/tutorial';
import { ViewEnum } from '../shared/components/modal/tutorials/entities/enums/view.enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseUnsubscribe implements OnInit {

  userInfo: UserInfo = new UserInfo();

  readonly caq: SimulationType = SimulationType.caq;
  readonly planning: SimulationType = SimulationType.planning;
  readonly planningByStateSphereAdm: SimulationType = SimulationType.planningByStateSphereAdm;
  readonly planningByCitySphereAdm: SimulationType = SimulationType.planningByCitySphereAdm;
  readonly financingFederatedEntitiesByStateSphereAdm: SimulationType = SimulationType.financingFederatedEntitiesByStateSphereAdm;
  readonly financingFederatedEntitiesByCitySphereAdm: SimulationType = SimulationType.financingFederatedEntitiesByCitySphereAdm;
  readonly financingFederatedEntitiesGroupByCityOrState: SimulationType = SimulationType.financingFederatedEntitiesGroupByCityOrState;

  nomeHost: string = "";

  constructor(private simulatorService: SimulatorService, private authService: AuthService, private bsModalService: BsModalService, private matomoTracker: MatomoTracker) {
    super();
  }

  ngOnInit(): void {
    console.log('url',window.location.hostname)
    this.nomeHost = window.location.hostname.toLowerCase()

    this.matomoTracker.setCustomUrl('/home');
    this.matomoTracker.trackPageView();

    this.verifyUserInfo();

    this.authService.userLoggedObserver.pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userIsLoggedIn => {
        if (userIsLoggedIn) {
          this.verifyUserInfo();
        } else {
          this.userInfo = new UserInfo();
        }
      });

  }

  startSimulation(simulationType: SimulationType): void {
    if (simulationType === SimulationType.caq) {
      this.matomoTracker.trackEvent('simulation', 'click', 'Simulacao CAQ');
    } else {
      this.matomoTracker.trackEvent('simulation', 'click', 'Simulacao Orçamento educacional');
    }

    this.simulatorService.startSimulation(simulationType);
  }

  openVideoTutorial(): void {
    const tutorial: Tutorial = new Tutorial({ videoSource: 'https://www.youtube.com/embed/wWhQob6Efhw' });
    this.bsModalService.show(TutorialsComponent, { ignoreBackdropClick: true, class: 'modal-lg modal-tutorial', initialState: { currentView: ViewEnum.VideoTutorialPlatform, tutorial } });
  }

  private verifyUserInfo(): void {
    this.authService.getUserInfo().pipe(
      takeUntil(this.unsubscribe))
      .subscribe(userInfo => this.userInfo = userInfo);
  }

}
