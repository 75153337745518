export class StudentValueFundeb {

  stage_id: number;
  location_id: number;
  shift_id: number;
  minor: number;
  major: number;

  constructor(init?: Partial<StudentValueFundeb>) {
    Object.assign(this, init);
  }
}
