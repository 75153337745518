import { OnInit, OnDestroy, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { CurrentYearService } from '../../../simulator/shared/services/current-year/current-year.service';
import { NavigableComponentData } from '../../../simulator/shared/entities/base/navigable-component-data';
import { NavigableComponent } from './navigable-component';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { Functionality } from '../functionality/functionality';
import { BaseInconsistency } from './base-inconsistency';
import { RequiredDataAlertComponent } from '../../../simulator/shared/components/modal/required-data-alert/required-data-alert.component';
import { FunctionalityInfo } from '../functionality/functionality-info';
import { StepNavigatorService } from '../../../simulator/shared/components/step-navigator/services/step-navigator.service';
import { BaseUnsubscribe } from './base-unsubscribe';
import { SessionService } from '../../services/session/session.service';
import { NotificationService } from '../../services/notification/notification.service';
import { Inconsistency } from '../../components/inconsistency/entities/inconsistency';

export abstract class BaseNavigableComponent<T extends NavigableComponentData> extends BaseUnsubscribe implements OnInit, OnDestroy, NavigableComponent, BaseInconsistency {

    abstract data: T;
    abstract functionality: FunctionalityInfo;
    abstract inconsistencies: Array<Inconsistency>;

    pqrMode: boolean = false;

    protected previousData: T;
    protected router: Router = this.injectorEngine.get(Router);
    protected sessionService: SessionService = this.injectorEngine.get(SessionService);
    protected notificationService: NotificationService = this.injectorEngine.get(NotificationService);
    protected stepNavigatorService: StepNavigatorService = this.injectorEngine.get(StepNavigatorService);
    protected utilitiesService: UtilitiesService = this.injectorEngine.get(UtilitiesService);
    protected currentYearService: CurrentYearService = this.injectorEngine.get(CurrentYearService);
    protected bsModalService: BsModalService = this.injectorEngine.get(BsModalService);
    protected bsModalRef: BsModalRef;

    private missingRequiredData: Array<FunctionalityInfo> = new Array<FunctionalityInfo>();

    constructor(private injectorEngine?: Injector) {
        super();

        if (!this.pqrMode) {
            this.stepNavigatorService.stepChangeRequested.pipe(
                takeUntil(this.unsubscribe))
                .subscribe(stepType => this.stepNavigatorService.approveStepChange());
        }
    }

    abstract processData(): Observable<T>;

    ngOnInit() {
        if (!this.pqrMode && !this.utilitiesService.isFullPqrRoute()) {
            // Ensures that all current years have been loaded.
            this.currentYearService.loadCurrentYears().pipe(
                takeUntil(this.unsubscribe))
                .subscribe(() => {
                    super.ngOnInit();

                    setTimeout(() => {
                        this.stepNavigatorService.setPreviousStepLabel(this.getPreviousStepLabel());
                        this.stepNavigatorService.setNextStepLabel(this.getNextStepLabel());
                        this.stepNavigatorService.setPreviousStepRoute(this.getPreviousStepRoute());
                        this.stepNavigatorService.setNextStepRoute(this.getNextStepRoute());

                        this.setMissingRequiredData();

                        if (this.missingRequiredData.length === 0) {
                            const sessionData: T = this.sessionService.getItem<T>(this.functionality.key);

                            if (sessionData) {
                                this.processSessionData(sessionData);
                                this.previousData = _.cloneDeep(this.data);
                            } else {
                                this.processData().pipe(
                                    takeUntil(this.unsubscribe))
                                    .subscribe(data => {
                                        this.data = data;
                                        this.processSpecificBehaviors();
                                    });
                            }
                        } else {
                            this.processRequiredData();
                        }
                    }, 0);
                });
        }
    }

    ngOnDestroy() {
        if (!this.pqrMode) {
            super.ngOnDestroy();

            const isDiscardContentSimulation: boolean = this.isDiscardContentSimulation();

            if (!this.hasError() &&
                !isDiscardContentSimulation &&
                (!this.inconsistencies || this.inconsistencies.length === 0) &&
                this.missingRequiredData.length === 0 &&
                (!_.isEmpty(this.data) || _.isNumber(this.data))) {
                if (this.functionality.key !== Functionality.caqReport.key && this.functionality.key !== Functionality.financingFederatedEntitiesReport.key) {
                    this.sessionService.setItem(this.functionality.key, this.data);
                }
            } else {
                this.sessionService.removeItem(this.functionality.key);

                if (isDiscardContentSimulation) {
                    this.sessionService.removeItem(UtilitiesService.isDiscardContentSimulation);
                }
            }

            this.processRequireMyData();
        }
    }

    processValidation(): void {
        this.inconsistencies = new Array<Inconsistency>();
    }

    hasMissingRequiredData(): boolean {
        return this.missingRequiredData.length > 0;
    }

    protected processSessionData(sessionData: T): void {
        this.data = sessionData;
        this.processSpecificBehaviors();
    }

    protected processSpecificBehaviors(): void {
    }

    protected dataHasChanged(): boolean {
        return !_.isEqual(_.cloneDeep(this.data), this.previousData);
    }

    protected getPreviousStepLabel(): string {
        return undefined;
    }

    protected getNextStepLabel(): string {
        return undefined;
    }

    protected getPreviousStepRoute(): Array<string> {
        return this.functionality.previousStep;
    }

    protected getNextStepRoute(): Array<string> {
        return this.functionality.nextStep;
    }

    private processRequireMyData(): void {
        if (this.dataHasChanged()) {
            const requireMyData: Array<FunctionalityInfo> = Functionality.getRequireMyData(this.functionality.key);

            for (const functionality of requireMyData) {
                this.sessionService.removeItem(functionality.key);
            }
        }
    }

    private processRequiredData(): void {
        this.sessionService.setItem(UtilitiesService.hasRequiredDataSessionKey, true);

        this.bsModalRef = this.bsModalService.show(RequiredDataAlertComponent, { keyboard: false, ignoreBackdropClick: true });
        this.bsModalRef.content.missingRequiredData = this.missingRequiredData;
    }

    private setMissingRequiredData(): void {
        this.sessionService.removeItem(UtilitiesService.hasRequiredDataSessionKey);

        if (this.functionality.requiredData) {
            for (const functionality of this.functionality.requiredData) {
                if (!functionality.disabled && !this.sessionService.getItem(functionality.key)) {
                    this.missingRequiredData.push(functionality);
                }
            }
        }
    }

    private hasError(): boolean {
        return this.sessionService.getItem<boolean>(UtilitiesService.hasErrorSessionKey);
    }

    private isDiscardContentSimulation(): boolean {
        return this.sessionService.getItem<boolean>(UtilitiesService.isDiscardContentSimulation);
    }
}
