import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { CurrentYearService } from '../../../simulator/shared/services/current-year/current-year.service';
import { SelectLocation } from '../../../simulator/select-location/entities/select-location';
import { HttpService } from '../http/http.service';
import { Functionality } from '../../entities/functionality/functionality';
import { FormationLevel } from '../../entities/formation-level';
import { UnitOfMeasurement } from '../../entities/unit-of-measurement';
import { PriceIndice } from '../../entities/price-indice';
import { Serie } from '../../entities/serie';
import { Stage } from '../../entities/stage';
import { StageAndSeries } from '../../entities/stage-and-series';
import { AdmDependency } from '../../entities/adm-dependency';
import { Location } from '../../entities/location';
import { AccountPlan } from '../../entities/account-plan';
import { ExpenseType } from '../../entities/expense-type';
import { SessionService } from '../session/session.service';
import { State } from '../../entities/state';
import { City } from '../../entities/city';
import { SimulationTime } from '../../../simulator/simulation-time/entities/simulation-time';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { NumberStudentClass } from '../../../simulator/quality-conditions/number-student-class/entities/number-student-class';
import { OfferGoalEnrollmentFullTime } from '../../../simulator/quality-conditions/offer-goal-enrollment-full-time/entities/offer-goal-enrollment-full-time';
import { CurrentYearMonthService } from '../../../simulator/shared/services/current-year-month/current-year-month.service';
import { DemandClassRoom } from '../../../simulator/quality-conditions/new-room-building/entities/demand-class-room';
import { DemandClassRoomLocation } from '../../../simulator/quality-conditions/new-room-building/entities/demand-class-room-location';
import { ClassSizeByLocation } from '../../entities/class-size-by-location';
import { LocationEnum } from '../../entities/enums/location.enum';
import { ClassSize } from '../../entities/class-size';
import { FinancialData } from '../../../simulator/results/budget-forecast-report/entities/financial-data';
import { FunctionalityInfo } from '../../entities/functionality/functionality-info';
import { TeacherJourney } from './../../entities/teacher-journey';
import { WorkJourneyTeacher } from './../../../simulator/quality-conditions/work-journey-teacher/entities/work-journey-teacher';
import { TeacherFormation } from './../../entities/teacher-career';
import { CareerAndRemunerationTeachers } from './../../../simulator/quality-conditions/career-and-remuneration-teachers/entities/career-and-remuneration-teachers';
import { DailyTeachingLoad } from './../../../simulator/quality-conditions/daily-teaching-load/entities/daily-teaching-load';
import { SchoolDayPerWeek } from './../../../simulator/quality-conditions/school-day-per-week/entities/school-day-per-week';
import { TeacherByClass } from './../../entities/teacher-by-class';
import { SimulationType } from '../../../simulator/simulator/entities/enums/simulation-type.enum';
import { AdmDependencyEnum } from '../../entities/enums/adm-dependency.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  static readonly caqText: string = 'Consulte os valores do CAQ';
  static readonly caqSimulationTagText: string = UtilitiesService.caqText.replace('Consulte', 'consultando');
  static readonly planningText: string = 'Elaborando o orçamento educacional';
  static readonly planningSimulationTagText: string = UtilitiesService.planningText.replace('Elabore', 'Elaborando');
  static readonly planningByStateSphereAdmSimulationTagText: string = `${UtilitiesService.planningSimulationTagText} para o plano da rede estadual`;
  static readonly planningByStateSimulationTagText: string = `${UtilitiesService.planningSimulationTagText} para o plano estadual`;
  static readonly planningByCitySphereAdmSimulationTagText: string = `${UtilitiesService.planningSimulationTagText} para o plano da rede municipal`;
  static readonly planningByCitySimulationTagText: string = `${UtilitiesService.planningSimulationTagText} para o plano municipal`;
  static readonly planningNationalSimulationTagText: string = `${UtilitiesService.planningSimulationTagText} para o plano nacional`;
  static readonly financingText: string = 'Acesse os valores de um fundo de manutenção';
  static readonly financingSimulationTagText: string = UtilitiesService.financingText.replace('Acesse', 'acessando');
  static readonly financingFederatedEntitiesText: string = 'Financiamento dos entes federados';

  static readonly financingFederatedEntitiesSimulationTagPrefixText: string = 'acessando o módulo "Financiamento atual x necessário"';
  static readonly financingFederatedSchoolsSimulationTagPrefixText: string = 'acessando o módulo "Custo por escola pública"';

  static readonly financingFederatedEntitiesByStateSphereAdmSimulationTagText: string = `${UtilitiesService.financingFederatedEntitiesSimulationTagPrefixText} para o plano estadual`;
  static readonly financingFederatedEntitiesByCitySphereAdmSimulationTagText: string = `${UtilitiesService.financingFederatedEntitiesSimulationTagPrefixText} para a rede municipal`;
  static readonly financingNationalFederatedEntitiesSimulationTagText: string = `${UtilitiesService.financingFederatedEntitiesSimulationTagPrefixText} para o plano nacional`;

  static readonly financingFederatedSchoolsByStateSphereAdmSimulationTagText: string = `${UtilitiesService.financingFederatedSchoolsSimulationTagPrefixText} para o plano estadual`;
  static readonly financingFederatedSchoolsByCitySphereAdmSimulationTagText: string = `${UtilitiesService.financingFederatedSchoolsSimulationTagPrefixText} para a rede municipal`;
  static readonly financingNationalFederatedSchoolsSimulationTagText: string = `${UtilitiesService.financingFederatedSchoolsSimulationTagPrefixText} para o plano nacional`;

  static readonly hasErrorSessionKey: string = 'haserror';
  static readonly hasRequiredDataSessionKey: string = 'hasrequireddata';
  static readonly isDiscardContentSimulation: string = 'isdiscardcontentsimulation';
  static readonly simulationTypeSessionKey: string = `${FunctionalityInfo.simulationNamespace}.type`;
  static readonly minSimulationTime: number = 1;
  static readonly maxSimulationTime: number = 10;
  static readonly idStageEeExclusiva: number = 7;

  private readonly idEmptyLocation: number = 0;
  private readonly idStageNaoClassificada: number = null;
  private readonly idSerieEjaSemiPresencial: number = 64;
  private readonly idSerieNaoClassificado: number = 99;
  private readonly idAdmDependencyNaoClassificada: number = 99;

  constructor(private httpService: HttpService, private sessionService: SessionService, private router: Router, private currentYearService: CurrentYearService,
    private currentYearMonthService: CurrentYearMonthService) { }

  getSimulationYears(specificSimulationTime: number = 0): Array<number> {
    const simulationTime: SimulationTime = specificSimulationTime === 0
      ? this.sessionService.getItem<SimulationTime>(Functionality.simulationTime.key)
      : new SimulationTime({ time: specificSimulationTime });

    const enrollmentCurrentYear: number = this.currentYearService.getEnrollmentCurrentYear();
    const years: Array<number> = new Array<number>();

    for (let index = 1; index <= simulationTime.time; index++) {
      years.push(enrollmentCurrentYear + index);
    }

    return years;
  }

  getLiteralSimulationYear(specificSimulationTime: number = 0): string {
    if (specificSimulationTime === 0) {
      const simulationTime: SimulationTime = this.sessionService.getItem<SimulationTime>(Functionality.simulationTime.key);
      specificSimulationTime = simulationTime.time;
    }

    const simulationYears: Array<number> = this.getSimulationYears(specificSimulationTime);
    return simulationYears.length === 1 ? `${_.first(simulationYears)}` : `${_.first(simulationYears)} - ${_.last(simulationYears)}`;
  }

  getExpenseType(chosenIndice: Array<Number>): Observable<Array<ExpenseType>> {
    return this.httpService.get<Array<ExpenseType>>('assets/data/expense-type.data.json').pipe(
      map(expenseTypes => !chosenIndice ? expenseTypes : expenseTypes.filter(unit => chosenIndice.indexOf(unit.id) > -1)),
      map(expenseTypes => expenseTypes.map(unit => new ExpenseType({ id: unit.id, description: unit.description }))));
  }

  getAccountPLan(chosenIndice: Array<Number>): Observable<Array<AccountPlan>> {
    return this.httpService.get<Array<AccountPlan>>('assets/data/account-plan.data.json').pipe(
      map(accountPlans => !chosenIndice ? accountPlans : accountPlans.filter(unit => chosenIndice.indexOf(unit.id) > -1)),
      map(accountPlans => accountPlans.map(unit => new AccountPlan({ id: unit.id, description: unit.description }))));
  }

  getPriceIndice(chosenIndice: Array<Number>): Observable<Array<PriceIndice>> {
    return this.httpService.get<Array<PriceIndice>>('assets/data/price-indices.data.json').pipe(
      map(priceIndices => !chosenIndice ? priceIndices : priceIndices.filter(unit => chosenIndice.indexOf(unit.id) > -1)),
      map(priceIndices => priceIndices.map(unit => new PriceIndice({ id: unit.id, description: unit.description }))));
  }

  getUnitOfMeasurement(chosenUnit: Array<Number>): Observable<Array<UnitOfMeasurement>> {
    return this.httpService.get<Array<UnitOfMeasurement>>('assets/data/unit-of-measurement.data.json').pipe(
      map(unitsOfMeasurement => !chosenUnit ? unitsOfMeasurement : unitsOfMeasurement.filter(unit => chosenUnit.indexOf(unit.id) > -1)),
      map(unitsOfMeasurement => unitsOfMeasurement.map(unit => new UnitOfMeasurement({ id: unit.id, description: unit.description }))));
  }

  getFormationLevels(): Observable<Array<FormationLevel>> {
    return this.httpService.get<Array<FormationLevel>>('assets/data/formation-levels.data.json').pipe(
      map(formationLevels => formationLevels.map(formationLevel => new FormationLevel({ id: formationLevel.id, description: formationLevel.description }))));
  }

  getStages(): Observable<Array<Stage>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/enrollment/education_level_short`).pipe(
          map(educationLevels => educationLevels.filter(educationLevel => educationLevel.id !== this.idStageNaoClassificada && educationLevel.id !== UtilitiesService.idStageEeExclusiva)),
          map(educationLevels => educationLevels.map(educationLevel => new Stage({ id: educationLevel.id, description: educationLevel.name }))));
      }));
  }

  getSeries(): Observable<Array<Serie>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/enrollment/school_year`).pipe(
          map(schoolYears => schoolYears.filter(schoolYear =>
            schoolYear.id !== this.idSerieEjaSemiPresencial &&
            schoolYear.id !== this.idSerieNaoClassificado &&
            (schoolYear.id < +`${UtilitiesService.idStageEeExclusiva}0` || schoolYear.id > +`${UtilitiesService.idStageEeExclusiva}9`))),
          map(schoolYears => schoolYears.map(schoolYear => new Serie({ id: schoolYear.id, description: schoolYear.name }))));
      }));
  }

  getStagesAndSeries(): Observable<Array<StageAndSeries>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/education_years`).pipe(
          map(educationYears => educationYears.filter(educationYear => educationYear.id !== this.idStageNaoClassificada && educationYear.id !== UtilitiesService.idStageEeExclusiva)),
          map(educationYears => educationYears.map(educationYear => new StageAndSeries({
            id: educationYear.id,
            description: educationYear.name,
            series: educationYear.schoolYears
              .filter(schoolYear => schoolYear.id !== this.idSerieEjaSemiPresencial)
              .map(schoolYear => new Serie({ id: schoolYear.id, description: schoolYear.name }))
          }))));
      }));
  }

  getAdmDependenciesDetailed(): Observable<Array<AdmDependency>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/enrollment/adm_dependency_detailed`).pipe(
          map(admDependencies => admDependencies.filter(admDependency => admDependency.id !== this.idAdmDependencyNaoClassificada)),
          map(admDependencies => admDependencies.map(admDependency => new AdmDependency({ id: admDependency.id, description: admDependency.name }))));
      }));
  }

  getLocations(): Observable<Array<Location>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/enrollment/location`).pipe(
          map(locations => locations.filter(location => location.id !== this.idEmptyLocation)),
          map(locations => locations.map(location => new Location({ id: location.id, description: location.name }))));
      }));
  }

  getDemandConstruction(): Observable<Array<DemandClassRoom>> {

    const classroomCurrentYear: number = this.currentYearService.getClassroomCurrentYear();
    return this.httpService.get<Array<DemandClassRoom>>('assets/data/demand-construction.data.json').pipe(
      map(demands => {
        const demandsByClassroom: Array<DemandClassRoom> = demands.map(item =>
          new DemandClassRoom({
            id: item.id,
            description: item.id === 1 ? item.description + ' em ' + classroomCurrentYear : item.description,
            sourceNoteIdx: item.sourceNoteIdx,
            quantityDemandClassRoomLocation: new Array<DemandClassRoomLocation>()
          }));
        return demandsByClassroom;
      }));
  }

  getRandomNumber(min: number = 1, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  toNumber(number: any): number {
    if (typeof number === 'string') {
      number = number.replace(/\./g, '');
      number = number.replace(/,/g, '.');

      return +number;
    }

    return number;
  }

  getYearAndSelectLocationFilters(year: number) {
    return this.getYearFilter(year).concat(this.getSelectLocationFilter());
  }

  getYearFilter(year: number): Array<string> {
    return new Array<string>(
      `min_year:"${year}"`,
      `max_year:"${year}"`
    );
  }

  getSelectLocationFilter(): Array<string> {
    const resultForSelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    const filters: Array<string> = new Array<string>();

    if (resultForSelectLocation) {
      if (resultForSelectLocation.selectedState) {
        filters.push(`state:"${resultForSelectLocation.selectedState.value}"`);
      }

      if (resultForSelectLocation.selectedCity) {
        filters.push(`city:"${resultForSelectLocation.selectedCityId}"`);
      }
    }

    return filters;
  }

  getAdmDependencyFilter(): string {
    const simulationType: SimulationType = this.sessionService.getItem<SimulationType>(UtilitiesService.simulationTypeSessionKey);
    const resultForSelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);

    //// let admDependency: string = '"3"';
    //// let admDependency: string = '"1","2","3"';
    let admDependency: string = '"2","3"';

    if (simulationType === SimulationType.planningByStateSphereAdm || simulationType === SimulationType.financingFederatedEntitiesByStateSphereAdm) {
      admDependency = `"${AdmDependencyEnum.State}"`;
    } else if (simulationType === SimulationType.planningByCitySphereAdm || simulationType === SimulationType.financingFederatedEntitiesByCitySphereAdm) {
      admDependency = `"${AdmDependencyEnum.Municipal}"`;
    } else if (simulationType === SimulationType.caq) {
      // admDependency = `"${AdmDependencyEnum.Municipal}"`;
    } else if (simulationType === SimulationType.financingFederatedEntitiesGroupByCityOrState && resultForSelectLocation.selectedCity) {
      admDependency = `"${AdmDependencyEnum.Municipal}"`;
    }

    return `adm_dependency:[${admDependency}]`;
  }

  getStateFilter(): string {

    const state: string = '"52"';
    return `state:[${state}]`;
  }

  getCityFilter(): string {

    const city: string = '"3205309"';
    return `city:[${city}]`;
  }

  getSelectLocationName(selectLocation: SelectLocation = undefined): string {
    let locationName: string = 'BRASIL';

    if (selectLocation === undefined) {
      selectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    }

    if (selectLocation) {
      if (selectLocation.selectedCity) {
        locationName = selectLocation.selectedCity.label;
      } else if (selectLocation.selectedState) {
        locationName = selectLocation.selectedState.label;
      }
    }

    return locationName;
  }

  getSimulationTypeName(simulationType: number): string {
    let simulationName: string = UtilitiesService.planningText;
    if (simulationType === SimulationType.planning) {
      simulationName = UtilitiesService.planningByStateSphereAdmSimulationTagText;
    } else if (simulationType === SimulationType.planningByStateSphereAdm) {
      simulationName = UtilitiesService.planningByStateSphereAdmSimulationTagText;
    } else if (simulationType === SimulationType.planningByCitySphereAdm) {
      simulationName = UtilitiesService.planningByCitySphereAdmSimulationTagText;
    } else if (simulationType === SimulationType.planningNational) {
      simulationName = UtilitiesService.planningNationalSimulationTagText;
    } else if (simulationType === SimulationType.planningState) {
      simulationName = UtilitiesService.planningByStateSimulationTagText;
    } else if (simulationType === SimulationType.planningCity) {
      simulationName = UtilitiesService.planningByCitySimulationTagText;
    } else if (simulationType === SimulationType.financingFederatedEntitiesByStateSphereAdm) {
      simulationName = UtilitiesService.financingFederatedSchoolsByStateSphereAdmSimulationTagText;
    } else if (simulationType === SimulationType.financingFederatedEntitiesByCitySphereAdm) {
      simulationName = UtilitiesService.financingFederatedSchoolsByCitySphereAdmSimulationTagText;
    } else if (simulationType === SimulationType.financingNational) {
      simulationName = UtilitiesService.financingNationalFederatedEntitiesSimulationTagText;
    } else if (simulationType === SimulationType.financingNationalSchool) {
      simulationName = UtilitiesService.financingNationalFederatedSchoolsSimulationTagText;
    } else {
      simulationName = UtilitiesService.financingFederatedEntitiesSimulationTagPrefixText;
    }
    return simulationName;
  }

  isInternetExplorerOrEdge(): boolean {
    const userAgent = window.navigator.userAgent;

    // IE 10 or older - user agent contains 'MSIE '.
    // IE 11 - user agent contains 'Trident/' with version number.
    // IE 12 / Spartan / Edge - user agente contains 'Edge/'.
    return userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Trident/') > 0 || userAgent.indexOf('Edge/') > 0;
  }

  getStates(): Observable<Array<State>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/state`).pipe(
          map(states => states.map(state => new State({ id: state.id, description: state.name, abbreviation: state.abbreviation }))));
      }));
  }

  getReferenceDateMask(): any {
    return { mask: [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] };
  }

  isReferenceDateValid(referenceDate: string): Boolean {
    let result: Boolean = true;
    let array: Array<string> = new Array<string>();

    if (referenceDate !== undefined) {
      array = referenceDate.split('/');

      if (parseInt(array[0], 10) > 12) {
        result = false;
      } else if (parseInt(array[1], 10) < 1900) {
        result = false;
      }
    }
    return result;
  }

  getCities(state_id: number): Observable<Array<City>> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/city?filter=state:"${state_id}"`).pipe(
          map(cities => cities.map(city => new City({ id: city.id, description: city.name }))));
      }));
  }

  emailIsValid(email: string) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  }

  isHome(url: string): boolean {
    return url === '/' || url.startsWith('/?');
  }

  isFullPqrRoute(): boolean {
    return this.router.url.indexOf('/pqr') > -1;
  }

  roundNumber(number: number, decimalPlaces: number): number {
    return +(Math.round(parseFloat(`${number}e+${decimalPlaces}`)) + `e-${decimalPlaces}`);
  }

  getClassAndClassroomsAndEnrollment(numberstudentclass: NumberStudentClass,
    offergoalenrollmentfulltime: OfferGoalEnrollmentFullTime,
    resultForSchoolDayPerWeek: SchoolDayPerWeek,
    resultForDailyTeachingLoad: DailyTeachingLoad,
    resultForCareerAndRemunerationTeachers: CareerAndRemunerationTeachers,
    resultForWorkJourneyTeacher: WorkJourneyTeacher,
    dimsBrasilByState: boolean = false,
    dimsBrasilByCity: boolean = false,
    filterRegion: number = undefined): Observable<any> {

    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    const teacherFormation: Array<TeacherFormation> = this.prepareTeacherFormation(resultForCareerAndRemunerationTeachers);
    const classesSize: Array<ClassSize> = this.prepareNumberStudentsClass(numberstudentclass);
    const teacherJourney: TeacherJourney = new TeacherJourney({ journeyTotal: resultForWorkJourneyTeacher.journeyTotal, journeyWithInteraction: resultForWorkJourneyTeacher.journeyWithInteraction });
    const year: number = this.currentYearService.getEnrollmentCurrentYear();
    const filters: Array<string> = new Array<string>(
      `min_year:"${year}"`,
      `max_year:"${year}"`,
      'period_not:[99]'
    );

    let dimension: string;

    filters.push(this.getAdmDependencyFilter());
    //// filters.push(this.getCityFilter());

    if (!dimsBrasilByCity) {
      if (resultForSelectLocation.selectedCity) {
        filters.push(`city:"${resultForSelectLocation.selectedCityId}"`);
        dimension = 'city';
      } else if (resultForSelectLocation.selectedState) {
        filters.push(`state:"${resultForSelectLocation.selectedState.value}"`);
        dimension = 'state';
      } else if (dimsBrasilByState) {
        dimension = 'state';
      }
    } else {
      if (resultForSelectLocation) {
        if (resultForSelectLocation.selectedCity) {
          filters.push(`city:"${resultForSelectLocation.selectedCityId}"`);
        } else if (resultForSelectLocation.selectedState) {
          filters.push(`state:"${resultForSelectLocation.selectedState.value}"`);
        }
      }
      dimension = 'city,school';
    }

    if (filterRegion !== undefined) {
      filters.push(`region:${filterRegion}`);
    } else {
      // filters.push(this.getStateFilter());
    }

    const body = new HttpParams()
      .set('class_size', JSON.stringify(classesSize))
      .set('integral_time', JSON.stringify(offergoalenrollmentfulltime.fullTime))
      .set('school_days', JSON.stringify(resultForSchoolDayPerWeek.schoolDaysWeek))
      .set('teaching_hours', JSON.stringify(resultForDailyTeachingLoad.teachingLoads))
      .set('teacher_formation', JSON.stringify(teacherFormation))
      .set('teacher_journey', JSON.stringify(teacherJourney));

    let par = new HttpParams()
      .set('filter', filters.join(','));

    if (dimension !== '' && dimension !== undefined) {
      par = par.set('dims', dimension);
    }
    const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }), params: par };

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.post(`${apiEndpoint}/classroom_count`, body.toString(), requestOptions).pipe(
          map(result => result));
      }));
  }

  getCub(AllStates: boolean = false): Observable<any> {

    let options;
    const resultForSelectLocation: SelectLocation = this.sessionService.getItem<SelectLocation>(Functionality.selectLocation.key);
    let year: number;
    let month: number;
    const yearsMonth = this.currentYearMonthService.getCubCurrentYearMonth();

    for (let i = 0; i < yearsMonth.length; i++) {
      year = yearsMonth[i].year;
      month = yearsMonth[i].month;
    }

    const filters: Array<string> = new Array<string>(
      `min_year:"${year}"`,
      `max_year:"${year}"`,
      `min_month:"${month}"`,
      `max_month"${month}"`
    );

    if (AllStates) {
      options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
        ['dims', 'state'],
        ['filter', filters.join(',')]
      ]));
    } else {
      if (resultForSelectLocation.selectedState || resultForSelectLocation.selectedCity) {
        filters.push(`state:"${resultForSelectLocation.selectedState.value}"`);
      }
      options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
        ['filter', filters.join(',')]
      ]));
    }

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/cub`, options).pipe(
          map(priceCub => priceCub));
      }));
  }

  getFinancialsDatas(year: number, financialsDatasTypes: Array<string>, filtersLocation: Array<string> = undefined, dimcity: boolean = false,
    sphereAdm: number = undefined): Observable<Array<FinancialData>> {

    const financialsDatas: Array<FinancialData> = new Array<FinancialData>();
    let filtersYear: Array<string>;
    let filters: Array<string> = new Array<string>();
    let options: any;

    filtersYear = new Array<string>(
      `min_year:"${year}"`,
      `max_year:"${year}"`,
      `financial_data:[${financialsDatasTypes}]`
    );

    if (sphereAdm) {
      filters.push(`sphere_adm:["${sphereAdm}"]`);
    } else {
      filters.push(`sphere_adm:["1","2"]`);
    }

    filters = filters.concat(filtersYear);

    if (!dimcity) {
      if (filtersLocation !== undefined) {
        filters = filters.concat(filtersLocation);
      }
      options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
        ['filter', filters.join(',')]
      ]));
    } else {
      if (filtersLocation !== undefined) {
        filters = filters.concat(filtersLocation);
      }
      options = this.httpService.getRequestOptionsWithSearchParams(new Map<string, string>([
        ['dims', 'city'],
        ['filter', filters.join(',')]
      ]));
    }

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<Array<any>>(`${apiEndpoint}/financial`, options).pipe(
          map(financialsDatasRoute => {

            for (let i = 0; i < financialsDatasRoute.length; i++) {
              financialsDatas.push(new FinancialData({
                stateId: financialsDatasRoute[i].state_id,
                cityId: financialsDatasRoute[i].city_id,
                sphereAdmId: financialsDatasRoute[i].sphere_adm_id,
                financialDataId: financialsDatasRoute[i].financial_data_id,
                year: financialsDatasRoute[i].year,
                value: financialsDatasRoute[i].valor,
              }));
            }
            return financialsDatas;
          }));
      }));
  }

  private prepareNumberStudentsClass(resultForNumberstudentclass: NumberStudentClass): Array<ClassSize> {
    const classesSize = new Array<ClassSize>();

    for (let i = 0; i < resultForNumberstudentclass.studentClasses.length; i++) {

      const studentClass = resultForNumberstudentclass.studentClasses[i];
      const urban = _.find(studentClass.studentsTeachersClass, sTC => sTC.location.id === LocationEnum.urban);
      const country = _.find(studentClass.studentsTeachersClass, sTC => sTC.location.id === LocationEnum.rural);

      classesSize.push(new ClassSize({
        id: studentClass.serieId === null ? studentClass.id : studentClass.serieId,
        numberStudentClass: new ClassSizeByLocation({ urban: urban.numberStudentClass, country: country.numberStudentClass }),
        teacherByClass: new TeacherByClass({ urban: urban.numberTeacherClass, country: country.numberTeacherClass })
      }));
    }

    return classesSize;
  }

  private prepareTeacherFormation(resultForCareerAndRemunerationTeachers: any): Array<TeacherFormation> {
    const teachersFormations = new Array<TeacherFormation>();

    const levels = resultForCareerAndRemunerationTeachers.levels ? resultForCareerAndRemunerationTeachers.levels : resultForCareerAndRemunerationTeachers.teacherFormationLevels.levels;

    for (let i = 0; i < levels.length; i++) {

      const careerAndRemunerationTeacher = levels[i];

      teachersFormations.push(new TeacherFormation({
        sequence: careerAndRemunerationTeacher.sequence,
        denomination: careerAndRemunerationTeacher.denomination,
        idFormationLevel: careerAndRemunerationTeacher.formationLevel.id,
        descriptionFormationLevel: careerAndRemunerationTeacher.formationLevel.description,
        journeyNoInteraction: careerAndRemunerationTeacher.journeyNoInteraction,
        journeyTotal: careerAndRemunerationTeacher.journeyTotal,
        journeyWithInteraction: careerAndRemunerationTeacher.journeyWithInteraction
      }));
    }
    return teachersFormations;
  }
}
