import { IOption } from 'ng-select';

export class City implements IOption {

  value: string;
  label: string;

  constructor(init?: Partial<City>) {
    Object.assign(this, init);
  }

}
