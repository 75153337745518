import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { HttpService } from '../../../shared/services/http/http.service';
import { SessionService } from '../../../shared/services/session/session.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { PqrComponentData } from '../../shared/entities/base/pqr-component-data';
import { SchoolDayPerWeekService } from '../../quality-conditions/school-day-per-week/services/school-day-per-week.service';
import { SchoolDayPerWeekComponent } from '../../quality-conditions/school-day-per-week/school-day-per-week.component';
import { SchoolsStaffService } from '../../quality-conditions/schools-staff/services/schools-staff.service';
import { SchoolsStaffComponent } from '../../quality-conditions/schools-staff/schools-staff.component';
import { SchoolFeedingService } from '../../quality-conditions/school-feeding/services/school-feeding.service';
import { SchoolFeedingComponent } from '../../quality-conditions/school-feeding/school-feeding.component';
import { DailyTeachingLoadService } from '../../quality-conditions/daily-teaching-load/services/daily-teaching-load.service';
import { DailyTeachingLoadComponent } from '../../quality-conditions/daily-teaching-load/daily-teaching-load.component';
import { OfferGoalEnrollmentFullTimeService } from '../../quality-conditions/offer-goal-enrollment-full-time/services/offer-goal-enrollment-full-time.service';
import { OfferGoalEnrollmentFullTimeComponent } from '../../quality-conditions/offer-goal-enrollment-full-time/offer-goal-enrollment-full-time.component';
import { NumberStudentClassService } from '../../quality-conditions/number-student-class/services/number-student-class.service';
import { NumberStudentClassComponent } from '../../quality-conditions/number-student-class/number-student-class.component';
import { WorkJourneyTeacherService } from '../../quality-conditions/work-journey-teacher/services/work-journey-teacher.service';
import { WorkJourneyTeacherComponent } from '../../quality-conditions/work-journey-teacher/work-journey-teacher.component';
import { CareerAndRemunerationTeachersService } from '../../quality-conditions/career-and-remuneration-teachers/services/career-and-remuneration-teachers.service';
import { CareerAndRemunerationTeachersComponent } from '../../quality-conditions/career-and-remuneration-teachers/career-and-remuneration-teachers.component';
import { InfrastructureSchoolBuildingsService } from '../../quality-conditions/infrastructure-school-buildings/services/infrastructure-school-buildings.service';
import { InfrastructureSchoolBuildingsComponent } from '../../quality-conditions/infrastructure-school-buildings/infrastructure-school-buildings.component';
import { NewRoomBuildingService } from '../../quality-conditions/new-room-building/services/new-room-building.service';
import { NewRoomBuildingComponent } from '../../quality-conditions/new-room-building/new-room-building.component';
import { SchoolsOperationService } from '../../quality-conditions/schools-operation/services/schools-operation.service';
import { SchoolsOperationComponent } from '../../quality-conditions/schools-operation/schools-operation.component';
import { TeacherTrainingService } from '../../quality-conditions/teacher-training/services/teacher-training.service';
import { TeacherTrainingComponent } from '../../quality-conditions/teacher-training/teacher-training.component';
import { SocialChargesService } from '../../charges-and-additionals/social-charges/services/social-charges.service';
import { SocialChargesComponent } from '../../charges-and-additionals/social-charges/social-charges.component';
import { AdministrativeAreaCostingService } from '../../quality-conditions/administrative-area-costing/services/administrative-area-costing.service';
import { AdministrativeAreaCostingComponent } from '../../quality-conditions/administrative-area-costing/administrative-area-costing.component';
import { AdditionalsService } from '../../charges-and-additionals/additionals/services/additionals.service';
import { AdditionalsComponent } from '../../charges-and-additionals/additionals/additionals.component';

@Injectable({
  providedIn: 'root'
})
export class PqrService {

  constructor(
    private httpService: HttpService,
    private sessionService: SessionService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private schoolDayPerWeekService: SchoolDayPerWeekService,
    private dailyTeachingLoadService: DailyTeachingLoadService,
    private offerGoalEnrollmentFullTimeService: OfferGoalEnrollmentFullTimeService,
    private numberStudentClassService: NumberStudentClassService,
    private workJourneyTeacherService: WorkJourneyTeacherService,
    private careerAndRemunerationTeachersService: CareerAndRemunerationTeachersService,
    private infrastructureSchoolBuildingsService: InfrastructureSchoolBuildingsService,
    private newRoomBuildingService: NewRoomBuildingService,
    private schoolsStaffService: SchoolsStaffService,
    private schoolFeedingService: SchoolFeedingService,
    private schoolsOperationService: SchoolsOperationService,
    private teacherTrainingService: TeacherTrainingService,
    private socialChargesService: SocialChargesService,
    private administrativeAreaCostingService: AdministrativeAreaCostingService,
    private additionalsService: AdditionalsService
  ) { }

  getPqrData(pqrComponentType: Type<PqrComponentData>): Observable<any> {
    switch (pqrComponentType) {
      case SchoolDayPerWeekComponent:
        return this.schoolDayPerWeekService.getData();

      case DailyTeachingLoadComponent:
        return this.dailyTeachingLoadService.getData();

      case OfferGoalEnrollmentFullTimeComponent:
        return this.offerGoalEnrollmentFullTimeService.getData();

      case NumberStudentClassComponent:
        return this.numberStudentClassService.getData();

      case WorkJourneyTeacherComponent:
        return this.workJourneyTeacherService.getData();

      case CareerAndRemunerationTeachersComponent:
        return this.careerAndRemunerationTeachersService.getData(true);

      case InfrastructureSchoolBuildingsComponent:
        return this.infrastructureSchoolBuildingsService.getData(true);

      case NewRoomBuildingComponent:
        return this.newRoomBuildingService.getData(true);

      case SchoolsStaffComponent:
        return this.schoolsStaffService.getData();

      case SchoolFeedingComponent:
        return this.schoolFeedingService.getData();

      case SchoolsOperationComponent:
        return this.schoolsOperationService.getData();

      case TeacherTrainingComponent:
        return this.teacherTrainingService.getData();

      case SocialChargesComponent:
        return this.socialChargesService.getData();

      case AdministrativeAreaCostingComponent:
        return this.administrativeAreaCostingService.getData();

      case AdditionalsComponent:
        return this.additionalsService.getData();
    }
  }

  loadPqr(localVersion: boolean = false): Observable<void> {
    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.get<any>(!localVersion ? `${apiEndpoint}/simulation/pqr` : 'assets/data/pqr.data.json').pipe(
          map(pqr => {
            const pqrData = JSON.parse(pqr.content);
            this.loadPqrToSession(pqrData);
          }));
      }));
  }

  savePqr(pqrData: any): Observable<any> {
    const postBody: any = { content: JSON.stringify(pqrData) };

    return this.httpService.getApiEndpoint().pipe(
      switchMap(apiEndpoint => {
        return this.httpService.put<any>(`${apiEndpoint}/simulation/pqr`, postBody).pipe(
          map(result => {
            if (result && result.msg === 'PQR updated') {
              this.loadPqrToSession(pqrData);
              this.notificationService.showSuccess('PQR atualizado com sucesso');
            }
          },
            (err: any) => this.authService.getAuthErrorHandling(err)
          ));
      }));
  }

  private loadPqrToSession(pqrData: any): void {
    if (pqrData) {
      this.sessionService.clear('pqr');
      Object.keys(pqrData).map(pqrKey => this.sessionService.setItem(pqrKey, pqrData[pqrKey]));
    }
  }

}
