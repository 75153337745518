import { Injectable, ViewContainerRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastrService: ToastrService) { }

  showSuccess(message: string, title?: string, optionsOverride?: any) {
    this.toastrService.success(message, title, optionsOverride);
  }

  showError(message: string, title?: string, optionsOverride?: any) {
    this.toastrService.error(message, title, optionsOverride);
  }

  showWarning(message: string, title?: string, optionsOverride?: any) {
    this.toastrService.warning(message, title, optionsOverride);
  }

  showInfo(message: string, title?: string, optionsOverride?: any) {
    this.toastrService.info(message, title, optionsOverride);
  }

}
