import { Location } from '../../../../shared/entities/location';

export class EmployeeLocation {

  location: Location;
  value: boolean;

  constructor(init?: Partial<EmployeeLocation>) {
    Object.assign(this, init);
  }
}
