import { Stage } from '../../../../shared/entities/stage';

export class StagesSpecificities {

    stage: Stage;
    value: boolean;

    constructor(init?: Partial<StagesSpecificities>) {
        Object.assign(this, init);
    }
}
