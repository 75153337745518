import { Component, OnInit, Input } from '@angular/core';

import { SourceInformation } from './entities/source-information';

@Component({
  selector: 'app-source-information',
  templateUrl: './source-information.component.html',
  styleUrls: ['./source-information.component.scss']
})
export class SourceInformationComponent implements OnInit {

  @Input() title: string = 'Fonte: Elaborado pelo Laboratório de Dados Educacionais a partir dos microdados:';
  @Input() sourceInformation: SourceInformation;
  @Input() sourceInformations: Array<SourceInformation>;

  defaultTitle: string;

  constructor() { }

  ngOnInit(): void {
    this.defaultTitle = this.title;
  }

}
