import { Footnote } from './../../../../shared/components/footnote/entities/footnote';
import { UnitOfMeasurement } from '../../../../shared/entities/unit-of-measurement';
import { Location } from '../../../../shared/entities/location';
import { NavigableComponentData } from '../../../shared/entities/base/navigable-component-data';
import { NewRooms } from './new-rooms';

export class NewRoomsBuildings extends NavigableComponentData {

  newRooms: NewRooms = new NewRooms();
  unitsOfMeasurement: Array<UnitOfMeasurement>;
  locations: Array<Location>;
  sourceInformation: Footnote;
  sourceInformations: Array<Footnote>;

  constructor(init?: Partial<NewRoomsBuildings>) {
    super();
    Object.assign(this, init);
  }
}
