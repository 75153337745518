import { ColumnReportByLocation } from './column-report-by-location';

export class ColumnReport {

  stage_id: number;
  stage_description: string;
  columnsReportByLocations: Array<ColumnReportByLocation>;

  constructor(init?: Partial<ColumnReport>) {
    Object.assign(this, init);
  }
}
