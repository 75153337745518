import { StaffNumberByYear } from './staff-number-by-year';

export class StaffNumberByFunction {

  id: number;
  functionDescription: string;
  staffNumberByYear: Array<StaffNumberByYear>;

  constructor(init?: Partial<StaffNumberByFunction>) {
    Object.assign(this, init);
  }

}
