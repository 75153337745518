export class UserCredential {

    email: string;
    password: string;
    cpf: string;

    constructor(init?: Partial<UserCredential>) {
        Object.assign(this, init);
    }
}
